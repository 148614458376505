import { MonitoringTable } from "@/features/monitoring";
import { Header } from "@/features/standard/Header";
import { Main } from "@/features/standard/Main";
import { HeaderSubTitle } from "@/features/standard/SubTitle";
import { HeaderTitle } from "@/features/standard/Title";
import { Wrapper } from "@/features/standard/Wrapper";

const Monitoring = Object.assign({}, Main, {
    Header: Header,
    HeaderTitle: HeaderTitle,
    HeaderSubTitle: HeaderSubTitle,
    MonitoringWrapper: Wrapper,
    MonitoringTable: MonitoringTable
});

export const MonitoringPage = () => {
    return (
        <Monitoring.MonitoringWrapper>
            <Monitoring.Header>
                <Monitoring.HeaderTitle>
                    PRESS 현황 모니터링
                </Monitoring.HeaderTitle>
                <Monitoring.HeaderSubTitle>
                    우리 회사에서 다루는 PRESS 현황 모니터링을 확인할 수 있습니다.
                </Monitoring.HeaderSubTitle>
            </Monitoring.Header>
            <Monitoring.MonitoringTable />
        </Monitoring.MonitoringWrapper>
    )
}