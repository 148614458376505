import { WorkByProductionStateContext } from "@/features/workByProductionPlan/context/WorkByProductionProvider";
import { useContext } from "react";

export function useWorkByProductionState() {
  const value = useContext(WorkByProductionStateContext);
  if (value === undefined) {
    throw new Error("useCounterState should be used within CounterProvider");
  }
  return value;
}
