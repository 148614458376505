// Operation 페이지의 유효성 검사 함수

type PageType =
  | "items"
  | "equipment"
  | "defects"
  | "routings"
  | "operation"
  | "users"
  | "site"
  | "location"
  | "operationOutsource"
  | "inspectSpecification"
  | "mold"
  | "moldStock"
  | "abstractItem"
  | "itemCategory"
  | "downtimeReasons"
  | "inputMaterial"
  | "incomingAll"
  | "purchaseOrderItems"
  | "material"
  ;

interface OperationFormValues {
  pageType: PageType;
  values: any; // TODO: 타입 지정
}

// 페이지별 유효성 검사
export function validateFormValues(params: OperationFormValues) {
  const { pageType, values } = params;

  switch (pageType) {
    case "items":
      return !(values.itemCode && values.code);
    case "equipment":
      return !(values.siteCode && values.code && values.name);
    case "defects":
      return !(values.code && values.name);
    case "routings":
      return !(
        values.siteCode &&
        values.code &&
        values.fromLocationCode &&
        values.toLocationCode
      );
    case "operation":
      return !(
        values.itemCode &&
        values.routingCode &&
        values.code &&
        values.operationType &&
        values.consumeType &&
        values.seq
      );
    case "users":
      return !(values.code && values.name && values.username);
    case "site":
      return !(values.code && values.name);
    case "location":
      return !(values.code && values.name && values.siteCode);
    case "operationOutsource":
      return !(
        values.name &&
        values.operationCode &&
        values.fromLocationCode &&
        values.toLocationCode
      );
    case "inspectSpecification":
      return !(values.itemCode && values.criteria);
    case "mold":
      return !values.code;
    case "moldStock":
      return !(values.code && values.moldCode);
    case "abstractItem":
      return !values.code;
    case "itemCategory":
      return !values.name;
    case "downtimeReasons":
      return !(values.code && values.name);
    case "inputMaterial":
      return !(values.lotId && values.sourceLocationCode);
    case "incomingAll":
      return !(values.itemCode && values.locationCode && values.quantity && values.lotInfo.name && values.num);
    case "purchaseOrderItems":
      return !(values.orderDate && values.deliveryDeadline && values.itemCode && values.quantityOrdered &&values.contractorName);
    case "material":
      return !(values.code && values.name && values.itemType && values.unitText);
    default:
      break;
  }

  return false;
}
