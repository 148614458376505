import { items } from "@/api/items/useItemQuery";
import useGetItemOperationsQuery from "@/api/useGetOperationQuery";
import useWorkSitesQuery from "@/api/useGetSitesQuery";
import { PlanningTable } from "@/features/ui/table/PlanningTable";
import { useWorkByEquipmentState } from "@/features/workByEquipment/hook/useWorkByEquipmentState";
import { useWorkByProductionState } from "@/features/workByProductionPlan/hook/useWorkByProductionState";
import { useModal, useStepper } from "@/hooks";
import { theme } from "@/styles/theme";
import styled from "@emotion/styled";
import {
  Button,
  CloseButton,
  Flex,
  Select,
  Stepper,
  Text,
  TextInput,
} from "@mantine/core";
import { DateInput } from "@mantine/dates";
import { ItemsGet200ResponseRowsInnerOperationsInner } from "@sizlcorp/sizl-api-document/dist/models";
import { IconCalendar } from "@tabler/icons-react";
import dayjs from "dayjs";
import { useEffect, useRef, useState } from "react";
import { useQuery } from "react-query";

export interface ProductionPlanInput {
  date?: Date | string;
  workplace?: string;
  product?: string; //ItemsGet200ResponseRowsInner | null;
  targetQuantity?: string;
}

export function CreatePlanningModal({ pageKey }: any) {
  const inputRef = useRef<{
    date: HTMLInputElement | null;
    workplace: HTMLInputElement | null;
    product?: HTMLInputElement | null;
    targetQuantity?: HTMLInputElement | null;
  }>({
    date: null,
    workplace: null,
    product: null,
    targetQuantity: null,
  });

  const stateObj: any = {
    equipment: useWorkByEquipmentState(),
    plan: useWorkByProductionState(),
  };

  const state: any = stateObj[pageKey];

  const [productionPlanInput, setProductionPlanInput] =
    useState<ProductionPlanInput>({
      date: state?.date ?? new Date(),
      workplace: state?.site ?? "",
      product: "",
      targetQuantity: "0",
    });

  // const [item, setItem] = useState<ItemsGet200ResponseRowsInner>({});
  const [itemCode, setItemCode] = useState<string>("");

  const { active = 0, setActive, next: nextStep } = useStepper();
  const { modal, closeModal: onClose } = useModal();

  const { data: itemList } = useQuery(items.get());
  const itemData = itemList?.data.rows;
  const productList = itemData?.map((d) => d.code);
  const { data: siteData } = useWorkSitesQuery();
  const sites = siteData?.data.rows;
  const siteList = sites?.map((d) => d.name);

  const { data: operations } = useGetItemOperationsQuery({
    itemCode: itemCode,
    quantity: productionPlanInput.targetQuantity ?? "",
  });

  let operationInputs: (ProductionPlanInput &
    ItemsGet200ResponseRowsInnerOperationsInner)[]; // Fix: Removed incomplete statement

  if (Array.isArray(operations?.data)) {
    operationInputs = operations?.data.map(
      (operation: ItemsGet200ResponseRowsInnerOperationsInner) => {
        return { ...operation, ...productionPlanInput, isItemOperation: true };
      }
    );
  } else if (operations?.data && !operations?.data.isExist) {
    operationInputs = [
      { ...operations?.data, ...productionPlanInput, isItemOperation: true },
    ];
  }
  const goNextStep = () => {
    setProductionPlanInput({
      date: inputRef.current["date"]?.value, // productionPlanInput.date,
      workplace: inputRef.current["workplace"]?.value, //
      product: inputRef.current["product"]?.value, // item,
      targetQuantity: inputRef.current["targetQuantity"]?.value,
    });
    nextStep();
  };

  useEffect(() => {
    setActive(0);
  }, [modal?.isOpen]);

  const FirstStepper = () => {
    // console.log("시작 : " + inputRef.current["date"]?.value);
    // console.log(dayjs(inputRef.current["date"]?.value).toDate());
    console.log("시작?");
    console.log(dayjs(inputRef.current["date"]?.value).toISOString());
    return (
      <>
        <PlanContainer>
          <PlanContent>
            <DateLine
              icon={<IconCalendar size="1.2rem" />}
              defaultValue={
                dayjs(inputRef.current["date"]?.value).toDate() ?? new Date()
              }
              ref={(el: any) => {
                // console.log(el);
                inputRef.current["date"] = el;
              }}
              valueFormat="YYYY-MM-DD"
              label="계획일자"
              name="date"
              description="생산 예정일자를 선택합니다."
              size="md"
              mr={12}
              onChange={(e: any) => {
                // console.log(e);
                console.log(inputRef.current["date"]);
                inputRef.current["date"] = e; //dayjs(e).format("YYYY-MM-DD").toString();
                // console.log("change : " + inputRef.current["date"]);

                // console.log(dayjs(inputRef.current["date"]?.value));

                // console.log(dayjs(e).format("YYYY-MM-DD"));
                // inputRef.current["date"].value = e.target.value;
              }}
            />
            <SelectLine
              defaultValue={inputRef.current["workplace"]}
              ref={(el: any) => (inputRef.current["workplace"] = el)}
              label="사업장"
              description="생산할 사업장을 선택합니다"
              placeholder="Pick value"
              data={siteList}
              size="md"
              ml={12}
              allowDeselect
              onChange={(e: any) => {
                console.log(e);
              }}
            />
          </PlanContent>
          <PlanContent>
            <SelectLine
              defaultValue={inputRef.current["product"] ?? ""}
              ref={(el: any) => {
                inputRef.current["product"] = el;
              }}
              label="품목 선택"
              description="생산할 품목을 선택합니다."
              placeholder="Pick value"
              data={itemData
                ?.filter((i) => i.itemType == "PRODUCT")
                .map((v) => v.code)} // itemList?.map((item) => item.name) ?? []
              size="md"
              allowDeselect
              mr={12}
              onChange={(value: string) => {
                const code =
                  value && productList?.filter((item) => item === value)[0];
                if (code) {
                  setItemCode(code);
                }
              }}
            />
            <TextLine
              ref={(el: any) => (inputRef.current["targetQuantity"] = el)}
              name="targetQuantity"
              label="목표 수량 "
              description="생산 목표 수량을 입력합니다."
              placeholder="Focus me to see tooltip"
              size="md"
              ml={12}
            />
          </PlanContent>
          <Flex justify="center" align="center">
            <WarningOperation operations={operations} />
          </Flex>
          <SaveButton
            disabled={!operations || operations?.data.length < 1}
            onClick={goNextStep}
          >
            저장
          </SaveButton>
        </PlanContainer>
      </>
    );
  };

  const SecondStepper = () => {
    return (
      <SecondContainer>
        <PlanningTable data={operationInputs} type={pageKey} />
      </SecondContainer>
    );
  };

  return (
    <>
      {modal?.isOpen && modal.modalType === "CreatePlanning" && (
        <ModalContainer active={active}>
          <Flex align="center">
            <Text fz="lg" mb={12} mr="auto">
              생산 계획 생성
            </Text>
            <CloseButton
              size="xl"
              iconSize={32}
              aria-label="Close modal"
              onClick={onClose}
            />
          </Flex>
          <StepperTitleOnActive active={active} />
          <Stepper
            active={active}
            onStepClick={setActive}
            breakpoint="sm"
            allowNextStepsSelect={false}
            mt={20}
          >
            <Stepper.Step label="생산 계획정보 입력" description="Description">
              <FirstStepper />
            </Stepper.Step>
            <Stepper.Step
              label="생성될 작업지시 확인"
              description="Description"
            >
              <SecondStepper />
            </Stepper.Step>
            <Stepper.Completed>Completed !!</Stepper.Completed>
          </Stepper>
        </ModalContainer>
      )}
    </>
  );
}

const WarningOperation = ({ operations }: any) =>
  operations?.data.length < 1 ? (
    <WarningText>
      {
        "품목을 생산하기 위한 공정이 설정되어 있지 않아 생산을 계획할 수 없습니다."
      }
    </WarningText>
  ) : (
    <></>
  );

const StepperTitleOnActive = ({ active }: { active: number }) =>
  active === 0 ? (
    <>
      <Text size="sm">새로운 생산 계획을 생성합니다.</Text>
      <Text size="sm">
        품목의 공정 정보를 통해 작업 지시를 자동으로 생성합니다.
      </Text>
    </>
  ) : (
    <Text size="sm">품목 2 (P02)의 공정별 작업지시를 생성합니다.</Text>
  );

const ModalContainer = styled.div<{ active: number }>`
  position: fixed;
  width: ${(props) => (props.active === 1 ? "786px" : "492px")};
  height: auto;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 20px;
  background-color: ${theme.colors?.white?.[1]};
  border: 1px solid #ccc;
  z-index: 100;
`;

const PlanContainer = styled.div`
  width: 100%;
  height: 100%;
`;

const PlanContent = styled.div`
  width: 100%;
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
  margin: 8px auto;
`;

const DateLine = styled<any>(DateInput)`
  width: 48%;
`;
const TextLine = styled<any>(TextInput)`
  width: 48%;
`;
const SelectLine = styled<any>(Select)`
  width: 48%;
`;

const SaveButton = styled<any>(Button)`
  margin-top: 16px;
  width: 100%;
  color: ${theme.colors?.blue?.[6]};
  background-color: ${theme.colors?.white};
  border: 1px solid ${theme.colors?.blue?.[6]};
  height: 32px;
  font-size: 14px;

  &:hover {
    background-color: ${theme.colors?.blue?.[0]};
  }
`;

const SecondContainer = styled.div`
  width: 100%;
  height: auto;
`;

const WarningText = styled<any>(Text)`
  color: var(--red-6, #fa5252);
  /* components/input/error/xs */
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 12px; /* 120% */
`;
