import { DetailBadge } from "@/features/ui/detail/DetailBadge";
import { DetailContent } from "@/features/ui/detail/DetailContent";
import { DetailField } from "@/features/ui/detail/DetailField";
import { DetailHeader } from "@/features/ui/detail/DetailHeader";
import { DetailInformTitle } from "@/features/ui/detail/DetailInformTitle";
import { DetailLabel } from "@/features/ui/detail/DetailLabel";
import { DetailLink } from "@/features/ui/detail/DetailLink";
import { DetailLinkWrapper } from "@/features/ui/detail/DetailLinkWrapper";
import { DetailTitle } from "@/features/ui/detail/DetailTitle";
import { Space } from "@mantine/core";
import { ItemCategoriesGet200Response } from "@sizlcorp/sizl-api-document/dist/models";
import { AxiosResponse } from "axios";

const DetailItemCategory = Object.assign(DetailHeader, {
    Title: DetailTitle,
    Inform: DetailInformTitle,
    Field: DetailField,
    Label: DetailLabel,
    Link: DetailLink,
    LinkWrapper: DetailLinkWrapper,
    Badge: DetailBadge,
    Content: DetailContent,
});

interface AbstractItemDetailHeaderProps {
    data : AxiosResponse<ItemCategoriesGet200Response, any[]> | undefined;
}

export const ItemCategoryDetailHeader = (params : AbstractItemDetailHeaderProps) => {
    const { data } = params;

    const abstractItem = data?.data?.rows && data?.data?.rows[0];

    return (
        <DetailItemCategory>
            <DetailItemCategory.Title>
                {"[품목분류 상세]"}
            </DetailItemCategory.Title>
            <Space h={8} />
            <DetailItemCategory.Field>
                <DetailItemCategory.Label>
                    {"품목분류 명"}
                </DetailItemCategory.Label>
                <DetailItemCategory.Content>
                    {abstractItem?.name}
                </DetailItemCategory.Content>
            </DetailItemCategory.Field>
        </DetailItemCategory>
    )
}