import { theme } from "@/styles/theme"
import { Text } from "@mantine/core"

export const WorkProductionPlanTableHead = () => {
    return (
        <thead>
            <tr>
                <th></th>
                <th>
                    <Text fz="sm" fw={500} c="black" ta="center">
                        품목명
                    </Text>
                </th>
                <th>
                    <Text fz="sm" fw={500} c="black" ta="center">
                        품목코드
                    </Text>
                </th>
                <th>
                    <Text fz="sm" fw={500} color={theme.colors?.blue?.[9]} ta="center">
                        목표수량 / 완료수량
                    </Text>
                </th>
                <th>
                    <Text fz="sm" fw={500} c="black" ta="center">
                        생산계획코드
                    </Text>
                </th>
            </tr>
        </thead>
    )
}