import { OperationFormProps } from "@/features/item/components/form/ItemsCreateForm";
import { OperationBomRow } from "@/features/item/components/form/itemsStepperForm/operationsBomList/OperationBomRow";
import { OperationRow } from "@/features/item/components/form/itemsStepperForm/operationsBomList/OperationRow";
import { theme } from "@/styles/theme";
import styled from "@emotion/styled";
import { Table, Text } from "@mantine/core";
import { ItemsGet200ResponseRowsInnerOperationsInner } from "@sizlcorp/sizl-api-document/dist/models";

const Thead = styled.thead`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
`;
const Tbody = styled.tbody`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
`;
const Tr = styled.tr`
  display: flex;
  width: 100%;
  min-width: 818px;
  align-items: flex-start;
  align-self: stretch;
`;

const Th = styled.th<{ width?: number }>`
  display: flex;
  width: ${(props) => (props.width ? `${props.width}%` : "auto")};
  padding: 7px 10px;
  align-items: flex-start;
  gap: 10px;
`;

const OperationListContainer = styled.div`
  width: 75rem;
`;

const LeftSpace = styled.span`
  width: 32px;
  align-self: stretch;
  background: ${theme.colors?.gray?.[3]};
  border-bottom: ${theme.colors?.gray?.[3]} solid 1px;
  opacity: 0.2;
`;

const RightSpace = styled.span`
  flex: 1 0 0;
  align-self: stretch;
  background: ${theme.colors?.gray?.[3]};
  border-bottom: ${theme.colors?.gray?.[3]} solid 1px;
  opacity: 0.2;
`;

const ItemTabBodyContainer = styled.div`
  display: flex;
  padding: 16px 32px;
  flex-direction: column;
  align-items: flex-start;
  flex: 1 0 0;
  align-self: stretch;
`;

const OperationTitle = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
`;

export const OperationBomList = (params: {
  formData: Partial<OperationFormProps>;
}) => {
  const form = params.formData;

  const rows = form.operationRows;

  return (
    <OperationListContainer>
      <ItemTabBodyContainer>
        <OperationTitle>
          <Text fz="xl" fw={500}>
            공정
          </Text>
          <Text fz="xs" fw={400}>
            품목을 생산하기 위한 공정과 공정에 필요한 BOM들을 보여줍니다.
          </Text>
        </OperationTitle>
        <Table>
          <Thead>
            <Tr>
              <Th width={4}>&nbsp;</Th>
              <Th width={9}>시퀀스</Th>
              <Th width={11}>라우팅명</Th>
              <Th width={13}>공정코드</Th>
              <Th width={9}>공정타입</Th>
              <Th width={9}>소모 시점</Th>
              <Th width={45}>금형</Th>
            </Tr>
          </Thead>
          <Tbody>
            {rows &&
              rows.map(
                (
                  data: ItemsGet200ResponseRowsInnerOperationsInner,
                  i: number
                ) => {
                  return (
                    data && (
                      <OperationRow data={data} key={data.id}>
                        {data?.operationBoms?.length &&
                          Object.keys(data.operationBoms[0]).length && (
                            <OperationBomRow
                              operationBoms={data.operationBoms ?? []}
                            />
                          )}
                      </OperationRow>
                    )
                  );
                }
              )}
          </Tbody>
        </Table>
      </ItemTabBodyContainer>
    </OperationListContainer>
  );
};
