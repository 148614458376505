import useWorksMutation from "@/api/works/useWorksMutation";
import { useModal } from "@/features/modal/ModalStackManager";
import { EquipmentAutoComplete } from "@/features/ui/autoComplete/equipment/equipment-autoComplete";
import { Button, Flex, Table, TextInput } from "@mantine/core";
import { DateInput } from "@mantine/dates";
import { useForm } from "@mantine/form";
import {
  AuthSignupPost201ResponseEquipmentWorksInner,
  DefaultApiProductionPlansProductionPlanIdPutRequest,
  DefaultApiWorksWorkIdPutRequest,
  ProductionPlansGet200ResponseRowsInnerWorksInner,
  WorksWorkIdDeleteRequest,
} from "@sizlcorp/sizl-api-document/dist/models";
import { IconCalendar } from "@tabler/icons-react";

type PageKeyType = 'equipment' | 'plan'; // pageKey의 가능한 값들을 정의합니다.

type ItemType<T extends PageKeyType> = T extends 'equipment'
  ? AuthSignupPost201ResponseEquipmentWorksInner : ProductionPlansGet200ResponseRowsInnerWorksInner; // pageKey에 따른 item의 타입을 정의합니다.

interface WorkUpdateFormProps<T extends PageKeyType> {
  workRowData: ItemType<T>;
}

export function WorkUpdateForm<T extends PageKeyType>(params: WorkUpdateFormProps<T>): JSX.Element {

  const workData = params.workRowData;

  const id = workData?.id!;
  const productionPlanId = workData?.productionPlanId!;
  const operationCode = workData?.operationCode!;
  const equipmentCode = (workData as ProductionPlansGet200ResponseRowsInnerWorksInner)?.equipmentCode!;
  const targetQuantity = workData?.targetQuantity!;
  const scheduledAt = (workData as ProductionPlansGet200ResponseRowsInnerWorksInner)?.scheduledAt!;

  const { closeModal } = useModal();
  const { mutate: updateMutate } = useWorksMutation("update");
  const form = useForm<
    Partial<
      DefaultApiWorksWorkIdPutRequest &
      DefaultApiProductionPlansProductionPlanIdPutRequest
    >
  >({
    initialValues: {
      workId: id,
      worksWorkIdDeleteRequest: {
        operationCode,
        equipmentCode,
        targetQuantity,
        scheduledAt,
      },
      productionPlanId,
      productionPlansProductionPlanIdDeleteRequest: {},
    },
  });

  const inputProps = form.getInputProps("worksWorkIdDeleteRequest.scheduledAt");
  inputProps.value = new Date(inputProps.value);

  const onSave = () => {
    const { workId, worksWorkIdDeleteRequest, productionPlanId } = form.values;

    updateMutate(
      {
        workId: workId as number,
        worksWorkIdDeleteRequest:
          worksWorkIdDeleteRequest as WorksWorkIdDeleteRequest,
        productionPlanId: productionPlanId as number,
        productionPlansProductionPlanIdDeleteRequest: {
          scheduledAt: worksWorkIdDeleteRequest?.scheduledAt!,
          targetQuantity: worksWorkIdDeleteRequest?.targetQuantity!,
        },
      },
      {
        onSuccess: () => {
          alert("작업 지시가 수정되었습니다.");
          form.reset();
          closeModal(null);
        },
      }
    );
  };

  return (
    <>
      <Table>
        <thead>
          <tr>
            <th>일자</th>
            <th>공정</th>
            <th>설비</th>
            <th>목표수량</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <DateInput
                popoverProps={{ withinPortal: true }}
                icon={<IconCalendar size="1.2rem" />}
                valueFormat="YYYY-MM-DD"
                {...inputProps}
              />
            </td>
            <td>{operationCode}</td>
            <td>
              {" "}
              <EquipmentAutoComplete
                width="384px"
                placeholder="설비 선택"
                {...form.getInputProps(
                  "worksWorkIdDeleteRequest.equipmentCode"
                )}
              />
            </td>
            <td>
              <TextInput
                placeholder="목표수량"
                type="number"
                {...form.getInputProps(
                  "worksWorkIdDeleteRequest.targetQuantity"
                )}
              />
            </td>
          </tr>
        </tbody>
      </Table>
      <Flex justify="flex-end">
        <Button onClick={onSave}>수정</Button>
      </Flex>
    </>
  );
};
