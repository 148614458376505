import { modalAtom } from "@/recoil/modalAtom";
import { useRecoilState } from "recoil";

export interface ModalType {
  isOpen?: boolean;
  modalType: any;
  param?: any;
}

export const useModal = () => {
  const [modal, setModal] = useRecoilState(modalAtom);

  const openModal = ({ modalType, param }: ModalType) => {
    setModal({ modalType, isOpen: true, param });
  };

  const closeModal = () => {
    setModal(null);
  };

  return { modal, openModal, closeModal };
};
