import { InspectionRecordTable } from "@/features/inspectionRecords/table";
import { Header } from "@/features/standard/Header";
import { Main } from "@/features/standard/Main";
import { HeaderSubTitle } from "@/features/standard/SubTitle";
import { HeaderTitle } from "@/features/standard/Title";
import { Wrapper } from "@/features/standard/Wrapper";

const InspectionRecords = Object.assign({}, Main, {
    Header: Header,
    HeaderTitle: HeaderTitle,
    HeaderSubTitle: HeaderSubTitle,
    SpecificationsWrapper: Wrapper,
    InspectionRecordsTable: InspectionRecordTable,
});

export const InspectionRecordsPage = () => {

    return (
        <InspectionRecords.SpecificationsWrapper>
            <InspectionRecords.Header>
                <InspectionRecords.HeaderTitle>
                    검사 기록
                </InspectionRecords.HeaderTitle>
                <InspectionRecords.HeaderSubTitle>
                    우리 회사에서 다루는 모든 검사 기록을 확인할 수 있습니다.
                </InspectionRecords.HeaderSubTitle>
            </InspectionRecords.Header>
            <InspectionRecords.InspectionRecordsTable />
        </InspectionRecords.SpecificationsWrapper>
    );
};