import { mutatePurchaseOrderItems } from "@/api/purchaseOrderItems/usePurchaseOrderItems";
import { useModal } from "@/features/modal/ModalStackManager";
import { FormButtonBox } from "@/features/standard/Form/FormButtonBox";
import { FormSelect } from "@/features/standard/Form/FormSelect";
import { FormWrapper } from "@/features/standard/Form/FormWrapper";
import { Main } from "@/features/standard/Main";
import customAlert from "@/features/ui/alert/alert";
import { ItemAutoComplete } from "@/features/ui/autoComplete/item/item-autoComplete";
import { validateFormValues } from "@/utils/validation";
import { Button, TextInput } from "@mantine/core";
import { DateInput } from "@mantine/dates";
import { useForm } from "@mantine/form";
import { AuthSignupPost201ResponseEquipmentPurchaseOrderItem, ScmApiPurchaseOrderItemsPurchaseOrderItemIdPutRequest } from "@sizlcorp/sizl-api-document/dist/models";
import { IconCalendar } from "@tabler/icons-react";
import { useMutation, useQueryClient } from "react-query";

const Update = Object.assign({}, Main, {
    Button: Button,
    Input: TextInput,
    Select: FormSelect,
    Wrapper: FormWrapper,
    ButtonBox: FormButtonBox,
    DateInput: DateInput
});

interface PurchaseOrderItemUpdateFormProps {
    formatterProps: AuthSignupPost201ResponseEquipmentPurchaseOrderItem;
}

export const PurchaseOrderItemUpdateForm = (params: PurchaseOrderItemUpdateFormProps) => {
    const { formatterProps } = params;
    const queryClient = useQueryClient();
    const { mutate: updateMutate } = useMutation(
        (params: ScmApiPurchaseOrderItemsPurchaseOrderItemIdPutRequest) =>
            mutatePurchaseOrderItems.update(params).mutationFn(params as ScmApiPurchaseOrderItemsPurchaseOrderItemIdPutRequest | any),
        {
            onSuccess: () => {
                queryClient.invalidateQueries('purchaseOrderItems');
            }
        }
    );

    const { closeModal } = useModal();

    const form = useForm({
        initialValues: {
            orderDate: formatterProps.orderDate ? new Date(formatterProps.orderDate) : null,
            deliveryDeadline: formatterProps.deliveryDeadline ? new Date(formatterProps.deliveryDeadline) : null,
            itemCode: formatterProps.itemCode,
            quantityOrdered: formatterProps.quantityOrdered,
            contractorName: formatterProps.contractorName,
        }
    });

    const onSubmit = async () => {
        try {
            updateMutate({
                purchaseOrderItemId: formatterProps.id ?? 0,
                purchaseOrderItemsPurchaseOrderItemIdDeleteRequest: {
                    orderDate: form.values.orderDate ? form.values.orderDate.toISOString() : undefined!,
                    deliveryDeadline: form.values.deliveryDeadline?.toISOString()!,
                    itemCode: form.values.itemCode!,
                    quantityOrdered: form.values.quantityOrdered!,
                    contractorName: form.values.contractorName!,
                }
            }, {
                onSuccess: (res) => {
                    closeModal(formatterProps);
                    if (res.status === 200) {
                        customAlert(
                            "수주가 성공적으로 수정되었습니다.",
                            "수주 수정 성공",
                            "green",
                        )
                    } else {
                        customAlert(
                            "수주 수정에 실패하였습니다.",
                            "수주 수정 실패",
                            "red",
                        )
                    }
                }
            });
        } catch (e) {
            console.error(e);
        }
    };

    return (
        <>
            <Update.Wrapper>
                <Update.Input
                    label="거래처"
                    placeholder="거래처 입력"
                    defaultValue={formatterProps.contractorName}
                    {...form.getInputProps("contractorName")}
                    error={form.isDirty("contractorName") ? "저장 필요" : ""}
                    styles={{
                        input: {
                            color: form.isDirty("contractorName") ? "cadetblue !important" : "inherit",
                            borderColor: form.isDirty("contractorName") ? "cadetblue !important" : "inherit",
                        },
                        error: { fontSize: 11, color: "cadetblue" }
                    }}
                />
                <ItemAutoComplete
                    label="품목코드"
                    placeholder="품목 선택"
                    defaultValue={formatterProps.itemCode}
                    {...form.getInputProps("itemCode")}
                    error={form.isDirty("itemCode") ? "저장 필요" : ""}
                    styles={{
                        input: {
                            color: form.isDirty("itemCode") ? "cadetblue !important" : "inherit",
                            borderColor: form.isDirty("itemCode") ? "cadetblue !important" : "inherit",
                        },
                        error: { fontSize: 11, color: "cadetblue" }
                    }}
                />
                <Update.Input
                    label="수주 수량"
                    placeholder="수주 수량 입력"
                    defaultValue={formatterProps.quantityOrdered}
                    {...form.getInputProps("quantityOrdered")}
                    error={form.isDirty("quantityOrdered") ? "저장 필요" : ""}
                    styles={{
                        input: {
                            color: form.isDirty("quantityOrdered") ? "cadetblue !important" : "inherit",
                            borderColor: form.isDirty("quantityOrdered") ? "cadetblue !important" : "inherit",
                        },
                        error: { fontSize: 11, color: "cadetblue" }
                    }}
                />
                <Update.DateInput
                    popoverProps={{ withinPortal: true }}
                    icon={<IconCalendar size="1.2rem" />}
                    label="수주 접수일"
                    valueFormat="YYYY-MM-DD"
                    {...form.getInputProps("orderDate")}
                    error={form.isDirty("orderDate") ? "저장 필요" : ""}
                    styles={{
                        input: {
                            color: form.isDirty("orderDate") ? "cadetblue !important" : "inherit",
                            borderColor: form.isDirty("orderDate") ? "cadetblue !important" : "inherit",
                        },
                        error: { fontSize: 11, color: "cadetblue" }
                    }}
                />
                <Update.DateInput
                    popoverProps={{ withinPortal: true }}
                    icon={<IconCalendar size="1.2rem" />}
                    label="납기일"
                    valueFormat="YYYY-MM-DD"
                    {...form.getInputProps("deliveryDeadline")}
                    error={form.isDirty("deliveryDeadline") ? "저장 필요" : ""}
                    styles={{
                        input: {
                            color: form.isDirty("deliveryDeadline") ? "cadetblue !important" : "inherit",
                            borderColor: form.isDirty("deliveryDeadline") ? "cadetblue !important" : "inherit",
                        },
                        error: { fontSize: 11, color: "cadetblue" }
                    }}
                />
                <Update.ButtonBox>
                    <Update.Button color="gray" onClick={closeModal}>취소</Update.Button>
                    <Update.Button
                        disabled={validateFormValues({
                            pageType: "purchaseOrderItems",
                            values: form.values,
                        })}
                        onClick={onSubmit}
                    >
                        수주 수정
                    </Update.Button>
                </Update.ButtonBox>
            </Update.Wrapper>
        </>
    )
};