import styled from "@emotion/styled";
import { Button, Select, TextInput } from "@mantine/core";
// // TODO: formData 타입 정의
// import { OperationsGetRequest } from "@sizlcorp/sizl-api-document/dist/models";
import { consumeType, operationType } from "@/constants/operations";
import { MoldAutoComplete } from "@/features/ui/autoComplete/mold/mold-autoComplete";
import { RoutingAutoComplete } from "@/features/ui/autoComplete/routing/routing-autoComplete";
import { theme } from "@/styles/theme";
import { IconTrashX } from "@tabler/icons-react";
import { useTranslation } from "react-i18next";

const Tr = styled.tr`
  display: flex;
  align-items: flex-start;
  align-self: stretch;
  width: 100%;
`;

const Td = styled.td<{ width?: number }>`
  display: flex;
  width: ${(props) => (props.width ? `${props.width}%` : "auto")};
  padding: 7px 10px;
  align-items: flex-start;
  gap: 10px;
`;

const TdMold = styled(Td)`
  flex: 1 0 0;
`;

const DeleteButton = styled<any>(Button)`
  color: black;
  background-color: ${theme.colors?.white};
  font-size: 14px;

  &:hover {
    background-color: ${theme.colors?.blue?.[0]};
  }
`;

const TextInputWidth = styled(TextInput)`
  width: 100%;
`;

// // TODO: formData 타입 정의
export const TableRow = ({ formIndex, formData }: any) => {
  const { t } = useTranslation();

  const lnOperationType = operationType.map((item: any) => ({
    ...item,
    label: t(item.label),
  }));

  const lnConsumeType = consumeType.map((item: any) => ({
    ...item,
    label: t(item.label),
  }));

  const handleClickDelete = (index: number) => {
    formData.removeListItem("operationRows", index);
  };

  return (
    <>
      <Tr key={formIndex}>
        <Td width={6}>
          <TextInputWidth
            type="number"
            {...formData.getInputProps(`operationRows.${formIndex}.seq`)}
          />
        </Td>
        <Td width={18}>
          <RoutingAutoComplete
            {...formData.getInputProps(
              `operationRows.${formIndex}.routingCode`
            )}
          />
        </Td>
        <Td width={12}>
          <TextInputWidth
            placeholder="공정코드 입력"
            {...formData.getInputProps(`operationRows.${formIndex}.code`)}
          />
        </Td>
        <Td width={11}>
          <Select
            data={lnOperationType}
            {...formData.getInputProps(
              `operationRows.${formIndex}.operationType`
            )}
          ></Select>
        </Td>
        <Td width={11}>
          <Select
            data={lnConsumeType}
            {...formData.getInputProps(
              `operationRows.${formIndex}.consumeType`
            )}
          ></Select>
        </Td>
        <TdMold width={34}>
          <MoldAutoComplete
            {...formData.getInputProps(`operationRows.${formIndex}.moldCode`)}
          />
        </TdMold>
        <Td width={8}>
          <DeleteButton
            leftIcon={<IconTrashX />}
            disabled={!(formData.values.operationRows.length > 1)}
            onClick={() => handleClickDelete(formIndex)}
          >
            삭제
          </DeleteButton>
        </Td>
      </Tr>
    </>
  );
};
