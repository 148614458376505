import { DowntimeReasonsCreateForm } from "@/features/downtimeReasons/form/CreateForm";
import { DowntimeReasonsTable } from "@/features/downtimeReasons/table";
import { useModal } from "@/features/modal/ModalStackManager";
import { ActionHeader } from "@/features/standard/ActionHeader";
import { Header } from "@/features/standard/Header";
import { Main } from "@/features/standard/Main";
import { HeaderSubTitle } from "@/features/standard/SubTitle";
import { HeaderTitle } from "@/features/standard/Title";
import { Wrapper } from "@/features/standard/Wrapper";
import { usePub } from "@/hooks";
import { Button } from "@mantine/core";
import { IconPlus, IconTrash } from "@tabler/icons-react";

const downtimeReasons = Object.assign({}, Main, {
  Header: Header,
  HeaderTitle: HeaderTitle,
  HeaderSubTitle: HeaderSubTitle,
  Wrapper: Wrapper,
  ActionHeader: ActionHeader,
  ActionButton: Button,
  CreateForm: DowntimeReasonsCreateForm,
});

export const DowntimeReasonsPage = () => {
  const { openModal } = useModal();

  const publish = usePub();

  return (
    <downtimeReasons.Wrapper>
      <downtimeReasons.ActionHeader>
        <downtimeReasons.ActionButton
          leftIcon={<IconPlus />}
          onClick={() =>
            openModal(
              <downtimeReasons.CreateForm />,
              null,
              "비가동사유 추가",
              true
            )
          }
        >
          추가
        </downtimeReasons.ActionButton>
        <downtimeReasons.ActionButton
          color="red"
          rightIcon={<IconTrash />}
          onClick={() => publish("deleteSelectedItems")}
        >
          선택된 행 삭제
        </downtimeReasons.ActionButton>
      </downtimeReasons.ActionHeader>
      <downtimeReasons.Header>
        <downtimeReasons.HeaderTitle>비가동사유</downtimeReasons.HeaderTitle>
        <downtimeReasons.HeaderSubTitle>
          우리 회사에서 다루는 비가동사유에 대해 조회할 수 있는 페이지 입니다.
        </downtimeReasons.HeaderSubTitle>
      </downtimeReasons.Header>
      <DowntimeReasonsTable />
    </downtimeReasons.Wrapper>
  );
};
