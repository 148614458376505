import { createContext, useMemo, useState } from "react";

export interface WorkByEquipmentContextProps {
  site?: string;
  line?: string;
  date: Date | string;
  defectCodes: string[] | null;
}

export const WorkByEquipmentStateContext = createContext({
  site: "",
  line: "",
  date: new Date(),
  defectCodes: null,
});
export const WorkByEquipmentActionsContext = createContext("");

export const WorkByEquipmentProvider = ({ children }: any) => {
  const [workByEquipmentState, setWorkByEquipmentState]: any =
    useState<WorkByEquipmentContextProps>({
      site: "",
      line: "",
      date: new Date(),
      defectCodes: null,
    });

  const actions: any = useMemo(
    () => ({
      setDate: (date: Date) => {
        setWorkByEquipmentState((prev: any) => ({
          ...prev,
          date,
        }));
      },
      setSite: (site: string) => {
        setWorkByEquipmentState((prev: any) => ({
          ...prev,
          site,
        }));
      },
      setLine: (line: string) => {
        setWorkByEquipmentState((prev: any) => ({
          ...prev,
          line,
        }));
      },
      setDefectReason: (defectCodes: string[]) => {
        setWorkByEquipmentState((prev: any) => ({
          ...prev,
          defectCodes: defectCodes,
        }));
      },
    }),
    []
  );

  return (
    <WorkByEquipmentActionsContext.Provider value={actions}>
      <WorkByEquipmentStateContext.Provider value={workByEquipmentState}>
        {children}
      </WorkByEquipmentStateContext.Provider>
    </WorkByEquipmentActionsContext.Provider>
  );
};
