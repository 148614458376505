import { DetailContent } from "@/features/ui/detail/DetailContent";
import { DetailField } from "@/features/ui/detail/DetailField";
import { DetailHeader } from "@/features/ui/detail/DetailHeader";
import { DetailInformTitle } from "@/features/ui/detail/DetailInformTitle";
import { DetailLabel } from "@/features/ui/detail/DetailLabel";
import { DetailLink } from "@/features/ui/detail/DetailLink";
import { DetailLinkWrapper } from "@/features/ui/detail/DetailLinkWrapper";
import { DetailTitle } from "@/features/ui/detail/DetailTitle";
import DatetimeUtil from "@/utils/dateTimeUtil";
import { Space } from "@mantine/core";

const DetailSite = Object.assign(DetailHeader, {
    Title: DetailTitle,
    Inform: DetailInformTitle,
    Field: DetailField,
    Label: DetailLabel,
    Link: DetailLink,
    LinkWrapper: DetailLinkWrapper,
    Content: DetailContent,
});

interface SiteDetailHeaderProps {
    data: any;
};

export const SiteDetailHeader = (params: SiteDetailHeaderProps) => {
    const { data } = params;
    const site = data?.data?.rows[0];

    return (
        <>
            <DetailSite>
                <DetailSite.Title>{`[사업장 상세]`}</DetailSite.Title>
                <Space h={8} />
                <DetailSite.Field>
                    <DetailSite.Label>
                        {"사업장코드"}
                    </DetailSite.Label>
                    <DetailSite.Content>
                        {site?.code}
                    </DetailSite.Content>
                </DetailSite.Field>
                <DetailSite.Field>
                    <DetailSite.Label>
                        {"사업장명"}
                    </DetailSite.Label>
                    <DetailSite.Content>
                        {site?.name}
                    </DetailSite.Content>
                </DetailSite.Field>
                <DetailSite.Field>
                    <DetailSite.Label>
                        {"생성자 (생성일)"}
                    </DetailSite.Label>
                    <DetailSite.Content>
                        {site?.creatorUserCode} ({site?.createdAt ? DatetimeUtil(site?.createdAt) : ''})
                    </DetailSite.Content>
                </DetailSite.Field>
            </DetailSite>
        </>
    )
}