import { mutateOperationOutsources } from "@/api/operationOutsource/useOperationOutsourcesQuery";
import { useModal } from "@/features/modal/ModalStackManager";
import { OperationOutSourcesForm } from "@/features/ui/form/operationOutsources/form";
import styled from "@emotion/styled";
import { Button, Flex, Select, SelectProps, Text } from "@mantine/core";
import { DefaultApiOperationOutsourcesPostRequest } from "@sizlcorp/sizl-api-document/dist/models";
import { IconX } from "@tabler/icons-react";
import { EventHandler, ReactNode, forwardRef, useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import {
  OperationOutsourceOption,
  useOperationOutsourcesAutoComplete,
  useOperationOutsourcesCode,
} from "./auto-useGetOperationOutsourcesQuery";

interface OperationOutsourceProps
  extends React.ComponentPropsWithoutRef<"div"> {
  group: string;
  label: string;
  value: string;
}

export interface OperationOutsourceAutoCompleteProps
  extends Partial<SelectProps>,
    Partial<React.RefAttributes<HTMLInputElement>> {
  value?: string | null;
  onChange?: (itemCode: string | null) => void;
  maxDropdownHeight?: number;
  setName: (name: string) => void;
  width?: string;
}

export const OperationOutsourceAutoComplete = (
  params: OperationOutsourceAutoCompleteProps
) => {
  const {
    value: operationOutsourceCode,
    onChange,
    maxDropdownHeight,
    setName,
    width,
    ...etcParams
  } = params;
  const [focused, setFocused] = useState<boolean>(true);
  const [searchKeyword, setSearchKeyword] = useState<string>("");
  const [changeSelect, setChangeSelect] = useState<string>("");

  const { data: options } = useOperationOutsourcesAutoComplete(
    focused,
    searchKeyword
  );

  const { data: initialOptions } = useOperationOutsourcesCode(
    !!changeSelect,
    changeSelect ?? null
  );

  let selectedRoute = initialOptions?.find((item) => {
    return item.value === operationOutsourceCode;
  });

  const onChangeHandler = (e: string | null) => {
    selectedRoute = options?.find((item) => item.value === e);
    setChangeSelect(selectedRoute?.label ?? "");
    setName(selectedRoute?.label ?? "");
    onChange && onChange(e);
  };

  const SelectItem = forwardRef<HTMLDivElement, OperationOutsourceProps>(
    ({ label: name, value: code, group, ...others }, ref) => (
      <div ref={ref} {...others}>
        <Flex direction="row" justify="space-between" align="center">
          <Flex align={"center"}>
            <Text>{group}</Text>
            <Text fz="xs"> (name: {name === "null" ? "-" : name})</Text>
          </Flex>
        </Flex>
      </div>
    )
  );

  return (
    <OperationOutsourceSelect
      styles={
        operationOutsourceCode
          ? {
              input: {
                width: width,
                fontSize: "0.8em",
                paddingBottom: "0.5rem",
              },
            }
          : { input: { width: width } }
      }
      onDropdownOpen={() => setFocused(true)}
      onDropdownClose={() => setFocused(false)}
      clearable
      inputContainer={(children: ReactNode) => (
        <div style={{ position: "relative" }}>
          {children}
          {selectedRoute?.value && (
            <div
              style={{
                position: "absolute",
                bottom: "0px",
                fontSize: "0.8em",
                color: "#888",
                left: "5px",
              }}
            >
              {"Code: " + selectedRoute?.value}
            </div>
          )}
        </div>
      )}
      value={operationOutsourceCode}
      itemComponent={SelectItem}
      data={[...(options ?? []), ...(initialOptions ?? [])].reduce(
        (
          unique: OperationOutsourceProps[],
          option: OperationOutsourceProps
        ) => {
          return unique.some((u) => u.value === option.value)
            ? unique
            : [...unique, option];
        },
        []
      )}
      searchable
      maxDropdownHeight={maxDropdownHeight ?? 150}
      onChange={onChangeHandler}
      onSearchChange={setSearchKeyword}
      rightSection={OperationOutsourceInfo({
        operationOutsource: selectedRoute as OperationOutsourceOption,
        onChange: onChangeHandler,
      })}
      filter={(value, item) =>
        item?.label?.toLowerCase().includes(value.toLowerCase().trim()) ||
        item?.value?.toLowerCase().includes(value.toLowerCase().trim())
      }
      nothingFound={AddNewOperationOutsource({
        operationOutSourceName: searchKeyword,
        onChange: onChangeHandler,
      })}
      {...etcParams}
    />
  );
};

const OperationOutsourceInfo = (params: {
  operationOutsource?: OperationOutsourceOption;
  onChange: (routingCode: string | null) => void;
}) => {
  const { operationOutsource, onChange } = params;

  const clearHandler: React.MouseEventHandler<SVGSVGElement> = (e) => {
    e.stopPropagation();
    e.preventDefault();
    onChange(null);
  };

  return operationOutsource?.value ? (
    <OperationOutsourceInfoLabel>
      {/* ({operationOutsource.label})  */}
      <IconX size="1rem" onClick={clearHandler} />
    </OperationOutsourceInfoLabel>
  ) : null;
};

const AddNewOperationOutsource = (params: {
  operationOutSourceName: string;
  onChange: (itemCode: string) => void;
}) => {
  const { operationOutSourceName, onChange } = params;
  const { openModal, closeModal } = useModal();
  const queryClient = useQueryClient();
  const { mutate: postMutate } = useMutation(
    (params : DefaultApiOperationOutsourcesPostRequest) =>
      mutateOperationOutsources.create(params).mutationFn(params as DefaultApiOperationOutsourcesPostRequest | any),
    {
      onSuccess : () => {
        queryClient.invalidateQueries('operationOutsources')
      }
    }
  );

  const onCloseHandler: EventHandler<any> = (values) => {
    postMutate(
      {
        operationOutsourcesGetRequest: {
          operationCode: values.operationCode,
          name: values.name,
          fromLocationCode: values.fromLocationCode,
          toLocationCode: values.toLocationCode,
        },
      },
      {
        onSuccess: () => {
          alert("외주처 생성 성공");
          closeModal(values);
        },
      }
    );
  };
  const addNewModalHandler: React.MouseEventHandler<HTMLButtonElement> = (
    e
  ) => {
    e.preventDefault();
    openModal(
      <OperationOutSourcesForm
        name={operationOutSourceName}
        onSubmit={onCloseHandler}
      />,
      null,
      "신규 외주처 등록",
      true
    ).then((result) => {
      onChange(result.name);
    });
  };

  return (
    <Button variant="subtle" onClick={addNewModalHandler}>
      신규 외주처({operationOutSourceName}) 등록
    </Button>
  );
};
const OperationOutsourceInfoLabel = styled.div`
  font-size: 12px;
  color: #666666;
  // padding-right: 8px;
  padding-left: 0.4rem;
  display: flex;
  align-items: center;
  position: absolute;
`;

const OperationOutsourceSelect = styled(Select)`
  width: 100%;
`;
