import { Flex, FlexProps } from "@mantine/core";

export const DetailField = (params: FlexProps) => {
  const { children } = params;

  return (
    <Flex align={"flex-start"} gap={23} direction={"row"} {...params}>
      {children}
    </Flex>
  );
};
