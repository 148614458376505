import { AbstractItemAutoComplete } from "@/features/ui/autoComplete/abstractItem/abstractItem-autoComplete";
import { ItemCategoryAutoComplete } from "@/features/ui/autoComplete/itemCategory/itemCategory-autoComplete";
import { validateFormValues } from "@/utils/validation";
import styled from "@emotion/styled";
import { Box, Button, Checkbox, Group, Input, NumberInput, Select, Space, Text, Title } from "@mantine/core";
import { useForm } from "@mantine/form";

export function MaterialCreateForm(params: {
  name: string;
  onSubmit?: (values: any) => void;
}) {
  const { name, onSubmit } = params;
  const form = useForm({
    initialValues: {
      name: name,
      code: null,
      itemType: null,
      itemCategoryName: null,
      abstractItemCode: null,
      spec: null,
      height: 0,
      depth: 0,
      width: 0,
      texture: null,
      unitText: null,
      usesLotName: false,
      usesLotExpiration: false,
    },
  });

  return (
    <Box maw={700} mx="auto">
      <form onSubmit={form.onSubmit((values) => onSubmit && onSubmit(values))}>
        <ItemInfoWrapper>
          <ItemTitle>
            <InputField>
              <Title order={2}>*품명</Title>
              <Input
                placeholder="품목명"
                size="lg"
                {...form.getInputProps("name")}
              />
            </InputField>
          </ItemTitle>
          <Spacing />
          <Field>
            <LabelText fw={700} c="dimmed">
              *코드
            </LabelText>
            <InputContent>
              <InputText
                placeholder="코드를 입력해주세요."
                {...form.getInputProps("code")}
              />
            </InputContent>
          </Field>
          <Field>
            <LabelText fw={700} c="dimmed">
              *타입
            </LabelText>
            <InputContent>
              <SelectInput
                placeholder="품목유형을 선택해 주세요."
                data={[
                  { label: "원자재", value: "MATERIAL" },
                  { label: "부자재", value: "SUBSIDIARY" },
                ]}
                {...form.getInputProps("itemType")}
              />
            </InputContent>
          </Field>
          <Field>
            <LabelText fw={700} c="dimmed">
              분류
            </LabelText>
            <InputContent>
              <ItemCategoryAutoComplete
                placeholder="품목분류를 선택해 주세요."
                {...form.getInputProps("itemCategoryName")}
              />
            </InputContent>
          </Field>
          <Field>
            <LabelText fw={700} c="dimmed">
              품목군
            </LabelText>
            <InputContent>
              <AbstractItemAutoComplete
                placeholder="품목군을 선택해 주세요."
                {...form.getInputProps("abstractItemCode")}
              />
            </InputContent>
          </Field>
          <Field>
            <LabelText fw={700} c="dimmed">
              규격
            </LabelText>
            <InputContent>
              <InputText
                placeholder="규격을 입력해주세요."
                {...form.getInputProps("spec")}
              />
            </InputContent>
          </Field>
          <Field>
            <LabelText fw={700} c="dimmed">
              높이
            </LabelText>
            <InputContent>
              <NumberInput
                min={1}
                placeholder="높이를 입력해주세요."
                {...form.getInputProps("height")}
              />
            </InputContent>
          </Field>
          <Field>
            <LabelText fw={700} c="dimmed">
              깊이
            </LabelText>
            <InputContent>
              <NumberInput
                min={1}
                placeholder="깊이를 입력해주세요."
                {...form.getInputProps("depth")}
              />
            </InputContent>
          </Field>
          <Field>
            <LabelText fw={700} c="dimmed">
              폭
            </LabelText>
            <InputContent>
              <NumberInput
                min={1}
                placeholder="폭 을 입력해주세요."
                {...form.getInputProps("width")}
              />
            </InputContent>
          </Field>
          <Field>
            <LabelText fw={700} c="dimmed">
              재질
            </LabelText>
            <InputContent>
              <InputText
                placeholder="재질을 입력해주세요."
                {...form.getInputProps("texture")}
              />
            </InputContent>
          </Field>
          <Field>
            <LabelText fw={700} c="dimmed">
              단위
            </LabelText>
            <InputContent>
              <InputText
                placeholder="단위를 입력해주세요."
                {...form.getInputProps("unitText")}
              />
            </InputContent>
          </Field>
          <Field>
            <LabelText fw={700} c="dimmed">
              LOT 관리 기준
            </LabelText>
            <Group>
              <Checkbox label="이름" {...form.getInputProps("usesLotName")} />
              <Checkbox
                label="유효기한"
                {...form.getInputProps("usesLotExpiration")}
              />
            </Group>
          </Field>
          <Spacing />
          <Button
            w={"100%"}
            type="submit"
            disabled={validateFormValues({
              pageType: "material",
              values: form.values,
            })}
          >
            저장
          </Button>
        </ItemInfoWrapper>
      </form>
    </Box>
  );
}


const ItemInfoWrapper = styled.div`
  display: flex;
  padding: 16px 32px;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
`;

const ItemTitle = styled.div`
  display: flex;
  height: 108px;
  align-items: flex-start;
  align-self: stretch;
`;

const Spacing = styled(Space)`
  display: flex;
  height: 16px;
  flex-direction: column;
  align-items: flex-start;
`;

const InputField = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 5px;
  flex: 1 0 0;
`;

const InputContent = styled.div`
  display: flex;
  padding: 2px 0px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  align-self: stretch;
`;

const LabelText = styled<any>(Text)`
  display: flex;
  width: 120px;
  padding: 2px 0px;
  flex-direction: column;
  align-items: flex-start;
`;

const InputText = styled<any>(Input)`
  display: flex;
  width: 410px;

  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 5px;
`;

const SelectInput = styled<any>(Select)`
  display: flex;
  width: 200px;

  align-items: flex-start;
`;

const Field = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 23px;
  align-self: stretch;
`;