import { WorkContextProps, WorkStateContext } from "@/features/workByManagement/context/WorkProvider";
import { useContext } from "react";

export function useWorkState(): WorkContextProps {
  const context = useContext(WorkStateContext);
  if (context === undefined) {
    throw new Error("useWorkState must be used within a WorkProvider");
  }
  return context;
}
