import { theme } from "@/styles/theme";
import { Anchor, Flex } from "@mantine/core";
import { IconLink } from "@tabler/icons-react";
import { ReactEventHandler } from "react";

export const DetailLink = (params : {children: React.ReactNode, onClick? : ReactEventHandler}) => {
  const { children, onClick } = params;
  return (
    <Flex gap={2} justify={"center"} align={"center"}>
      <Anchor fw={700} size={"14px"} lh={"155%"} c={theme.colors?.blue?.[7]} onClick={onClick}>
        {children}
      </Anchor>
      <IconLink width={16} height={16} color={theme.colors?.blue?.[6]} />
    </Flex>
  );
};
