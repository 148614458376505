import { useQuery } from "react-query";

import DefaultInstance from "@/instance/axios";
import { RoutingsGet200Response, RoutingsGet200ResponseRowsInner } from "@sizlcorp/sizl-api-document/dist/models";

export type RoutingOption = {
    value: string;
    label: string;
};

export const useRoutingsMultiAutoComplete = (
    focus = false,
    keyword = "",
    options?: {
        onSuccess: (data: RoutingOption[]) => void;
    }
) => {
    return useQuery(
        ["routings-get-multi-autocomplete-keyword", keyword],
        () =>
            DefaultInstance.routingsFindPost({
                search: keyword,
                searchFields: ["name"],
            }).then((res) => res.data),
        {
            enabled: focus,
            select: (
                data: RoutingsGet200ResponseRowsInner[]
            ): RoutingOption[] =>
                data.map((Routing) => {
                    return {
                        value: String(Routing.code),
                        label: String(Routing.name),
                    };
                }) ?? [],
            onSuccess: options?.onSuccess,
        }
    );
};

const getRoutingByName = (name: string | null) =>
    DefaultInstance.routingsGet({
        ...(name && { search: name }),
        searchFields: ["name"],
    }).then((res) => res.data);

export const useRoutingsMultiCode = (
    enabled: boolean = false,
    name: string | null,
    options?: {
        onSuccess: (data?: RoutingOption[]) => void;
    }
) => {
    return useQuery(
        ["routings-multi-get-name", name],
        () => getRoutingByName(name),
        {
            enabled,
            select: (data: RoutingsGet200Response) =>
                data?.rows?.map((Routing) => {
                    return {
                        value: Routing.name as string,
                        label: Routing.name as string,
                    };
                }),
            onSuccess: options?.onSuccess,
        }
    );
};
