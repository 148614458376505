import { molds } from "@/api/mold/useMoldQuery";
import { MoldDetailInform } from "@/features/mold/inform/moldInForm";
import { InMoldStockTable } from "@/features/moldStock/table/inTable";
import { Main } from "@/features/standard/Main";
import { DetailContainer } from "@/features/standard/detail/Container";
import { DetailContent } from "@/features/standard/detail/Content";
import { DetailWrapper } from "@/features/standard/detail/Wrapper";
import { theme } from "@/styles/theme";
import styled from "@emotion/styled";
import { Tabs } from "@mantine/core";
import { useQuery } from "react-query";
import { CavityForm } from "../cavity/form/cavityForm";

const Detail = Object.assign(Main, {
  Container: DetailContainer,
  Wrapper: DetailWrapper,
  Content: DetailContent,
})

export interface DetailMoldInformProps {
  moldCode: string | undefined;
}

export const MoldDetailForm = ({ moldCode }: DetailMoldInformProps) => {

  const { data: moldData } = useQuery(molds.get({
    query: { $and: [{ "code": { "$eq": moldCode } }] },
  }));

  return (
    <>
      <Detail.Container>
        <Detail.Wrapper>
          <Detail.Content>
            <MoldDetailInform data={moldData} />
            <Tabs
              variant="outline"
              defaultValue="cavity"
              styles={(theme) => ({
                tab: {
                  "&:not([data-active])": {
                    backgroundColor: theme.colors?.gray?.[0],
                  },
                },
              })}
            >
              <Tabs.List>
                <LeftSpace />
                <Tabs.Tab value="cavity">캐비티 정보</Tabs.Tab>
                <Tabs.Tab value="modalStock">금형 재고</Tabs.Tab>
                <RightSpace />
              </Tabs.List>
              <Tabs.Panel value="cavity" p={20}>
                <CavityForm moldCodeProps={moldCode} />
              </Tabs.Panel>
              <Tabs.Panel value="modalStock" p={20}>
                <InMoldStockTable moldCodeProps={moldCode} />
              </Tabs.Panel>
            </Tabs>
          </Detail.Content>
        </Detail.Wrapper>
      </Detail.Container>
    </>
  );
};

const LeftSpace = styled.span`
  width: 32px;
  align-self: stretch;
  background: ${theme.colors?.gray?.[3]};
  border-bottom: ${theme.colors?.gray?.[3]} solid 1px;
  opacity: 0.2;
`;

const RightSpace = styled.span`
  flex: 1 0 0;
  align-self: stretch;
  background: ${theme.colors?.gray?.[3]};
  border-bottom: ${theme.colors?.gray?.[3]} solid 1px;
  opacity: 0.2;
`;
