import { EquipmentModal } from "@/features/equipment/modal/EquipmentModal";
import { CalendarModal } from "@/features/ui/modal/CalendarModal";
import { CreatePlanningModal } from "@/features/ui/modal/CreatePlanningModal";
import { DefectTypeModal } from "@/features/ui/modal/DefectTypeModal";
import ItemModifyModal from "@/features/ui/modal/ItemModifyModal";
import { RawMaterialModal } from "@/features/ui/modal/RawMaterialModal";
import { WorkOrderModal } from "@/features/ui/modal/WorkOrderModal";
import { WorkCalendarModal } from "@/features/workByRows/modal/workCalenderModal";
import { modalAtom } from "@/recoil/modalAtom";
import { theme } from "@/styles/theme";
import ModalPortal from "@/utils/modalPortal";
import styled from "@emotion/styled";
import { useRecoilState } from "recoil";

export const MODAL_TYPES = {
  DefectModal: "Defect",
  CreatePlanningModal: "CreatePlanning",
  RawMaterialModal: "RawMaterial",
  WorkOrderModal: "WorkOrder",
  CalendarModal: "Calendar",
  WorkCalendarModal: "WorkCalendar",
  ItemModifyModal: "ItemModify",
  EquipmentModal: "Equipment",
} as const;

const ModalComponents: any = {
  [MODAL_TYPES.DefectModal]: DefectTypeModal,
  [MODAL_TYPES.CreatePlanningModal]: CreatePlanningModal,
  [MODAL_TYPES.RawMaterialModal]: RawMaterialModal,
  [MODAL_TYPES.WorkOrderModal]: WorkOrderModal,
  [MODAL_TYPES.CalendarModal]: CalendarModal,
  [MODAL_TYPES.WorkCalendarModal]: WorkCalendarModal,
  [MODAL_TYPES.ItemModifyModal]: ItemModifyModal,
  [MODAL_TYPES.EquipmentModal]: EquipmentModal,
};

export const GlobalModal = () => {
  const [modal, setModal] = useRecoilState(modalAtom);
  const { modalType } = modal || {};

  const renderComponent = () => {
    if (!modalType) {
      return null;
    }

    const ModalComponent = ModalComponents[modalType];

    return (
      <ModalPortal>
        <OverLay onClick={() => setModal(null)} />
        <ModalComponent />
      </ModalPortal>
    );
  };
  return <>{renderComponent()}</>;
};

const OverLay = styled.div`
  position: absolute;
  z-index: 10;
  position: fixed;
  justify-content: center;
  align-items: center;
  opacity: 0.5;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: ${theme.colors?.gray?.[0]};
`;
