import { Flex, MultiSelectProps, Text } from "@mantine/core";
import { forwardRef, useState } from "react";

import { FKInfoLabel, FKMultiSelect } from "@/features/ui/Base/List/CustomFilter/MultiField";
import { IconX } from "@tabler/icons-react";
import { useUsersMultiAutoComplete, useUsersMultiCode } from "./multiAuto-useGetUsersQuery";

interface UserMultiProps extends React.ComponentPropsWithoutRef<"div"> {
    label: string;
    value: string;
}

export interface UsersMultiAutoCompleteProps
    extends Partial<MultiSelectProps>,
    Partial<React.RefAttributes<HTMLInputElement>> {
    value: string[];
    onChange?: (e: string[]) => void; // (value: string[]) => React.Dispatch<React.SetStateAction<string>>;
    maxDropdownHeight?: number;
    width?: string;
}

export const UsersMultiAutoComplete = (
    params: UsersMultiAutoCompleteProps
) => {
    const {
        value: users,
        onChange,
        maxDropdownHeight,
        width,
        ...etcParams
    } = params;
    const [focused, setFocused] = useState<boolean>(true);
    const [searchKeyword, setSearchKeyword] = useState<string>("");
    const { data: options } = useUsersMultiAutoComplete(
        focused,
        searchKeyword
    );
    const { data: initialOptions } = useUsersMultiCode(
        !!searchKeyword,
        searchKeyword ?? null
    );

    const onChangeHandler = (e: string[]) => {
        onChange && onChange(e);
    };

    const SelectUser = forwardRef<HTMLDivElement, UserMultiProps>(
        ({ label: name, value: code, ...others }, ref) => (
            <div ref={ref} {...others}>
                <Flex direction="row" justify="space-between" align="center">
                    <Flex align={"center"}>
                        <Text>{name}</Text>
                        <Text fz="xs">(사용자명: {code})</Text>
                    </Flex>
                </Flex>
            </div>
        )
    );

    return (
        <FKMultiSelect
            clearable
            value={users}
            data={[...(options ?? []), ...(initialOptions ?? [])].reduce(
                (unique: UserMultiProps[], option: UserMultiProps) => {
                    return unique.some((u) => u.value === option.value)
                        ? unique
                        : [...unique, option];
                },
                []
            )}
            onChange={onChangeHandler}
            searchable
            itemComponent={SelectUser}
            searchValue={searchKeyword}
            withinPortal
            onDropdownOpen={() => setFocused(true)}
            onDropdownClose={() => setFocused(false)}
            maxDropdownHeight={maxDropdownHeight ?? 150}
            onSearchChange={setSearchKeyword}
            nothingFound="No Options"
            rightSection={UserInfo({
                User: users,
                onChange: onChangeHandler,
            })}
            {...etcParams}
        />
    );
};

const UserInfo = (params: {
    User?: string[];
    onChange: (e: string[]) => void
}) => {
    const { User, onChange } = params;

    const clearHandler: React.MouseEventHandler<SVGSVGElement> = (e) => {
        e.stopPropagation();
        e.preventDefault();
        onChange([]);
    };

    return User?.length ? (
        <FKInfoLabel>
            <IconX size="1rem" onClick={clearHandler} />
        </FKInfoLabel>
    ) : null;
};

