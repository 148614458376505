import { MasterInstance } from "@/instance/axios";
import { useQuery } from "react-query";

const IdFetcher = (itemId: any) => {
  MasterInstance.itemsItemIdGet(itemId);
};

const useGetItemIdQuery = (itemId: any) => {
  return useQuery({
    queryKey: `/items/${itemId}`,
    queryFn: () => IdFetcher(itemId),
  });
};

export default useGetItemIdQuery;
