import { theme } from "@/styles/theme";
import { Flex, FlexProps } from "@mantine/core";

export const DetailHeader = (params: FlexProps) => {
  const { children } = params;
  return (
    <Flex
      p={"16px 32px"}
      direction={"column"}
      align={"flex-start"}
      gap={8}
      bg={theme.colors?.indigo?.[0]}
      {...params}
    >
      {children}
    </Flex>
  );
};
