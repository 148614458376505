import DefaultInstance from "@/instance/axios";
import { createMutationKeys, createQueryKeys, mergeQueryKeys } from "@lukemorales/query-key-factory";
import { DefaultApiMoldCavitiesFindPostRequest, DefaultApiMoldCavitiesGetRequest, DefaultApiMoldCavitiesMoldCavityIdDeleteRequest, DefaultApiMoldCavitiesMoldCavityIdGetRequest, DefaultApiMoldCavitiesMoldCavityIdPutRequest, DefaultApiMoldCavitiesPostRequest } from "@sizlcorp/sizl-api-document/dist/models";


export const moldCavity = createQueryKeys('moldCavity', {
    all : null,
    get : (params: DefaultApiMoldCavitiesGetRequest) => {
        const query = {...params}; // params 객체를 복사합니다.
        // params.query.$and가 배열이고 길이가 0보다 클 때만 query.query를 설정합니다.
        if (Array.isArray(params.query.$and) && params.query.$and.length > 0) {
            query["query"] = JSON.stringify(params.query);
        }
        // params.search가 있을 때만 query.search와 query.searchFields를 설정합니다.
        if (params.search) {
            query["search"] = params.search;
            query["searchFields"] = params.searchFields;
        }
        return {
            queryKey : [query, JSON.stringify(query.query)],
            queryFn : () => DefaultInstance.moldCavitiesGet(query),
        };
    },
    detail : (params : DefaultApiMoldCavitiesMoldCavityIdGetRequest) => ({
        queryKey : [params],
        queryFn : () => DefaultInstance.moldCavitiesMoldCavityIdGet(params)
    }),
    find : (params: DefaultApiMoldCavitiesFindPostRequest) => {
        const query = {...params}; // params 객체를 복사합니다.
        // params.query.$and가 배열이고 길이가 0보다 클 때만 query.query를 설정합니다.
        if (Array.isArray(params.query.$and) && params.query.$and.length > 0) {
            query["query"] = JSON.stringify(params.query);
        }
        // params.search가 있을 때만 query.search와 query.searchFields를 설정합니다.
        if (params.search) {
            query["search"] = params.search;
            query["searchFields"] = params.searchFields;
        }
        return {
            queryKey : [query, JSON.stringify(query.query)],
            queryFn : () => DefaultInstance.moldCavitiesFindPost(query),
        };
    },
});

export const mutateMoldCavity = createMutationKeys('moldCavity', {
    create : (params : DefaultApiMoldCavitiesPostRequest) => ({
        mutationKey : [params.moldCavitiesGetRequest],
        mutationFn : () => DefaultInstance.moldCavitiesPost(params),
    }),
    update : (params : DefaultApiMoldCavitiesMoldCavityIdPutRequest) => ({
        mutationKey : [params.moldCavityId, params.moldCavitiesGetRequest],
        mutationFn : () => DefaultInstance.moldCavitiesMoldCavityIdPut(params)
    }),
    delete : (params : DefaultApiMoldCavitiesMoldCavityIdDeleteRequest) => ({
        mutationKey : [params.moldCavityId],
        mutationFn : () => DefaultInstance.moldCavitiesMoldCavityIdDelete(params)
    }),
});

export const moldCavityQueryKeys = mergeQueryKeys(moldCavity, mutateMoldCavity);