import { DetailBadge } from "@/features/ui/detail/DetailBadge";
import { DetailContent } from "@/features/ui/detail/DetailContent";
import { DetailField } from "@/features/ui/detail/DetailField";
import { DetailHeader } from "@/features/ui/detail/DetailHeader";
import { DetailInformTitle } from "@/features/ui/detail/DetailInformTitle";
import { DetailLabel } from "@/features/ui/detail/DetailLabel";
import { DetailLink } from "@/features/ui/detail/DetailLink";
import { DetailLinkWrapper } from "@/features/ui/detail/DetailLinkWrapper";
import { DetailTitle } from "@/features/ui/detail/DetailTitle";

const DetailOperation = Object.assign(DetailHeader, {
  Title: DetailTitle,
  Inform: DetailInformTitle,
  Field: DetailField,
  Label: DetailLabel,
  Link: DetailLink,
  LinkWrapper: DetailLinkWrapper,
  Badge: DetailBadge,
  Content: DetailContent,
});

export const OperationBomInform = () => {
  return (
    <DetailOperation bg={"#FFFFFF"}>
      <DetailOperation.Title size={26}>
        {"공정 BOM(Bill of Materials)"}
      </DetailOperation.Title>
      <DetailOperation.Inform>
        {"선택된 공정이 1회 진행될 때 마다 소모될 원/부자재와 수량입니다"}
      </DetailOperation.Inform>
    </DetailOperation>
  );
};
