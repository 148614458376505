import { PropsWithStrictChildren } from "@/types/PropsWithStrictChildren";
import styled from "@emotion/styled";

export const RightActionBar = ({
  children,
}: PropsWithStrictChildren<any, any>) => {
  return <StyledRightActionBar>{children}</StyledRightActionBar>;
};

const StyledRightActionBar = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  gap: 8px;
`;
