import { operationOutsources } from "@/api/operationOutsource/useOperationOutsourcesQuery";
import { OperationOutsourceDetailHeader } from "@/features/operationOutsource/header/DetailHeader";
import { Main } from "@/features/standard/Main";
import { DetailContainer } from "@/features/standard/detail/Container";
import { DetailContent } from "@/features/standard/detail/Content";
import { DetailWrapper } from "@/features/standard/detail/Wrapper";
import { useQuery } from "react-query";

const Detail = Object.assign(Main, {
    Container: DetailContainer,
    Wrapper: DetailWrapper,
    Content: DetailContent,
})

interface OperationOutsourceDetailFormProps {
    name : string | undefined;
}

export const OperationOutsourceDetailForm = (params : OperationOutsourceDetailFormProps) => {
    const { name } = params;

    const { data : operationOutsourceData } = useQuery(operationOutsources.get({
        query : { $and : [{ "name" : { "$eq" : name } }] },
        populate : ["location"]
    }));

    return (
        <>
            <Detail.Container>
                <Detail.Wrapper>
                    <Detail.Content>
                        <OperationOutsourceDetailHeader data={operationOutsourceData} />
                    </Detail.Content>
                </Detail.Wrapper>
            </Detail.Container>
        </>
    )
}