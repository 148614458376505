import { Button, Select, Table, TextInput } from "@mantine/core";
import { useState } from "react";

import styled from "@emotion/styled";

import { useModal } from "@/hooks";
import { theme } from "@/styles/theme";
import { IconPlus, IconTrash } from "@tabler/icons-react";

export const RawMaterialTable = () => {
  const { closeModal } = useModal();

  const [content, setContent] = useState([{ rawMaterials: "", lotNum: "" }]);

  // add
  const handleClickAdd = () => {
    setContent((prev) => [...prev, { rawMaterials: "", lotNum: "" }]);
  };

  // delete
  const handleClickDelete = (index: any) => {
    if (content.length <= 1) return;
    setContent(content.filter((_, listIdx) => listIdx !== index));
  };

  const handleChange = (index: number, cell: { id: string; value: string }) => {
    const list = [...content] as any;
    list[index][cell.id] = cell.value;
    setContent(list);
  };

  return (
    <>
      <TableContainer>
        <thead>
          <tr>
            <th>원부자재</th>
            <th>로트명</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {content.map((item, index) => (
            <>
              <tr key={index}>
                <td>
                  <Select
                    id={"rawMaterials"}
                    value={item.rawMaterials}
                    size="md"
                    onChange={(value: string) =>
                      handleChange(index, {
                        id: "rawMaterials",
                        value,
                      } as any)
                    }
                    data={["React", "Angular", "Vue", "Svelte"]}
                  />
                </td>
                <td>
                  <TextInput
                    id="lotNum"
                    size="md"
                    onChange={(e) =>
                      handleChange(index, {
                        id: e.target.id,
                        value: e.target.value,
                      } as any)
                    }
                  />
                </td>
                <td>
                  <DeleteButton
                    leftIcon={<IconTrash />}
                    onClick={() => handleClickDelete(index)}
                  >
                    삭제
                  </DeleteButton>
                </td>
              </tr>
              {content.length - 1 === index && content.length <= 10 && (
                <AddButtonTr>
                  <AddButton leftIcon={<IconPlus />} onClick={handleClickAdd}>
                    작업 지시 추가
                  </AddButton>
                </AddButtonTr>
              )}
            </>
          ))}
        </tbody>
        <tfoot></tfoot>
      </TableContainer>
      <SaveButton onClick={closeModal}> 저장</SaveButton>
    </>
  );
};

const TableContainer = styled<any>(Table)`
  height: 184px;
`;

const AddButtonTr = styled.div`
  width: 100%;
  margin: 0 auto;
`;

const AddButton = styled<any>(Button)`
  width: 100%;
  background-color: ${theme.colors?.white};
  color: black;
  top: 50%;
  left: 75%;
  height: 32px;
  font-size: 14px;

  &:hover {
    background-color: ${theme.colors?.blue?.[0]};
  }
`;

const DeleteButton = styled<any>(Button)`
  color: black;
  background-color: ${theme.colors?.white};
  font-size: 14px;

  &:hover {
    background-color: ${theme.colors?.blue?.[0]};
  }
`;

const SaveButton = styled<any>(Button)`
  width: 100%;
  margin-top: 8px;
  color: ${theme.colors?.blue?.[6]};
  background-color: ${theme.colors?.white};
  border: 1px solid ${theme.colors?.blue?.[6]};
  height: 32px;
  border-radius: 4px;
  font-size: 14px;

  &:hover {
    background-color: ${theme.colors?.blue?.[0]};
  }
`;
