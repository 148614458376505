import { createContext, useMemo, useState } from "react";

export interface LedgerContextProps {
    Date?: Date[] | string[];
}

export const LedgerStateContext = createContext({
    Date: [new Date(), new Date()],
});

export const LedgerActionsContext = createContext({});

export const LedgerProvider = ({ children }: any) => {
    const [ledgerState, setLedgerState]: any = useState<LedgerContextProps>({
        Date: [new Date(), new Date()],
    });

    const actions: any = useMemo(
        () => ({
            setDate: (Date: Date[]) => {
                setLedgerState((prev: any) => ({
                    ...prev,
                    Date,
                }));
            },
        }),
        []
    );
    
    return (
        <LedgerActionsContext.Provider value={actions}>
            <LedgerStateContext.Provider value={ledgerState}>
                {children}
            </LedgerStateContext.Provider>
        </LedgerActionsContext.Provider>
    );
}