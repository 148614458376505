import { useModal } from "@/features/modal/ModalStackManager";
import { RoutingsCreateForm } from "@/features/routings/components/form/RoutingsCreateForm";
import { RoutingsTable } from "@/features/routings/components/table/RoutingsTable";
import { ActionHeader } from "@/features/standard/ActionHeader";
import { Header } from "@/features/standard/Header";
import { Main } from "@/features/standard/Main";
import { HeaderSubTitle } from "@/features/standard/SubTitle";
import { HeaderTitle } from "@/features/standard/Title";
import { Wrapper } from "@/features/standard/Wrapper";
import { usePub } from "@/hooks";
import { Button } from "@mantine/core";
import { IconPlus, IconTrash } from "@tabler/icons-react";

const RoutingsWrap = Object.assign({}, Main, {
  Header: Header,
  HeaderTitle: HeaderTitle,
  HeaderSubTitle: HeaderSubTitle,
  Wrapper: Wrapper,
  ActionHeader: ActionHeader,
  ActionButton: Button,
  CreateForm: RoutingsCreateForm,
});

export const Routings = () => {
  const { openModal } = useModal();

  const publish = usePub();

  return (
    <RoutingsWrap.Wrapper>
      <RoutingsWrap.ActionHeader>
        <RoutingsWrap.ActionButton
          leftIcon={<IconPlus />}
          onClick={() =>
            openModal(<RoutingsWrap.CreateForm />, null, "라우팅 추가", true)
          }
        >
          추가
        </RoutingsWrap.ActionButton>
        <RoutingsWrap.ActionButton
          color="red"
          rightIcon={<IconTrash />}
          onClick={() => publish("deleteSelectedItems")}
        >
          선택된 행 삭제
        </RoutingsWrap.ActionButton>
      </RoutingsWrap.ActionHeader>
      <RoutingsWrap.Header>
        <RoutingsWrap.HeaderTitle>라우팅 리스트</RoutingsWrap.HeaderTitle>
        <RoutingsWrap.HeaderSubTitle>
          우리 회사에서 다루는 라우팅에 대해 조회할 수 있는 페이지 입니다.
        </RoutingsWrap.HeaderSubTitle>
      </RoutingsWrap.Header>
      <RoutingsTable />
    </RoutingsWrap.Wrapper>
  );
};