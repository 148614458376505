import { useWorkByEquipmentActions } from "@/features/workByEquipment/hook/useWorkByEquipmentActions";
import { useWorkByProductionActions } from "@/features/workByProductionPlan/hook/useWorkByProductionActions";
import { useModal } from "@/hooks";
import { theme } from "@/styles/theme";
import styled from "@emotion/styled";
import {
  Button,
  CloseButton,
  Flex,
  Text,
  TransferList,
  TransferListData,
  TransferListItem,
} from "@mantine/core";
import { useEffect, useState } from "react";

export const DefectTypeModal = ({ pageKey, defectCodes }: any) => {
  const { modal, closeModal } = useModal();
  const [defectList, setDefectData] = useState<TransferListData>([[], []]);

  const actionsObj: any = {
    equipment: useWorkByEquipmentActions(),
    plan: useWorkByProductionActions(),
  };

  const actions: any = actionsObj[pageKey];

  const trasnferDefectCodes: TransferListItem[] =
    defectCodes?.map((data: any) => ({
      label: data?.name ?? "-",
      value: data?.code ?? "-",
      key: data?.code ?? "0",
    })) ?? [];

  const handleTransferList = (defectList: TransferListData) => {
    setDefectData(defectList);
  };

  const handleTransferClick = (defectList: TransferListData) => {
    const [_, inUseDefect] = defectList;
    actions.setDefectReason(
      inUseDefect.map((defect) => ({ name: defect.label, code: defect.value }))
    );

    closeModal();
  };

  useEffect(() => {
    if (trasnferDefectCodes) {
      setDefectData([[], trasnferDefectCodes]);
    }
  }, [defectCodes]);

  return (
    <>
      {modal?.modalType === "Defect" && modal.isOpen && (
        <ModalContainer>
          <Flex align="center">
            <Text fz="lg" mr="auto">
              {"불량 유형 설정"}
            </Text>
            <CloseButton
              size="xl"
              iconSize={32}
              aria-label="Close modal"
              onClick={closeModal}
            />
          </Flex>
          <SubText>
            <Text fz="md">
              {
                " 작업 지시 실적 등록 화면에서 사용할 불량 유형들과 순서를 설정합니다."
              }
            </Text>
          </SubText>
          <TypeContent>
            <TransferList
              value={defectList}
              onChange={handleTransferList}
              searchPlaceholder="Search..."
              titles={["불량 유형", "사용할 불량 유형"]}
              breakpoint="sm"
              listHeight={140}
              listComponent="div"
            />
            <SaveButton onClick={() => handleTransferClick(defectList)}>
              {"저장"}
            </SaveButton>
          </TypeContent>
        </ModalContainer>
      )}
    </>
  );
};

const ModalContainer = styled.div`
  position: fixed;
  width: 624px;
  height: 312px;
  border-radius: 4px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 20px;
  background-color: ${theme.colors?.white?.[1]};
  border: 1px solid #ccc;
  z-index: 100;
`;

const SubText = styled.div`
  width: 100%;
  margin-top: 12px;
`;

const TypeContent = styled.div`
  width: 100%;
  height: auto;
  margin-top: 12px;
`;

const SaveButton = styled<any>(Button)`
  margin-top: 16px;
  width: 100%;
  color: ${theme.colors?.blue?.[6]};
  background-color: ${theme.colors?.white};
  border: 1px solid ${theme.colors?.blue?.[6]};
  height: 32px;
  border-radius: 4px;
  font-size: 14px;
`;
