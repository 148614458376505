import { InspectSpecificationCreateFormWithItems } from "@/features/inspect/components/inspectSpecification/form/InspectSpecificationCreateFormWithItems";
import { InspectSpecificationTable } from "@/features/inspect/components/inspectSpecification/table/InspectSpecificationTable";
import { useModal } from "@/features/modal/ModalStackManager";
import { ActionButtonBox } from "@/features/standard/ActionButtonBox";
import { ActionHeader } from "@/features/standard/ActionHeader";
import { Header } from "@/features/standard/Header";
import { Main } from "@/features/standard/Main";
import { HeaderSubTitle } from "@/features/standard/SubTitle";
import { HeaderTitle } from "@/features/standard/Title";
import { Wrapper } from "@/features/standard/Wrapper";
import { usePub } from "@/hooks";
import { Button } from "@mantine/core";
import { IconPlus, IconTrash } from "@tabler/icons-react";

const InspectSpecification = Object.assign({}, Main, {
  Header: Header,
  HeaderTitle: HeaderTitle,
  HeaderSubTitle: HeaderSubTitle,
  Wrapper: Wrapper,
  ActionHeader: ActionHeader,
  Table: InspectSpecificationTable,
  ActionButton: Button,
});

export const InspectSpecificationPage = () => {
  const { openModal } = useModal();

  const publish = usePub();

  return (
    <InspectSpecification.Wrapper>
      <InspectSpecification.ActionHeader>
        <ActionButtonBox>
          <InspectSpecification.ActionButton
            onClick={() =>
              openModal(
                <InspectSpecificationCreateFormWithItems />,
                "",
                "",
                true
              )
            }
            leftIcon={<IconPlus />}
          >
            추가
          </InspectSpecification.ActionButton>
        </ActionButtonBox>
        <InspectSpecification.ActionButton
          color="red"
          rightIcon={<IconTrash />}
          onClick={() => publish("deleteSelectedItems")}
        >
          선택된 행 삭제
        </InspectSpecification.ActionButton>
      </InspectSpecification.ActionHeader>

      <InspectSpecification.Header>
        <InspectSpecification.HeaderTitle>
          {"검사 표준 리스트"}
        </InspectSpecification.HeaderTitle>
        <InspectSpecification.HeaderSubTitle>
          {
            "우리 회사에서 사용하는 품목의 검사표준을 조회할 수 있는 페이지 입니다."
          }
        </InspectSpecification.HeaderSubTitle>
      </InspectSpecification.Header>
      <InspectSpecification.Table />
    </InspectSpecification.Wrapper>
  );
};
