import styled from "@emotion/styled";
import { Title } from "@mantine/core";
interface HeaderTitleProps {
  children: React.ReactNode;
}

export const HeaderTitle = ({ children }: HeaderTitleProps) => {
  return <MainTitle>{children}</MainTitle>;
};

const MainTitle = styled<any>(Title)`
  font-family: Noto Sans KR;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 162.176%; /* 32.435px */
  letter-spacing: -0.4px;
`;
