import { StatusObjType } from "@/utils/workStatus";

export const EquipmentStatusColor : StatusObjType = {
    "동작 중" : "#70ff17",
    "대기 중" : "#ffdd1f",
    "에러" : "#ff461a",
    "완료" : "#19b9df",
    "전원 OFF" : "#b3b3b3",
    "전원 ON" : "#ff8873",
};

export const setEquipmentStatus = (status: string | undefined): string => {
    if (!status) return "";
    return EquipmentStatusColor[status];
};