import { Box, Button, Code, Text, TextInput } from "@mantine/core";
import { useForm } from "@mantine/form";

export function AbstractItemForm(params: {
  code: string;
  onSubmit?: (values: any) => void;
}) {
  const { code, onSubmit } = params;
  const form = useForm({
    initialValues: {
      code: code, // 품목군 코드
    },
  });

  return (
    <Box maw={320} mx="auto">
      <form onSubmit={form.onSubmit((values) => onSubmit && onSubmit(values))}>
        <TextInput
          label="품목군 코드"
          placeholder="code"
          defaultValue={code}
          {...form.getInputProps("code")}
        />
        <Text size="sm" weight={500} mt="xl">
          Form values:
        </Text>
        <Code block mt={5}>
          {JSON.stringify(form.values, null, 2)}
        </Code>
        <Button type="submit">Submit</Button>
      </form>
    </Box>
  );
}
