import useGetItemIdQuery from "@/api/useGetItemIDQuery";
import { useModal } from "@/hooks";
import { theme } from "@/styles/theme";
import styled from "@emotion/styled";
import { CloseButton, TextInput } from "@mantine/core";
import { useEffect } from "react";

const ItemModifyModal = ({ itemId, setIsOpen }: any) => {
  const { closeModal } = useModal();
  const { data, isSuccess } = useGetItemIdQuery(itemId);

  useEffect(() => {
    if (isSuccess) {
    }
  }, []);

  const onClickClose = () => {
    setIsOpen(false);
  };
  return (
    <>
      <ModalContainer>
        <Content>
          <Title>품목 수정</Title>
          <CloseButton size="xl" onClick={onClickClose} />
          <TextInput
            label="Name"
            size="md"
            // defaultValue={data?.id}
          />
          <TextInput
            label="Type"
            size="md"
            // defaultValue={data?.itemType}
          />
          <TextInput
            label="Code"
            size="md"
            // defaultValue={data?.code}
          />
        </Content>
      </ModalContainer>
    </>
  );
};

export default ItemModifyModal;
const ModalContainer = styled.div`
  position: fixed;
  width: 400px;
  height: 800px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 20px;
  background-color: ${theme.colors?.white?.[1]};
  border: 1px solid #ccc;
  z-index: 100;
`;

const Content = styled.div`
  width: 100%;
`;

const Title = styled.div`
  font-size: 20px;
`;
