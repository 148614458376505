import { useModal } from "@/hooks";
import { theme } from "@/styles/theme";
import styled from "@emotion/styled";
import { CloseButton, Flex, Text } from "@mantine/core";

import { RawMaterialTable } from "@/features/ui/table/RawMaterialTable";

export const RawMaterialModal = () => {
  const { modal, closeModal } = useModal();

  return (
    <>
      {modal?.modalType === "RawMaterial" && modal.isOpen && (
        <ModalContainer>
          <Flex align="center">
            <Text fz="lg" mr="auto">
              원/부자재 투입
            </Text>
            <CloseButton
              size="xl"
              iconSize={32}
              aria-label="Close modal"
              onClick={closeModal}
            />
          </Flex>
          <SubText>
            <Text fz="sm" mb="sm">
              선택된 품목()의 공정()에 투입된 원/부자재의 로트를 입력합니다.
            </Text>
          </SubText>
          <RawMaterialTable />
        </ModalContainer>
      )}
    </>
  );
};

const ModalContainer = styled.div`
  position: fixed;
  width: 624px;
  height: auto;
  border-radius: 4px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 20px;
  background-color: ${theme.colors?.white?.[1]};
  border: 1px solid #ccc;
  z-index: 100;
`;

const SubText = styled.div`
  width: 100%;
  margin-top: 12px;
`;

// const TypeContent = styled.div`
//   width: 100%;
//   margin-top: 12px;
// `;

// const SaveButton = styled<any>(Button)`
//   margin-top: 8px;
//   width: 100%;
//   color: ${theme.colors?.blue?.[6]};
//   background-color: ${theme.colors?.white};
//   border: 1px solid ${theme.colors?.blue?.[6]};
// `;
