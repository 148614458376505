import {
    MantineReactTable,
    useMantineReactTable,
    // createRow,
    type MRT_ColumnDef
} from 'mantine-react-table';
import { useMemo } from 'react';

import { wipInventory } from '@/api/wipInventories/useWipInventoriesQuery';
import { LocationDetailForm } from '@/features/location/detail/LocationDetailForm';
import { useModal } from '@/features/modal/ModalStackManager';
import DatetimeUtil from '@/utils/dateTimeUtil';
import { Anchor, Checkbox } from '@mantine/core';
import { WorkLogsWorkLogIdCancelEndPut200ResponseCancelProgressInner } from '@sizlcorp/sizl-api-document/dist/models';
import { useQuery } from 'react-query';
import { ProductionPlanFormProps } from '../../view';

export interface WorkLogsGet200ResponseRowsInner {
    updaterUserCode: string | any;
    updatedAt: string | any;
    quantity: number | any;
    id: number | any;
    productionPlanId: number | any;
    locationCode: string | any;
    operationStep: {
        map(
            arg0: (step: any, index: any) => import("react/jsx-runtime").JSX.Element
        ): unknown;
        FULL01?: boolean;
        FULL02?: boolean;
        FULL03?: boolean;
        FULL04?: boolean;
    };
}

export const WorkInventoryTabs = ({ data }: ProductionPlanFormProps) => {

    const planId = data && data?.id;

    const { data: wipInvetory } = useQuery(wipInventory.get({
        query: data ? { $and: [{ "productionPlanId": { "$eq": planId } }] } : '',
    }));
    
    const rows: WorkLogsWorkLogIdCancelEndPut200ResponseCancelProgressInner[] =
        wipInvetory?.data.rows ?? [];

    const { openModal } = useModal();
    
    const columns = useMemo<MRT_ColumnDef<WorkLogsWorkLogIdCancelEndPut200ResponseCancelProgressInner>[]>(
        () => [
            {
                accessorFn: (row) => row.id,
                accessorKey: 'id',
                header: '생산 계획 번호',
                size: 80,
                Cell: (rows) => {
                    return (
                        <div style={{ textAlign: 'right' }}>{rows.row._valuesCache.id}</div>
                    )
                }
            },
            {
                accessorFn: (row) => 
                    <Anchor
                        onClick={() => openModal(
                            <LocationDetailForm locationCode={row.locationCode} />,
                            null,
                            '로케이션 상세'
                        )}
                    >
                        {row.locationCode}
                    </Anchor>,
                accessorKey: 'locationCode',
                header: '로케이션',
                size: 140,
                mantineEditTextInputProps: {
                    type: 'string',
                    required: true,
                },
            },
            {
                accessorFn: (row) => row.operationStep,
                accessorKey: 'operationStep',
                header: '공정',
                size: 140,
                mantineEditTextInputProps: {
                    type: 'string',
                    required: true,
                },
                Cell: (row) => {
                    if (row?.row._valuesCache.operationStep && typeof row?.row._valuesCache.operationStep === 'object') {
                        return Object.entries(row?.row._valuesCache.operationStep).map(([key, checked], index) => {
                            return (
                                <Checkbox
                                    key={index}
                                    value={key}
                                    label={`${index + 1}. ${key} 공정`}
                                    checked={checked as boolean}
                                    readOnly
                                    mb={8}
                                />
                            );
                        });
                    }
                    return null;
                },
            },
            {
                accessorFn: (row) => row.quantity,
                accessorKey: 'quantity',
                header: '수량',
                size: 80,
                mantineEditTextInputProps: {
                    type: 'string',
                    required: true,
                },
                Cell: (rows) => {
                    return (
                        <div style={{ textAlign: 'right' }}>{rows.row._valuesCache.quantity}</div>
                    )
                }
            },
            {
                accessorFn: (row) => `${DatetimeUtil(row.updatedAt ?? '')} (${row.updaterUserCode})`,
                accessorKey: 'updatedAt',
                header: '최근 수정정보',
                size: 120,
                mantineEditTextInputProps: {
                    type: 'string',
                    required: true,
                },
            },
        ],
        [],
    );

    const table = useMantineReactTable({
        columns,
        data: rows,
        editDisplayMode: 'modal',
        enableEditing: false,
        enableToolbarInternalActions: false, // 우측상단 아이콘 안보이게
        enablePagination: false,
    });

    return <MantineReactTable table={table} />;
};


