import { handleKeyDown } from "@/utils/event";
import { Button, Group, Select, TextInput } from "@mantine/core";
import { useForm } from "@mantine/form";

export interface SearchProps {
  search: string;
  searchFields: string[];
  pageSize: string;
}

interface SetSearchProps {
  searchType: { label: string; value: string }[];
  setSearch: any;
  search?: SearchProps;
}

export const SearchBox = ({ searchType, setSearch, search }: SetSearchProps) => {
  const form = useForm({
    initialValues: {
      searchFields: search?.searchFields.join(',') || "",
      search: search?.search || "",
      pageSize: search?.pageSize || "10"
    },
  });

  const handleSearch = () => {
    setSearch((prev: any) => ({
      ...prev,
      pageSize: form.values.pageSize,
      search: form.values.search,
      searchFields: form.values.searchFields ? [form.values.searchFields] : prev.searchFields,
    }));
  };

  return (
    <Group position="right">
      <Select data={[
        { value: '5', label: "5개씩" },
        { value: '10', label: "10개씩" },
        { value: '15', label: "15개씩" },
        { value: '20', label: "20개씩" },
      ]} style={{ width: "8rem" }}
        {...form.getInputProps("pageSize")}
      />
      <Select
        data={searchType}
        placeholder="통합 검색"
        {...form.getInputProps("searchFields")}
      />
      <TextInput
        placeholder="검색어를 입력하세요"
        {...form.getInputProps("search")}
        onKeyDown={(e) => handleKeyDown(e, handleSearch)}
      />
      <Button onClick={handleSearch}>검색</Button>
    </Group>
  );
};
