import { LabeledInput } from "@/features/ui/input/LabeledInput";
import { UserInstance } from "@/instance/axios";
import { theme } from "@/styles/theme";
import styled from "@emotion/styled";
import { useRef } from "react";
import { useNavigate } from "react-router-dom";

function Login() {
  const formData = useRef<Map<string, any>>(new Map());
  const navigate = useNavigate();

  const onSignUp = async () => {
    const nameValue = formData.current.get("name");
    const userNameValue = formData.current.get("userName");
    const passwordValue = formData.current.get("password");
    const validate = nameValue && userNameValue && passwordValue;

    if (!validate) {
      alert("please enter email, password");
      return;
    }

    try {
      const response = await UserInstance.authSignupPost(
        {
          authSignupPostRequest: {
            code: userNameValue,
            name: nameValue,
            username: userNameValue,
            password: passwordValue,
          },
        },
        {
          headers: {
            Authorization: undefined,
          },
        }
      );
      if (response.status === 200) {
        alert("회원가입이 완료되었습니다.");
        navigate("/");
      }
      return;
    } catch (error: any) {
      alert(error?.response?.data?.message);
    }
  };

  return (
    <Container>
      <LogoBox>{/* <LightLogo /> */}</LogoBox>

      <SignInSection>
        <AuthenticationTitle>Sign Up</AuthenticationTitle>

        <AuthForm
          onSubmit={(e) => {
            e.preventDefault();
          }}
        >
          <LabeledInput
            htmlFor="name-input"
            labelText="Name"
            placeholder="Name"
            notifier={(value) => {
              formData.current?.set("name", value);
            }}
          />

          <LabeledInput
            htmlFor="userName-input"
            labelText="userName"
            placeholder="userName"
            notifier={(value) => {
              formData.current?.set("userName", value);
            }}
          />

          <LabeledInput
            htmlFor="password-input"
            labelText="Password"
            placeholder="Password"
            type="password"
            notifier={(value) => {
              formData.current?.set("password", value);
            }}
          />
        </AuthForm>

        <OptionBox>
          {/* <CheckAndRadio>
            <BaseCheckbox notifier={(ischecked) => setIsRemember(ischecked)} />
            <CheckboxText>Remember me</CheckboxText>
          </CheckAndRadio> */}

          {/* <ForgotPass>Forgot password?</ForgotPass> */}
        </OptionBox>

        <ButtonBox>
          <Button onClick={onSignUp}>Sign Up</Button>
        </ButtonBox>

        <SignUpBox>
          <SignUpText>Already have an account?</SignUpText>
          <SignUpAnchor
            onClick={(e) => {
              e.preventDefault();
              navigate("/");
            }}
          >
            Sign In
          </SignUpAnchor>
        </SignUpBox>
      </SignInSection>
      <Ellipse />
    </Container>
  );
}

const Container = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  background-color: ${theme?.colors?.gray?.[6]};
`;

const LogoBox = styled.div`
  position: absolute;
  top: 38px;
  left: 36px;
  z-index: var(--zIndex-0st);
`;

const Ellipse = styled.div`
  width: 3000px;
  height: 1200px;
  background: ${theme?.colors?.gray?.[7]};
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 70%;
  border: 1px black solid;
  position: absolute;
  transform: translateY(-75%);
`;

const SignInSection = styled.section`
  width: 432px;
  height: fit-content;
  background-color: ${theme?.colors?.gray?.[9]};
  z-index: var(--zIndex-1st);
  color: ${theme?.colors?.white?.[0]};

  padding-left: 24px;
  padding-right: 24px;
  padding-top: 40px;
  padding-bottom: 40px;
  box-shadow: 0px 4px 20px rgba(6.82, 6.02, 18.06, 0.1);
  border-radius: 12px;

  transform: translateY(40px);

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
`;

const AuthenticationTitle = styled.h2`
  font-size: 24px;
  font-family: Roboto;
  font-weight: 700;
  word-wrap: break-word;
`;

const AuthForm = styled.form`
  width: 100%;
  margin-top: 40px;

  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const OptionBox = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-top: 10px;
`;

const ButtonBox = styled.div`
  width: 100%;
  height: 48px;
  margin-top: 40px;
`;
const Button = styled.button`
  width: 100%;
  height: 100%;

  background-color: #0077e4;
  border-radius: 5px;

  display: flex;
  align-items: center;
  justify-content: center;

  color: white;
  font-size: 16px;
  font-family: Roboto;
  font-weight: 500;
  word-wrap: break-word;
`;

const SignUpBox = styled.div`
  width: 100%;
  margin-top: 20px;
  display: flex;
  gap: 3px;
  padding: 12px 0;
  justify-content: center;
`;
const SignUpText = styled.p`
  color: #dde1e8;
  font-size: 14px;
  font-family: Roboto;
  font-weight: 400;
  word-wrap: break-word;
`;
const SignUpAnchor = styled.a`
  color: #319dff;
  font-size: 14px;
  font-family: Roboto;
  font-weight: 400;
  word-wrap: break-word;
  cursor: pointer;
`;

export default Login;
