import { theme } from "@/styles/theme";
import { Badge, BadgeProps } from "@mantine/core";

export const DetailBadge = (params: BadgeProps) => {
  const { children } = params;
  return (
    <Badge
      size="xs"
      variant="outline"
      color={params.color ?? theme.colors?.indigo?.[6]}
    >
      {children}
    </Badge>
  );
};
