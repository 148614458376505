import { mutateSites } from "@/api/sites/useSitesQuery";
import { useModal } from "@/features/modal/ModalStackManager";
import customAlert from "@/features/ui/alert/alert";
import { handleErrorResponse } from "@/utils/errorMessage";
import { validateFormValues } from "@/utils/validation";
import styled from "@emotion/styled";
import { Box, Button, TextInput } from "@mantine/core";
import { useForm } from "@mantine/form";
import { MasterApiSitesPostRequest } from "@sizlcorp/sizl-api-document/dist/models";
import { useMutation, useQueryClient } from "react-query";

export function SiteCreateForm() {
  const { closeModal } = useModal();
  const queryClient = useQueryClient();
  const { mutate: postMutate } = useMutation(
    (params: MasterApiSitesPostRequest) =>
      mutateSites.create(params).mutationFn(params as MasterApiSitesPostRequest | any),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('sites')
      }
    }
  );

  const form = useForm({
    initialValues: {
      code: undefined,
      name: undefined,
    },
  });

  const onSubmit = async () => {
    try {
      postMutate(
        {
          sitesGetRequest: {
            code: form.values.code!,
            name: form.values.name!,
          },
        },
        {
          onSuccess: (res) => {
            closeModal(form.values);
            if (res.status === 200) {
              customAlert(
                "사업장 생성에 성공하였습니다.",
                "생성 성공",
                "green"
              )
            } else {
              customAlert(
                "사업장 생성에 실패하였습니다.",
                "생성 실패",
                "red"
              )
            }
          },
          onError: (error: any) => {
            customAlert(handleErrorResponse({ error, errorContext: '사업장코드' }), '사업장 생성 실패', 'red')
          }
        }
      );
    } catch (e) {
      customAlert(
        "사업장 생성에 실패하였습니다.",
        "생성 실패",
        "red"
      )
    }
  };

  return (
    <Box maw={320} mx="auto">
      <Form>
        <ModalFieldSet>
          <TextInput
            label="사업장코드"
            withAsterisk
            {...form.getInputProps("code")}
          />
          <TextInput
            label="사업장명"
            withAsterisk
            width={"100%"}
            {...form.getInputProps("name")}
          />
          <ModalConfirmBtnBox>
            <Button color="gray" onClick={closeModal}>
              취소
            </Button>
            <Button
              disabled={validateFormValues({
                pageType: "site",
                values: form.values,
              })}
              onClick={onSubmit}
            >
              사업장 생성
            </Button>
          </ModalConfirmBtnBox>
        </ModalFieldSet>
      </Form>
    </Box>
  );
}

const Form = styled.form`
  width: 100%;
  height: 100%;
`;

const ModalFieldSet = styled.fieldset`
  border: none;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const ModalConfirmBtnBox = styled.div`
  padding: 24px 0;
  display: flex;
  justify-content: flex-end;
  gap: 20px;

  // & button {
  //     width: 54px;
  //     height: 40px;
  //     border-radius: 4px;

  //   &.cancel_btn {
  //     background-color: #ffffff;
  //   }
  // }
`;
