import { MasterInstance } from "@/instance/axios";
import { SitesGet200Response } from "@sizlcorp/sizl-api-document/dist/models";
import { AxiosResponse } from "axios";
import { useQuery } from "react-query";

// useQuery에서 사용할 UniqueKey를 상수로 선언하고 export로 외부에 노출합니다.
// 상수로 UniqueKey를 관리할 경우 다른 Component (or Custom Hook)에서 쉽게 참조가 가능합니다.

// 추후에 쿼리KEY를 관리할 예정이다.
export const DEFECT_QUERY_KEY = "/site";

const getSiteFetch = () => MasterInstance.sitesGet();

const useWorkSitesQuery = (params?: { firstRequest: boolean }) => {
  return useQuery<AxiosResponse<SitesGet200Response, any[]>>({
    queryKey: [DEFECT_QUERY_KEY],
    queryFn: () => getSiteFetch(),
    // enabled: params?.firstRequest ?? false,
  });
};

export default useWorkSitesQuery;
