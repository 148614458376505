import { useQuery } from "react-query";

import DefaultInstance from "@/instance/axios";
import {
  AbstractItemsGet200Response,
  AbstractItemsGet200ResponseRowsInner,
} from "@sizlcorp/sizl-api-document/dist/models";

export type AbstractItemOption = {
  value: string;
  label: string;
};

export const useAbstractItemsAutoComplete = (
  focus = false,
  keyword = "",
  options?: {
    onSuccess: (data: AbstractItemOption[]) => void;
  }
) => {
  return useQuery(
    ["AbstractItems-get-autocomplete-keyword", keyword],
    () =>
    DefaultInstance.abstractItemsFindPost({
        search: keyword,
        searchFields: ["code"],
      }).then((res) => res.data),
    {
      enabled: focus,
      select: (data: AbstractItemsGet200ResponseRowsInner[]): AbstractItemOption[] =>
        data.map((AbstractItem) => {
          return {
            value: String(AbstractItem.code),
            label: String(AbstractItem.code),
          };
        }) ?? [],
      onSuccess: options?.onSuccess,
    }
  );
};

const getAbstractItemByCode = (code: string | null) =>
  DefaultInstance.abstractItemsGet({
    ...(code && { search: code }),
    searchFields: ["code"],
  }).then((res) => res.data);

export const useAbstractItemsCode = (
  enabled: boolean = false,
  code: string | null,
  options?: {
    onSuccess: (data?: AbstractItemOption[]) => void;
  }
) => {
  return useQuery(
    ["AbstractItems-get-code", code],
    () => getAbstractItemByCode(code),
    {
      enabled,
      select: (data: AbstractItemsGet200Response) =>
        data?.rows?.map((AbstractItem) => {
          return {
            value: AbstractItem.code as string,
            label: AbstractItem.code as string,
          };
        }),
      onSuccess: options?.onSuccess,
    }
  );
};
