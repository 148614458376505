import { Flex, FlexProps } from "@mantine/core";

export const DetailLinkWrapper = (params: FlexProps) => {
  const { children } = params;

  return (
    <Flex direction={"column"} align={"flex-start"} gap={8}>
      {children}
    </Flex>
  );
};
