import { mutateSpecifications, specifications } from "@/api/inspect/inspectSpecification/useSpecificationsQuery";
import { useModal } from "@/features/modal/ModalStackManager";
import customAlert from "@/features/ui/alert/alert";
import { theme } from "@/styles/theme";
import styled from "@emotion/styled";
import { Button, Table, Title, useMantineColorScheme } from "@mantine/core";
import { useForm } from "@mantine/form";
import {
  SpcApiSpecificationsSpecificationIdPutRequest,
  SpecificationsGet200ResponseRowsInnerCriteriaInner,
  SpecificationsGetRequest,
} from "@sizlcorp/sizl-api-document/dist/models";
import { IconPlus } from "@tabler/icons-react";
import { useEffect } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { InspectCriteriaCreateRow } from "../table/InspectCriteriaCreateRow";

export const TableContainer = styled.section`
  display: flex;
  width: 1000px;
  min-height: 250px;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
`;

export const TableWrapper = styled(Table)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  flex: 1 0 0;
  align-self: stretch;
`;

export const Thead = styled.thead`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
`;

export const Tbody = styled.tbody`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
`;

export const Tr = styled.tr`
  display: flex;
  width: 100%;
  align-items: flex-start;
  align-self: stretch;
`;

export const Th = styled.th<{ width?: number }>`
  display: flex;
  width: ${(props) => (props.width ? `${props.width}%` : "auto")};
  padding: 7px 10px;
  align-items: flex-start;
  gap: 10px;
`;

const Td = styled.td<{ width?: number }>`
  display: flex;
  width: ${(props) => (props.width ? `${props.width}%` : "auto")};
  padding: 7px 10px;
  align-items: flex-start;
  gap: 10px;
`;

const AddButton = styled<any>(Button)`
  display: flex;
  padding: 1px 8px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex: 1 0 0;
`;

const SaveButton = styled<any>(Button)`
  display: flex;
  padding: 4px 16px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  border-radius: 4px;
  border: 1px dashed var(--blue-6, #228be6);
  background: rgba(34, 139, 230, 0.03);
  color: ${theme.colors?.blue?.[6]};

  &:hover {
    background: rgba(34, 139, 230, 0.1);
  }
`;

const ButtonTr = styled.tr`
  display: flex;
  width: 100%;
  padding: 7px 10px;
  align-items: flex-start;
  gap: 10px;
  flex: 1 0 0;
`;

const SubTitle = styled(Title)`
  color: rgba(0, 0, 0, 0.87);
  font-family: "Noto Sans KR";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px; /* 150% */
  letter-spacing: -0.24px;
`;

interface InspectCriteriaUpdateFormProps {
  data?: any;
}

export const InspectCriteriaUpdateForm = (
  params: InspectCriteriaUpdateFormProps
): JSX.Element => {
  const { data } = params;
  const queryClient = useQueryClient();

  const { data: criteriaData } = useQuery(specifications.detail({
    specificationId: data?.specificationId as number,
  }))

  const { mutate: updateMutate } = useMutation(
    (params: SpcApiSpecificationsSpecificationIdPutRequest) =>
      mutateSpecifications.update(params).mutationFn(params as SpcApiSpecificationsSpecificationIdPutRequest | any),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("specifications");
      }
    }
  );
  const { closeModal } = useModal();

  const form = useForm<Partial<SpecificationsGetRequest>>({
    initialValues: {
      criteria: criteriaData?.data.criteria,
    },
  });

  const { colorScheme, toggleColorScheme } = useMantineColorScheme();
  const isDarkMode: boolean = colorScheme === "light";

  const handleClickAdd = () => {
    form.insertListItem("criteria", {
      name: "",
      type: "boolean",
      standard: true,
      min: undefined,
      max: undefined,
    });
  };

  const onSubmit = async () => {
    try {
      updateMutate(
        {
          specificationId: data?.specificationId as number,
          specificationsGetRequest: {
            itemCode: data?.itemCode as string,
            criteria: form.values
              .criteria as SpecificationsGet200ResponseRowsInnerCriteriaInner[],
          },
        },
        {
          onSuccess: (res) => {
            closeModal({});
            if (res.status === 200) {
              customAlert(
                "검사 기준 수정에 성공하였습니다.",
                "수정 성공",
                "green"
              )
            } else {
              customAlert(
                "검사 기준 수정에 실패하였습니다.",
                "수정 실패",
                "red"
              )
            }
          },
        }
      );
    } catch (e) {
      customAlert(
        "검사 기준 수정에 실패하였습니다.",
        "수정 실패",
        "red"
      )
    }
  };

  useEffect(() => {
    if (criteriaData) {
      form.setValues({
        criteria: criteriaData?.data.criteria,
      });
    }
  }, [criteriaData]);

  return (
    <>
      <SubTitle>{`선택된 품목 (${data?.itemCode}) 공정 (${data?.code}) 의 초/중/종물 검사 기준을 작성합니다.`}</SubTitle>
      <TableContainer>
        <TableWrapper>
          <Thead>
            <Tr>
              <Th width={7}>포인트</Th>
              <Th width={25}>포인트명</Th>
              <Th width={20}>검사 타입</Th>
              <Th width={12}>표준값</Th>
              <Th width={10}>샘플 수</Th>
              <Th width={12}>최소</Th>
              <Th width={12}>최대</Th>
              <Th width={12}>액션</Th>
            </Tr>
          </Thead>
          <Tbody>
            {form.values.criteria &&
              form.values.criteria.map((_, index) => (
                <>
                  <InspectCriteriaCreateRow formData={form} formIndex={index} />
                  <>
                    {form.values.criteria &&
                      form.values.criteria?.length - 1 === index &&
                      form.values.criteria?.length <= 10 && (
                        <ButtonTr>
                          <Td width={100} rowSpan={7}>
                            <AddButton
                              leftIcon={<IconPlus />}
                              variant={isDarkMode ? "white" : "dark"}
                              color={isDarkMode ? "dark" : "white"}
                              onClick={handleClickAdd}
                            >
                              새로운 검사 기준 추가
                            </AddButton>
                          </Td>
                        </ButtonTr>
                      )}
                  </>
                </>
              ))}
          </Tbody>
          <SaveButton
            disabled={form.values.criteria?.length === 0}
            onClick={onSubmit}
          >
            검사 기준 수정
          </SaveButton>
        </TableWrapper>
      </TableContainer>
    </>
  );
};
