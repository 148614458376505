import styled from "@emotion/styled";

interface ActionButtonBoxProps {
  children: React.ReactNode;
}

export const ActionButtonBox = ({ children }: ActionButtonBoxProps) => {
  return <ButtonBox>{children}</ButtonBox>;
};

const ButtonBox = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 10px;
`;
