import { items } from "@/api/items/useItemQuery";
import { InspectSpecificationsByItemTable } from "@/features/inspect/components/inspectSpecification/table/inspectSpecificationsByItemTable";

import { OperationFormProps } from "@/features/item/components/form/ItemsCreateForm";
import { LotList } from "@/features/item/components/form/itemsStepperForm/LotList";
import { OperationBomList } from "@/features/item/components/form/itemsStepperForm/OperationBomList";
import { Main } from "@/features/standard/Main";
import { DetailContainer } from "@/features/standard/detail/Container";
import { DetailContent } from "@/features/standard/detail/Content";
import { DetailWrapper } from "@/features/standard/detail/Wrapper";
import { Header } from "@/features/ui/view/header";
import { theme } from "@/styles/theme";
import styled from "@emotion/styled";
import { Tabs } from "@mantine/core";
import { useQuery } from "react-query";

const Detail = Object.assign(Main, {
  Container: DetailContainer,
  Wrapper: DetailWrapper,
  Content: DetailContent,
})

interface ItemsDetailFormProps {
  itemCode: string | undefined;
}
export const ItemsDetailForm = ({
  itemCode,
}: ItemsDetailFormProps) => {
  const { data: itemTypeData } = useQuery(items.codeSearch({
    query: {
      code: itemCode,
    },
  }));
  const itemType = itemTypeData?.data?.rows?.[0]?.itemType;

  const { data: item } = useQuery(items.typeGet({
    query: {
      code: itemCode,
      itemType : itemType
    },
  }));
  
  const itemData = item?.data?.rows?.[0];

  const lotData = itemData?.lots;
  const formData: Partial<OperationFormProps> = {
    operationRows: itemData?.operations ?? [],
  };
  const unitText = itemData?.unitText;

  return (
    <Detail.Container>
      <Detail.Wrapper>
        <Detail.Content>
            {itemData && <Header itemData={itemData} />}
            <Tabs
              variant="outline"
              defaultValue="lot"
              styles={(theme) => ({
                tab: {
                  "&:not([data-active])": {
                    backgroundColor: theme.colors?.gray?.[0],
                  },
                },
              })}
            >
              <Tabs.List>
                <LeftSpace />
                <Tabs.Tab value="lot">LOT</Tabs.Tab>
                {(itemType === "PRODUCT" || itemType === "SEMI_PRODUCT") && <Tabs.Tab value="operation">BOM</Tabs.Tab>}
                {(itemType === "PRODUCT" || itemType === "SEMI_PRODUCT") && <Tabs.Tab value="specifications">검사표준</Tabs.Tab>}
                <RightSpace />
              </Tabs.List>

              <Tabs.Panel value="lot">
                <LotList lotsData={lotData} unitText={unitText} />
              </Tabs.Panel>
              {(itemType === "PRODUCT" || itemType === "SEMI_PRODUCT") && (
                <Tabs.Panel value="operation">
                  <OperationBomList formData={formData} />
                </Tabs.Panel>
              )}
              {(itemType === "PRODUCT" || itemType === "SEMI_PRODUCT") && (
                <Tabs.Panel value="specifications">
                  <InspectSpecificationsByItemTable itemCode={itemCode} />
                </Tabs.Panel>
              )}
            </Tabs>
        </Detail.Content>
      </Detail.Wrapper>
    </Detail.Container>
  );
};

const ItemDetailFormContainer = styled.div`
  width: 75rem;
`;

const LeftSpace = styled.span`
  width: 32px;
  align-self: stretch;
  background: ${theme.colors?.gray?.[3]};
  border-bottom: ${theme.colors?.gray?.[3]} solid 1px;
  opacity: 0.2;
`;

const RightSpace = styled.span`
  flex: 1 0 0;
  align-self: stretch;
  background: ${theme.colors?.gray?.[3]};
  border-bottom: ${theme.colors?.gray?.[3]} solid 1px;
  opacity: 0.2;
`;
