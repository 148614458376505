import { AbstractItemCreateForm } from "@/features/abstractItem/form/createForm";
import { AbstractItemTable } from "@/features/abstractItem/table";
import { useModal } from "@/features/modal/ModalStackManager";
import { ActionHeader } from "@/features/standard/ActionHeader";
import { Header } from "@/features/standard/Header";
import { Main } from "@/features/standard/Main";
import { HeaderSubTitle } from "@/features/standard/SubTitle";
import { HeaderTitle } from "@/features/standard/Title";
import { Wrapper } from "@/features/standard/Wrapper";
import { usePub } from "@/hooks";
import { Button } from "@mantine/core";
import { IconPlus, IconTrash } from "@tabler/icons-react";

const AbstractItem = Object.assign({}, Main, {
  Header: Header,
  HeaderTitle: HeaderTitle,
  HeaderSubTitle: HeaderSubTitle,
  Wrapper: Wrapper,
  ActionHeader: ActionHeader,
  ActionButton: Button,
  CreateForm: AbstractItemCreateForm,
});

export const AbstractItemPage = () => {
  const { openModal } = useModal();

  const publish = usePub();

  return (
    <AbstractItem.Wrapper>
      <AbstractItem.ActionHeader>
        <AbstractItem.ActionButton
          leftIcon={<IconPlus />}
          onClick={() =>
            openModal(<AbstractItem.CreateForm />, null, "품목군 추가", true)
          }
        >
          추가
        </AbstractItem.ActionButton>
        <AbstractItem.ActionButton
          color="red"
          rightIcon={<IconTrash />}
          onClick={() => publish("deleteSelectedItems")}
        >
          선택된 행 삭제
        </AbstractItem.ActionButton>
      </AbstractItem.ActionHeader>
      <AbstractItem.Header>
        <AbstractItem.HeaderTitle>품목군</AbstractItem.HeaderTitle>
        <AbstractItem.HeaderSubTitle>
          우리 회사에서 다루는 품목군에 대해 조회할 수 있는 페이지 입니다.
        </AbstractItem.HeaderSubTitle>
      </AbstractItem.Header>
      <AbstractItemTable />
    </AbstractItem.Wrapper>
  );
};
