import { MasterInstance } from "@/instance/axios";
import { createMutationKeys, createQueryKeys, mergeQueryKeys } from "@lukemorales/query-key-factory";
import { MasterApiUsersFindPostRequest, MasterApiUsersGetRequest, MasterApiUsersPostRequest, MasterApiUsersUserIdDeleteRequest, MasterApiUsersUserIdPutRequest } from "@sizlcorp/sizl-api-document/dist/models";

export const users = createQueryKeys('users', {
    all: null,
    get: (params: MasterApiUsersGetRequest) => {
        const query = { ...params }; // params 객체를 복사합니다.

        if (Array.isArray(params.query.$and) && params.query.$and.length > 0) {
            query["query"] = JSON.stringify(params.query);
        }
        // params.search가 있을 때만 query.search와 query.searchFields를 설정합니다.
        if (params.search) {
            query["search"] = params.search;
            query["searchFields"] = params.searchFields;
        }
        return {
            queryKey: [query, JSON.stringify(query.query)],
            queryFn: () => MasterInstance.usersGet(query)
        }
    },
    find: (params: MasterApiUsersFindPostRequest) => {
        const query = { ...params }; // params 객체를 복사합니다.

        if (Array.isArray(params.query.$and) && params.query.$and.length > 0) {
            query["query"] = JSON.stringify(params.query);
        }
        // params.search가 있을 때만 query.search와 query.searchFields를 설정합니다.
        if (params.search) {
            query["search"] = params.search;
            query["searchFields"] = params.searchFields;
        }
        return {
            queryKey: [query, JSON.stringify(query.query)],
            queryFn: () => MasterInstance.usersFindPost(query)
        }
    },
    who : (params) => {
        const token = localStorage.getItem('authToken') ?? "";
        return {
            queryKey: [params],
            queryFn: () => MasterInstance.authWhoamiGet({ headers: { Authorization: `Bearer ${token}` } })
        }
    }
});

export const mutateUsers = createMutationKeys('users', {
    create: (params: MasterApiUsersPostRequest) => ({
        mutationKey: [params.authSignupPostRequest],
        mutationFn: () => MasterInstance.usersPost(params),
    }),
    update: (params: MasterApiUsersUserIdPutRequest) => ({
        mutationKey: [params.userId, params.usersUserIdDeleteRequest],
        mutationFn: () => MasterInstance.usersUserIdPut(params)
    }),
    delete: (params: MasterApiUsersUserIdDeleteRequest) => ({
        mutationKey: [params.userId],
        mutationFn: () => MasterInstance.usersUserIdDelete(params)
    }),
});

export const usersQueryKeys = mergeQueryKeys(users, mutateUsers);