import { FormButtonBox } from "@/features/standard/Form/FormButtonBox";
import { FormWrapper } from "@/features/standard/Form/FormWrapper";

import { mutateInventories } from "@/api/inventories/useInventoriesQuery";
import { items } from "@/api/items/useItemQuery";
import { useModal } from "@/features/modal/ModalStackManager";
import customAlert from "@/features/ui/alert/alert";
import { ItemAutoComplete } from "@/features/ui/autoComplete/item/item-autoComplete";
import { LocationAutoComplete } from "@/features/ui/autoComplete/location/location-autoComplate";
import { LotAutoComplete } from "@/features/ui/autoComplete/lot/lot-autoComplete";
import { getPrinterCodeByUserWithWhoami } from "@/utils/checkData";
import { validateFormValues } from "@/utils/validation";
import { Button, NumberInput, TextInput } from "@mantine/core";
import { DateInput } from "@mantine/dates";
import { useForm } from "@mantine/form";
import { DefaultApiWmsStockSerialPostRequest, WmsStockSerialPostRequest, WorkLogsWorkLogIdCancelStartPut200ResponseCancelConsumeInnerLotInfo } from "@sizlcorp/sizl-api-document/dist/models";
import { useRef } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";

const Create = Object.assign(
    {},
    {
        Button: Button,
        Input: TextInput,
        Date: DateInput,
        NumberInput: NumberInput,
        Wrapper: FormWrapper,
        ButtonBox: FormButtonBox,
        ItemAutoComplete: ItemAutoComplete,
        LocationAutoComplete: LocationAutoComplete,
        LotAutoComplete: LotAutoComplete,
    }
);


export const IncomingAllForm = () => {
    const { closeModal } = useModal();
    // const { mutate: postMutate } = useInventoryMutation("incomingAll"); 

    const queryClient = useQueryClient();
    const { mutate: incomingAllMutate } = useMutation(
        (params: DefaultApiWmsStockSerialPostRequest) =>
            mutateInventories.incomingAll(params).mutationFn(params as DefaultApiWmsStockSerialPostRequest | any),
        {
            onSuccess: () => {
                queryClient.invalidateQueries("inventories");
            }
        }
    )
    const form = useForm<Partial<WmsStockSerialPostRequest>>({
        initialValues: {
            itemCode: undefined,
            locationCode: undefined,
            quantity: undefined,
            lotInfo: {
                name: undefined,
                expiration: undefined,
            },
            additional: {},
            num: undefined
        },
    });
    const { data: itemData } = useQuery(items.codeSearch({
        query: form.values.itemCode
            ? { $and: [{ code: { $eq: form.values.itemCode } }] }
            : "",
    }));

    const isSubmitting = useRef(false); // 추가

    const onSubmit = () => {
        if (isSubmitting.current) return; // 중복 요청 방지

        isSubmitting.current = true;
        try {
            const { itemCode, locationCode, quantity, lotInfo, num } = form.values;

            incomingAllMutate(
                {
                    wmsStockSerialPostRequest: {
                        itemCode: itemCode as string,
                        locationCode: locationCode as string,
                        quantity: quantity as string,
                        lotInfo: lotInfo as WorkLogsWorkLogIdCancelStartPut200ResponseCancelConsumeInnerLotInfo | null,
                        num: Number(num),
                        actionFrom: 'WEB'
                    },
                },
                {
                    onSuccess: async (res: { data: any; status: number }) => {
                        closeModal(form.values);
                        if (res.status === 200) {
                            customAlert(
                                "재고 입고에 성공하였습니다.",
                                "재고 입고 성공",
                                "green"
                            )
                            const lotInfo = res.data.map((d: any) => d.lotData.name);
                            fetch('https://598h0folg6.execute-api.ap-northeast-2.amazonaws.com/dev/barcode/print', {
                                method: 'POST',
                                headers: {
                                    'Content-Type': 'application/json'
                                },
                                body: JSON.stringify({
                                    "company_code": "7KH99D", // TODO: 환경변수로 관리?
                                    "lot_name": lotInfo,
                                    "printer_code": await getPrinterCodeByUserWithWhoami(),
                                    "quantity": 1, // 몇장 뽑을 것인지에 대한 quantity 정보
                                    "location_code": locationCode,
                                    "item_code": itemCode,

                                })
                            }).then(response => response.json()
                            ).then(data => console.log(data)
                            ).catch((error) => {
                                console.error('Error:', error);
                            });

                        } else {
                            customAlert(
                                "재고 입고에 실패하였습니다.",
                                "재고 입고 실패",
                                "red"
                            )
                        }
                        isSubmitting.current = false;
                    },
                }
            );
        } catch (e) {
            customAlert(
                "재고 입고에 실패하였습니다.",
                "재고 입고 실패",
                "red"
            )
            isSubmitting.current = false;
        }
    };

    const item = itemData?.data?.rows && itemData?.data?.rows.find((row) => row.code === form.values.itemCode);

    return (
        <>
            <Create.Wrapper>
                <Create.ItemAutoComplete
                    label="품목코드"
                    withAsterisk
                    {...form.getInputProps("itemCode")}
                />
                <Create.LocationAutoComplete
                    label="로케이션코드"
                    withAsterisk
                    {...form.getInputProps("locationCode")}
                />
                <Create.Input
                    label="수량"
                    withAsterisk
                    type="number"
                    {...form.getInputProps("quantity")}
                />
                <Create.Input
                    label="로트명"
                    description={`${form.values.itemCode &&
                        item?.usesLotName
                        ? ""
                        : "LOT 관리 기준 : 이름"
                        }`}
                    styles={
                        form.values.itemCode &&
                            item?.usesLotName
                            ? {
                                description: { fontSize: 10, color: "red" },
                                input: { fontSize: "0.8em", paddingBottom: "0.5rem" },
                            }
                            : {
                                description: { fontSize: 10, color: "red" },
                            }
                    }
                    disabled={
                        form.values.itemCode &&
                            item?.usesLotName ? false : true
                    }
                    withAsterisk
                    type="text"
                    {...form.getInputProps("lotInfo.name")}
                />
                <Create.Date
                    label="로트 유효기한"
                    clearable
                    description={`${form.values.itemCode &&
                        item?.usesLotExpiration
                        ? ""
                        : "LOT 관리 기준 : 만료일"
                        }`}
                    styles={
                        form.values.itemCode &&
                            item?.usesLotExpiration
                            ? {
                                description: { fontSize: 10, color: "red" },
                                input: { fontSize: "0.8em", paddingBottom: "0.5rem" },
                            }
                            : {
                                description: { fontSize: 10, color: "red" },
                            }
                    }
                    disabled={
                        form.values.itemCode &&
                            item?.usesLotExpiration ? false : true
                    }
                    withAsterisk={
                        form.values.itemCode &&
                            item?.usesLotExpiration ? true : false
                    }
                    valueFormat="YYYY-MM-DD"
                    {...form.getInputProps("lotInfo.expiration")}
                />
                <Create.NumberInput
                    label="입고할 로트 갯수"
                    withAsterisk
                    type="number"
                    {...form.getInputProps("num")}
                />
                <Create.ButtonBox>
                    <Create.Button color="gray" onClick={closeModal}>
                        취소
                    </Create.Button>
                    <Create.Button
                        disabled={
                            validateFormValues({
                                pageType: "incomingAll",
                                values: form.values,
                            }) || (item?.usesLotExpiration && !form.values.lotInfo?.expiration)
                        }
                        onClick={onSubmit}>재고 일괄 입고</Create.Button>
                </Create.ButtonBox>
            </Create.Wrapper >
        </>
    );
};
