import styled from "@emotion/styled";
import { Paper } from "@mantine/core";

interface HeaderProps {
  children: React.ReactNode;
}
export const Header = ({ children }: HeaderProps) => {
  return <HeaderWrapper withBorder>{children}</HeaderWrapper>;
};

const HeaderWrapper = styled<any>(Paper)`
  display: flex;
  padding: 0px 12px;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  align-self: stretch;
`;
