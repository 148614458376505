import { Flex, FlexProps, Text } from "@mantine/core";

export const DetailContent = (params: FlexProps) => {
  const { children } = params;

  return (
    <Flex
      p={"2px 0px"}
      direction={"column"}
      justify={"center"}
      align={"center"}
      {...params}
    >
      <Text fz="sm" fw={500}>
        {children}
      </Text>
    </Flex>
  );
};
