import { purchaseOrderItems } from "@/api/purchaseOrderItems/usePurchaseOrderItems";
import { Main } from "@/features/standard/Main";
import { DetailContainer } from "@/features/standard/detail/Container";
import { DetailContent } from "@/features/standard/detail/Content";
import { DetailWrapper } from "@/features/standard/detail/Wrapper";
import { useQuery } from "react-query";
import { PurchaseOrderItemDetailHeader } from "../header/purchaseOrderItemDetailHeader";

const Detail = Object.assign(Main, {
    Container: DetailContainer,
    Wrapper: DetailWrapper,
    Content: DetailContent,
})

interface PurchaseOrderItemDetailFormProps {
    contractorName: string;
}

export const PurchaseOrderItemDetailForm = (params: PurchaseOrderItemDetailFormProps) => {

    const { contractorName } = params;

    const { data: purchaseData } = useQuery(purchaseOrderItems.get({
        query: {
            $and: [
                { contractorName: { $eq: contractorName } }
            ]
        }
    }))

    return (
        <>
            <Detail.Container>
                <Detail.Wrapper>
                    <Detail.Content>
                        <PurchaseOrderItemDetailHeader data={purchaseData?.data?.rows?.[0]} />
                    </Detail.Content>
                </Detail.Wrapper>
            </Detail.Container>
        </>
    )
};
