import { ThemeIcon, ThemeIconProps } from "@mantine/core";

export const BadgeIcon = (params: ThemeIconProps) => {
    const { children } = params;

    return (
        <ThemeIcon
            variant="Outline"
            size="lg"
            color="violet"
            style={{ border: 'none' }}
        >
            {children}
        </ThemeIcon>
    )
}