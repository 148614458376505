import DefaultInstance from "@/instance/axios";
import { ItemsGet200ResponseRowsInnerLotsInner } from "@sizlcorp/sizl-api-document/dist/models";
import { AxiosResponse } from "axios";
import { useQuery } from "react-query";

export type LotOption = {
  value: string;
  label: string;
  expiration: string;
  group: string;
};

const getSelectCompleteKeyword = (keyword: string) =>
  DefaultInstance.lotsFindPost({
    search: keyword ? keyword : "",
    searchFields: ["itemCode"],
  }).then(
    (res: AxiosResponse<ItemsGet200ResponseRowsInnerLotsInner[]>) => res.data
  );

export const useLotsSelectAutoComplate = (
  focus = false,
  keyword = "",
  options?: {
    onSuccess: (data: LotOption[]) => void;
  }
) => {
  return useQuery(
    ["lots-get-autocomplete", keyword ? keyword : ""],
    () => getSelectCompleteKeyword(keyword ? keyword : ""),
    {
      enabled: focus,
      select: (data: ItemsGet200ResponseRowsInnerLotsInner[]) =>
        data?.map((lot) => {
          return {
            value: String(lot.id),
            label: String(lot.name) === "null" ? "-" : String(lot.name),
            expiration: String(lot.expiration),
            group: String(lot.itemCode),
          };
        }) ?? [],
      onSuccess: options?.onSuccess,
    }
  );
};

const getLotById = (id: number) =>
  DefaultInstance.lotsLotIdGet({
    lotId: id,
  }).then(
    (res: AxiosResponse<ItemsGet200ResponseRowsInnerLotsInner>) => res.data
  );

const useGetLotsIdQuery = (id: number, enabled: boolean) => {
  return useQuery(["lots-get-id", id], () => getLotById(id), {
    enabled: enabled ?? false,
  });
};

export default useGetLotsIdQuery;
