import { ProductionInstance } from "@/instance/axios";
import {
    ProductionActionApiWorkLogsWorkLogIdCancelInputPutRequest
} from "@sizlcorp/sizl-api-document/dist/models";
import { useMutation } from "react-query";

interface CancelParams extends ProductionActionApiWorkLogsWorkLogIdCancelInputPutRequest { }

type MutationType = "cancel"

type Params<T extends MutationType> = T extends "cancel" ? CancelParams : never;

const cancelInputFetcher = (params: CancelParams) =>
    ProductionInstance.workLogsWorkLogIdCancelInputPut(params);

const useCancelInputPutMutation = <T extends MutationType>(type: T) => {

    const cancelMutation = useMutation(
        (params: Params<typeof type>) => cancelInputFetcher(params as CancelParams),
    );

    if (type === "cancel") {
        return cancelMutation;
    } else throw new Error("Invalid mutation type");
};

export default useCancelInputPutMutation;
