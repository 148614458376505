import styled from "@emotion/styled";

interface BadgeContainerProps {
    children: React.ReactNode;
}
export const BadgeContainer = ({ children } : BadgeContainerProps) => {
    return (
        <Container>{children}</Container>
    )
}

const Container = styled.div`
    display : flex;
    height : 4rem;
    align-items : flex-start;
    align-self : stretch;
`