import { DefectTypeItem } from "@/features/ui/DefectTypeSet";
import DefaultInstance from "@/instance/axios";
import { createQueryKeys } from "@lukemorales/query-key-factory";
import { DefaultApiEquipmentsGetRequest, DefaultApiProductionPlansGetRequest, DefaultApiWorksGetRequest } from "@sizlcorp/sizl-api-document/dist/models";

interface ExtendedWorkPRequest extends 
DefaultApiWorksGetRequest {
    defectCodes: DefectTypeItem[] | null;
}

interface ExtendedEquipmentRequest extends
DefaultApiEquipmentsGetRequest {
    defectCodes: DefectTypeItem[] | null;
    scheduledAt?: string;
}
interface ExtendedProductionPlanRequest extends
DefaultApiProductionPlansGetRequest {
    defectCodes: DefectTypeItem[] | null;
}


export const workPage = createQueryKeys('workPage', {
    all : null,
    work : (params : ExtendedWorkPRequest) => {
        // query 객체 생성 및 초기화
        const query = { ...params };
    
        // params.query 처리
        if (Array.isArray(params.query?.$and) && params.query?.$and.length > 0) {
            query["query"] = JSON.stringify(params.query);
        }
    
        // defectCodes 처리
        const defectCodes = params?.defectCodes?.map((data: DefectTypeItem) => data.value) ?? ["null"];
    
        // queryKey와 queryFn 반환
        return {
            queryKey: [JSON.stringify(query.query), defectCodes, params],
            queryFn: async () => {
                try {
                    const response = await DefaultInstance.worksGet(
                        {
                            query: query.query,
                            page: params.page,
                            pageSize: 10,
                            populate : ['summary', 'item', 'equipment', 'productionPlan', 'operationData', 'purchaseOrderItem', 'locationSetting'],
                        },
                        {
                            params: {
                                defectCodes: defectCodes,
                                sort: query.sort,
                            }
                        }
                    );
                    
                    return response.data ?? response.data;
                } catch (error) {
                    console.error(error, 'API Error');
                    throw error;
                }
            }
        }
    },
    equipment : (params : ExtendedEquipmentRequest) => {
        // query 객체 생성 및 초기화
        const query = { ...params };
    
        // params.query 처리
        if (Array.isArray(params.query?.$and) && params.query?.$and.length > 0) {
            query["query"] = JSON.stringify(params.query);
        }
    
        // defectCodes 처리
        const defectCodes = params.defectCodes?.map((data: DefectTypeItem) => data.value) ?? ["null"];
        
        // queryKey와 queryFn 반환
        return {
            queryKey: [JSON.stringify(query.query), defectCodes, params],
            queryFn: async () => {
                try {
                    const response = await DefaultInstance.equipmentsGet(
                        {
                            query: query.query,
                            pageSize: 10,
                            page: params.page,
                            populate : ['works', 'purchaseOrderItem'],
                        },
                        {
                            params: {
                                defectCodes: defectCodes,
                                scheduledAt : params.scheduledAt,
                                sort: params.sort,
                            }
                        }
                    );
                    return response.data;
                } catch (error) {
                    console.error(error, 'API Error');
                    throw error;
                }
            }
        }
    },
    productionPlan : (params : ExtendedProductionPlanRequest) => {
        // query 객체 생성 및 초기화
        const query = { ...params };
    
        // params.query 처리
        if (Array.isArray(params.query?.$and) && params.query?.$and.length > 0) {
            query["query"] = JSON.stringify(params.query);
        }
    
        // defectCodes 처리
        const defectCodes = params.defectCodes?.map((data: DefectTypeItem) => data.value) ?? ["null"];
    
        // queryKey와 queryFn 반환
        return {
            queryKey: [JSON.stringify(query.query), defectCodes, params],
            queryFn: async () => {
                try {
                    const response = await DefaultInstance.productionPlansGet(
                        {
                            query: query.query,
                            page: params.page,
                            pageSize: 10,
                            populate : ['works', 'equipment', 'itemUnit', 'purchaseOrderItem'],
                        },
                        {
                            params: {
                                defectCodes: defectCodes,
                                sort: query.sort,
                            }
                        }
                    );
                    return response.data;
                } catch (error) {
                    console.error(error, 'API Error');
                    throw error;
                }
            }
        }
    }
});