import { WorkBox } from "@/features/monitoring/components/childrenBox/WorkBox";
import { WorkBoxTitle } from "@/features/monitoring/components/childrenBox/WorkBoxTitle";
import { WorkStatusBox } from "@/features/monitoring/components/childrenBox/WorkStatusBox";
import { WorkTextBox } from "@/features/monitoring/components/childrenBox/WorkTextBox";
import { WorkValueText } from "@/features/monitoring/components/childrenBox/WorkValueText";
import { Parents } from "@/features/monitoring/components/parentsBox/Parents";
import { ParentsBox } from "@/features/monitoring/components/parentsBox/ParentsBox";
import { ParentsBoxDiv } from "@/features/monitoring/components/parentsBox/ParentsBoxDiv";
import { ParentsBoxText } from "@/features/monitoring/components/parentsBox/ParentsBoxText";
import { ParentsBoxTextDiv } from "@/features/monitoring/components/parentsBox/ParentsBoxTextDiv";
import { Main } from "@/features/standard/Main";
import { EquipmentStatusColor } from "@/utils/equipmentStatus";
import SeconeTimeUtil from "@/utils/secondTimeUtil";
import { setFilteringDecimalPoint, setToLocaleString } from "@/utils/unitMark";
import { Box } from "@mantine/core";
import { useEffect, useState } from "react";

const Monitoring = Object.assign({}, Main, {
    Parents: Parents,
    ParentsBox: ParentsBox,
    ParentsBoxDiv: ParentsBoxDiv,
    ParentsBoxTextDiv: ParentsBoxTextDiv,
    ParentsBoxText: ParentsBoxText,
    WorkStatusBox: WorkStatusBox,
    WorkBox: WorkBox,
    WorkTextBox: WorkTextBox,
    WorkText: WorkBoxTitle,
    Box: Box,
    WorkValueText: WorkValueText,
});

interface MonitoringBoxProps {
    data: any;
    index: number;
}

export const MonitoringBox = (params: MonitoringBoxProps) => {
    const { data, index } = params;

    const [highlight, setHighlight] = useState<{ [key: string]: boolean }>({
        spm: true,
        presetCounter: true,
        totalCounter: true,
        runTime: true,
        idleTime: true,
        runTimeRatio: true,
        moldCode: true,
        keyCamStatus: true,
        remainTime: true,
        numeratorLoad: true,
        denominatorLoad: true,
        limitCounter: true,
        moldCavity : true,
    });
    
    const numeratorLoad = data?.numeratorLoad === undefined ? "0" : data?.numeratorLoad;
    const denominatorLoad = data?.denominatorLoad === undefined ? "0" : data?.denominatorLoad;
    const runTimeRatio = data?.runTimeRatio === undefined ? "0" : setFilteringDecimalPoint(data?.runTimeRatio, 2);
    const idleTime = data?.idleTime === undefined ? "00:00:00" : data?.idleTime;
    const runTime = data?.runTime === undefined ? "00:00:00" : data?.runTime;
    const moldCode = data?.moldCode === undefined ? "-" : data?.moldCode;
    const spm = data?.spm === undefined ? "0" : data?.spm;
    const presetCounter = data?.presetCounter === undefined ? "0" : setToLocaleString(data?.presetCounter);
    const limitCounter = data?.limitCounter === undefined ? "0" : setToLocaleString(data?.limitCounter);
    const totalCounter = data?.totalCounter === undefined ? "0" : setToLocaleString(data?.totalCounter);
    const keyCamStatus = data?.keyCamStatus === undefined ? "OFF" : data?.keyCamStatus;
    const remainTime = data?.remainTime === '-1' ? '∞' : SeconeTimeUtil(data?.remainTime);
    const moldCavity = data?.moldCavity === undefined ? "0" : data?.moldCavity;

    const [prevData, setPrevData] = useState<any>(null);

    useEffect(() => {
        if (data && prevData) {
            Object.keys(data).forEach(key => {
                if (data[key] !== prevData[key]) {
                    setHighlight(prevState => ({ ...prevState, [key]: true }));
                    const timer = setTimeout(() => {
                        setHighlight(prevState => ({ ...prevState, [key]: false }));
                    }, 500);
                    return () => clearTimeout(timer);
                }
            });
        }
        setPrevData(data);
    }, [data]);

    return (
        <>
            <Monitoring.ParentsBox key={index}
            >
                <Monitoring.ParentsBoxDiv height="30%">
                    <Monitoring.ParentsBoxTextDiv flex={1}>
                        <Monitoring.ParentsBoxText>
                            기계명 : {data?.equipmentName}
                        </Monitoring.ParentsBoxText>
                        <Monitoring.ParentsBoxText>
                            품목코드 : {data?.itemCode}
                        </Monitoring.ParentsBoxText>
                        <Monitoring.ParentsBoxText>
                            규격 : {data?.itemSpec}
                        </Monitoring.ParentsBoxText>
                    </Monitoring.ParentsBoxTextDiv>
                    <Monitoring.ParentsBoxTextDiv flex={2}>
                        <Monitoring.ParentsBoxText>
                            P넘버 : {data?.influxEquipmentCode}
                        </Monitoring.ParentsBoxText>
                        <Monitoring.ParentsBoxText>
                            생산품목 : {data?.itemName}
                        </Monitoring.ParentsBoxText>
                        <Monitoring.ParentsBoxText>
                            담당자 : {data?.worker}
                        </Monitoring.ParentsBoxText>
                    </Monitoring.ParentsBoxTextDiv>
                </Monitoring.ParentsBoxDiv>
                <Monitoring.ParentsBoxDiv height="70%">
                    <Monitoring.WorkStatusBox backgroundColor={EquipmentStatusColor[data?.pressStatus as string]}>
                        {data?.pressStatus}
                    </Monitoring.WorkStatusBox>
                    <Monitoring.Box w="85%">
                        <Monitoring.WorkBox>
                            <Monitoring.WorkTextBox highlight={highlight.spm}>
                                <Monitoring.WorkText>
                                    SPM
                                </Monitoring.WorkText>
                                <Monitoring.WorkValueText>
                                    {spm}
                                </Monitoring.WorkValueText>
                            </Monitoring.WorkTextBox>
                            <Monitoring.WorkTextBox highlight={highlight.presetCounter}>
                                <Monitoring.WorkText>
                                    프리셋 카운터
                                </Monitoring.WorkText>
                                <Monitoring.WorkValueText>
                                    {presetCounter} / {limitCounter}
                                </Monitoring.WorkValueText>
                            </Monitoring.WorkTextBox>
                            <Monitoring.WorkTextBox highlight={highlight.totalCounter}>
                                <Monitoring.WorkText>
                                    종합 카운터
                                </Monitoring.WorkText>
                                <Monitoring.WorkValueText>
                                    {totalCounter}
                                </Monitoring.WorkValueText>
                            </Monitoring.WorkTextBox>
                            <Monitoring.WorkTextBox highlight={highlight.runTime}>
                                <Monitoring.WorkText>
                                    기계 가동시간
                                </Monitoring.WorkText>
                                <Monitoring.WorkValueText>
                                    {runTime}
                                </Monitoring.WorkValueText>
                            </Monitoring.WorkTextBox>
                            <Monitoring.WorkTextBox highlight={highlight.idleTime}>
                                <Monitoring.WorkText>
                                    기계 비가동시간
                                </Monitoring.WorkText>
                                <Monitoring.WorkValueText>
                                    {idleTime}
                                </Monitoring.WorkValueText>
                            </Monitoring.WorkTextBox>
                            <Monitoring.WorkTextBox highlight={highlight.runTimeRatio}>
                                <Monitoring.WorkText>
                                    기계 가동률
                                </Monitoring.WorkText>
                                <Monitoring.WorkValueText>
                                    {runTimeRatio} %
                                </Monitoring.WorkValueText>
                            </Monitoring.WorkTextBox>
                        </Monitoring.WorkBox>
                        <Monitoring.WorkBox>
                            <Monitoring.WorkTextBox highlight={highlight.moldCode}>
                                <Monitoring.WorkText>
                                    금형코드
                                </Monitoring.WorkText>
                                <Monitoring.WorkValueText>
                                    {moldCode}
                                </Monitoring.WorkValueText>
                            </Monitoring.WorkTextBox>
                            <Monitoring.WorkTextBox highlight={highlight.remainTime}>
                                <Monitoring.WorkText>
                                    생산 남은 시간
                                </Monitoring.WorkText>
                                <Monitoring.WorkValueText>
                                    {remainTime}
                                </Monitoring.WorkValueText>
                            </Monitoring.WorkTextBox>
                            <Monitoring.WorkTextBox highlight={highlight.keyCamStatus}>
                                <Monitoring.WorkText>
                                    키캠상태
                                </Monitoring.WorkText>
                                <Monitoring.WorkValueText>
                                    {keyCamStatus}
                                </Monitoring.WorkValueText>
                            </Monitoring.WorkTextBox>
                            <Monitoring.WorkTextBox highlight={highlight.presetCounter}>
                                <Monitoring.WorkText>
                                    생산수량
                                </Monitoring.WorkText>
                                <Monitoring.WorkValueText>
                                    {presetCounter}
                                </Monitoring.WorkValueText>
                            </Monitoring.WorkTextBox>
                            <Monitoring.WorkTextBox backgroundColor="gray" highlight={highlight.numeratorLoad}>
                                <Monitoring.WorkText color="white">
                                    부하율
                                </Monitoring.WorkText>
                                <Monitoring.WorkValueText color="white">
                                    {`${setFilteringDecimalPoint(data?.loadRatio)} % \n${numeratorLoad} / ${denominatorLoad}`}
                                </Monitoring.WorkValueText>
                            </Monitoring.WorkTextBox>
                            <Monitoring.WorkTextBox highlight={highlight.moldCavity}>
                                <Monitoring.WorkText>
                                    캐비티
                                </Monitoring.WorkText>
                                <Monitoring.WorkValueText>
                                    {moldCavity}
                                </Monitoring.WorkValueText>
                            </Monitoring.WorkTextBox>
                        </Monitoring.WorkBox>
                    </Monitoring.Box>
                </Monitoring.ParentsBoxDiv>
                {data?.pressStatus === "에러"
                    ?
                    <div style={{padding : '8px'}}>
                        에러명: {data?.errorName}
                    </div>
                    : null
                }
            </Monitoring.ParentsBox>
        </>
    )
};