import { theme } from "@/styles/theme";
import styled from "@emotion/styled";
import { Button, Select, Table, TextInput } from "@mantine/core";
import { DateTimePicker } from "@mantine/dates";
import { IconCalendar, IconPlus, IconTrash } from "@tabler/icons-react";
import { useState } from "react";

export const WorkOrderTable = ({ data }: any) => {
  const [content, setContent] = useState([
    {
      date: new Date(),
      product: "",
      process: "",
      equipmentCode: "",
      amount: "",
    },
  ]);

  // const handleClickAdd = () => {
  //   setContent((prev) => [
  //     ...prev,
  //     {
  //       date: new Date(),
  //       product: "",
  //       process: "",
  //       equipmentCode: "",
  //       amount: "",
  //     },
  //   ]);
  // };

  // const handleClickDelete = (index: any) => {
  //   if (content.length <= 1) return;
  //   setContent(content.filter((_, listIdx) => listIdx !== index));
  // };

  // const handleChange = (index: number, cell: { id: string; value: string }) => {
  //   const list = [...content] as any;
  //   list[index][cell.id] = cell.value;
  //   setContent(list);
  // };

  return (
    <>
      <TableContainer>
        <thead>
          <tr>
            <th>일자</th>
            <th>품목코드</th>
            <th>공정</th>
            <th>설비</th>
            <th>목표수량</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {content.map((item, index) => (
            <>
              <tr key={index}>
                <td>
                  <DateTimePicker
                    id="date"
                    icon={<IconCalendar size="1.2rem" />}
                    value={item?.date}
                    defaultValue={new Date()}
                    size="md"
                    style={{ overflow: "hidden", whiteSpace: "nowrap" }}
                    placeholder={"DateTime"}
                  // onChange={(date: Date) => {
                  //   handleChange(index, {
                  //     id: "date",
                  //     value: date,
                  //   } as any);
                  // }}
                  />
                </td>
                <td>
                  <Select
                    id="product"
                    value={item.product}
                    size="md"
                    // onChange={(value: string) =>
                    //   handleChange(index, {
                    //     id: "product",
                    //     value,
                    //   } as any)
                    // }
                    data={["React", "Angular", "Vue", "Svelte"]}
                  />
                </td>
                <td>
                  <Select
                    id="process"
                    value={item.process}
                    size="md"
                    // onChange={(value: string) =>
                    //   handleChange(index, {
                    //     id: "process",
                    //     value,
                    //   } as any)
                    // }
                    data={["React", "Angular", "Vue", "Svelte"]}
                  />
                </td>
                <td>
                  <Select
                    id={"equipmentCode"}
                    value={item.equipmentCode}
                    size="md"
                    // onChange={(value: string) =>
                    //   handleChange(index, {
                    //     id: "equipmentCode",
                    //     value,
                    //   } as any)
                    // }
                    data={["React", "Angular", "Vue", "Svelte"]}
                  />
                </td>
                <td>
                  <TextInput
                    id="amount"
                    size="md"
                  // onChange={(e) =>
                  //   handleChange(index, {
                  //     id: e.target.id,
                  //     value: e.target.value,
                  //   } as any)
                  // }
                  />
                </td>
                <td>
                  <DeleteButton
                    leftIcon={<IconTrash size="2rem" />}
                  // onClick={() => handleClickDelete(index)}
                  >
                    삭제
                  </DeleteButton>
                </td>
              </tr>
              {content.length - 1 === index && content.length <= 10 && (
                <AddButtonTr>
                  <AddButton
                    leftIcon={<IconPlus size="2rem" />}
                  // onClick={handleClickAdd}
                  >
                    작업 지시 추가
                  </AddButton>
                </AddButtonTr>
              )}
            </>
          ))}
        </tbody>
      </TableContainer>
    </>
  );
};

const TableContainer = styled<any>(Table)`
  height: 188px;
`;

const AddButtonTr = styled.div`
  width: 100%;
  margin: 0 auto;
`;

const AddButton = styled<any>(Button)`
  width: 100%;
  background-color: ${theme.colors?.white};
  color: black;
  top: 50%;
  left: 250%;
  height: 32px;
  font-size: 14px;

  &:hover {
    background-color: ${theme.colors?.blue?.[0]};
  }
`;

const DeleteButton = styled<any>(Button)`
  color: black;
  background-color: ${theme.colors?.white};
  height: 32px;
  font-size: 14px;

  &:hover {
    background-color: ${theme.colors?.blue?.[0]};
    border-radius: 4px;
  }
`;

const SaveButton = styled<any>(Button)`
  width: 100%;
  margin-top: 8px;
  color: ${theme.colors?.blue?.[6]};
  background-color: ${theme.colors?.white};
  border: 1px solid ${theme.colors?.blue?.[6]};
  height: 32px;
  border-radius: 4px;
  font-size: 14px;

  &:hover {
    background-color: ${theme.colors?.blue?.[0]};
  }
`;
