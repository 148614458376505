import { WorkByEquipmentActionsContext } from "@/features/workByEquipment/context/WorkByEquipmentProvider";
import { useContext } from "react";

export function useWorkByEquipmentActions() {
  const value = useContext(WorkByEquipmentActionsContext);
  if (value === undefined) {
    throw new Error("useCounterActions should be used within CounterProvider");
  }
  return value;
}
