import { LocationAutoComplete } from "@/features/ui/autoComplete/location/location-autoComplate";
import { SitesAutoComplete } from "@/features/ui/autoComplete/site/sites-autoComplete";
import { Box, Button, Code, Text, TextInput } from "@mantine/core";
import { useForm } from "@mantine/form";

export function EquipmentForm(params: {
  name: string;
  onSubmit?: (values: any) => void;
}) {
  const { name, onSubmit } = params;
  const form = useForm({
    initialValues: {
      siteCode: null,
      name: name,
      code: null,
      equipmentType: null,
      seq: null,
      toLocationCode: null,
      targetLocationCode: null
    },
  });

  return (
    <Box maw={320} mx="auto">
      <form onSubmit={form.onSubmit((values) => onSubmit && onSubmit(values))}>
        <SitesAutoComplete
          withAsterisk
          label="사업장코드"
          width={"100%"}
          placeholder="사업장코드"
          {...form.getInputProps("siteCode")}
        />
        <TextInput
          label="name"
          placeholder="name"
          {...form.getInputProps("name")}
        />
        <TextInput
          label="code"
          placeholder="code"
          {...form.getInputProps("code")}
        />
        <TextInput
          label="설비유형"
          placeholder="설비유형"
          {...form.getInputProps("equipmentType")}
        />

        <LocationAutoComplete
          label="입고창고"
          placeholder="입고창고"
          {...form.getInputProps("toLocationCode")}
        />

        <LocationAutoComplete
          label="품목 생산 창고"
          placeholder="품목 생산 창고"
          {...form.getInputProps("targetLocationCode")}
        />
        <TextInput
          type="number"
          label="시퀀스(순서)"
          placeholder="시퀀스(순서)"
          {...form.getInputProps("seq")}
        />
        <Text size="sm" weight={500} mt="xl">
          Form values:
        </Text>
        <Code block mt={5}>
          {JSON.stringify(form.values, null, 2)}
        </Code>
        <Button type="submit">Submit</Button>
      </form>
    </Box>
  );
}
