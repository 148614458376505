import { WorkActionsContext } from "@/features/workByManagement/context/WorkProvider";
import { useContext } from "react";

export function useWorkActions() {
  const context = useContext(WorkActionsContext);
  if (context === undefined) {
    throw new Error("useWorkActions must be used within a WorkProvider");
  }
  return context;
}
