/**
 * 작성자명 : 전찬민
 * 작성일자 : 2024.04.11 (목)
 * 작성내용 : 입력값에 맞는 시간을 초 소수점 둘째자리에서 반올림 한다.
 * 
 * @param TimeString 문자열 형태의 데이터
 * @returns 입력값에 맞게 시간을 초 소수점 둘째자리에서 반올림한 데이터.
 */
const SeconeTimeUtil = (TimeString : string | undefined) => {
    return TimeString?.split(':').map((time, index) => {
        if (index === 2) {
            return Math.round(parseFloat(time)).toString();
        }
        return time;
    }).join(':');
}

export default SeconeTimeUtil;