import { DetailBadge } from "@/features/ui/detail/DetailBadge";
import { DetailContent } from "@/features/ui/detail/DetailContent";
import { DetailField } from "@/features/ui/detail/DetailField";
import { DetailHeader } from "@/features/ui/detail/DetailHeader";
import { DetailInformTitle } from "@/features/ui/detail/DetailInformTitle";
import { DetailLabel } from "@/features/ui/detail/DetailLabel";
import { DetailLink } from "@/features/ui/detail/DetailLink";
import { DetailLinkWrapper } from "@/features/ui/detail/DetailLinkWrapper";
import { DetailTitle } from "@/features/ui/detail/DetailTitle";
import { Checkbox, Space } from "@mantine/core";
import { AuthSignupPost201ResponseEquipmentPurchaseOrderItem } from "@sizlcorp/sizl-api-document/dist/models";
import dayjs from "dayjs";

const PurchaseOrderItemDetail = Object.assign(DetailHeader, {
    Title: DetailTitle,
    Inform: DetailInformTitle,
    Field: DetailField,
    Label: DetailLabel,
    Link: DetailLink,
    LinkWrapper: DetailLinkWrapper,
    Badge: DetailBadge,
    Content: DetailContent,
    CheckBox: Checkbox,
});


export interface PurchaseOrderItemDetailHeaderProps {
    data: AuthSignupPost201ResponseEquipmentPurchaseOrderItem | undefined;
}

export const PurchaseOrderItemDetailHeader = (params: PurchaseOrderItemDetailHeaderProps) => {
    const { data } = params;

    return (
        <>
            <PurchaseOrderItemDetail>
                <PurchaseOrderItemDetail.Title>{`[수주 상세]`}</PurchaseOrderItemDetail.Title>
                <Space h={8} />
                <PurchaseOrderItemDetail.Field>
                    <PurchaseOrderItemDetail.Label>
                        {"수주 id"}
                    </PurchaseOrderItemDetail.Label>
                    <PurchaseOrderItemDetail.Content>
                        {data?.id}
                    </PurchaseOrderItemDetail.Content>
                </PurchaseOrderItemDetail.Field>
                <PurchaseOrderItemDetail.Field>
                    <PurchaseOrderItemDetail.Label>
                        {"업체명"}
                    </PurchaseOrderItemDetail.Label>
                    <PurchaseOrderItemDetail.Content>
                        {data?.contractorName}
                    </PurchaseOrderItemDetail.Content>
                </PurchaseOrderItemDetail.Field>
                <PurchaseOrderItemDetail.Field>
                    <PurchaseOrderItemDetail.Label>
                        {"품목코드"}
                    </PurchaseOrderItemDetail.Label>
                    <PurchaseOrderItemDetail.Content>
                        {data?.itemCode}
                    </PurchaseOrderItemDetail.Content>
                </PurchaseOrderItemDetail.Field>
                <PurchaseOrderItemDetail.Field>
                    <PurchaseOrderItemDetail.Label>
                        {"수량"}
                    </PurchaseOrderItemDetail.Label>
                    <PurchaseOrderItemDetail.Content>
                        {data?.quantityOrdered}
                    </PurchaseOrderItemDetail.Content>
                </PurchaseOrderItemDetail.Field>
                <PurchaseOrderItemDetail.Field>
                    <PurchaseOrderItemDetail.Label>
                        {"접수일"}
                    </PurchaseOrderItemDetail.Label>
                    <PurchaseOrderItemDetail.Content>
                        {dayjs(data?.orderDate).format("YYYY-MM-DD")}
                    </PurchaseOrderItemDetail.Content>
                </PurchaseOrderItemDetail.Field>
                <PurchaseOrderItemDetail.Field>
                    <PurchaseOrderItemDetail.Label>
                        {"납기일"}
                    </PurchaseOrderItemDetail.Label>
                    <PurchaseOrderItemDetail.Content>
                        {dayjs(data?.deliveryDeadline).format("YYYY-MM-DD")}
                    </PurchaseOrderItemDetail.Content>
                </PurchaseOrderItemDetail.Field>
            </PurchaseOrderItemDetail>
        </>
    )
};
