import ProductionInstance from "@/instance/axios";
import {
  DefaultApiProductionPlansProductionPlanIdPutRequest,
  DefaultApiWorksWorkIdDeleteRequest,
  DefaultApiWorksWorkIdPutRequest,
} from "@sizlcorp/sizl-api-document/dist/models";
import { useMutation, useQueryClient } from "react-query";

const QUERY_KEY = "/works";
interface UpdateProductionPlanParams
  extends DefaultApiProductionPlansProductionPlanIdPutRequest {}
interface UpdateParams extends DefaultApiWorksWorkIdPutRequest {}
interface DeleteParams extends DefaultApiWorksWorkIdDeleteRequest {}

type MutationType = "update" | "delete";

type Params<T extends MutationType> = T extends "update"
  ? UpdateParams & UpdateProductionPlanParams
  : DeleteParams;

// post
// -

// put
const PutWorksFetch = (params: UpdateParams & UpdateProductionPlanParams) => {
  ProductionInstance.productionPlansProductionPlanIdPut(params);
  return ProductionInstance.worksWorkIdPut(params);
};
// delete
const DeleteWorksFetch = (workId: DeleteParams) => {
  return ProductionInstance.worksWorkIdDelete(workId);
};

const useWorksMutation = <T extends MutationType>(type: T) => {
  const queryClient = useQueryClient();

  const updateMutation = useMutation(
    (params: Params<typeof type>) =>
      PutWorksFetch(params as UpdateParams & UpdateProductionPlanParams),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(QUERY_KEY);
      },
    }
  );

  const deleteMutation = useMutation(
    (params: Params<typeof type>) => DeleteWorksFetch(params as DeleteParams),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(QUERY_KEY);
      },
    }
  );

  if (type === "update") {
    return updateMutation;
  } else if (type === "delete") {
    return deleteMutation;
  }
  throw new Error("Invalid mutation type");
};

export default useWorksMutation;
