import { mutateSites } from "@/api/sites/useSitesQuery";
import { useModal } from "@/features/modal/ModalStackManager";
import customAlert from "@/features/ui/alert/alert";
import { validateFormValues } from "@/utils/validation";
import styled from "@emotion/styled";
import { Box, Button, TextInput } from "@mantine/core";
import { useForm } from "@mantine/form";
import { MasterApiSitesSiteIdPutRequest } from "@sizlcorp/sizl-api-document/dist/models";
import { useMutation, useQueryClient } from "react-query";

export function SiteUpdateForm(params: { formatterProps: any }) {
  const { closeModal } = useModal();
  const { formatterProps } = params;
  const queryClient = useQueryClient();
  const { mutate: updateMutate } = useMutation(
    (params: MasterApiSitesSiteIdPutRequest) =>
      mutateSites.update(params).mutationFn(params as MasterApiSitesSiteIdPutRequest | any),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('sites')
      }
    }
  );
  const form = useForm({
    initialValues: {
      code: formatterProps.code,
      name: formatterProps.name,
    },
  });

  const onSubmit = async () => {
    try {
      updateMutate(
        {
          siteId: formatterProps.id as number,
          sitesGetRequest: {
            code: form.values.code as any,
            name: form.values.name as any,
          },
        },
        {
          onSuccess: (res) => {
            closeModal(formatterProps);
            if (res.status === 200) {
              customAlert(
                "사업장 수정에 성공하였습니다.",
                "수정 성공",
                "green"
              )
            } else {
              customAlert(
                "사업장 수정에 실패하였습니다.",
                "수정 실패",
                "red"
              )
            }
          },
        } as const
      );
    } catch (e) {
      customAlert(
        "사업장 수정에 실패하였습니다.",
        "수정 실패",
        "red"
      )
    }
  };

  return (
    <Box maw={320} mx="auto">
      <Form>
        <ModalFieldSet>
          <TextInput
            label="사업장코드"
            withAsterisk
            {...form.getInputProps("code")}
            defaultValue={formatterProps.code}
            error={form.isDirty("code") ? "저장 필요" : ""}
            styles={{
              input: {
                color: form.isDirty("code")
                  ? "cadetblue !important"
                  : "inherit",
                borderColor: form.isDirty("code")
                  ? "cadetblue !important"
                  : "inherit",
              },
              error: { fontSize: 11, color: "cadetblue" },
            }}
          />
          <TextInput
            label="사업장명"
            withAsterisk
            {...form.getInputProps("name")}
            defaultValue={formatterProps.name}
            error={form.isDirty("name") ? "저장 필요" : ""}
            styles={{
              input: {
                color: form.isDirty("name")
                  ? "cadetblue !important"
                  : "inherit",
                borderColor: form.isDirty("name")
                  ? "cadetblue !important"
                  : "inherit",
              },
              error: { fontSize: 11, color: "cadetblue" },
            }}
          />
          <ModalConfirmBtnBox>
            <Button color="gray" onClick={closeModal}>
              취소
            </Button>
            <Button
              disabled={validateFormValues({
                pageType: "site",
                values: form.values,
              })}
              onClick={onSubmit}
            >
              사업장 수정
            </Button>
          </ModalConfirmBtnBox>
        </ModalFieldSet>
      </Form>
    </Box>
  );
}

const Form = styled.form`
  width: 100%;
  height: 100%;
`;

const ModalFieldSet = styled.fieldset`
  border: none;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const ModalConfirmBtnBox = styled.div`
  padding: 24px 0;
  display: flex;
  justify-content: flex-end;
  gap: 20px;
  // & button {
  //   width: 54px;
  //   height: 40px;
  //   border-radius: 4px;
  //   &.cancel_btn {
  //     background-color: white;
  //   }
  // }
`;
