import DefaultInstance from "@/instance/axios";
import { createMutationKeys, createQueryKeys, mergeQueryKeys } from "@lukemorales/query-key-factory";
import { DefaultApiOperationsFindPostRequest, DefaultApiOperationsGetRequest, DefaultApiOperationsOperationIdDeleteRequest, DefaultApiOperationsOperationIdGetRequest, DefaultApiOperationsOperationIdPutRequest, DefaultApiOperationsPostRequest } from "@sizlcorp/sizl-api-document/dist/models";

export const operations = createQueryKeys('operations', {
    all: null,
    get: (params: DefaultApiOperationsGetRequest) => {
        const query = { ...params }; // params 객체를 복사합니다.
        // params.query.$and가 배열이고 길이가 0보다 클 때만 query.query를 설정합니다.
        if (Array.isArray(params.query.$and) && params.query.$and.length > 0) {
            query["query"] = JSON.stringify(params.query);
        }
        // params.search가 있을 때만 query.search와 query.searchFields를 설정합니다.
        if (params.search) {
            query["search"] = params.search;
            query["searchFields"] = params.searchFields;
        }
        if (params.populate?.length) {
            query["populate"] = params.populate;
        }
        return {
            queryKey: [query, JSON.stringify(query.query)],
            queryFn: () => DefaultInstance.operationsGet(query)
        };
    },
    detail: (params: DefaultApiOperationsOperationIdGetRequest) => ({
        queryKey: [params.operationId],
        queryFn: () => DefaultInstance.operationsOperationIdGet(params)
    }),
    find: (params: DefaultApiOperationsFindPostRequest) => {
        const query = { ...params }; // params 객체를 복사합니다.
        // params.query.$and가 배열이고 길이가 0보다 클 때만 query.query를 설정합니다.
        if (Array.isArray(params.query.$and) && params.query.$and.length > 0) {
            query["query"] = JSON.stringify(params.query);
        }
        // params.search가 있을 때만 query.search와 query.searchFields를 설정합니다.
        if (params.search) {
            query["search"] = params.search;
            query["searchFields"] = params.searchFields;
        }
        return {
            queryKey: [query, JSON.stringify(query.query)],
            queryFn: () => DefaultInstance.operationsFindPost(query)
        };
    },
})

export const mutateOperations = createMutationKeys('operations', {
    create: (params: DefaultApiOperationsPostRequest) => ({
        mutationKey: [params.operationsGetRequest],
        mutationFn: () => DefaultInstance.operationsPost(params)
    }),
    update: (params: DefaultApiOperationsOperationIdPutRequest) => ({
        mutationKey: [params.operationId, params.operationsGetRequest],
        mutationFn: () => DefaultInstance.operationsOperationIdPut(params)
    }),
    delete: (params: DefaultApiOperationsOperationIdDeleteRequest) => ({
        mutationKey: [params.operationId],
        mutationFn: () => DefaultInstance.operationsOperationIdDelete(params)
    }),
});

export const operationsQueryKeys = mergeQueryKeys(operations, mutateOperations);