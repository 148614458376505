import { theme } from "@/styles/theme";
import { Text, TextProps } from "@mantine/core";

export const DetailLabel = (params: TextProps) => {
  const { children } = params;
  return (
    <Text
      color={theme.colors?.dark?.[2]}
      size={"14px"}
      fs={"normal"}
      fw={600}
      lh={"18px"}
      w={160}
    >
      {children}
    </Text>
  );
};
