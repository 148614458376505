import { consumeTypeColor, operationTypeColor } from "@/constants/operations";
import { ItemsDetailForm } from "@/features/item/components/form/ItemsDetailForm";
import { useModal } from "@/features/modal/ModalStackManager";
import { MoldDetailForm } from "@/features/mold/form/moldDetailForm";
import { RoutingDetailForm } from "@/features/routings/components/form/routingDetailForm";
import { BadgeBox } from "@/features/standard/badge/BadgeBox";
import { BadgeContainer } from "@/features/standard/badge/BadgeContainer";
import { BadgeIcon } from "@/features/standard/badge/BadgeIcon";
import { BadgeMain } from "@/features/standard/badge/BadgeMain";
import { BadgeQuantity } from "@/features/standard/badge/BadgeQuantity";
import { BadgeSmallBox } from "@/features/standard/badge/BadgeSmallBox";
import { BadgeTextBox } from "@/features/standard/badge/BadgeTextBox";
import { DetailBadge } from "@/features/ui/detail/DetailBadge";
import { DetailContent } from "@/features/ui/detail/DetailContent";
import { DetailField } from "@/features/ui/detail/DetailField";
import { DetailHeader } from "@/features/ui/detail/DetailHeader";
import { DetailInformTitle } from "@/features/ui/detail/DetailInformTitle";
import { DetailLabel } from "@/features/ui/detail/DetailLabel";
import { DetailLink } from "@/features/ui/detail/DetailLink";
import { DetailLinkWrapper } from "@/features/ui/detail/DetailLinkWrapper";
import { DetailTitle } from "@/features/ui/detail/DetailTitle";
import { UsersDetailForm } from "@/features/users/components/form/UsersDetailForm";
import { theme } from "@/styles/theme";
import { Space, Text } from "@mantine/core";
import {
  ItemsGet200ResponseRowsInnerOperationsInner,
  ProductionPlansGet200ResponseRowsInnerOperationsDataInner,
} from "@sizlcorp/sizl-api-document/dist/models";
import { IconBuildingWarehouse } from "@tabler/icons-react";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import { OperationDetailForm } from "../form/OperationDetailForm";

const DetailOperation = Object.assign(DetailHeader, {
  Title: DetailTitle,
  Inform: DetailInformTitle,
  Field: DetailField,
  Label: DetailLabel,
  Link: DetailLink,
  LinkWrapper: DetailLinkWrapper,
  Badge: DetailBadge,
  Content: DetailContent,
});

const Badge = Object.assign(BadgeMain, {
  Box: BadgeBox,
  SmallBox: BadgeSmallBox,
  Quantity: BadgeQuantity,
  Icon: BadgeIcon,
  Text: Text,
  TextBox: BadgeTextBox,
  Container: BadgeContainer
});
interface DetailOperationInformProps {
  data:
  | ItemsGet200ResponseRowsInnerOperationsInner
  | ProductionPlansGet200ResponseRowsInnerOperationsDataInner
  | undefined;
}

export const OperationInform = (params: DetailOperationInformProps) => {
  const { data } = params;
  const { t } = useTranslation();
  const { openModal } = useModal();

  const MaxSeq =
    data?.operationBoms &&
    Math.max(...data.operationBoms.map((bom) => bom.seq ?? 0));

  return (
    <>
      <Badge.Container>
        <Badge.Box>
          <Badge.SmallBox>
            <Badge.Icon>
              <IconBuildingWarehouse size={26} color={theme?.colors?.violet?.[6]} />
            </Badge.Icon>
            <Badge.TextBox>
              <Badge.Quantity fz="lg" color="violet">
                {data?.operationBoms && data?.operationBoms[0]?.seq} /{" "}
                {MaxSeq === -Infinity ? "" : MaxSeq}
              </Badge.Quantity>
              <Badge.Text fz="xs" color={theme.colors?.gray?.[6]}>공정순서</Badge.Text>
            </Badge.TextBox>
          </Badge.SmallBox>
        </Badge.Box>
      </Badge.Container>
      <DetailOperation>
        <DetailOperation.Title>{`[공정] ${data?.code}`}</DetailOperation.Title>
        <DetailOperation.Inform>
          {
            "공정 정보 : 작업지시의 공정정보는 작업지시를 생선한 시점의 공정 정보를 스냅샷하여 사용합니다."
          }
        </DetailOperation.Inform>
        <Space h={8} />
        <DetailOperation.Field>
          <DetailOperation.Label>{"품목"}</DetailOperation.Label>
          <DetailOperation.LinkWrapper>
            <DetailOperation.Badge>{"제품"}</DetailOperation.Badge>
            <DetailOperation.Link onClick={() => {
              openModal(
                <ItemsDetailForm itemCode={data?.itemCode ?? ''} />,
                null,
                ""
              )
            }}>{data?.itemCode}</DetailOperation.Link>
          </DetailOperation.LinkWrapper>
        </DetailOperation.Field>
        <DetailOperation.Field>
          <DetailOperation.Label>{"공정 시퀀스"}</DetailOperation.Label>
          <DetailOperation.Content>{data?.seq}</DetailOperation.Content>
        </DetailOperation.Field>
        <DetailOperation.Field>
          <DetailOperation.Label>{"공정코드"}</DetailOperation.Label>
          <DetailOperation.Link
            onClick={() => openModal(<OperationDetailForm operactionCode={data?.code} />, null, "")}
          >
            {data?.code}
          </DetailOperation.Link>
        </DetailOperation.Field>
        <DetailOperation.Field>
          <DetailOperation.Label>{"라우팅"}</DetailOperation.Label>
          <DetailOperation.LinkWrapper>
            <DetailOperation.Badge>{"라우팅"}</DetailOperation.Badge>
            <DetailOperation.Link
              onClick={() => openModal(<RoutingDetailForm routingCode={data?.routingCode} />, null, "")}>
              {data?.routingCode}
            </DetailOperation.Link>
          </DetailOperation.LinkWrapper>
        </DetailOperation.Field>
        <DetailOperation.Field>
          <DetailOperation.Label>{"사용 금형"}</DetailOperation.Label>
          <DetailOperation.LinkWrapper>
            <DetailOperation.Badge>{"금형"}</DetailOperation.Badge>
            <DetailOperation.Link
              onClick={() => openModal(<MoldDetailForm moldCode={data?.moldCode} />,
                null,
                "")}
            >
              {data?.moldCode}
            </DetailOperation.Link>
          </DetailOperation.LinkWrapper>
        </DetailOperation.Field>
        <DetailOperation.Field>
          <DetailOperation.Label>{"BOM 소모 시점"}</DetailOperation.Label>
          <DetailOperation.Content>
            <DetailOperation.Badge color={consumeTypeColor[data?.consumeType!]}>{t(data?.consumeType!)}</DetailOperation.Badge>
          </DetailOperation.Content>
        </DetailOperation.Field>
        <DetailOperation.Field>
          <DetailOperation.Label color={operationTypeColor[data?.operationType!]}>{"공정 타입"}</DetailOperation.Label>
          <DetailOperation.Content>
            <DetailOperation.Badge>{t(data?.operationType!)}</DetailOperation.Badge>
          </DetailOperation.Content>
        </DetailOperation.Field>
        <DetailOperation.Field>
          <DetailOperation.Label>{"생성정보"}</DetailOperation.Label>
          <DetailOperation.Link
            onClick={() => openModal(<UsersDetailForm UserCode={data?.creatorUserCode} />,
              null,
              "")}
          >
            {data?.creatorUserCode}
            {dayjs(data?.createdAt).format("YYYY-MM-DD HH:mm:ss")}
          </DetailOperation.Link>
        </DetailOperation.Field>
        <DetailOperation.Field>
          <DetailOperation.Label>{"수정정보"}</DetailOperation.Label>
          <DetailOperation.Link
            onClick={() => openModal(<UsersDetailForm UserCode={data?.updaterUserCode} />,
              null,
              "")}
          >
            {data?.updaterUserCode}
            {dayjs(data?.updatedAt).format("YYYY-MM-DD HH:mm:ss")}
          </DetailOperation.Link>
        </DetailOperation.Field>
      </DetailOperation>
    </>
  );
};
