import { useInterportMutationQuery } from "@/api/interport/useInterportGetQuery";
import { Box, Button, FileButton, Group, Text } from "@mantine/core";
import { useRef, useState } from 'react';

export const FileImportForm = () => {
    const [file, setFile] = useState<File | undefined>(undefined);
    const { mutate: importFile } = useInterportMutationQuery(file);

    const resetRef = useRef<() => void>(null);

    const clearFile = () => {
        setFile(undefined);
        resetRef.current?.();
    };

    return (
        <Box maw={320} mx="auto" w={400}>
            <Group position="center">
                <FileButton 
                    key={file ? file.name : 'no-file'}
                    onChange={(file) => setFile(file || undefined)} 
                    accept=".xls,.xlsx,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                >
                    {(props) => <Button color="indigo" w="100%" {...props}>파일 선택</Button>}
                </FileButton>
            </Group>

            {file && (
                <Text size="sm" align="center" mt="sm">
                    업로드 파일: {file.name}
                </Text>
            )}
            <Group position="right" mt="lg">
                <Button disabled={!file} color="teal" onClick={clearFile}>
                    Reset
                </Button>
                <Button ml="md"onClick={() => importFile()}>저장</Button>
            </Group>
        </Box>
    )
};