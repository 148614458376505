import {
    MantineReactTable,
    useMantineReactTable,
    // createRow,
    type MRT_ColumnDef
} from 'mantine-react-table';
import { useMemo } from 'react';

import { DetailEquipmentInformProps } from '@/features/equipment/components/form/EquipmentDetailInForm';
import { ItemsDetailForm } from '@/features/item/components/form/ItemsDetailForm';
import { useModal } from '@/features/modal/ModalStackManager';
import { OperationOutsourceDetailForm } from '@/features/operationOutsource/form/DetailForm';
import { WorkView } from '@/features/work/WorkView/WorkView';
import { setToLocaleString } from '@/utils/unitMark';
import { trackingStatusColor } from "@/utils/workStatus";
import { Anchor, Badge } from '@mantine/core';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

export interface Row {
    id: number | any;
    productionPlanId: number | any;
    scheduledAt: string | any;
    targetQuantity: number | any;
    trackingStatus: string | any;
    item: {
        code: string | any;
        name: string | any;
        spec: string | any;
        unitText: string | any;
    }
    operationOutsourceId: number | any;
    operationOutsourceData: {
        name: string | any;
    }
    summary: {
        totalQuantity: number | any;
        defectTotal: number | any;
        todoQuantity: number | any;
    }
}

type TrackingStatus = 'WAITING' | 'WORKING' | 'PAUSED' | 'DONE' | 'DEFAULT';



export const EquipmentWorksTabs = (
    { data }: DetailEquipmentInformProps,
    trackingStatus: TrackingStatus
) => {

    const rows: Row[] = data?.data.rows[0]?.works ?? [];

    const { openModal } = useModal();

    const { t } = useTranslation();

    const columns = useMemo<MRT_ColumnDef<Row>[]>(
        () => [
            {
                accessorFn: (row) => row.id,
                accessorKey: 'id',
                header: '작업번호',
                size: 60,
                Cell: (rows) => {
                    return (
                        <div style={{ textAlign: 'right' }}>
                            <Anchor
                                onClick={() => {
                                    openModal(
                                        <WorkView workId={rows.row.original.id} />,
                                        null,
                                        ""
                                    )
                                }}
                            >
                                {rows.row.original.id}
                            </Anchor>
                        </div>
                    )
                }
            },
            {
                accessorFn: (row) =>
                    <Anchor
                        onClick={() => {
                            openModal(
                                <ItemsDetailForm itemCode={row.item.code} />,
                                null,
                                "품목 상세"
                            )
                        }}
                    >
                        {row.item.code}
                    </Anchor>,
                accessorKey: 'itemCode',
                header: '품목코드',
                size: 80,
            },
            {
                accessorFn: (row) => row.item.name,
                accessorKey: 'itemName',
                header: '품목명',
                size: 120,
            },
            {
                accessorFn: (row) => row.item.spec,
                accessorKey: 'spec',
                header: '규격',
                size: 160,
            },
            {
                accessorFn: (row) => t(row.trackingStatus),
                accessorKey: 'trackingStatus',
                header: '작업상태',
                size: 80,
                Cell: (rows) => {
                    const workLogType = rows.row._valuesCache.workLogType as keyof typeof trackingStatusColor;
                    return (
                        <Badge color={trackingStatus ? trackingStatusColor[trackingStatus] : ""}>
                            {t(rows.row._valuesCache.trackingStatus)}
                        </Badge>
                    )
                },
            },
            {
                accessorFn: (row) => row.targetQuantity,
                accessorKey: 'targetQuantity',
                header: '계획 수량',
                size: 80,
                Cell: (rows) => {
                    const unitText = rows.row.original.item.unitText;
                    const targetQuantity = setToLocaleString(rows.row._valuesCache.targetQuantity) === "NaN" ? '0' : setToLocaleString(rows.row._valuesCache.targetQuantity);
                    return (
                        <div style={{ textAlign: 'right' }}>{targetQuantity} {unitText}</div>
                    )
                }
            },
            {
                accessorFn: (row) => row.summary.todoQuantity,
                accessorKey: 'todoQuantity',
                header: '생산 잔량',
                size: 80,
                Cell: (rows) => {
                    const unitText = rows.row.original.item.unitText;
                    return (
                        <div style={{ textAlign: 'right' }}>{setToLocaleString(rows.row._valuesCache.todoQuantity)} {unitText}</div>
                    )
                },
            },
            {
                accessorFn: (row) => row.summary.totalQuantity,
                accessorKey: 'totalQuantity',
                header: '총 생산수량',
                size: 80,
                Cell: (rows) => {
                    const unitText = rows.row.original.item.unitText;
                    return (
                        <div style={{ textAlign: 'right' }}>{setToLocaleString(rows.row._valuesCache.totalQuantity)} {unitText}</div>
                    )
                },
            },
            {
                accessorFn: (row) => row.summary.defectTotal,
                accessorKey: 'defectTotal',
                header: '불량수량',
                size: 80,
                Cell: (rows) => {
                    const unitText = rows.row.original.item.unitText;
                    return (
                        <div style={{ textAlign: 'right' }}>{setToLocaleString(rows.row._valuesCache.defectTotal)} {unitText}</div>
                    )
                },
            },
            {
                accessorFn: (row) => row.operationOutsourceData?.name,
                accessorKey: 'operationOutsourceData',
                header: '외주처',
                size: 80,
                Cell: (rows) => {
                    return (
                        <Anchor
                            onClick={() =>
                                openModal(
                                    <OperationOutsourceDetailForm name={rows.row.original.operationOutsourceData.name} />,
                                    null,
                                    ""
                                )}
                        >
                            {rows.row._valuesCache.operationOutsourceData !== null ? rows.row.original.operationOutsourceData?.name : ""}
                        </Anchor>
                    )
                },
            },
            {
                accessorFn: (row) => dayjs(row.scheduledAt).format("YYYY-MM-DD"),
                accessorKey: 'scheduledAt',
                header: '작업일',
                size: 140,
            }
        ],
        [],
    );

    const table = useMantineReactTable({
        columns,
        data: rows,
        editDisplayMode: 'modal', //default ('row', 'cell', 'table', and 'custom' are also available)
        enableEditing: false,
        enableToolbarInternalActions: false, // 우측상단 아이콘 안보이게
        enablePagination: false,
    });

    return <MantineReactTable table={table} />;
};
