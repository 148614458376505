import { DefectTypeItem } from "@/features/ui/DefectTypeSet";
import DefaultInstance from "@/instance/axios";
import { createQueryKeys } from "@lukemorales/query-key-factory";
import { DefaultApiProductionPlansGetRequest, DefaultApiProductionPlansProductionPlanIdGetRequest } from "@sizlcorp/sizl-api-document/dist/models";

interface ExtendedDefaultApiProductionPlansGetRequest extends DefaultApiProductionPlansGetRequest {
    defectCodes: DefectTypeItem[] | null;
}

export const productionPlans = createQueryKeys('productionPlans', {
    all : null,
    get : (params : DefaultApiProductionPlansGetRequest) => {
        const query = { ...params };
        if (Array.isArray(params.query?.$and) && params.query?.$and.length > 0) {
            query["query"] = JSON.stringify(params.query);
        }
        if (params.search) {
            query["search"] = params.search;
            query["searchFields"] = params.searchFields;
        }

        return {
            queryKey: [query, JSON.stringify(query.query)],
            queryFn: () => DefaultInstance.productionPlansGet(query)
        }
    },
    detail : (params : DefaultApiProductionPlansProductionPlanIdGetRequest) => {
        return {
            queryKey: [params.productionPlanId],
            queryFn: () => DefaultInstance.productionPlansProductionPlanIdGet(params)
        }
    },
    getDefect: (params: ExtendedDefaultApiProductionPlansGetRequest) => {
        // query 객체 생성 및 초기화
        const query = { ...params };
    
        // params.query 처리
        if (Array.isArray(params.query?.$and) && params.query?.$and.length > 0) {
            query["query"] = JSON.stringify(params.query);
        }
    
        // defectCodes 처리
        const defectCodes = params.defectCodes?.map((data: DefectTypeItem) => data.value) ?? ["null"];
    
        // queryKey와 queryFn 반환
        return {
            queryKey: [JSON.stringify(query.query), defectCodes],
            queryFn: async () => {
                try {
                    const response = await DefaultInstance.productionPlansGet(
                        {
                            query: query.query,
                            page: query.page,
                            pageSize: 10,
                        },
                        {
                            params: {
                                defectCodes: defectCodes,
                                populate: query.populate,
                                sort: query.sort,
                            }
                        }
                    );
                    return response.data;
                } catch (error) {
                    console.error(error, 'API Error');
                    throw error;
                }
            }
        }
    }

});
