import { ColumnHeaderProps } from "@/constants/columnHeader";
import { operatorMap } from "@/constants/filters";
import { useModal } from "@/features/modal/ModalStackManager";
import { CustomFilterForm } from "@/features/ui/Base/List/CustomFilter/CustomFilterForm";
import { theme } from "@/styles/theme";
import styled from "@emotion/styled";
import { Avatar, Button, Popover, Text } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { IconFilterPlus, IconPlus, IconX } from "@tabler/icons-react";
import { EventHandler, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

interface InputItemProps {
  id: string;
  values: {
    name: string;
    label: string;
    type: string;
    content: string;
    category: string;
    [key: string]: string;
  };
}

// export type QueryType = { $and: Array<{ [key: string]: { [key: string]: string | number } }> };

// TODO: 타입 재정의 (any 제거)
type SetQueryFunction = React.Dispatch<React.SetStateAction<any>>;

interface FilterProps {
  pageType?: string;
  filterType: ColumnHeaderProps[];
  setQuery: SetQueryFunction;
  query?: any;
}

const transformData = (data: { id: number; values: { [key: string]: { [operator: string]: string } } }, columnHeader: ColumnHeaderProps[]): InputItemProps => {
  const key = Object.keys(data.values)[0];
  const operator = Object.keys(data.values[key])[0];
  let content = data.values[key][operator];
  const category = getCategory(key, columnHeader);
  const operatorInfo = operatorMap.find((op) => op.value === operator && op.category === category);

  if (!operatorInfo) {
    throw new Error("Invalid operator or category");
  }

  // "%" 제거
  if (typeof content === 'string' && (operator === "$iLike" || operator === "$notILike")) {
    content = content.replace(/%/g, '');
  }

  // multi select의 경우에는 따로 조건 처리를 해주었음.
  if (operator === "$in") {
    return {
      id: data.id.toString(),
      values: {
        name: key,
        label: "",
        type: operator,
        content: content,
        [key]: content,
        category: operatorInfo.category,
      }
    }
  }

  return {
    id: data.id.toString(),
    values: {
      name: key,
      label: operatorInfo.label,
      type: operator,
      content: content,
      category: operatorInfo.category,
    },
  };
}

const getCategory = (key: string, columnHeader: ColumnHeaderProps[]): string => {
  const data = columnHeader.find(item => item.value === key);
  return data ? data.category : "text";
}

export const CustomFilter = ({ pageType, filterType, setQuery, query }: FilterProps) => {
  const { openModal, closeModal } = useModal();
  const [opened, { close, open }] = useDisclosure(false);
  const [inputs, setInputs] = useState<any[]>([]);

  useEffect(() => {
    if (query?.$and) {
      const newInputs = query.$and.map((condition: any, index: number) =>
        transformData({ id: index + 1, values: condition }, filterType)
      );
      setInputs(newInputs);
    }
  }, [query]);

  const [inputLength, setInputLength] = useState<number | any>();

  const { t } = useTranslation();

  // filter를 생성할 때 임의의 ID값을 추가
  const onCloseHandler: EventHandler<any> = (values) => {
    closeModal(values);
    setInputs((prevFilterList: any[]) => {
      const newId = prevFilterList.length + 1;
      return [...prevFilterList, { id: newId, values }];
    });
  };

  // filter 핸들러
  const FilterHandler = (i: any) => {
    setInputLength(i);
  };

  useEffect(() => {
    setInputLength(inputLength);
  }, [inputs[inputLength]?.values.name]);

  // 만들어진 filter 닫기
  const FilterCloseHandler = (id: any) => {
    const inputData = inputs.find((item: any) => item.id === id);
    const fieldKey = inputData.values.name.toString();
    const conditionKey = inputData.values.type.toString().length ? inputData.values.type.toString() : "$eq";

    let closeItem: { [x: string]: { [x: string]: string; }; };

    if (!inputData.values.type) {
      // fk 데이터라는 뜻
      closeItem = {
        [fieldKey]: {
          $in: inputData.values[fieldKey],
        },
      };
    }
    else {
      // like일 경우에는 content 앞 뒤에 %를 추가하여야 한다.
      closeItem = {
        [fieldKey]: {
          [conditionKey]:
            conditionKey === "$iLike" || conditionKey === "$notILike" ? `%${inputData.values.content}%` : inputData.values.content,
        },
      };
    }

    // const closeItem = {
    //   [fieldKey]: {
    //     [conditionKey]:
    //       conditionKey === "$iLike" || conditionKey === "$notILike"
    //         ? `%${inputData.values.content}%`
    //         : inputData.values.content,
    //   },
    // };

    setInputs(inputs.filter((item: any) => item.id !== id));
    setQuery((prevQuery: any) => {
      const newObj = {
        $and: [
          ...prevQuery.$and.filter((item: any) => {
            return JSON.stringify(item) !== JSON.stringify(closeItem);
          }),
        ],
      };

      return { ...newObj };
    });
  };

  const addFilterHandler: React.MouseEventHandler<HTMLButtonElement> = (e) => {
    e.preventDefault();
    openModal(
      <CustomFilterForm filterType={filterType} onSubmit={onCloseHandler} />,
      null,
      "사용자 정의 필터",
      true
    ).then((result) => {
      handleFilterChange(result);
    });
  };

  // 필터 정보 객체로 만들어 부모 컴포넌트에서 전달 받은 query 상태 변경
  const handleFilterChange = (newQuery: InputItemProps["values"]) => {
    const { name, label, type, content, category } = newQuery;
    // 바깥 영역 클릭 시 모달 닫기
    if (!newQuery || !newQuery.name) return;

    const fieldKey = newQuery.name.toString();
    const conditionKey = newQuery.type.toString().length ? newQuery.type.toString() : "$eq";

    let condition: { [x: string]: { [x: string]: string; }; };


    if (!newQuery.type) {
      // fk 데이터라는 뜻
      condition = {
        [fieldKey]: {
          $in: newQuery[name],
        },
      };
    }
    else {
      // like일 경우에는 content 앞 뒤에 %를 추가하여야 한다.
      condition = {
        [fieldKey]: {
          [conditionKey]:
            conditionKey === "$iLike" || conditionKey === "$notILike" ? `%${newQuery.content}%` : newQuery.content,
        },
      };
    }

    setQuery((prevQuery: any) => {
      const newObj = {
        $and: [...prevQuery.$and, condition],
      };
      return { ...newObj };
    });
  };

  return (
    <FilterContainer>
      {/* <Flex w={40} align="flex-start"> */}
      {/* <Text fz="sm" span>
          사용자 정의
        </Text> */}
      <Popover
        width={200}
        position="bottom"
        withArrow
        shadow="md"
        opened={opened}
      >
        <Popover.Target>
          <Avatar color="blue" variant="outline">
            <IconFilterPlus onMouseEnter={open} onMouseLeave={close} />
          </Avatar>
        </Popover.Target>
        <Popover.Dropdown sx={{ pointerEvents: "none" }}>
          <Text size="sm">
            사용자 정의 필터입니다. <br /> "필터 추가" 또는 "정렬 추가" 버튼을 클릭하여 조건을
            입력합니다.
          </Text>
        </Popover.Dropdown>
      </Popover>
      {/* <Text fz="sm" span>
          필터
        </Text> */}
      {/* </Flex> */}
      {inputs &&
        inputs?.map((v: InputItemProps, i: any) => {
          const target = filterType.find(
            (item) => item.value === v.values.name
          );
          const name = target ? target.label : null;

          // 값이 enum일 경우 번역
          let newValueName = '';
          if (Array.isArray(v.values[v.values.name])) {
            newValueName = [...v.values[v.values.name]].map((item: string) => {
              return t(item)
            }).toString();
          } else {
            newValueName = t(v.values[v.values.name]);
          }

          // 중첩된 삼항 연산자를 독립적인 문장으로 추출
          let buttonText;
          if (v.values.category === "text") {
            // MultiSelect 데이터에 대한 텍스트 표시
            if (!v.values.type || !v.values.label) {
              buttonText = `"${name}" : "${v.values.name ? newValueName : ""}" `;
            } else {
              buttonText = `"${name}" : "${v.values.content}"  ${v.values.label}`;
            }
          } else {
            buttonText = `"${name}" : ${v.values.label}  ${v.values.content}`;
          }

          return (
            <FilterButton
              rightIcon={
                <IconX onClick={() => FilterCloseHandler(v.id)} size="1rem" />
              }
              onClick={() => FilterHandler(i)}
              key={v.id + " " + i}
            >
              {buttonText}
            </FilterButton>
          );
        })}
      <AddFilterBtn
        variant="subtle"
        color="dark"
        leftIcon={<IconPlus size="1rem" />}
        onClick={(e: any) => addFilterHandler(e)}
      >
        {"필터 추가"}
      </AddFilterBtn>
    </FilterContainer>
  );
};

const FilterContainer = styled.div`
  width: auto;
  height: auto;
  display: flex;
`;

const AddFilterBtn = styled<any>(Button)`
  // color: var(--black-0, #000000);
  // background-color: ${theme.colors?.white};
`;

const FilterButton = styled<any>(Button)`
  font-size: 14px;
  // background-color: ${theme.colors?.white};
  // color: ${theme.colors?.dark?.[8]};
  border-radius: 200px;
  // border: 1px solid ${theme.colors?.dark?.[8]};
  margin: 0 8px;
`;
