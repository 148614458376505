import { mutateDownTimeReasons } from "@/api/downtimeReasons/useDowntimeReasonsQuery";
import { useModal } from "@/features/modal/ModalStackManager";
import { FormButtonBox } from "@/features/standard/Form/FormButtonBox";
import { FormMain } from "@/features/standard/Form/FormMain";
import { FormWrapper } from "@/features/standard/Form/FormWrapper";
import customAlert from "@/features/ui/alert/alert";
import { RoutingAutoComplete } from "@/features/ui/autoComplete/routing/routing-autoComplete";
import { validateFormValues } from "@/utils/validation";
import { Button, TextInput } from "@mantine/core";
import { useForm } from "@mantine/form";
import { DefaultApiDowntimeReasonsDowntimeReasonCodePutRequest, DowntimeReasonsGet200ResponseRowsInner } from "@sizlcorp/sizl-api-document/dist/models";
import { useMutation, useQueryClient } from "react-query";

const Update = Object.assign(FormMain, {
  Button: Button,
  Input: TextInput,
  Wrapper: FormWrapper,
  ButtonBox: FormButtonBox,
});

export const DowntimeReasonsUpdateForm = (params: {
  formatterProps: DowntimeReasonsGet200ResponseRowsInner;
}) => {
  const { closeModal } = useModal();
  const { formatterProps } = params;
  const queryClient = useQueryClient();
  const { mutate: updateMutate } = useMutation(
    (params: DefaultApiDowntimeReasonsDowntimeReasonCodePutRequest) =>
      mutateDownTimeReasons.update(params).mutationFn(params as DefaultApiDowntimeReasonsDowntimeReasonCodePutRequest | any),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('downtimeReasons');
      },
    }
  );

  const form = useForm({
    initialValues: {
      code: formatterProps.code,
      name: formatterProps.name,
      routingCode: formatterProps.routingCode,
    },
  });

  const onSubmit = () => {
    try {
      updateMutate(
        {
          downtimeReasonCode: formatterProps.code!,
          downtimeReasonsGetRequest: {
            code: form.values.code!,
            name: form.values.name!,
            routingCode: form.values.routingCode!,
          },
        },
        {
          onSuccess: (res) => {
            closeModal(formatterProps);
            if (res.status === 200) {
              customAlert(
                "비가동사유 수정에 성공하였습니다.",
                "수정 성공",
                "green"
              );
            } else {
              customAlert(
                "비가동사유 수정에 실패하였습니다.",
                "수정 실패",
                "red"
              );
            }
          },
        }
      );
    } catch (e) {
      customAlert(
        "비가동사유 수정에 실패하였습니다.",
        "수정 실패",
        "red"
      );
    }
  };

  return (
    <Update.Wrapper>
      <Update.Input
        label="비가동사유코드"
        defaultValue={formatterProps?.code}
        {...form.getInputProps("code")}
        disabled
      />
      <Update.Input
        label="비가동사유명"
        defaultValue={formatterProps?.name}
        {...form.getInputProps("name")}
        error={form.isDirty("name") ? "저장 필요" : ""}
        styles={{
          input: {
            color: form.isDirty("name") ? "cadetblue !important" : "inherit",
            borderColor: form.isDirty("name")
              ? "cadetblue !important"
              : "inherit",
          },
          error: { fontSize: 11, color: "cadetblue" },
        }}
      />
      <RoutingAutoComplete
        label="라우팅코드"
        defaultValue={formatterProps?.routingCode}
        {...form.getInputProps("routingCode")}
        error={form.isDirty("routingCode") ? "저장 필요" : ""}
        styles={{
          input: {
            color: form.isDirty("routingCode")
              ? "cadetblue !important"
              : "inherit",
            borderColor: form.isDirty("routingCode")
              ? "cadetblue !important"
              : "inherit",
          },
          error: { fontSize: 11, color: "cadetblue" },
        }}
      />
      <Update.ButtonBox>
        <Update.Button color="gray" onClick={closeModal}>
          취소
        </Update.Button>
        <Update.Button
          color="blue"
          disabled={validateFormValues({
            pageType: "downtimeReasons",
            values: form.values,
          })}
          onClick={onSubmit}
        >
          비가동사유 수정
        </Update.Button>
      </Update.ButtonBox>
    </Update.Wrapper>
  );
};
