import { useWorkByEquipmentActions } from "@/features/workByEquipment/hook/useWorkByEquipmentActions";
import { useWorkByProductionActions } from "@/features/workByProductionPlan/hook/useWorkByProductionActions";
import { useModal } from "@/hooks";
import { theme } from "@/styles/theme";
import styled from "@emotion/styled";
import { DatePicker } from "@mantine/dates";
import { useState } from "react";

export const CalendarModal = ({ pageKey }: any) => {
  const [value, setValue] = useState<Date | null>(null);
  const { modal, closeModal } = useModal();
  const actionObj: any = {
    equipment: useWorkByEquipmentActions(),
    plan: useWorkByProductionActions(),
  };
  const actions: any = actionObj[pageKey];

  const onChange = (date: Date | null) => {
    actions.setDate(date);
    setValue(date);
    closeModal();
  };

  return (
    <>
      {modal?.isOpen && modal.modalType === "Calendar" && (
        <ModalContainer>
          <DatePicker value={value} onChange={onChange} size="md" />
        </ModalContainer>
      )}
    </>
  );
};

const ModalContainer = styled.div`
  position: fixed;
  width: 380px;
  height: 360px;
  border-radius: 4px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 20px;
  background-color: ${theme.colors?.white?.[1]};
  border: 1px solid #ccc;
  border-radius: 4px;
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
`;
