import { Flex, MultiSelectProps, Text } from "@mantine/core";
import { forwardRef, useState } from "react";

import { operationType } from "@/constants/operations";
import { FKMultiSelect } from "@/features/ui/Base/List/CustomFilter/MultiField";
import { useTranslation } from "react-i18next";

interface OperationTypeMultiProps extends React.ComponentPropsWithoutRef<"div"> {
    label: string;
    value: string;
}

export interface OperationTypesMultiAutoCompleteProps
    extends Partial<MultiSelectProps>,
    Partial<React.RefAttributes<HTMLInputElement>> {
    value: string[];
    onChange?: (e: string[]) => void; // (value: string[]) => React.Dispatch<React.SetStateAction<string>>;
    maxDropdownHeight?: number;
    width?: string;
}

export const OperationTypesMultiAutoComplete = (
    params: OperationTypesMultiAutoCompleteProps
) => {
    const {
        value,
        onChange,
        maxDropdownHeight,
        width,
        ...etcParams
    } = params;

    const { t } = useTranslation();
    const translatedOperations = operationType.map(item => ({ ...item, label: t(item.label) }));

    const [searchKeyword, setSearchKeyword] = useState<string>("");

    const onChangeHandler = (e: string[]) => {
        onChange && onChange(e);
    };

    const SelectOperationType = forwardRef<HTMLDivElement, OperationTypeMultiProps>(
        ({ label: name, value: code, ...others }, ref) => {
            const { t } = useTranslation();
            return (
                <div ref={ref} {...others}>
                    <Flex direction="row" justify="space-between" align="center">
                        <Flex align={"center"}>
                            <Text>{t(name)}</Text>
                            <Text fz="xs">(품목유형: {code})</Text>
                        </Flex>
                    </Flex>
                </div>
            );
        }
    );

    return (
        <FKMultiSelect
            clearable
            data={translatedOperations}
            onChange={onChangeHandler}
            searchable
            itemComponent={SelectOperationType}
            searchValue={searchKeyword}
            withinPortal
            maxDropdownHeight={maxDropdownHeight ?? 150}
            onSearchChange={setSearchKeyword}
            nothingFound="No Options"
            {...etcParams}
        />
    );
};


