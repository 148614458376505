import DefaultInstance from "@/instance/axios";
import { OperationOutsourcesGet200ResponseRowsInner } from "@sizlcorp/sizl-api-document/dist/models";
import { AxiosResponse } from "axios";
import { useQuery } from "react-query";

export type OperationOutsourceOption = {
  value: string;
  label: string;
  group: string;
};

const getAutoCompleteKeyword = () =>
  DefaultInstance.operationOutsourcesFindPost().then(
    (res: AxiosResponse<OperationOutsourcesGet200ResponseRowsInner[]>) => res.data
  );

export const useOperationOutsourcesAutoComplete = (
  focus = false,
  keyword = "",
  options?: {
    onSuccess: (data: OperationOutsourceOption[]) => void;
  }
) => {
  return useQuery(
    ["operationCode-get-autocomplete"],
    () => getAutoCompleteKeyword(),
    {
      enabled: focus,
      select: (data: OperationOutsourcesGet200ResponseRowsInner[]) =>
        data?.map((operationOutsource) => {
          return {
            value: String(operationOutsource.id),
            label: String(operationOutsource.name),
            group: String(operationOutsource.operationCode),
          };
        }) ?? [],
      onSuccess: options?.onSuccess,
    }
  );
};

const getOperationOutsourcesByCode = (operationCode: string | null) =>
  DefaultInstance.operationOutsourcesFindPost({
    ...(operationCode && { search: operationCode }),
    searchFields: ["name"],
  }).then((res: AxiosResponse<OperationOutsourcesGet200ResponseRowsInner[]>) => res.data);

export const useOperationOutsourcesCode = (
  enabled: boolean,
  operationCode: string | null,
  options?: {
    onSuccess: (data?: OperationOutsourceOption[]) => void;
  }
) => {
  return useQuery(["operationOutsource-get-code", operationCode], () => getOperationOutsourcesByCode(operationCode), {
    enabled: enabled ?? false,
    select: (data: OperationOutsourcesGet200ResponseRowsInner[]) =>
      data?.map((operationOutsource) => {
        return {
          value: String(operationOutsource.id),
          label: String(operationOutsource.name),
          group: String(operationOutsource.operationCode),
        };
      }),
    onSuccess: options?.onSuccess,
  });
};
