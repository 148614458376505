import { WorkByEquipmentStateContext } from "@/features/workByEquipment/context/WorkByEquipmentProvider";
import { useContext } from "react";

export function useWorkByEquipmentState() {
  const value = useContext(WorkByEquipmentStateContext);
  if (value === undefined) {
    throw new Error("useCounterState should be used within CounterProvider");
  }
  return value;
}
