import useWorkGetQuery from "@/api/works/useWorkGetQuery";
import { Main } from "@/features/standard/Main";
import { DetailContainer } from "@/features/standard/detail/Container";
import { DetailContent } from "@/features/standard/detail/Content";
import { DetailWrapper } from "@/features/standard/detail/Wrapper";
import { ActionBar } from "@/features/work/WorkView/ActionBar";
import { WorkContent } from "@/features/work/WorkView/WorkContent";

const Detail = Object.assign(Main, {
  Container: DetailContainer,
  Wrapper: DetailWrapper,
  Content: DetailContent,
})

export interface WorkViewProps {
  workId?: number;
}

export const WorkView = ({ workId }: WorkViewProps) => {
  const { data: workData } = useWorkGetQuery(workId);

  return (
    <Detail.Container>
      <ActionBar workData={workData?.data} />
      <Detail.Wrapper>
        <Detail.Content>
          <WorkContent workData={workData?.data} />
        </Detail.Content>
      </Detail.Wrapper>
    </Detail.Container>
  );
};
