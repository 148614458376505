import { useModal } from "@/features/modal/ModalStackManager";
import { MoldCreateForm } from "@/features/mold/form/createForm";
import { MoldTable } from "@/features/mold/table";
import { ActionButtonBox } from "@/features/standard/ActionButtonBox";
import { ActionHeader } from "@/features/standard/ActionHeader";
import { Header } from "@/features/standard/Header";
import { Main } from "@/features/standard/Main";
import { HeaderSubTitle } from "@/features/standard/SubTitle";
import { HeaderTitle } from "@/features/standard/Title";
import { Wrapper } from "@/features/standard/Wrapper";
import { usePub } from "@/hooks";
import { Button } from "@mantine/core";
import { IconPlus, IconTrash } from "@tabler/icons-react";

const Mold = Object.assign({}, Main, {
  Header: Header,
  HeaderTitle: HeaderTitle,
  HeaderSubTitle: HeaderSubTitle,
  InventoryWrapper: Wrapper,
  ActionHeader: ActionHeader,
  ActionButton: Button,
  ActionButtonBox: ActionButtonBox,
  MoldTable: MoldTable,
  MoldCreateForm: MoldCreateForm,
});

export const MoldPage = () => {
  const { openModal } = useModal();

  const publish = usePub();
  return (
    <>
      <Mold.InventoryWrapper>
        <Mold.ActionHeader>
          <ActionButtonBox>
            <Mold.ActionButton
              leftIcon={<IconPlus />}
              onClick={() =>
                openModal(<Mold.MoldCreateForm />, null, "금형 추가", true)
              }
            >
              추가
            </Mold.ActionButton>
          </ActionButtonBox>
          <Mold.ActionButton
            color="red"
            rightIcon={<IconTrash />}
            onClick={() => publish("deleteSelectedItems")}
          >
            선택된 행 삭제
          </Mold.ActionButton>
        </Mold.ActionHeader>
        <Mold.Header>
          <Mold.HeaderTitle>금형</Mold.HeaderTitle>
          <Mold.HeaderSubTitle>
            우리회사에서 다루는 금형을 관리합니다.
          </Mold.HeaderSubTitle>
        </Mold.Header>
        <Mold.MoldTable />
      </Mold.InventoryWrapper>
    </>
  );
};
