import ProtectedRoute from "@/features/Router/ProtectedRoute";
import { Layout } from "@/features/layout/Template";
import ModalExample from "@/features/modal/example";
import { ProductionPlanWithWorksForm } from "@/features/productionPlan/form/CreateForm";
import { AbstractItemPage } from "@/pages/abstractItem";
import { MainDashboard } from "@/pages/dashboard";
import { Defects } from "@/pages/defects";
import { DeliveryStatusPage } from "@/pages/deliveryStatus";
import { DowntimeReasonsPage } from "@/pages/downtimeReasons";
import { Equipment } from "@/pages/equipment";
import { InspectSpecificationPage } from "@/pages/inspect";
import { InspectionRecordsPage } from "@/pages/inspectionRecords";
import { InventoryPage } from "@/pages/inventory";
import { Item } from "@/pages/item";
import { ItemCategoryPage } from "@/pages/itemCategory";
import { LedgerAllPage } from "@/pages/ledgersAll";
import { LocationPage } from "@/pages/location";
import Login from "@/pages/login";
import { FormExample } from "@/pages/modalExample/form";
import WizardForm from "@/pages/modalExample/wizard";
import { MoldPage } from "@/pages/mold";
import { MoldStockPage } from "@/pages/moldStock";
import { MonitoringPage } from "@/pages/monitoring";
import { Operation } from "@/pages/operation";
import { OperationOutsourcePage } from "@/pages/operationOutsource";
import { OverViewPage } from "@/pages/overview";
import { PurchaseOrderItemsPage } from "@/pages/purchaseOrderItems";
import { Routings } from "@/pages/routings";
import SignUp from "@/pages/signUp";
import { Site } from "@/pages/site";
import { Users } from "@/pages/users";
import { WipInventoryPage } from "@/pages/wipInventory";
import { WmsLogsPage } from "@/pages/wmsLogs";
import { WorkByEquipment } from "@/pages/workByEquipment";
import { WorkByProductionPlan } from "@/pages/workByProductionPlan";
import { WorkByRows } from "@/pages/workByRows";
import { WorkLogPage } from "@/pages/workLog";
import { useCheckAuth } from "@/utils/checkAuth";

import { Route, Routes, useLocation } from "react-router-dom";


const RoutePaths = [
  { path: "/dashboard", element: <MainDashboard /> },
  { path: "/items", element: <Item /> },
  { path: "/equipment", element: <Equipment /> },
  { path: "/defects", element: <Defects /> },
  { path: "/routings", element: <Routings /> },
  { path: "/users", element: <Users /> },
  { path: "/operation", element: <Operation /> },
  { path: "/site", element: <Site /> },
  { path: "/location", element: <LocationPage /> },
  { path: "/operation-outsource", element: <OperationOutsourcePage /> },
  { path: "/abstract-item", element: <AbstractItemPage /> },
  { path: "/item-category", element: <ItemCategoryPage /> },
  { path: "/work-by-equipment", element: <WorkByEquipment /> },
  { path: "/work-by-production-plan", element: <WorkByProductionPlan /> },
  { path: "/work-by-row", element: <WorkByRows /> },
  { path: "/wizard", element: <WizardForm /> },
  { path: "/form", element: <FormExample /> },
  { path: "/modal-multi", element: <ModalExample /> },
  { path: "/production-plan-with-works-form", element: <ProductionPlanWithWorksForm /> },
  { path: "/inventory", element: <InventoryPage /> },
  { path: "/wipInventory", element: <WipInventoryPage /> },
  { path: "/ledgersAll", element: <LedgerAllPage /> },
  { path: "/mold", element: <MoldPage /> },
  { path: "/moldStock", element: <MoldStockPage /> },
  { path: "/inspectSpecification", element: <InspectSpecificationPage /> },
  { path: "/downtimeReasons", element: <DowntimeReasonsPage /> },
  { path: "/work-log", element: <WorkLogPage /> },
  { path: "/overview", element: <OverViewPage /> },
  { path: "/monitoring", element: <MonitoringPage /> },
  { path: "/purchaseOrderItems", element: <PurchaseOrderItemsPage /> },
  { path: "/deliveryStatus", element: <DeliveryStatusPage /> },
  { path: "/wmsLogs", element: <WmsLogsPage /> },
  { path: "/inspectionRecords", element: <InspectionRecordsPage /> }
];

function RoutesConfig() {
  const location = useLocation();
  const auth = useCheckAuth();

  return (
    <Routes location={location}>
      <Route element={<Layout auth={auth} />}>
        <Route
          path="/"
          element={
            <ProtectedRoute redirectCondition={auth} redirectPath={"/dashboard"}>
              <Login />
            </ProtectedRoute>
          }
        />
        <Route
          path="/signup"
          element={
            <ProtectedRoute redirectCondition={auth} redirectPath={"/"}>
              <SignUp />
            </ProtectedRoute>
          }
        />
        {RoutePaths.map((route) => (
          <Route
            key={route.path}
            path={route.path}
            element={
              <ProtectedRoute redirectCondition={!auth} redirectPath={"/"}>
                {route.element}
              </ProtectedRoute>
            }
          />
        ))}
      </Route>
    </Routes>
  );
}

export default RoutesConfig;
