import { theme } from "@/styles/theme";
import { Text, TextProps } from "@mantine/core";

export const DetailInformTitle = (params: TextProps) => {
  const { children } = params;

  return (
    <Text
      size={"12px"}
      fs={"normal"}
      lh={"155%"}
      fw={400}
      c={theme.colors?.dark?.[2]}
    >
      {children}
    </Text>
  );
};
