import { EquipmentCreateForm } from "@/features/equipment/components/form/EquipmentCreateForm";
import { EquipmentTable } from "@/features/equipment/components/table/EquipmentTable";
import { useModal } from "@/features/modal/ModalStackManager";
import { ActionHeader } from "@/features/standard/ActionHeader";
import { Header } from "@/features/standard/Header";
import { Main } from "@/features/standard/Main";
import { HeaderSubTitle } from "@/features/standard/SubTitle";
import { HeaderTitle } from "@/features/standard/Title";
import { Wrapper } from "@/features/standard/Wrapper";
import { usePub } from "@/hooks";
import { Button } from "@mantine/core";
import { IconPlus, IconTrash } from "@tabler/icons-react";

const EquipmentWrap = Object.assign({}, Main, {
  Header: Header,
  HeaderTitle: HeaderTitle,
  HeaderSubTitle: HeaderSubTitle,
  Wrapper: Wrapper,
  ActionHeader: ActionHeader,
  ActionButton: Button,
  CreateForm: EquipmentCreateForm,
});

export const Equipment = () => {

  const { openModal } = useModal();

  const publish = usePub();
  return (
    <EquipmentWrap.Wrapper>
      <EquipmentWrap.ActionHeader>
        <EquipmentWrap.ActionButton
          leftIcon={<IconPlus />}
          onClick={() =>
            openModal(<EquipmentWrap.CreateForm />, null, "설비 추가", true)
          }
        >
          추가
        </EquipmentWrap.ActionButton>
        <EquipmentWrap.ActionButton
          color="red"
          rightIcon={<IconTrash />}
          onClick={() => publish("deleteSelectedItems")}
        >
          선택된 행 삭제
        </EquipmentWrap.ActionButton>
      </EquipmentWrap.ActionHeader>
      <EquipmentWrap.Header>
        <EquipmentWrap.HeaderTitle>설비 리스트</EquipmentWrap.HeaderTitle>
        <EquipmentWrap.HeaderSubTitle>
          우리 회사에서 다루는 설비에 대해 조회할 수 있는 페이지 입니다.
        </EquipmentWrap.HeaderSubTitle>
      </EquipmentWrap.Header>
      <EquipmentTable />
    </EquipmentWrap.Wrapper>
  );
};