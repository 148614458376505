import { DetailBadge } from "@/features/ui/detail/DetailBadge";
import { DetailContent } from "@/features/ui/detail/DetailContent";
import { DetailField } from "@/features/ui/detail/DetailField";
import { DetailHeader } from "@/features/ui/detail/DetailHeader";
import { DetailInformTitle } from "@/features/ui/detail/DetailInformTitle";
import { DetailLabel } from "@/features/ui/detail/DetailLabel";
import { DetailLink } from "@/features/ui/detail/DetailLink";
import { DetailLinkWrapper } from "@/features/ui/detail/DetailLinkWrapper";
import { DetailTitle } from "@/features/ui/detail/DetailTitle";
import { Checkbox, Space } from "@mantine/core";
import { ItemsGet200ResponseRowsInnerLotsInnerInventoriesInner } from "@sizlcorp/sizl-api-document/dist/models";

const DetailProductionPlan = Object.assign(DetailHeader, {
  Title: DetailTitle,
  Inform: DetailInformTitle,
  Field: DetailField,
  Label: DetailLabel,
  Link: DetailLink,
  LinkWrapper: DetailLinkWrapper,
  Badge: DetailBadge,
  Content: DetailContent,
  CheckBox: Checkbox,
});


export interface DetailLocationInformProps {
  data: ItemsGet200ResponseRowsInnerLotsInnerInventoriesInner | any;
}

export const LocationDetailInForm = (params: DetailLocationInformProps) => {
  const { data } = params;

  return (
    <>
      <DetailProductionPlan>
        <DetailProductionPlan.Title>{`[재고 상세]`}</DetailProductionPlan.Title>
        <Space h={8} />
        <DetailProductionPlan.Field>
          <DetailProductionPlan.Label>
            {"로케이션코드"}
          </DetailProductionPlan.Label>
          <DetailProductionPlan.Content>
            {data?.data[0]?.locationCode}
          </DetailProductionPlan.Content>
        </DetailProductionPlan.Field>
      </DetailProductionPlan>
    </>
  );
};
