import { AuthSignupPost201ResponseEquipmentWorksInner, ProductionPlansGet200ResponseRowsInnerWorksInner } from "@sizlcorp/sizl-api-document/dist/models";
import dayjs from "dayjs";
import { createContext, useMemo, useState } from "react";

export interface SocketData {
  equipmentCounter: string | null;
  equipmentLimitCounter: string | null;
  isLimitHit: boolean;
  activeWorkId: string | null;
  workId?: number;
}

type tempData = { key: string, value: string };
export interface WorkContextProps {
  site?: string;
  line?: string;
  date: [Date | null, Date | null]; // string;
  works: (AuthSignupPost201ResponseEquipmentWorksInner | ProductionPlansGet200ResponseRowsInnerWorksInner)[];
  tempQuantity: tempData[];
  tempDefects: { id?: string, defect: tempData[] }[];
}

const initialDate = dayjs(new Date()).startOf('day').toDate();

// 초기 상태에 대한 타입 안정성 강화
const initialWorkState: WorkContextProps = {
  site: undefined,
  line: undefined,
  date: [initialDate, initialDate],
  works: [],
  tempQuantity: [],
  tempDefects: []
};

export const WorkStateContext = createContext<WorkContextProps>(initialWorkState);

export const WorkActionsContext = createContext<{
  setSite: (site?: string) => void;
  setLine: (line?: string) => void;
  setDate: (date: [Date | null, Date | null]) => void; // 여러 날짜를 설정할 수 있도록 배열로 변경
  setWorks: (works: any[]) => void;
  setTempQuantity: (quantity: tempData[]) => void;
  setTempDefects: (defects: { id?: string, defect: tempData[] }[]) => void;
}>({
  setSite: () => { },
  setLine: () => { },
  setDate: () => { },
  setWorks: () => { },
  setTempQuantity: () => { },
  setTempDefects: () => { },
});

export const WorkProvider = ({ children }: { children: React.ReactNode }) => {
  const [workState, setWorkState] = useState<WorkContextProps>(initialWorkState);

  const actions = useMemo(() => ({
    setDate: (date: [Date | null, Date | null]) => {
      setWorkState((prev) => ({
        ...prev,
        date,
      }));
    },
    setSite: (site?: string) => {
      setWorkState((prev) => ({
        ...prev,
        site,
      }));
    },
    setLine: (line?: string) => {
      setWorkState((prev) => ({
        ...prev,
        line,
      }));
    },
    setWorks: (works: any[]) => {
      setWorkState((prev) => ({
        ...prev,
        works,
      }));
    },
    setTempQuantity: (quantity: tempData[]) => {
      setWorkState((prev) => ({
        ...prev,
        tempQuantity: quantity
      }));
    },
    setTempDefects: (defects: { id?: string, defect: tempData[] }[]) => {
      setWorkState((prev) => ({
        ...prev,
        tempDefects: defects
      }));
    },
  }), []);

  return (
    <WorkActionsContext.Provider value={actions}>
      <WorkStateContext.Provider value={workState}>
        {children}
      </WorkStateContext.Provider>
    </WorkActionsContext.Provider>
  );
};

