// import { IconArrowUpRight, IconDeviceAnalytics } from '@tabler/icons-react';
import {
  Button,
  Container,
  Group,
  List,
  Text,
  ThemeIcon,
  Title,
  createStyles,
  rem,
} from "@mantine/core";
import { IconCheck } from "@tabler/icons-react";

interface StatsSegmentsProps {
  total: string;
  diff: number;
  data: {
    label: string;
    count: string;
    part: number;
    color: string;
  }[];
}

const useStyles = createStyles((theme) => ({
  progressLabel: {
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,
    lineHeight: 1,
    fontSize: theme.fontSizes.sm,
  },

  stat: {
    borderBottom: `${rem(3)} solid`,
    paddingBottom: rem(5),
  },

  statCount: {
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,
    lineHeight: 1.3,
  },

  diff: {
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,
    display: "flex",
    alignItems: "center",
  },

  icon: {
    color:
      theme.colorScheme === "dark"
        ? theme.colors.dark[3]
        : theme.colors.gray[4],
  },

  inner: {
    display: "flex",
    justifyContent: "space-between",
    paddingTop: `calc(${theme.spacing.xl} * 4)`,
    paddingBottom: `calc(${theme.spacing.xl} * 4)`,
  },

  content: {
    maxWidth: rem(480),
    marginRight: `calc(${theme.spacing.xl} * 3)`,

    [theme.fn.smallerThan("md")]: {
      maxWidth: "100%",
      marginRight: 0,
    },
  },

  title: {
    color: theme.colorScheme === "dark" ? theme.white : theme.black,
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,
    fontSize: rem(44),
    lineHeight: 1.2,
    fontWeight: 900,

    [theme.fn.smallerThan("xs")]: {
      fontSize: rem(28),
    },
  },

  control: {
    [theme.fn.smallerThan("xs")]: {
      flex: 1,
    },
  },

  image: {
    flex: 1,

    [theme.fn.smallerThan("md")]: {
      display: "none",
    },
  },

  highlight: {
    position: "relative",
    backgroundColor: theme.fn.variant({
      variant: "light",
      color: theme.primaryColor,
    }).background,
    borderRadius: theme.radius.sm,
    padding: `${rem(4)} ${rem(12)}`,
  },
}));

export function MainDashboard() {
  const { classes } = useStyles();
  // const dashboardData = [{
  //   label: '2021-10-01',
  //   count: "100",
  //   part: 10,
  //   color: theme.colors?.blue?.[5] ?? 'black'
  // }];

  return (
    <div>
      <Container>
        <div className={classes.inner}>
          <div className={classes.content}>
            <Title className={classes.title}>
              가장 <span className={classes.highlight}>편한</span> MES <br />
            </Title>
            <Text color="dimmed" mt="md">
              우리 회사에 맞는 MES를 경험해보세요
            </Text>

            <List
              mt={30}
              spacing="sm"
              size="sm"
              icon={
                <ThemeIcon size={20} radius="xl">
                  <IconCheck size={rem(12)} stroke={1.5} />
                </ThemeIcon>
              }
            >
              <List.Item>
                <b>쉽고 빠른 기준정보 입력</b> – 단 하나의 엑셀파일로 기준정보를
                한번에 입력할 수 있습니다.
              </List.Item>
              <List.Item>
                <b>한 화면에서 관리되는 생산 현황</b> – 한 화면에서 생산계획,
                생산실적, 불량실적을 한눈에
              </List.Item>
            </List>

            <Group mt={30}>
              <Button radius="xl" size="md" className={classes.control}>
                시작하기
              </Button>
            </Group>
          </div>
          {/* <Image src={image.src} className={classes.image} /> */}

          {/* <StatItem total="1024" diff={203} data={dashboardData} /> */}
        </div>
      </Container>
    </div>
  );
}

// export function StatItem({ total, diff, data }: StatsSegmentsProps) {
//   const { classes } = useStyles();

//   const segments = data.map((segment) => ({
//     value: segment.part,
//     color: segment.color,
//     label: segment.part > 10 ? `${segment.part}%` : undefined,
//   }));

//   const descriptions = data.map((stat) => (
//     <Box key={stat.label} sx={{ borderBottomColor: stat.color }} className={classes.stat}>
//       <Text tt="uppercase" fz="xs" c="dimmed" fw={700}>
//         {stat.label}
//       </Text>

//       <Group position="apart" align="flex-end" spacing={0}>
//         <Text fw={700}>{stat.count}</Text>
//         <Text c={stat.color} fw={700} size="sm" className={classes.statCount}>
//           {stat.part}%
//         </Text>
//       </Group>
//     </Box>
//   ));

//   return (
//     <Paper withBorder p="md" radius="md">
//       <Group position="apart">
//         <Group align="flex-end" spacing="xs">
//           <Text fz="xl" fw={700}>
//             {total}
//           </Text>
//           <Text c="teal" className={classes.diff} fz="sm" fw={700}>
//             <span>{diff}%</span>
//             <IconArrowUpRight size="1rem" style={{ marginBottom: rem(4) }} stroke={1.5} />
//           </Text>
//         </Group>
//         <IconDeviceAnalytics size="1.4rem" className={classes.icon} stroke={1.5} />
//       </Group>

//       <Text c="dimmed" fz="sm">
//         Page views compared to previous month
//       </Text>

//       <Progress
//         sections={segments}
//         size={34}
//         classNames={{ label: classes.progressLabel }}
//         mt={40}
//       />
//       <SimpleGrid cols={3} breakpoints={[{ maxWidth: 'xs', cols: 1 }]} mt="xl">
//         {descriptions}
//       </SimpleGrid>
//     </Paper>
//   );
// }
