import { defects } from '@/api/defect/useDefectsQuery';
import { useModal } from '@/features/modal/ModalStackManager';
import styled from '@emotion/styled';
import { Button, TransferList, TransferListData } from '@mantine/core';
import { DefectsGet200ResponseRowsInner } from '@sizlcorp/sizl-api-document/dist/models';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useQuery } from 'react-query';

export interface DefectTypeItem {
    value: string;
    label: string;
}

export const DefectTypeSet = () => {
    const { closeModal } = useModal();
    const storageValue = window.localStorage.getItem('defectTypes');
    const parsedStorageValue = storageValue ? JSON.parse(storageValue) : null;

    // const { data: defectData, isLoading } = useGetDefectsAllQuery();
    const { queryKey, queryFn } = defects.findSelect({});
    const { data: defectData, isLoading } = useQuery(
        queryKey,
        queryFn,
        {
            select: (data: DefectsGet200ResponseRowsInner[]) =>
                data?.map((defect) => {
                    return {
                        value: defect.code!,
                        label: defect.name!,
                    };
                }) ?? [],
        }
    );

    // defectData를 TransferList에서 사용할 수 있는 형태로 변환
    const formattedDefectData = useMemo(() => {
        return defectData ? defectData.map(defect => ({
            value: defect.value.toString(),
            label: defect.label
        })) : [];
    }, [defectData]);

    // 초기값 설정 함수
    // useCallback을 사용하여 getInitialData 함수를 메모이제이션
    const getInitialData = useCallback(() => {
        if (defectData && parsedStorageValue) {
            // 로컬 스토리지에서 가져온 데이터를 평탄화(flatten)
            const flattedStorageValue = parsedStorageValue.flat(1);
            const [defectTypes, useableDefectTypes] = parsedStorageValue; // 구조 분해 할당으로 각 배열을 추출

            // 로컬 스토리지에 저장된 모든 불량유형의 value 값을 배열로 추출
            const storedDefectValues = flattedStorageValue.map((defect: { value: string }) => defect.value);
            // API에서 가져온 불량유형의 value 값을 배열로 추출
            const defectValues = defectData.map(defect => defect.value.toString());

            // 로컬 스토리지에는 존재하지만 API 데이터에는 존재하지 않는 불량유형을 찾아 제거 대상으로 설정
            const diffFromDefect = storedDefectValues.filter((item: string) => !defectValues.includes(item));

            // API 데이터에는 존재하지만 로컬 스토리지에는 존재하지 않는 불량유형을 찾아 추가 대상으로 설정
            const diffFromStorage = defectValues.filter((item: string) => !storedDefectValues.includes(item))
                .map(data => defectData.find(defect => defect.value.toString() === data));

            // 새로운 불량유형 목록을 생성: 기존 불량유형 + 추가된 불량유형 - 제거된 불량유형
            const newDefectValues = [...defectTypes, ...diffFromStorage].filter(defect => !diffFromDefect.includes(defect.value.toString()));
            // 사용 가능한 불량유형 목록을 갱신: 기존 사용 가능 불량유형 - 제거된 불량유형
            const newUseableDefectValues = [...useableDefectTypes].filter(defect => !diffFromDefect.includes(defect.value.toString()));

            // 갱신된 불량유형 목록과 사용 가능한 불량유형 목록을 반환
            return [newDefectValues, newUseableDefectValues];
        } else if (parsedStorageValue) {
            // 로컬 스토리지 데이터만 존재하는 경우 그대로 반환
            return parsedStorageValue;
        } else if (formattedDefectData.length > 0) {
            // API 데이터만 존재하는 경우 초기 사용 가능 목록을 빈 배열로 설정
            return [[], formattedDefectData];
        }
        // 기본적으로 빈 배열을 반환
        return [[], []];
    }, [defectData, parsedStorageValue, formattedDefectData]);


    const [data, setData] = useState<TransferListData>([[], []]);

    useEffect(() => {
        if (defectData && defectData.length > 0) { // 예: defectData가 비어있지 않을 때만 실행
            setData(getInitialData());
        }
    }, [defectData]);

    useEffect(() => {
        if (!parsedStorageValue && defectData && !isLoading) {
            setData([[], formattedDefectData]);
            window.localStorage.setItem('defectTypes', JSON.stringify([[], formattedDefectData]));
        }
    }, [defectData, formattedDefectData, isLoading, parsedStorageValue]);

    if (isLoading || !defectData) {
        return null;
    }

    const handleDefectSave = () => {
        window.localStorage.setItem('defectTypes', JSON.stringify(data));
        closeModal(data);
        /**
         * 테이블에 불량 유형을 표시할 때는 1번째 인덱스의 데이터를 사용한다.
         */
    }

    return (
        <DefectTypeContainer>
            <TransferList
                value={data}
                onChange={(newValue) => {
                    setData(newValue);
                }}
                searchPlaceholder="Search..."
                nothingFound="Nothing here"
                titles={['불량 유형', '사용할 불량 유형']}
                breakpoint="sm"
            />
            <Button variant="outline" onClick={() => handleDefectSave()}>저장</Button>
        </DefectTypeContainer>
    );
}

const DefectTypeContainer = styled.section`
    display: flex;
    flex-direction: column;
    gap: 1rem;
`