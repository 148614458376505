import { MODAL_TYPES } from "@/features/ui/modal/GlobalModals";
import { atom } from "recoil";

export type DefectModalType = {
  modalType: typeof MODAL_TYPES.DefectModal;
  isOpen: boolean;
  param: {};
};

export type PlanningModalType = {
  modalType: typeof MODAL_TYPES.CreatePlanningModal;
  isOpen: boolean;
  param: {};
};

export type RawMaterialModalType = {
  modalType: typeof MODAL_TYPES.RawMaterialModal;
  isOpen: boolean;
  param: {};
};

export type WorkOrderModalType = {
  modalType: typeof MODAL_TYPES.WorkOrderModal;
  isOpen: boolean;
  param: {};
};

export type CalendarModalType = {
  modalType: typeof MODAL_TYPES.CalendarModal;
  isOpen: boolean;
  param: {};
};

export type WorkCalendarModalType = {
  modalType: typeof MODAL_TYPES.WorkCalendarModal;
  isOpen: boolean;
  param: {};
};

export type EquipmentModalType = {
  modalType: typeof MODAL_TYPES.EquipmentModal;
  isOpen: boolean;
  param: {
    equipmentId: number;
  };
};

export type ItemModifyModal = {
  modalType: typeof MODAL_TYPES.ItemModifyModal;
  isOpen: boolean;
  param: {};
};

export type ModalType =
  | DefectModalType
  | ItemModifyModal
  | PlanningModalType
  | RawMaterialModalType
  | WorkOrderModalType
  | CalendarModalType
  | EquipmentModalType
  | WorkCalendarModalType;

export const modalAtom = atom<ModalType | null>({
  key: "modalState",
  default: null,
});
