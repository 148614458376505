import { useModal } from "@/features/modal/ModalStackManager";
import { MoldStockCreateForm } from "@/features/moldStock/form/stockCreateForm";
import { MoldStockTable } from "@/features/moldStock/table";
import { ActionButtonBox } from "@/features/standard/ActionButtonBox";
import { ActionHeader } from "@/features/standard/ActionHeader";
import { Header } from "@/features/standard/Header";
import { Main } from "@/features/standard/Main";
import { HeaderSubTitle } from "@/features/standard/SubTitle";
import { HeaderTitle } from "@/features/standard/Title";
import { Wrapper } from "@/features/standard/Wrapper";
import { usePub } from "@/hooks";
import { Button } from "@mantine/core";
import { IconPlus, IconTrash } from "@tabler/icons-react";

const MoldStock = Object.assign({}, Main, {
  Header: Header,
  HeaderTitle: HeaderTitle,
  HeaderSubTitle: HeaderSubTitle,
  InventoryWrapper: Wrapper,
  ActionHeader: ActionHeader,
  ActionButton: Button,
  ActionButtonBox: ActionButtonBox,
  MoldStockTable: MoldStockTable,
  MoldStockCreateForm: MoldStockCreateForm,
});

export const MoldStockPage = () => {
  const { openModal } = useModal();

  const publish = usePub();
  return (
    <>
      <MoldStock.InventoryWrapper>
        <MoldStock.ActionHeader>
          <MoldStock.ActionButtonBox>
            <MoldStock.ActionButton
              leftIcon={<IconPlus />}
              onClick={() =>
                openModal(
                  <MoldStock.MoldStockCreateForm />,
                  null,
                  "금형 재고 추가",
                  true
                )
              }
            >
              추가
            </MoldStock.ActionButton>
          </MoldStock.ActionButtonBox>
          <MoldStock.ActionButton
            color="red"
            rightIcon={<IconTrash />}
            onClick={() => publish("deleteSelectedItems")}
          >
            선택된 행 삭제
          </MoldStock.ActionButton>
        </MoldStock.ActionHeader>
        <MoldStock.Header>
          <MoldStock.HeaderTitle>금형 재고 리스트</MoldStock.HeaderTitle>
          <MoldStock.HeaderSubTitle>
            우리회사에서 다루는 실 금형을 조회할 수 있는 페이지 입니다.
          </MoldStock.HeaderSubTitle>
        </MoldStock.Header>
        <MoldStock.MoldStockTable />
      </MoldStock.InventoryWrapper>
    </>
  );
};
