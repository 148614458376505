import styled from '@emotion/styled';

interface BadgeTextBoxProps {
    children: React.ReactNode;
}

export const BadgeTextBox = (params: BadgeTextBoxProps) => {
    const { children } = params;

    return (
        <TextBox>{children}</TextBox>
    )
}

const TextBox = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-end;
`