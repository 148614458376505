import { theme } from "@/styles/theme";
import styled from "@emotion/styled";
import { Tabs } from "@mantine/core";

import { operations } from "@/api/operation/useOperationQuery";
import { InspectSpecificationUpdateForm } from "@/features/inspect/components/inspectSpecification/form/InspectSpecificationUpdateForm";
import { OperationBomRow } from "@/features/item/components/form/itemsStepperForm/operationsBomList/OperationBomRow";
import { OperationBomInform } from "@/features/operation/components/inform/OperationBomInform";
import { OperationInform } from "@/features/operation/components/inform/OperationInform";
import { Main } from "@/features/standard/Main";
import { DetailContainer } from "@/features/standard/detail/Container";
import { DetailContent } from "@/features/standard/detail/Content";
import { DetailWrapper } from "@/features/standard/detail/Wrapper";
import { ItemsGet200ResponseRowsInnerOperationsInnerOperationBomsInner } from "@sizlcorp/sizl-api-document/dist/models";
import { useQuery } from "react-query";
import { OperationDetailActionHeader } from "../header/OperationDetailActionHeader";

const Detail = Object.assign(Main, {
  Container: DetailContainer,
  Wrapper: DetailWrapper,
  Content: DetailContent,
})

interface OperationDetailFormProps {
  operactionCode: string | undefined;
}
export const OperationDetailForm = ({
  operactionCode,
}: OperationDetailFormProps) => {
  const { data: operation } = useQuery(operations.get({
    query: {
      $and: [{ "code": { "$eq": operactionCode } }]
    },
    populate: ["operationBoms"]
  }));

  const operationData = operation?.data?.rows && operation?.data?.rows[0];

  return (
    <>
      <Detail.Container>
        <OperationDetailActionHeader data={operationData} />
        <Detail.Wrapper>
          <Detail.Content>
            <OperationInform data={operationData} />
            <Tabs
              variant="outline"
              defaultValue="bom"
              styles={(theme) => ({
                tab: {
                  "&:not([data-active])": {
                    backgroundColor: theme.colors?.gray?.[0],
                  },
                },
              })}
            >
              <Tabs.List>
                <LeftSpace />
                <Tabs.Tab value="bom">BOM</Tabs.Tab>
                <Tabs.Tab value="inspectSpecification">검사표준</Tabs.Tab>
                <RightSpace />
              </Tabs.List>
              <Tabs.Panel value="bom">
                <OperationBomInform />
                <OperationBomRow
                  operationBoms={
                    operationData?.operationBoms as ItemsGet200ResponseRowsInnerOperationsInnerOperationBomsInner[]
                  }
                />
              </Tabs.Panel>
              <Tabs.Panel value="inspectSpecification">
                <InspectSpecificationUpdateForm data={operationData} />
              </Tabs.Panel>
            </Tabs>
          </Detail.Content>
        </Detail.Wrapper>
      </Detail.Container>
    </>
  );
};

const LeftSpace = styled.span`
  width: 32px;
  align-self: stretch;
  background: ${theme.colors?.gray?.[3]};
  border-bottom: ${theme.colors?.gray?.[3]} solid 1px;
  opacity: 0.2;
`;

const RightSpace = styled.span`
  flex: 1 0 0;
  align-self: stretch;
  background: ${theme.colors?.gray?.[3]};
  border-bottom: ${theme.colors?.gray?.[3]} solid 1px;
  opacity: 0.2;
`;
