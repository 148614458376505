import { items } from '@/api/items/useItemQuery';
import { ItemAutoComplete } from '@/features/ui/autoComplete/item/item-autoComplete';
import { Box, Button, TextInput } from '@mantine/core';
import { DateInput } from '@mantine/dates';
import { useForm } from '@mantine/form';
import { useQuery } from 'react-query';

export function LotForm(params: { name?: string, itemCodeValue?: string, onSubmit?: (values: any) => void }) {
  const { name, itemCodeValue, onSubmit } = params;
  const form = useForm({
    initialValues: {
      name: name,
      expiredAt: null,
      itemCode: itemCodeValue,
    },
  });

  const { data : itemData } = useQuery(items.codeSearch({
    query : form.values.itemCode || itemCodeValue ? {$and : [{"code" : {"$eq" : form.values.itemCode || itemCodeValue}}]} : '',
  }));
  
  return (
    <Box maw={320} mx="auto" w={400} h={380} >
      <form onSubmit={form.onSubmit((values) => onSubmit && onSubmit(values))}>
      <ItemAutoComplete 
          label="품목코드"
          placeholder="품목코드"
          {...form.getInputProps('itemCode') || itemCodeValue}
        />
        <TextInput
          label="이름"
          placeholder="이름"
          {...form.getInputProps('name')}
          disabled={itemData?.data?.rows && itemData?.data?.rows[0]?.usesLotName ? false : true}
        />
        <DateInput
          label="만료일"
          placeholder="만료일"
          valueFormat="YYYY-MM-DD"
          locale='ko'
          size="xs"
          {...form.getInputProps('expiredAt')}
          disabled={itemData?.data?.rows && itemData?.data?.rows[0]?.usesLotExpiration ? false : true}
        />
        
        <Button my={20} type="submit">Submit</Button>
      </form>
    </Box>
  );
}