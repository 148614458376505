import { LocationAutoComplete } from "@/features/ui/autoComplete/location/location-autoComplate";
import { Box, Button, Code, Text, TextInput } from "@mantine/core";
import { useForm } from "@mantine/form";
import { OperationAutoComplete } from "../../autoComplete/operation/operation-autoComplete";

export function OperationOutSourcesForm(params: {
  name: string;
  onSubmit?: (values: any) => void;
}) {
  const { name, onSubmit } = params;
  const form = useForm({
    initialValues: {
      name: name, // 외주처명
      operationCode: null, // 공정코드
      fromLocationCode: null, // 자재 출고 로케이션코드
      toLocationCode: null, // 재공품 입고 로케이션코드
    },
  });


  return (
    <Box maw={320} mx="auto" w={400}>
      <form onSubmit={form.onSubmit((values) => onSubmit && onSubmit(values))}>
        <TextInput
          label="외주처명"
          placeholder="외주처명"
          {...form.getInputProps("name")}
        />
        <OperationAutoComplete
          label="공정코드"
          placeholder="공정코드"
          {...form.getInputProps("operationCode")}
        />
        <LocationAutoComplete
          label="자재 출고 로케이션코드"
          width={"100%"}
          {...form.getInputProps("fromLocationCode")}
        />
        <LocationAutoComplete
          label="재공품 입고 로케이션코드"
          width={"100%"}
          {...form.getInputProps("toLocationCode")}
        />
        <Text size="sm" weight={500} mt="xl">
          Form values:
        </Text>
        <Code block mt={5}>
          {JSON.stringify(form.values, null, 2)}
        </Code>
        <Button type="submit"
          disabled={
            !form.values.operationCode ||
            !form.values.name ||
            !form.values.fromLocationCode ||
            !form.values.toLocationCode
          }>Submit</Button>
      </form>
    </Box>
  );
}
