import { useModal } from "@/features/modal/ModalStackManager";
import { CreateForm } from "@/features/operationOutsource/form/CreateForm";
import { OperationOutsourceTable } from "@/features/operationOutsource/table";
import { ActionHeader } from "@/features/standard/ActionHeader";
import { Header } from "@/features/standard/Header";
import { Main } from "@/features/standard/Main";
import { HeaderSubTitle } from "@/features/standard/SubTitle";
import { HeaderTitle } from "@/features/standard/Title";
import { Wrapper } from "@/features/standard/Wrapper";
import { usePub } from "@/hooks";
import { Button } from "@mantine/core";
import { IconPlus, IconTrash } from "@tabler/icons-react";

const OperationOutsource = Object.assign({}, Main, {
  Header: Header,
  HeaderTitle: HeaderTitle,
  HeaderSubTitle: HeaderSubTitle,
  Wrapper: Wrapper,
  ActionHeader: ActionHeader,
  ActionButton: Button,
  CreateForm: CreateForm,
});

export const OperationOutsourcePage = () => {
  const { openModal } = useModal();

  const publish = usePub();

  return (
    <OperationOutsource.Wrapper>
      <OperationOutsource.ActionHeader>
        <OperationOutsource.ActionButton
          leftIcon={<IconPlus />}
          onClick={() =>
            openModal(
              <OperationOutsource.CreateForm />,
              null,
              "외주처 추가",
              true
            )
          }
        >
          추가
        </OperationOutsource.ActionButton>
        <OperationOutsource.ActionButton
          color="red"
          rightIcon={<IconTrash />}
          onClick={() => publish("deleteSelectedItems")}
        >
          선택된 행 삭제
        </OperationOutsource.ActionButton>
      </OperationOutsource.ActionHeader>
      <OperationOutsource.Header>
        <OperationOutsource.HeaderTitle>외주처</OperationOutsource.HeaderTitle>
        <OperationOutsource.HeaderSubTitle>
          공정 외주처를 관리합니다.
        </OperationOutsource.HeaderSubTitle>
      </OperationOutsource.Header>
      <OperationOutsourceTable />
    </OperationOutsource.Wrapper>
  );
};
