import { OperationFormProps } from "@/features/item/components/form/ItemsCreateForm";
import { TableRow } from "@/features/item/components/form/itemsStepperForm/operationsForm/TableRow";
import styled from "@emotion/styled";
import { Button, Table, useMantineColorScheme } from "@mantine/core";
import { UseFormReturnType } from "@mantine/form";
import { IconPlus } from "@tabler/icons-react";

export const TableContainer = styled.section`
  display: flex;
  width: 100%;
  min-height: 250px;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
`;

export const TableWrapper = styled(Table)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  flex: 1 0 0;
  align-self: stretch;
`;

export const Thead = styled.thead`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
`;

export const Tbody = styled.tbody`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
`;

export const Tr = styled.tr`
  display: flex;
  width: 100%;
  align-items: flex-start;
  align-self: stretch;
`;

export const Th = styled.th<{ width?: number }>`
  display: flex;
  width: ${(props) => (props.width ? `${props.width}%` : "auto")};
  padding: 7px 10px;
  align-items: flex-start;
  gap: 10px;
`;

const Td = styled.td<{ width?: number }>`
  display: flex;
  width: ${(props) => (props.width ? `${props.width}%` : "auto")};
  padding: 7px 10px;
  align-items: flex-start;
  gap: 10px;
`;

const AddButton = styled<any>(Button)`
  display: flex;
  padding: 1px 8px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex: 1 0 0;
`;

const AddButtonTr = styled.tr`
  display: flex;
  width: 100%;
  padding: 7px 10px;
  align-items: flex-start;
  gap: 10px;
  flex: 1 0 0;
`;

export const OperationsForm = (params: {
  formData: UseFormReturnType<Partial<OperationFormProps>>;
}): JSX.Element => {
  const form = params.formData;

  const { colorScheme, toggleColorScheme } = useMantineColorScheme();
  const isDarkMode: boolean = colorScheme === "light";

  const handleClickAdd = () => {
    form.insertListItem("operationRows", {
      seq: undefined, // 공정순서
      routingCode: undefined, // 라우팅코드
      code: undefined, // 공정코드
      operationType: undefined, // 공정유형 (공정 타입)
      consumeType: undefined, // 소모 시점
      moldCode: undefined, // 금형코드
      operationBoms: [
        {
          operationCode: undefined, // 공정코드
          itemCode: undefined,
          quantity: undefined,
          seq: undefined,
        },
      ], // 공정 BOM
    });
  };

  return (
    <>
      <TableContainer>
        <TableWrapper>
          <Thead>
            <Tr>
              <Th width={6}>시퀀스</Th>
              <Th width={18}>라우팅</Th>
              <Th width={12}>공정코드</Th>
              <Th width={11}>공정타입</Th>
              <Th width={11}>소모 시점</Th>
              <Th width={34}>금형</Th>
              <Th width={8}>&nbsp;</Th>
            </Tr>
          </Thead>
          <Tbody>
            {form.values.operationRows &&
              form.values.operationRows.map((_, index) => (
                <>
                  <TableRow key={index} formIndex={index} formData={form} />
                  {form.values.operationRows &&
                    form.values.operationRows.length - 1 === index &&
                    form.values.operationRows.length <= 10 && (
                      <AddButtonTr>
                        <Td width={100} rowSpan={7}>
                          <AddButton
                            leftIcon={<IconPlus />}
                            variant={isDarkMode ? "white" : "dark"}
                            color={isDarkMode ? "dark" : "white"}
                            onClick={handleClickAdd}
                          >
                            새로운 공정 추가
                          </AddButton>
                        </Td>
                      </AddButtonTr>
                    )}
                </>
              ))}
          </Tbody>
        </TableWrapper>
      </TableContainer>
    </>
  );
};
