import { css, keyframes } from '@emotion/react';
import styled from "@emotion/styled";

interface WorkTextBoxProps {
    children: React.ReactNode;
    backgroundColor? : string
    highlight? : boolean
}

const highlightAnimation = keyframes`
  0% {
    background-color: yellow;
  }
  100% {
    background-color: transparent;
  }
`;

export const WorkTextBox = ({children, backgroundColor, highlight = false} : WorkTextBoxProps) => {
    
    return (
        <Content highlight={highlight} style={{ backgroundColor : `${backgroundColor}`}}>{children}</Content>
    )
}

const Content = styled.div<{ highlight : boolean }>`
    padding : 4px;
    height: 5.9rem;
    background-color: white;
    border-radius: 4px;
    display: flex;
    flex : 1;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    margin-top: 0.5rem;
    animation: ${props => props.highlight ? css`${highlightAnimation} 0.5s ease-out` : 'none'};
`