interface BadgeMainProps {
    children: React.ReactNode;
}

export const BadgeMain = (params : BadgeMainProps) => {
    const {children} = params;

    return (
        <div>
            {children}
        </div>
    )
}
