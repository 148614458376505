import { mutateDefect } from "@/api/defect/useDefectsQuery";
import { useModal } from "@/features/modal/ModalStackManager";
import customAlert from "@/features/ui/alert/alert";
import { RoutingAutoComplete } from "@/features/ui/autoComplete/routing/routing-autoComplete";
import { handleErrorResponse } from "@/utils/errorMessage";
import { validateFormValues } from "@/utils/validation";
import styled from "@emotion/styled";
import { Box, Button, TextInput } from "@mantine/core";
import { useForm } from "@mantine/form";
import { DefaultApiDefectsPostRequest } from "@sizlcorp/sizl-api-document/dist/models";
import { useMutation, useQueryClient } from "react-query";

export const DefectCreateForm = () => {
  const { closeModal } = useModal();
  const queryClient = useQueryClient();
  const { mutate: postMutate } = useMutation(
    (params : DefaultApiDefectsPostRequest) => 
      mutateDefect.create(params).mutationFn(params as DefaultApiDefectsPostRequest | any),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('defects');
      },
    }
  );

  const form = useForm({
    initialValues: {
      code: undefined,
      name: undefined,
      routingCode: undefined,
    },
  });

  const onSubmit = async () => {
    try {
      postMutate(
        {
          defectsGetRequest: {
            code: form.values.code!,
            name: form.values.name!,
            routingCode: form.values.routingCode!,
          },
        },
        {
          onSuccess: (res) => {
            closeModal(form.values);
            if (res.status === 200) {
              customAlert(
                "불량코드 생성에 성공하였습니다.",
                "생성 성공",
                "green"
              )
            } else {
              customAlert(
                "불량코드 생성에 실패하였습니다.",
                "생성 실패",
                "red"
              )
            }
          },
          onError: (error: any) => {
            customAlert(handleErrorResponse({ error, errorContext: '불량코드' }), '불량 생성 실패', 'red')
          }
        },

      )
    } catch (e) {
      customAlert(
        "불량코드 생성에 실패하였습니다.",
        "생성 실패",
        "red"
      )
    }
  };

  return (
    <Box w={320} mx="auto">
      <Form>
        <ModalFieldSet>
          <TextInput
            withAsterisk
            label="불량코드"
            {...form.getInputProps("code")}
          />
          <TextInput
            withAsterisk
            label="불량명"
            {...form.getInputProps("name")}
          />
          <RoutingAutoComplete
            label="라우팅코드"
            {...form.getInputProps("routingCode")}
          />
          <ModalConfirmBtnBox>
            <Button color="gray" onClick={closeModal}>
              취소
            </Button>
            <Button
              disabled={validateFormValues({
                pageType: "defects",
                values: form.values,
              })}
              onClick={onSubmit}
            >
              불량코드 생성
            </Button>
          </ModalConfirmBtnBox>
        </ModalFieldSet>
      </Form>
    </Box>
  );
};

const Form = styled.form`
  width: 100%;
  height: 100%;
`;

const ModalFieldSet = styled.fieldset`
  border: none;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const ModalConfirmBtnBox = styled.div`
  padding: 24px 0;
  display: flex;
  justify-content: flex-end;
  gap: 20px;
`;
