import DefaultInstance from "@/instance/axios";
import { createMutationKeys, createQueryKeys, mergeQueryKeys } from "@lukemorales/query-key-factory";
import { DefaultApiWmsLogsGetRequest, DefaultApiWmsLogsPostRequest, DefaultApiWmsLogsWmsLogIdGetRequest } from "@sizlcorp/sizl-api-document/dist/models";

export const wmsLog = createQueryKeys('wmsLog', {
    all: null,
    get: (params : DefaultApiWmsLogsGetRequest) => {
        const query = { ...params };
        if (Array.isArray(params.query.$and) && params.query.$and.length > 0) {
            query["query"] = JSON.stringify(params.query);
        }
        if (params.search) {
            query["search"] = params.search;
            query["searchFields"] = params.searchFields;
        }
        return {
            queryKey: [query, JSON.stringify(query.query)],
            queryFn: () => DefaultInstance.wmsLogsGet(query)
        }
    },
    detail : (params : DefaultApiWmsLogsWmsLogIdGetRequest) => {
        return {
            queryKey: [params.wmsLogId],
            queryFn: () => DefaultInstance.wmsLogsWmsLogIdGet(params)
        }
    }
})

export const mutateWmsLog = createMutationKeys('wmsLog', {
    create: (params : DefaultApiWmsLogsPostRequest) => ({
        mutationKey: [params.wmsLogsGetRequest],
        mutationFn: () => DefaultInstance.wmsLogsPost(params),
    }),
})

export const wmsLogQuery = mergeQueryKeys(wmsLog);