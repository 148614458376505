import { useState } from "react";

export const useStepper = () => {
  const [active, setActive] = useState<number>(0);

  const nextStep = () => {
    setActive((current) => (current < 3 ? current + 1 : current));
  };
  const prevStep = () => {
    setActive((current) => (current > 0 ? current - 1 : current));
  };

  return {
    active: active,
    setActive: setActive,
    next: nextStep,
    prev: prevStep,
  };
};
