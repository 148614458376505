import { MoldStockCreateForm } from "@/features/moldStock/form/stockCreateForm";
import { ActionButtonBox } from "@/features/standard/ActionButtonBox";
import { ActionHeader } from "@/features/standard/ActionHeader";
import { Header } from "@/features/standard/Header";
import { Main } from "@/features/standard/Main";
import { HeaderSubTitle } from "@/features/standard/SubTitle";
import { HeaderTitle } from "@/features/standard/Title";
import { Wrapper } from "@/features/standard/Wrapper";
import { WorkLogTable } from "@/features/workLog/table";
import { Button } from "@mantine/core";

const WorkLog = Object.assign({}, Main, {
  Header: Header,
  HeaderTitle: HeaderTitle,
  HeaderSubTitle: HeaderSubTitle,
  Wrapper: Wrapper,
  ActionHeader: ActionHeader,
  ActionButton: Button,
  ActionButtonBox: ActionButtonBox,
  WorkLogTable: WorkLogTable,
  MoldStockCreateForm: MoldStockCreateForm,
});

export const WorkLogPage = () => {

  return (
    <>
      <WorkLog.Wrapper>
        <WorkLog.Header>
          <WorkLog.HeaderTitle>작업 일보 리스트</WorkLog.HeaderTitle>
          <WorkLog.HeaderSubTitle>
            우리회사에서 다루는 작업일보를 조회할 수 있는 페이지 입니다.
          </WorkLog.HeaderSubTitle>
        </WorkLog.Header>
        <WorkLog.WorkLogTable />
      </WorkLog.Wrapper>
    </>
  );
};
