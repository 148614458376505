import { HeaderMenu } from "@/features/layout/Header";
import styled from "@emotion/styled";
import { AppShell, Header } from "@mantine/core";
import { Outlet } from "react-router-dom";

export const Layout = ({ auth }: { auth: boolean }) => {
    return (
        <Main>
            {!auth ?
                <Outlet /> :
                <AppShell
                    padding="xs"
                    header={
                        <Header height={60} p="xs">
                            <HeaderMenu />
                        </Header>
                    }
                    styles={(theme) => ({
                        main: {
                            backgroundColor:
                                theme.colorScheme === "dark"
                                    ? theme.colors.dark[8]
                                    : theme.colors.gray[0],
                        },
                    })}
                >
                    <Outlet />
                </AppShell>
            }
        </Main>
    );
};

const Main = styled.main`
  min-width: 100vw;
  min-height: 100vh;
  width: 100vw;
  height: 100vh;
`;
