import DefaultInstance from "@/instance/axios";
import { createQueryKeys } from "@lukemorales/query-key-factory";
import { DefaultApiWorkLogsFindPostRequest, DefaultApiWorkLogsGetRequest } from "@sizlcorp/sizl-api-document/dist/models";


export const workLogs = createQueryKeys('workLogs', {
    all : null,
    get : (params : DefaultApiWorkLogsGetRequest) => {
        const query = { ...params };
        if (Array.isArray(params.query?.$and) && params.query?.$and.length > 0) {
            query["query"] = JSON.stringify(params.query);
        }
        if (params.search) {
            query["search"] = params.search;
            query["searchFields"] = params.searchFields;
        }

        return {
            queryKey: [query, JSON.stringify(query.query)],
            queryFn: () => DefaultInstance.workLogsGet(query)
        }
    },
    find : (params : DefaultApiWorkLogsFindPostRequest) => {
        const query = { ...params };
        if (Array.isArray(params.query?.$and) && params.query?.$and.length > 0) {
            query["query"] = JSON.stringify(params.query);
        }
        if (params.search) {
            query["search"] = params.search;
            query["searchFields"] = params.searchFields;
        }

        return {
            queryKey: [query, JSON.stringify(query.query)],
            queryFn: () => DefaultInstance.workLogsFindPost(query)
        }
    }
});