import styled from "@emotion/styled";

interface ParentsProps {
    children: React.ReactNode;
}

export const Parents = ({ children } : ParentsProps) => {
    return (
        <Container>{children}</Container>
    )
}

const Container = styled.div`
    display: grid;
    width : 100%;
    gap : 10px;
    grid-template-columns: repeat(auto-fill, minmax(704px, 2fr));
`