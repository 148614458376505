import { css } from "@emotion/css";
import styled from "@emotion/styled";
import {
  Anchor,
  Badge,
  Button,
  Flex,
  Pagination,
  useMantineColorScheme,
} from "@mantine/core";
import { ItemsGet200ResponseRowsInnerLotsInnerInventoriesInner } from "@sizlcorp/sizl-api-document/dist/models";
import { useEffect, useRef, useState } from "react";
import type { Column, SortColumn } from "react-data-grid";
import DataGrid, { SelectColumn } from "react-data-grid";

import { inventories } from "@/api/inventories/useInventoriesQuery";
import { inventoriesHeader } from "@/constants/columnHeader";
import { queryDefaultValues } from "@/constants/queryDefaultValues";
import { LedgerDetailForm } from "@/features/inventory/components/Form/detail/DetailForm";
import { ItemsDetailForm } from "@/features/item/components/form/ItemsDetailForm";
import { LocationDetailForm } from "@/features/location/detail/LocationDetailForm";
import { useModal } from "@/features/modal/ModalStackManager";
import { CustomFilter } from "@/features/ui/Base/List/CustomFilter/CustomFilter";
import { CustomSorter } from "@/features/ui/Base/List/CustomSorter/CustomSorter";
import { SearchBox, SearchProps } from "@/features/ui/Base/List/SearchBox/SearchBox";
import useQueryString from "@/hooks/useQueryString";
import useUpdateUrlParams from "@/hooks/useUpdateUrlParams";
import { theme } from "@/styles/theme";
import timeUtil from "@/utils/timeUtil";
import { setToLocaleString } from "@/utils/unitMark";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";

export interface Row {
  id: number;
  itemCode: string;
  itemName: string;
  spec: string;
  itemType: string;
  locationCode: string;
  locationName: string;
  closedAt: string;
  closedQuantity: string;
  increaseQuantity: string;
  decreaseQuantity: string;
  deltaQuantity: string;
  quantity: string;
  lotId: number;
  lot: {
    name?: string;
    code?: string;
    expiration?: string;
  };
  unitText: string;
}

interface ColorThemeProps {
  isDarkMode: boolean;
}

export const InventoryTable = ({ onRowSelect }: any) => {
  const [initialQueryString, setQueryString] = useQueryString(queryDefaultValues);

  const { colorScheme, toggleColorScheme } = useMantineColorScheme();
  const isDarkMode: boolean = colorScheme === "light";

  const [sortColumns, setSortColumns] = useState<readonly SortColumn[]>([]);

  const [selectedRows, setSelectedRows] = useState(
    (): ReadonlySet<any> => new Set()
  );

  const [selectedRowsInform, setSelectedRowsInform] = useState<ItemsGet200ResponseRowsInnerLotsInnerInventoriesInner[]>([]);
  const [formatterPropsData, setFormatterPropsData] = useState<any>([]);

  const [activePage, setPage] = useState(initialQueryString.page);
  const [query, setQuery] = useState(JSON.parse(initialQueryString.query));
  const [sort, setSort] = useState(initialQueryString.sort);
  const [search, setSearch] = useState<SearchProps>({
    search: initialQueryString.search,
    searchFields: initialQueryString.searchFields,
    pageSize: initialQueryString.pageSize,
  });

  const updateUrlParams = useUpdateUrlParams();

  const { openModal } = useModal();

  const searchFieldsHeader = inventoriesHeader.filter(
    (item) => item.category === "text"
  );

  const { data: inventory, refetch } = useQuery(inventories.get({
    query: query,
    search: search.search,
    searchFields: search.searchFields.length
      ? search.searchFields
      : searchFieldsHeader.map((inventory) => inventory.value),
    page: activePage,
    pageSize: Number(search.pageSize),
    sort: sort.length ? sort.join(",") : "-id",
    populate: ["lot", "location", "item"],
  }));

  // 새로운 데이터가 로드될 때, 현재 페이지가 유효하지 않으면 1페이지로 설정
  useEffect(() => {
    if (inventory && activePage > (inventory?.data?.totalPages ?? 0)) {
      setPage(1);
      setQueryString((prev) => ({
        ...prev,
        page: 1,
      }));
    }
  }, [inventory, activePage, setQueryString]);

  // 페이지 Query String으로 라우팅 이동
  useEffect(() => {
    updateUrlParams({
      page: activePage,
      pageSize: search.pageSize,
      search: search.search,
      searchFields: search.searchFields,
      query: JSON.stringify(query),
      sort: sort
    }, queryDefaultValues);
  }, [activePage, search, query, sort, updateUrlParams]);

  const rows: readonly ItemsGet200ResponseRowsInnerLotsInnerInventoriesInner[] =
    inventory?.data.rows ?? [];
  const selectedRowsRef = useRef<ReadonlySet<any>>(new Set());

  // 해당 코드가 어떤 이유에서 사용되는건지 잘 모르겠다.
  // 다같이 확인해보고 필요 없다면 삭제 처리해도 될 듯..
  useEffect(() => {
    const selectedRowId = selectedRows.values().next().value;
    if (selectedRowId) {
      const selectedRow = rows.find((row) => row.id === selectedRowId);

      setFormatterPropsData(selectedRow);
    } else {
      setFormatterPropsData([]);
    }
  }, [selectedRows]);

  useEffect(() => {
    // 부모에게 선택된 row를 넘겨주기 위한 함수
    if (formatterPropsData) {
      onRowSelect(selectedRows, formatterPropsData, selectedRowsRef, selectedRowsInform);
    }
  }, [selectedRows, formatterPropsData]);

  const { t } = useTranslation();

  const itemTypeColor: { [key: string]: string } = {
    "PRODUCT": "green.7",
    "SEMI_PRODUCT": "blue.7",
    "MATERIAL": "yellow.7",
    "SUBSIDIARY": "red.7",
  };

  const columns: readonly Column<Row>[] = [
    {
      ...SelectColumn,
      width: 70,
      maxWidth: 500,
      resizable: true,
      headerCellClass: css`
        & > * {
          justify-content: flex-start;
          padding-left: 24px;
        }
      `,
      cellClass: css`
        .rdg-checkbox-label {
          padding-left: 24px;
        }
      `,
    },
    {
      key: "itemCode",
      name: "품목코드",
      sortable: true,
      resizable: true,
      formatter: ({ row }) => {
        const detailItemAction = () => {
          openModal(
            <ItemsDetailForm itemCode={row.itemCode} />,
            null,
            "재고 상세"
          );
        };
        return <Anchor onClick={detailItemAction}>{row.itemCode}</Anchor>;
      },
    },
    {
      key: "itemName",
      name: "품목 명",
      sortable: true,
      resizable: true,
      formatter: ({ row }) => <div>{row.itemName}</div>,
    },
    {
      key: "spec",
      name: "규격",
      sortable: true,
      resizable: true,
      formatter: ({ row }) => <div>{row.spec}</div>,
    },
    {
      key: "itemType",
      name: "품목 타입",
      sortable: true,
      resizable: true,
      formatter: ({ row }) => {

        return (
          <Badge size="lg" color={itemTypeColor[row.itemType]}>{t(row.itemType)}</Badge>
        );
      },
    },
    {
      key: "lot.name",
      name: "로트명",
      sortable: true,
      resizable: true,
      formatter: ({ row }) => <div>{row.lot?.name}</div>,
    },
    {
      key: "lot.expiration",
      name: "로트 유효기한",
      sortable: true,
      resizable: true,
      formatter: ({ row }) => (
        <div>
          {row.lot?.expiration === null ? "" : timeUtil(row.lot?.expiration!)}
        </div>
      ),
    },
    {
      key: "locationName",
      name: "위치",
      sortable: true,
      resizable: true,
      formatter: ({ row }) => {
        const detailItemAction = () => {
          openModal(
            <LocationDetailForm locationCode={row.locationCode ?? ""} />,
            null,
            "로케이션 상세"
          );
        };
        return (
          <Anchor onClick={detailItemAction}>{row.locationName}</Anchor>
        );
      },
    },
    {
      key: "closedQuantity",
      name: "마감 재고",
      sortable: true,
      resizable: true,
      cellClass: css`
        justify-content: flex-end;
      `,
      formatter: ({ row }) => (
        <span>{row.closedQuantity !== null ? (setToLocaleString(row.closedQuantity) + " " + row.unitText) : ""}</span>
      ),
    },
    {
      key: "closedAt",
      name: "마감일시",
      sortable: true,
      resizable: true,
      width: 160,
      formatter: ({ row }) => {
        return (
          <span>
            {row.closedAt === null
              ? ""
              : dayjs(row.closedAt).format("YYYY-MM-DD HH:mm:ss")}
          </span>
        );
      },
    },
    {
      key: "increaseQuantity",
      name: "추가된 재고",
      sortable: true,
      resizable: true,
      cellClass: css`
        justify-content: flex-end;
      `,
      formatter: ({ row }) => {
        return <span>{setToLocaleString(row.increaseQuantity)} {row.unitText}</span>;
      },
    },
    {
      key: "decreaseQuantity",
      name: "감소된 재고",
      sortable: true,
      resizable: true,
      cellClass: css`
        justify-content: flex-end;
      `,
      formatter: ({ row }) => (
        <span>{setToLocaleString(row.decreaseQuantity)} {row.unitText}</span>
      ),
    },
    {
      key: "deltaQuantity",
      name: "재고 변화량",
      sortable: true,
      resizable: true,
      cellClass: css`
        justify-content: flex-end;
      `,
      formatter: ({ row }) => (
        <span>{setToLocaleString(row.deltaQuantity)} {row.unitText}</span>
      ),
    },
    {
      key: "quantity",
      name: "최종 재고",
      sortable: true,
      resizable: true,
      cellClass: css`
        justify-content: flex-end;
      `,
      formatter: ({ row }) => {
        const detailItemAction = () => {
          openModal(<LedgerDetailForm data={row} />, null, "재고 상세");
        };
        return (
          <span
            style={{ color: theme.colors?.blue?.[8], cursor: "pointer" }}
            onClick={detailItemAction}
          >
            {setToLocaleString(row.quantity)} {row.unitText}
          </span>
        );
      },
    },
  ];

  return (
    <InventoryTableWrapper>
      <GridWrapper isDarkMode={isDarkMode}>
        <TableWrapper>
          <RetrieveWrapper>
            <Flex direction="row" gap="xs" justify="flex-start" align="flex-center">
              <CustomFilter filterType={inventoriesHeader} setQuery={setQuery} query={query} />
              <CustomSorter sorterType={inventoriesHeader} setSort={setSort} sort={sort} />
            </Flex>
            <SearchBox searchType={searchFieldsHeader} setSearch={setSearch} search={search} />
          </RetrieveWrapper>
          <DataGrid
            columns={columns ?? []}
            rows={rows as any}
            rowHeight={40}
            rowKeyGetter={(row: Row) => row.id}
            sortColumns={sortColumns}
            selectedRows={selectedRows}
            onSelectedRowsChange={(e) => {
              setSelectedRows(e);
              selectedRowsRef.current = e;
              const selectedRowIds = Array.from(e);
              const selectedRowInform = rows.filter((row) => selectedRowIds.includes(row.id));
              setSelectedRowsInform(selectedRowInform);
            }}
          />
        </TableWrapper>
        <PaginationWrapper>
          <Pagination
            onChange={(page) => {
              setSelectedRows(new Set());
              setSelectedRowsInform([])
              setPage(page)
            }}
            value={activePage}
            total={inventory?.data?.totalPages ?? 0}
            size="lg"
            radius="sm"
          />
        </PaginationWrapper>
      </GridWrapper>
    </InventoryTableWrapper>
  );
};

const InventoryTableWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
`;

const GridWrapper = styled.div<ColorThemeProps>`
  & *[role="grid"] {
    height: inherit;
    --rdg-background-color: ${(props) => (props.isDarkMode ? "white" : "none")};
    --rdg-header-background-color: ${(props) =>
    props.isDarkMode ? "white" : "none"};
    --rdg-color: ${(props) => (props.isDarkMode ? "black" : "white")};
    --rdg-row-hover-background-color: ${(props) =>
    props.isDarkMode ? "#f5f5f5" : theme?.colors?.gray?.[7]};
  }
  & *[role="columnheader"] {
    // color: #7d8fa9;
    font-size: 12px;
    font-family: Roboto;
    font-weight: 500;
    word-wrap: break-word;
    // border: none;
    box-shadow: none;
    display: flex;
    align-items: center;
  }
  & *[aria-colindex="1"] {
  }

  & *[role="row"] {
    height: 100px;
  }

  & *[role="gridcell"] {
    display: flex;
    align-items: center;
    // border-left: none;
    // border-right: none;
    box-shadow: none;

    & > * {
      justify-content: flex-start;
    }
  }
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: space-between;
  gap: 1rem;
`;

const PaginationWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const TableWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const RetrieveWrapper = styled.div`
  display: flex;
  flex-direction: row;
  padding-bottom: 10px;
  justify-content: space-between;
`;

const OptionBox = styled.fieldset`
  display: flex;
  gap: 10px;
  border: none;
`;
const OptionBtn = styled<any>(Button)`
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
