import { LedgersAllTable } from "@/features/ledgersAll/components/table";
import { LedgerProvider } from "@/features/ledgersAll/context/ledgerProvider";
import { Header } from "@/features/standard/Header";
import { Main } from "@/features/standard/Main";
import { HeaderSubTitle } from "@/features/standard/SubTitle";
import { TimeHeader } from "@/features/standard/TimeHeader";
import { HeaderTitle } from "@/features/standard/Title";
import { Wrapper } from "@/features/standard/Wrapper";

const LedgerAll = Object.assign({}, Main, {
    Header: Header,
    HeaderTitle: HeaderTitle,
    HeaderSubTitle: HeaderSubTitle,
    LedgerAllWrapper: Wrapper,
    LedgersAllTable: LedgersAllTable,
    LedgersTimeHeader: TimeHeader,
    LedgerProvider: LedgerProvider,
});

export const LedgerAllPage = () => {
    return (
        <LedgerAll.LedgerProvider>
            <LedgerAll.LedgerAllWrapper>
                <LedgerAll.LedgersTimeHeader />
                <LedgerAll.Header>
                    <LedgerAll.HeaderTitle>
                        전체 입출고 내역
                    </LedgerAll.HeaderTitle>
                    <LedgerAll.HeaderSubTitle>
                        우리 회사에서 다루는 모든 입출고 내역을 확인할 수 있습니다.
                    </LedgerAll.HeaderSubTitle>
                </LedgerAll.Header>
                <LedgerAll.LedgersAllTable />
            </LedgerAll.LedgerAllWrapper>
        </LedgerAll.LedgerProvider>
    )
};