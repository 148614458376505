import styled from "@emotion/styled";
import { Box } from "@mantine/core";
interface FormMainProps {
  children: React.ReactNode;
}
export const FormWrapper = ({ children }: FormMainProps) => {
  return (
    <Box h="auto" w={400} mx="auto">
      <Form>
        <FormFieldSet>{children}</FormFieldSet>
      </Form>
    </Box>
  );
};

const FormFieldSet = styled.fieldset`
  border: none;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const Form = styled.form`
  width: 100%;
  height: 100%;
`;
