
import { routings } from "@/api/routings/useRoutingsQuery";
import { theme } from "@/styles/theme";
import styled from "@emotion/styled";
import { RoutingsGet200ResponseRowsInner } from "@sizlcorp/sizl-api-document/dist/models";
import { useQuery } from "react-query";
import { DetailHeader } from "../header/routingDetailHeader";

interface RoutingDetailFormProps {
    routingCode: string | undefined;
}
export const RoutingDetailForm = ({
    routingCode,
}: RoutingDetailFormProps) => {

    const { data: routing } = useQuery(routings.get({
        query: {
            $and : [{code: routingCode}]
        },
        populate :["location"]
    }));
    const routingData = routing?.data?.rows?.[0] as RoutingsGet200ResponseRowsInner;

    return (
        <RoutingDetailFormContainer>
            <RoutingContainer>
                <RoutingWrapper>
                    <DetailHeader routingData={routingData} />
                </RoutingWrapper>
            </RoutingContainer>
        </RoutingDetailFormContainer>
    );
};

const RoutingDetailFormContainer = styled.div`
  width: 75rem;
`;

const RoutingContainer = styled.div`
    padding: 8px;
    background: ${theme.colors?.gray?.[2]};
`;

const RoutingWrapper = styled.div`
    background-color: white;
`;
