import { useQuery } from "react-query";

import { MasterInstance } from "@/instance/axios";
import {
    ItemCategoriesGet200Response,
    ItemCategoriesGet200ResponseRowsInner,
} from "@sizlcorp/sizl-api-document/dist/models";

export type ItemCategoryOption = {
    value: string;
    label: string;
};

export const useItemCategoriesMultiAutoComplete = (
    focus = false,
    keyword = "",
    options?: {
        onSuccess: (data: ItemCategoryOption[]) => void;
    }
) => {
    return useQuery(
        ["item-categories-get-multi-autocomplete-keyword", keyword],
        () =>
            MasterInstance.itemCategoriesFindPost({
                search: keyword,
                searchFields: ["name"],
            }).then((res) => res.data),
        {
            enabled: focus,
            select: (
                data: ItemCategoriesGet200ResponseRowsInner[]
            ): ItemCategoryOption[] =>
                data.map((ItemCategory) => {
                    return {
                        value: String(ItemCategory.name),
                        label: String(ItemCategory.name),
                    };
                }) ?? [],
            onSuccess: options?.onSuccess,
        }
    );
};

const getItemCategoryByName = (name: string | null) =>
    MasterInstance.itemCategoriesGet({
        ...(name && { search: name }),
        searchFields: ["name"],
    }).then((res) => res.data);

export const useItemCategoriesMultiCode = (
    enabled: boolean = false,
    name: string | null,
    options?: {
        onSuccess: (data?: ItemCategoryOption[]) => void;
    }
) => {
    return useQuery(
        ["item-categories-multi-get-name", name],
        () => getItemCategoryByName(name),
        {
            enabled,
            select: (data: ItemCategoriesGet200Response) =>
                data?.rows?.map((ItemCategory) => {
                    return {
                        value: ItemCategory.name as string,
                        label: ItemCategory.name as string,
                    };
                }),
            onSuccess: options?.onSuccess,
        }
    );
};
