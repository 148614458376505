
export type QueryDefaultValuesType = "page" | "pageSize" | "query" | "search" | "searchFields" | "sort";

export type QueryDefaultValuesTypeValue = {
    page: number;
    pageSize: string;
    query: Record<string, any>; // { $and: any[]; };
    search: string;
    searchFields: string[];
    sort: string[];
};

export const queryDefaultValues: {
    page: number;
    pageSize: string;
    query: { $and: any[]; };
    search: string;
    searchFields: string[];
    sort: string[];
} = {
    page: 1,
    pageSize: "10",
    query: { $and: [] },
    search: "",
    searchFields: [],
    sort: []
};

