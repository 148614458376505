import { useModal } from "@/features/modal/ModalStackManager";
import styled from "@emotion/styled";
import { Box, Button, Select, TextInput } from "@mantine/core";
import { useForm } from "@mantine/form";

import { ItemAutoComplete } from "@/features/ui/autoComplete/item/item-autoComplete";
import { RoutingAutoComplete } from "@/features/ui/autoComplete/routing/routing-autoComplete";

import { mutateOperations } from "@/api/operation/useOperationQuery";
import { consumeType, operationType } from "@/constants/operations";
import customAlert from "@/features/ui/alert/alert";
import { MoldAutoComplete } from "@/features/ui/autoComplete/mold/mold-autoComplete";
import { validateFormValues } from "@/utils/validation";
import { DefaultApiOperationsOperationIdPutRequest } from "@sizlcorp/sizl-api-document/dist/models";
import { useTranslation } from "react-i18next";
import { useMutation, useQueryClient } from "react-query";

export const OperationEditForm = (params: { formatterProps: any }) => {
  const { t } = useTranslation();
  const { closeModal } = useModal();
  const { formatterProps } = params;
  const queryClient = useQueryClient();
  const { mutate: updateMutate } = useMutation(
    (params: DefaultApiOperationsOperationIdPutRequest) =>
      mutateOperations.update(params).mutationFn(params as DefaultApiOperationsOperationIdPutRequest | any),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('operations');
      },
    }
  );

  const lnOperationType = operationType.map((item: any) => ({
    ...item,
    label: t(item.label),
  }));

  const lnConsumeType = consumeType.map((item: any) => ({
    ...item,
    label: t(item.label),
  }));

  const form = useForm({
    initialValues: {
      itemCode: formatterProps.itemCode,
      code: formatterProps.code,
      operationType: formatterProps.operationType,
      moldCode: formatterProps.moldCode,
      consumeType: formatterProps.consumeType,
      routingCode: formatterProps.routingCode,
      seq: formatterProps.seq,
    },
  });

  const onSubmit = async () => {
    try {
      updateMutate(
        {
          operationId: formatterProps?.id,
          operationsGetRequest: {
            itemCode:
              form.values.itemCode ?? (formatterProps.itemCode as any),
            code: form.values.code ?? (formatterProps.code as any),
            operationType:
              form.values.operationType ??
              (formatterProps.operationType as any),
            moldCode:
              form.values.moldCode ?? (formatterProps.moldCode as any),
            consumeType:
              form.values.consumeType ?? (formatterProps.consumeType as any),
            routingCode:
              form.values.routingCode ?? (formatterProps.routingCode as any),
            seq: form.values.seq ?? (formatterProps.seq as any),
          },
        },
        {
          onSuccess: (res) => {
            closeModal(formatterProps);
            if (res.status === 200) {
              customAlert(
                "공정 수정에 성공하였습니다.",
                "수정 성공",
                "green"
              )
            } else {
              customAlert(
                "공정 수정에 실패하였습니다.",
                "수정 실패",
                "red"
              )
            }
          },
        }
      );
    } catch (e) {
      customAlert(
        "공정 수정에 실패하였습니다.",
        "수정 실패",
        "red"
      )
    }
  };

  return (
    <Box w={420} mx="auto">
      <Form>
        <ModalFieldSet>
          <ItemAutoComplete
            withAsterisk
            label="품목코드"
            defaultValue={formatterProps?.itemCode}
            {...form.getInputProps("itemCode")}
            error={form.isDirty("itemCode") ? "저장 필요" : ""}
            styles={{
              input: {
                color: form.isDirty("itemCode")
                  ? "cadetblue !important"
                  : "inherit",
                borderColor: form.isDirty("itemCode")
                  ? "cadetblue !important"
                  : "inherit",
              },
              error: { fontSize: 11, color: "cadetblue" },
            }}
          />
          <RoutingAutoComplete
            withAsterisk
            label="라우팅코드"
            defaultValue={formatterProps?.routingCode}
            {...form.getInputProps("routingCode")}
            error={form.isDirty("routingCode") ? "저장 필요" : ""}
            styles={{
              input: {
                color: form.isDirty("routingCode")
                  ? "cadetblue !important"
                  : "inherit",
                borderColor: form.isDirty("routingCode")
                  ? "cadetblue !important"
                  : "inherit",
              },
              error: { fontSize: 11, color: "cadetblue" },
            }}
          />
          <TextInput
            withAsterisk
            label="공정코드"
            defaultValue={formatterProps?.code}
            {...form.getInputProps("code")}
            error={form.isDirty("code") ? "저장 필요" : ""}
            styles={{
              input: {
                color: form.isDirty("code")
                  ? "cadetblue !important"
                  : "inherit",
                borderColor: form.isDirty("code")
                  ? "cadetblue !important"
                  : "inherit",
              },
              error: { fontSize: 11, color: "cadetblue" },
            }}
          />
          <Select
            withAsterisk
            label="공정유형"
            defaultValue={formatterProps?.operationType}
            data={lnOperationType}
            {...form.getInputProps("operationType")}
            error={form.isDirty("operationType") ? "저장 필요" : ""}
            styles={{
              input: {
                color: form.isDirty("operationType")
                  ? "cadetblue !important"
                  : "inherit",
                borderColor: form.isDirty("operationType")
                  ? "cadetblue !important"
                  : "inherit",
              },
              error: { fontSize: 11, color: "cadetblue" },
            }}
          />
          <MoldAutoComplete
            label="금형코드"
            defaultValue={formatterProps?.moldCode}
            {...form.getInputProps("moldCode")}
            error={form.isDirty("moldCode") ? "저장 필요" : ""}
            styles={{
              input: {
                color: form.isDirty("moldCode")
                  ? "cadetblue !important"
                  : "inherit",
                borderColor: form.isDirty("moldCode")
                  ? "cadetblue !important"
                  : "inherit",
              },
              error: { fontSize: 11, color: "cadetblue" },
            }}
          />
          <Select
            withAsterisk
            label="소모 시점"
            defaultValue={formatterProps?.consumeType}
            data={lnConsumeType}
            {...form.getInputProps("consumeType")}
            error={form.isDirty("consumeType") ? "저장 필요" : ""}
            styles={{
              input: {
                color: form.isDirty("consumeType")
                  ? "cadetblue !important"
                  : "inherit",
                borderColor: form.isDirty("consumeType")
                  ? "cadetblue !important"
                  : "inherit",
              },
              error: { fontSize: 11, color: "cadetblue" },
            }}
          />
          <TextInput
            withAsterisk
            label="공정순서"
            defaultValue={formatterProps?.seq}
            {...form.getInputProps("seq")}
            error={form.isDirty("seq") ? "저장 필요" : ""}
            styles={{
              input: {
                color: form.isDirty("seq") ? "cadetblue !important" : "inherit",
                borderColor: form.isDirty("seq")
                  ? "cadetblue !important"
                  : "inherit",
              },
              error: { fontSize: 11, color: "cadetblue" },
            }}
          />

          <ModalConfirmBtnBox>
            <Button color="gray" onClick={closeModal}>
              취소
            </Button>
            <Button
              disabled={validateFormValues({
                pageType: "operation",
                values: form.values,
              })}
              onClick={onSubmit}
            >
              공정 수정
            </Button>
          </ModalConfirmBtnBox>
        </ModalFieldSet>
      </Form>
    </Box>
  );
};

const Form = styled.form`
  width: 100%;
  height: 100%;
`;

const ModalFieldSet = styled.fieldset`
  border: none;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const ModalConfirmBtnBox = styled.div`
  padding: 24px 0;
  display: flex;
  justify-content: flex-end;
  gap: 20px;
`;
