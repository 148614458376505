import { useModal } from "@/features/modal/ModalStackManager";
import { DetailBadge } from "@/features/ui/detail/DetailBadge";
import { DetailContent } from "@/features/ui/detail/DetailContent";
import { DetailField } from "@/features/ui/detail/DetailField";
import { DetailHeader } from "@/features/ui/detail/DetailHeader";
import { DetailInformTitle } from "@/features/ui/detail/DetailInformTitle";
import { DetailLabel } from "@/features/ui/detail/DetailLabel";
import { DetailLink } from "@/features/ui/detail/DetailLink";
import { DetailLinkWrapper } from "@/features/ui/detail/DetailLinkWrapper";
import { DetailTitle } from "@/features/ui/detail/DetailTitle";
import DatetimeUtil from "@/utils/dateTimeUtil";
import { Checkbox, Space } from "@mantine/core";
import { OperationOutsourcesGet200ResponseRowsInner } from "@sizlcorp/sizl-api-document/dist/models";

const DetailOutsource = Object.assign(DetailHeader, {
    Title: DetailTitle,
    Inform: DetailInformTitle,
    Field: DetailField,
    Label: DetailLabel,
    Link: DetailLink,
    LinkWrapper: DetailLinkWrapper,
    Badge: DetailBadge,
    Content: DetailContent,
    CheckBox: Checkbox,
});

interface OperationOutsourceDetailHeaderProps {
    data: OperationOutsourcesGet200ResponseRowsInner | any;
}
export const OperationOutsourceDetailHeader = (params: OperationOutsourceDetailHeaderProps) => {
    const { data } = params;

    const operationOutsource = data?.data?.rows[0];

    const { openModal } = useModal();
    return (
        <>
            <DetailOutsource>
                <DetailOutsource.Title>{`[외주처 상세]`}</DetailOutsource.Title>
                <Space h={8} />
                <DetailOutsource.Field>
                    <DetailOutsource.Label>
                        {"외주처명"}
                    </DetailOutsource.Label>
                    <DetailOutsource.Content>
                        {operationOutsource?.name}
                    </DetailOutsource.Content>
                </DetailOutsource.Field>
                <DetailOutsource.Field>
                    <DetailOutsource.Label>
                        {"공정코드"}
                    </DetailOutsource.Label>
                    <DetailOutsource.Content>
                        {operationOutsource?.operationCode}
                    </DetailOutsource.Content>
                </DetailOutsource.Field>
                <DetailOutsource.Field>
                    <DetailOutsource.Label>
                        {"자재 출고 로케이션이름"}
                    </DetailOutsource.Label>
                    <DetailOutsource.Content>
                        {operationOutsource?.fromLocationName}
                    </DetailOutsource.Content>
                </DetailOutsource.Field>
                <DetailOutsource.Field>
                    <DetailOutsource.Label>
                        {"자재 출고 로케이션코드"}
                    </DetailOutsource.Label>
                    <DetailOutsource.Link onClick={() => { }}>
                        {operationOutsource?.fromLocationCode}
                    </DetailOutsource.Link>
                </DetailOutsource.Field>
                <DetailOutsource.Field>
                    <DetailOutsource.Label>
                        {"재공품 입고 로케이션이름"}
                    </DetailOutsource.Label>
                    <DetailOutsource.Content>
                        {operationOutsource?.toLocationName}
                    </DetailOutsource.Content>
                </DetailOutsource.Field>
                <DetailOutsource.Field>
                    <DetailOutsource.Label>
                        {"재공품 입고 로케이션코드"}
                    </DetailOutsource.Label>
                    <DetailOutsource.Link onClick={() => { }}>
                        {operationOutsource?.toLocationCode}
                    </DetailOutsource.Link>
                </DetailOutsource.Field>
                <DetailOutsource.Field>
                    <DetailOutsource.Label>{"생성정보"}</DetailOutsource.Label>
                    <DetailOutsource.Content>{operationOutsource?.creatorUserCode}({DatetimeUtil(operationOutsource?.createdAt ?? '')})</DetailOutsource.Content>
                </DetailOutsource.Field>
            </DetailOutsource>
        </>
    )
}