import { OverViewTable } from "@/features/overView/table"
import { Header } from "@/features/standard/Header"
import { Main } from "@/features/standard/Main"
import { HeaderSubTitle } from "@/features/standard/SubTitle"
import { HeaderTitle } from "@/features/standard/Title"
import { Wrapper } from "@/features/standard/Wrapper"

const OverView = Object.assign({}, Main, {
    Header: Header,
    HeaderTitle: HeaderTitle,
    HeaderSubTitle: HeaderSubTitle,
    OverViewWrapper: Wrapper,
    OverViewTable: OverViewTable
});


export const OverViewPage = () => {
    return (
        <OverView.OverViewWrapper>
            <OverView.Header>
                <OverView.HeaderTitle>
                    MES 운영현황판
                </OverView.HeaderTitle>
                <OverView.HeaderSubTitle>
                    우리 회사에서 다루는 운영현황판을 확인할 수 있습니다.
                </OverView.HeaderSubTitle>
            </OverView.Header>
            <OverView.OverViewTable />
        </OverView.OverViewWrapper>
    )
}