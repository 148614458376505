import { BadgeBox } from "@/features/standard/badge/BadgeBox"
import { BadgeContainer } from "@/features/standard/badge/BadgeContainer"
import { BadgeIcon } from "@/features/standard/badge/BadgeIcon"
import { BadgeMain } from "@/features/standard/badge/BadgeMain"
import { BadgeQuantity } from "@/features/standard/badge/BadgeQuantity"
import { BadgeSmallBox } from "@/features/standard/badge/BadgeSmallBox"
import { BadgeTextBox } from "@/features/standard/badge/BadgeTextBox"
import { theme } from "@/styles/theme"
import { Text } from "@mantine/core"
import { ProductionPlansGet200ResponseRowsInner } from "@sizlcorp/sizl-api-document/dist/models"
import { IconBuildingWarehouse } from "@tabler/icons-react"


const Badge = Object.assign(BadgeMain, {
    Box: BadgeBox,
    SmallBox: BadgeSmallBox,
    Quantity: BadgeQuantity,
    Icon: BadgeIcon,
    Text: Text,
    TextBox: BadgeTextBox,
    Container: BadgeContainer,
})

interface ProductionPlanDataProps {
    data : ProductionPlansGet200ResponseRowsInner | undefined;
    totalQuantity : number;
}
export const ProductionPlanDataForm = ({ data, totalQuantity } : ProductionPlanDataProps) => {

    return (
        <Badge.Container>
                <Badge.Box>
                    <Badge.SmallBox>
                        <Badge.Icon>
                            <IconBuildingWarehouse size={26} color={theme?.colors?.violet?.[6]} />
                        </Badge.Icon>
                        <Badge.TextBox>
                            <Badge.Quantity fz="lg" color="violet">{data?.works?.length} 개</Badge.Quantity>
                            <Badge.Text fz="xs" color={theme.colors?.gray?.[6]}>{"작업지시 갯수"}</Badge.Text>
                        </Badge.TextBox>
                    </Badge.SmallBox>
                    <Badge.SmallBox>
                        <Badge.Icon>
                            <IconBuildingWarehouse color={theme?.colors?.violet?.[6]} />
                        </Badge.Icon>
                        <Badge.TextBox>
                            <Badge.Quantity fz="lg" color="violet">{totalQuantity} EA</Badge.Quantity>
                            <Badge.Text fz="xs" color={theme.colors?.gray?.[6]}>{"현 사용가능 재고"}</Badge.Text>
                        </Badge.TextBox>
                    </Badge.SmallBox>
                </Badge.Box>
            </Badge.Container>
    )
}