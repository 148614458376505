import { Text } from "@mantine/core";

interface WorkNotFoundProps {
    defectLength: number;
}

export const WorkNotFound = ({ defectLength }: WorkNotFoundProps) => {
    return (
        <tr>
            <td colSpan={6 + defectLength}>
                <Text ta="center">해당하는 날짜의 정보가 존재하지 않습니다.</Text>
            </td>
        </tr>
    )
}

export const PlanNotFound = () => {
    return (
        <tr>
            <td colSpan={5}>
                <Text ta="center">해당하는 날짜의 정보가 존재하지 않습니다.</Text>
            </td>
        </tr>
    )
}