import { useQuery } from "react-query";

import { MasterInstance } from "@/instance/axios";
import { MoldsGet200Response, MoldsGet200ResponseRowsInner } from "@sizlcorp/sizl-api-document/dist/models";


export type MoldOption = {
    value: string;
    label: string;
};

export const useMoldsMultiAutoComplete = (
    focus = false,
    keyword = "",
    options?: {
        onSuccess: (data: MoldOption[]) => void;
    }
) => {
    return useQuery(
        ["molds-get-multi-autocomplete-keyword", keyword],
        () =>
            MasterInstance.moldsFindPost({
                search: keyword,
                searchFields: ["name"],
            }).then((res) => res.data),
        {
            enabled: focus,
            select: (
                data: MoldsGet200ResponseRowsInner[]
            ): MoldOption[] =>
                data.map((Mold) => {
                    return {
                        value: String(Mold.code),
                        label: String(Mold.code),
                    };
                }) ?? [],
            onSuccess: options?.onSuccess,
        }
    );
};

const getMoldByCode = (code: string | null) =>
    MasterInstance.moldsGet({
        ...(code && { search: code }),
        searchFields: ["name"],
    }).then((res) => res.data);

export const useMoldsMultiCode = (
    enabled: boolean = false,
    code: string | null,
    options?: {
        onSuccess: (data?: MoldOption[]) => void;
    }
) => {
    return useQuery(
        ["molds-multi-get-code", code],
        () => getMoldByCode(code),
        {
            enabled,
            select: (data: MoldsGet200Response) =>
                data?.rows?.map((Mold) => {
                    return {
                        value: Mold.code as string,
                        label: Mold.code as string,
                    };
                }),
            onSuccess: options?.onSuccess,
        }
    );
};
