import { WORK_BY_EQUIPMENT_QUERY_KEY } from "@/features/workByEquipment/api/useWorkByEquipmentQuery";
import { PRODUCTION_PLAN_QUERY_KEY } from "@/features/workByProductionPlan/api/useGetProductionPlanQuery";
import { WORKS_GET_KEY } from "@/features/workByRows/api/useWorksGetQuery";
import { ProductionInstance } from "@/instance/axios";
import { ProductionPlansWithWorksPostRequest } from "@sizlcorp/sizl-api-document/dist/models";
import { useMutation, useQueryClient } from "react-query";

const createPlansWithWorks = (params: ProductionPlansWithWorksPostRequest) => {
  return ProductionInstance.productionPlansWithWorksPost({
    productionPlansWithWorksPostRequest: params,
  });
};

const usePlansWithWorksMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(
    (params: ProductionPlansWithWorksPostRequest) =>
      createPlansWithWorks(params),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(PRODUCTION_PLAN_QUERY_KEY);
        queryClient.invalidateQueries(WORK_BY_EQUIPMENT_QUERY_KEY);
        queryClient.invalidateQueries(WORKS_GET_KEY);
      },
    }
  );
};

export default usePlansWithWorksMutation;
