import { ActionIcon, Anchor, Badge, Flex, Tooltip } from "@mantine/core";
import { IconEdit, IconTrash } from "@tabler/icons-react";
import {
  MRT_Row,
  MantineReactTable,
  useMantineReactTable,
  // createRow,
  type MRT_ColumnDef,
} from "mantine-react-table";
import { useMemo } from "react";

import { moldCavity, mutateMoldCavity } from "@/api/mold/useMoldCavityQuery";
import { AbstractItemDetailForm } from "@/features/abstractItem/form/detailForm";
import { ItemsDetailForm } from "@/features/item/components/form/ItemsDetailForm";
import { useModal } from "@/features/modal/ModalStackManager";
import { MoldCavityUpdateForm } from "@/features/mold/cavity/form/cavityUpdateForm";
import { DefaultApiMoldCavitiesMoldCavityIdDeleteRequest, MoldCavitiesGet200ResponseRowsInner } from "@sizlcorp/sizl-api-document/dist/models";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { MoldCodeProps } from "../form/cavityForm";

export interface Row {
  id: number | any;
  moldCode: string;
  itemCode: string;
  abstractItemCode: string;
  num: number;
  seq: number;
}

export const MoldCavityTable = ({ moldCodeProps }: MoldCodeProps) => {
  const { data: moldCavities } = useQuery(moldCavity.get({
    query: { $and: [{ moldCode: { $eq: moldCodeProps } }] }
  }));

  const { openModal } = useModal();

  const rows: MoldCavitiesGet200ResponseRowsInner[] =
    moldCavities?.data.rows ?? [];

  const columns = useMemo<MRT_ColumnDef<Row>[]>(
    () => [
      {
        accessorKey: "seq",
        header: "캐비티",
        size: 80,
        Cell: (rows) => {
          return (
            <div style={{ textAlign: "right" }}>
              {rows.row._valuesCache.seq}
            </div>
          );
        },
      },
      {
        accessorKey: "itemCode",
        header: "품목코드",
        mantineEditTextInputProps: {
          type: "string",
          required: true,
        },
        Cell: (rows) => {
          return (
            <Flex direction="column">
              {rows.row._valuesCache.itemCode && (
                <Badge size="sm" w={40}>
                  제품
                </Badge>
              )}
              <Anchor
                onClick={() => openModal(<ItemsDetailForm itemCode={rows.row._valuesCache.itemCode} />, null, "")}
              >
                {rows.row._valuesCache.itemCode}
              </Anchor>
            </Flex>
          );
        },
      },
      {
        accessorKey: "abstractItemCode",
        header: "품목군",
        mantineEditTextInputProps: {
          type: "string",
          required: true,
        },
        Cell: (rows) => {
          return (
            <Anchor
              onClick={() => openModal(<AbstractItemDetailForm AbstractItemCode={rows.row._valuesCache.abstractItemCode} />, null, "")}
            >
              {rows.row._valuesCache.abstractItemCode}
            </Anchor>
          );
        },
      },
      {
        accessorKey: "num",
        header: "개수",
        size: 120,
        mantineEditTextInputProps: {
          type: "number",
          required: true,
        },
        Cell: (rows) => {
          return (
            <div style={{ textAlign: "right" }}>
              {rows.row._valuesCache.num}
            </div>
          );
        },
      },
    ],
    []
  );

  const deleteUser = useDeleteUser();

  //DELETE action
  const openDeleteConfirmModal = (row: MRT_Row<Row>) => {
    if (window.confirm("정말 삭제하시겠습니까?")) {
      deleteUser(row.original);
    }
  };

  const table = useMantineReactTable({
    columns,
    data: rows as Row[],
    editDisplayMode: "modal", //default ('row', 'cell', 'table', and 'custom' are also available)
    enableEditing: true,
    enableToolbarInternalActions: false, // 우측상단 아이콘 안보이게
    enablePagination: false,
    positionActionsColumn: "last",

    renderRowActions: ({ row, table }) => (
      <Flex gap="md">
        <Tooltip label="수정">
          <ActionIcon
            onClick={() =>
              openModal(
                <MoldCavityUpdateForm formatterProps={row.original} />,
                null,
                "금형 수정",
                true
              )
            }
          >
            <IconEdit />
          </ActionIcon>
        </Tooltip>
        <Tooltip label="삭제">
          <ActionIcon color="red" onClick={() => openDeleteConfirmModal(row)}>
            <IconTrash />
          </ActionIcon>
        </Tooltip>
      </Flex>
    ),
  });

  return <MantineReactTable table={table} />;
};

function useDeleteUser() {
  const queryClient = useQueryClient();
  const { mutate: deleteMutate } = useMutation(
    (params: DefaultApiMoldCavitiesMoldCavityIdDeleteRequest) =>
      mutateMoldCavity.delete(params).mutationFn(params as DefaultApiMoldCavitiesMoldCavityIdDeleteRequest | any),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('moldCavity')
      }
    }
  );

  return async (values: Row) => {
    await deleteMutate(
      {
        moldCavityId: values.id,
      },
      {
        onSuccess: () => {
          alert("삭제되었습니다.");
        },
        onError: () => {
          alert("삭제가 불가능한 캐비티입니다.");
        },
      }
    );
  };
}
