import { useDrawer } from "@/features/drawer/DrawerContext";
import { useModal } from "@/features/modal/ModalStackManager";
import { FormExample } from "@/pages/modalExample/form";
import { Button, Group, Modal } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import React from "react";

const ModalExample: React.FC = () => {
  const { openModal, closeModal } = useModal();

  const handleOpenModal = () => {
    openModal(<FormExample />, "모달 2 전달할 파라미터", "테스트").then(
      (result) => {
        console.log("모달 2에서 전달된 결과값:", result);
      }
    );
  };

  const { openDrawer } = useDrawer();

  const handleOpenDrawer = () => {
    openDrawer(<FormExample />, "전달할 파라미터").then((result) => {
      console.log("Drawer에서 전달된 결과값:", result);
    });
  };

  const [opened, { open, close }] = useDisclosure(false);

  return (
    <>
      <button onClick={handleOpenModal}>모달 2 열기</button>
      <button onClick={handleOpenDrawer}>Drawer 열기</button>

      <Modal opened={opened} onClose={close} title="Authentication">
        {/* Modal content */}
      </Modal>

      <Group position="center">
        <Button onClick={open}>Open modal</Button>
      </Group>
    </>
  );
};

export default ModalExample;
