import { MasterInstance } from "@/instance/axios";
import { createMutationKeys, createQueryKeys, mergeQueryKeys } from "@lukemorales/query-key-factory";
import { MasterApiLocationsFindPostRequest, MasterApiLocationsGetRequest, MasterApiLocationsLocationIdDeleteRequest, MasterApiLocationsLocationIdGetRequest, MasterApiLocationsLocationIdPutRequest, MasterApiLocationsPostRequest } from "@sizlcorp/sizl-api-document/dist/models";


export const locations = createQueryKeys('locations', {
    all : null,
    get : (params: MasterApiLocationsGetRequest) => {
        const query = {...params}; // params 객체를 복사합니다.
        // params.query.$and가 배열이고 길이가 0보다 클 때만 query.query를 설정합니다.
        if (Array.isArray(params.query.$and) && params.query.$and.length > 0) {
            query["query"] = JSON.stringify(params.query);
        }
        // params.search가 있을 때만 query.search와 query.searchFields를 설정합니다.
        if (params.search) {
            query["search"] = params.search;
            query["searchFields"] = params.searchFields;
        }
        return {
            queryKey : [query, JSON.stringify(query.query)],
            queryFn : () => MasterInstance.locationsGet(query),
        }
    },
    detail : (params : MasterApiLocationsLocationIdGetRequest) => ({
        queryKey : [params.locationId],
        queryFn : () => MasterInstance.locationsLocationIdGet(params)
    }),
    find : (params: MasterApiLocationsFindPostRequest) => {
        const query = {...params}; // params 객체를 복사합니다.
        // params.query.$and가 배열이고 길이가 0보다 클 때만 query.query를 설정합니다.
        if (Array.isArray(params.query.$and) && params.query.$and.length > 0) {
            query["query"] = JSON.stringify(params.query);
        }
        // params.search가 있을 때만 query.search와 query.searchFields를 설정합니다.
        if (params.search) {
            query["search"] = params.search;
            query["searchFields"] = params.searchFields;
        }
        return {
            queryKey : [query, JSON.stringify(query.query)],
            queryFn : () => MasterInstance.locationsFindPost(query),
        }
    },
});

export const mutateLocations = createMutationKeys('locations', {
    create : (params : MasterApiLocationsPostRequest) => ({
        mutationKey : [params.locationsGetRequest],
        mutationFn : () => MasterInstance.locationsPost(params),
    }),
    update : (params : MasterApiLocationsLocationIdPutRequest) => ({
        mutationKey : [params.locationId, params.locationsGetRequest],
        mutationFn : () => MasterInstance.locationsLocationIdPut(params)
    }),
    delete : (params : MasterApiLocationsLocationIdDeleteRequest) => ({
        mutationKey : [params.locationId],
        mutationFn : () => MasterInstance.locationsLocationIdDelete(params)
    }),
});

export const locationsQueryKeys = mergeQueryKeys(locations, mutateLocations);