import { useModal } from "@/features/modal/ModalStackManager";
import {
  LotOption,
  useLotsAutoComplate,
} from "@/features/ui/autoComplete/lot/auto-useGetLotsQuery";
import { LotForm } from "@/features/ui/form/lots/form";
import styled from "@emotion/styled";
import { Button, Flex, Select, SelectProps, Text } from "@mantine/core";
import { IconX } from "@tabler/icons-react";
import { EventHandler, ReactNode, forwardRef, useState } from "react";

interface LotProps extends React.ComponentPropsWithoutRef<"div"> {
  group: string;
  label: string;
  value: string;
}

export interface LotAutoCompleteProps
  extends Partial<SelectProps>,
    Partial<React.RefAttributes<HTMLInputElement>> {
  value?: string | null;
  onChange?: (lotId: string | null) => void;
  maxDropdownHeight?: number;
  width?: string;
}

export const LotAutoComplete = (params: LotAutoCompleteProps) => {
  const {
    value: lotId,
    onChange,
    maxDropdownHeight,
    width,
    ...etcParams
  } = params;
  const [focused, setFocused] = useState<boolean>(false);
  const [searchKeyword, setSearchKeyword] = useState<string>("");
  const { data: options } = useLotsAutoComplate(focused, searchKeyword);
  let selectedLot = options?.find((item) => {
    return item.value === lotId;
  });

  const [sLot, setSLot] = useState<LotOption | null>(null);
  const onChangeHandler = (e: string | null) => {
    setSLot(options?.find((item) => item.value === e) || null);
    selectedLot = options?.find((item) => item.value === e);
    onChange && onChange(e);
  };

  const SelectItem = forwardRef<HTMLDivElement, LotProps>(
    ({ label: name, value: code, ...others }, ref) => (
      <div ref={ref} {...others}>
        <Flex direction="row" justify="space-between" align="center">
          <Flex align={"center"}>
            <Text>{name}</Text>
            <Text fz="xs"> (name: {code})</Text>
          </Flex>
        </Flex>
      </div>
    )
  );

  return (
    <LotSelect
      styles={
        lotId
          ? {
              input: {
                width: width,
                fontSize: "0.8em",
                paddingBottom: "0.5rem",
              },
            }
          : { input: { width: width } }
      }
      onDropdownOpen={() => setFocused(true)}
      onDropdownClose={() => setFocused(false)}
      clearable
      inputContainer={(children: ReactNode) => (
        <div style={{ position: "relative" }}>
          {children}
          {selectedLot?.value && (
            <div
              style={{
                position: "absolute",
                bottom: "0px",
                fontSize: "0.8em",
                color: "#888",
                left: "5px",
              }}
            >
              {"Code: " + selectedLot?.value}
            </div>
          )}
        </div>
      )}
      value={lotId && lotId}
      itemComponent={SelectItem}
      searchValue={searchKeyword}
      data={[...(options ?? [])].reduce(
        (unique: LotProps[], option: LotProps) => {
          return unique.some((u) => u.value === option.value)
            ? unique
            : [...unique, option];
        },
        []
      )}
      searchable
      maxDropdownHeight={maxDropdownHeight ?? 150}
      onChange={onChangeHandler}
      onSearchChange={setSearchKeyword}
      rightSection={LotInfo({
        lot: sLot as LotOption,
        onChange: onChangeHandler,
      })}
      filter={(value, item) =>
        item?.value?.toLowerCase().includes(value.toLowerCase().trim())
      }
      nothingFound={AddNewLot({
        lotName: searchKeyword,
        onChange: onChangeHandler,
      })}
      {...etcParams}
    />
  );
};

const LotInfo = (params: {
  lot?: LotOption;
  onChange: (lot: string | null) => void;
}) => {
  const { lot, onChange } = params;
  const clearHandler: React.MouseEventHandler<SVGSVGElement> = (e) => {
    e.stopPropagation();
    e.preventDefault();
    onChange(null);
  };

  return lot?.value ? (
    <LotInfoLabel>
      {/* ({lot.value})  */}
      <IconX size="1rem" onClick={clearHandler} />{" "}
    </LotInfoLabel>
  ) : null;
};
const AddNewLot = (params: {
  lotName: string;
  onChange: (lotId: string) => void;
}) => {
  const { lotName, onChange } = params;
  const { openModal, closeModal } = useModal();

  const onCloseHandler: EventHandler<any> = (values) => {
    // mutate({
    //     locationsGetRequest : {
    //         code: values.code!,
    //         name: values.name!,
    //         siteCode: values.siteCode!
    //     }
    // },)
    closeModal(values);
  };

  const addNewModalHandler: React.MouseEventHandler<HTMLButtonElement> = (
    e
  ) => {
    e.preventDefault();
    openModal(
      <LotForm name={lotName} onSubmit={onCloseHandler} />,
      null,
      "신규 LOT 등록",
      true
    ).then((result) => {
      onChange(result?.name);
    });
  };

  return (
    <Button variant="subtle" onClick={addNewModalHandler}>
      신규 LOT({lotName}) 등록
    </Button>
  );
};

const LotInfoLabel = styled.div`
  font-size: 12px;
  color: #666666;
  // padding-right: 8px;
  padding-left: 0.4rem;
  display: flex;
  align-items: center;
  position: absolute;
`;

const LotSelect = styled(Select)`
  width: 100%;
`;
