import { useModal } from "@/features/modal/ModalStackManager";
import { ProductionPlanWithWorksForm } from "@/features/productionPlan/form/CreateForm";
import { PurchaseOrderItemCreateForm } from "@/features/purchaseOrderItem/form/createForm";
import { DeliveryForm } from "@/features/purchaseOrderItem/form/deliveryForm";
import { PurchaseOrderItemsTable } from "@/features/purchaseOrderItem/table";
import { ActionButtonBox } from "@/features/standard/ActionButtonBox";
import { ActionHeader } from "@/features/standard/ActionHeader";
import { Header } from "@/features/standard/Header";
import { Main } from "@/features/standard/Main";
import { HeaderSubTitle } from "@/features/standard/SubTitle";
import { HeaderTitle } from "@/features/standard/Title";
import { Wrapper } from "@/features/standard/Wrapper";
import { FileImportForm } from "@/features/ui/form/file";
import { usePub } from "@/hooks";
import { Button } from "@mantine/core";
import { ItemsGet200ResponseRowsInnerLotsInnerInventoriesInner } from "@sizlcorp/sizl-api-document/dist/models";
import { IconFile, IconPlus, IconTrash } from "@tabler/icons-react";
import { useState } from "react";

const PurchaseOrderItems = Object.assign({}, Main, {
    Header: Header,
    HeaderTitle: HeaderTitle,
    HeaderSubTitle: HeaderSubTitle,
    Wrapper: Wrapper,
    ActionHeader: ActionHeader,
    ActionButton: Button,
    ActionButtonBox: ActionButtonBox,
    PurchaseOrderItemsTable: PurchaseOrderItemsTable,
    PurchaseOrderItemCreateForm: PurchaseOrderItemCreateForm,
    DeliveryForm: DeliveryForm,
    ProductionPlanWithWorksForm: ProductionPlanWithWorksForm
})

export const PurchaseOrderItemsPage = () => {
    const publish = usePub();
    const { openModal } = useModal();

    const [isRowBtnActive, setIsRowBtnActive] = useState<boolean>(false);
    const [selectedRowsInform, setSelectedRowsInform] = useState<ItemsGet200ResponseRowsInnerLotsInnerInventoriesInner[]>([]);
    const [data, setData] = useState<any>([]);

    const handleRowSelect = (
        selectedRows: any,
        formatterPropsData: any,
    ) => {
        setIsRowBtnActive(selectedRows.size === 1);
        setData(formatterPropsData);
        setSelectedRowsInform(selectedRowsInform);
    }

    return (
        <>
            <PurchaseOrderItems.Wrapper>
                <PurchaseOrderItems.ActionHeader>
                    <PurchaseOrderItems.ActionButtonBox>
                        <PurchaseOrderItems.ActionButton
                            leftIcon={<IconPlus />}
                            onClick={() => openModal(
                                <PurchaseOrderItems.PurchaseOrderItemCreateForm />,
                                null,
                                "수주 추가",
                            )}
                        >
                            추가
                        </PurchaseOrderItems.ActionButton>
                        <PurchaseOrderItems.ActionButton
                            leftIcon={<IconPlus />}
                            color="orange"
                            disabled={!isRowBtnActive}
                            onClick={() => openModal(
                                <PurchaseOrderItems.DeliveryForm formatterProps={data} />,
                                null,
                                "납품하기",
                            )}
                        >
                            납품하기
                        </PurchaseOrderItems.ActionButton>
                        <PurchaseOrderItems.ActionButton
                            leftIcon={<IconFile />}
                            color="green"
                            onClick={() => openModal(
                                <FileImportForm />,
                                null,
                                "기준정보 업로드",
                            )}
                        >
                            수주 업로드
                        </PurchaseOrderItems.ActionButton>
                    </PurchaseOrderItems.ActionButtonBox>
                    <PurchaseOrderItems.ActionButton
                        color="red"
                        rightIcon={<IconTrash />}
                        onClick={() => publish("deleteSelectedItems")}
                    >
                        선택된 행 삭제
                    </PurchaseOrderItems.ActionButton>
                </PurchaseOrderItems.ActionHeader>
                <PurchaseOrderItems.Header>
                    <PurchaseOrderItems.HeaderTitle>수주 리스트</PurchaseOrderItems.HeaderTitle>
                    <PurchaseOrderItems.HeaderSubTitle>
                        발주된 품목을 조회할 수 있는 페이지 입니다.
                    </PurchaseOrderItems.HeaderSubTitle>
                </PurchaseOrderItems.Header>
                <PurchaseOrderItems.PurchaseOrderItemsTable onRowSelect={handleRowSelect} />
            </PurchaseOrderItems.Wrapper>
        </>
    );
};