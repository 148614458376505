import { authEnum } from "@/config/auth";
import { authTokenAtom } from "@/recoil/authAtom";
import { useRecoilValue } from "recoil";

export function useCheckAuth() {
  const recoilValue = useRecoilValue(authTokenAtom);
  const localStorageValue = localStorage.getItem(authEnum.authToken);

  return !!recoilValue || !!localStorageValue;
}
