export function handleErrorResponse({ error, errorContext }: { error: any, errorContext: string }) {
    // error 객체에서 응답을 가져옴
    const response = error.response;

    // 응답이 없거나 상태 코드가 없는 경우, 일반적인 에러 메시지를 반환
    if (!response || !response.status) {
        return 'An error occurred.';
    }

    // 에러 메시지 매핑
    const errorMessageMapping: any = {
        400: 'Bad Request: ',
        404: 'Not Found: ',
        500: {
            "SequelizeUniqueConstraintError": `중복되는 ${errorContext}가 존재합니다.`,
            default: 'Internal Server Error: '
        }
    };

    // 상태 코드에 따른 기본 메시지
    const defaultMessage = errorMessageMapping[response.status] || 'An error occurred: ';


    // 상태 코드가 500인 경우, 특정 메시지에 따른 메시지를 반환
    if (response.status === 500 && typeof defaultMessage === 'object') {
        return defaultMessage[response.data.name] || defaultMessage.default + response.data.name;
    }

    // 그 외의 경우, 기본 메시지 + 서버에서 받은 메시지를 반환
    return defaultMessage + response.data.name;
}