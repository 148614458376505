import DefaultInstance from "@/instance/axios";
import { createMutationKeys, createQueryKeys, mergeQueryKeys } from "@lukemorales/query-key-factory";
import { DefaultApiLedgersFindPostRequest, DefaultApiLedgersGetRequest, DefaultApiLedgersLedgerIdDeleteRequest, DefaultApiLedgersLedgerIdGetRequest, DefaultApiLedgersLedgerIdPutRequest, DefaultApiLedgersPostRequest } from "@sizlcorp/sizl-api-document/dist/models";
import dayjs from "dayjs";

export const ledgers = createQueryKeys('ledgers', {
    all: null,
    get: (params : DefaultApiLedgersGetRequest) => {
        const query = { ...params };
        if (Array.isArray(params.query?.$and) && params.query.$and?.length > 0) {
            query["query"] = JSON.stringify(params.query);
        } else if (params.query.createdAt.$between[0] !== null) {

            const [beforeDate, afterDate] = params.query.createdAt.$between;

            const before = dayjs(
                dayjs(new Date(beforeDate)).format("YYYY-MM-DD")
            ).toISOString();

            const after = afterDate
                ? dayjs(afterDate).add(1, "day").subtract(1, "seconds").toISOString()
                : dayjs(before).add(1, "day").subtract(1, "seconds").toISOString();

            const date = after ? [before, after] : [before, before];

            query["query"] = JSON.stringify({
                $and: [],
                createdAt: {
                    $between: date,
                },
            });
            // query["query"] = JSON.stringify(params.query);
        }
        if (params.search) {
            query["search"] = params.search;
            query["searchFields"] = params.searchFields;
        }

        return {
            queryKey: [query, JSON.stringify(query.query)],
            queryFn: () => DefaultInstance.ledgersGet(query)
        }
    },
    detail : (params : DefaultApiLedgersLedgerIdGetRequest) => {
        return {
            queryKey: [params.ledgerId],
            queryFn: () => DefaultInstance.ledgersLedgerIdGet(params)
        }
    },
    find : (params : DefaultApiLedgersFindPostRequest) => {
        return {
            queryKey: [params],
            queryFn: () => DefaultInstance.ledgersGet(params)
        }
    }
});

export const mutateLedgers = createMutationKeys('ledgers', {
    create: (params : DefaultApiLedgersPostRequest) => ({
        mutationKey: [params.ledgersGetRequest],
        mutationFn: () => DefaultInstance.ledgersPost(params),
    }),
    update: (params : DefaultApiLedgersLedgerIdPutRequest) => ({
        mutationKey: [params.ledgerId, params.ledgersGetRequest],
        mutationFn: () => DefaultInstance.ledgersLedgerIdPut(params),
    }),
    delete: (params : DefaultApiLedgersLedgerIdDeleteRequest) => ({
        mutationKey: [params.ledgerId],
        mutationFn: () => DefaultInstance.ledgersLedgerIdDelete(params),
    }),
});

export const ledgersQuery = mergeQueryKeys(ledgers, mutateLedgers)