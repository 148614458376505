import { useModal } from "@/hooks";
import { MasterInstance } from "@/instance/axios";
import { EquipmentModalType } from "@/recoil/modalAtom";
import { theme } from "@/styles/theme";
import styled from "@emotion/styled";
import { CloseButton, Flex, Text } from "@mantine/core";
import { useCallback } from "react";
import { useQuery } from "react-query";

const useGetEquipmentsQuery = (params: { equipmentId: number }) => {
  const { equipmentId } = params;
  return useQuery({
    queryKey: ["equipment-id-get", equipmentId],
    queryFn: () =>
      MasterInstance.equipmentsEquipmentIdGet({
        equipmentId,
      }),
  });
};

export const EquipmentModal = () => {
  const { modal, closeModal } = useModal();
  const { status, data, error } = useGetEquipmentsQuery({
    equipmentId: (modal as EquipmentModalType)?.param?.equipmentId,
  });

  const renderByStatus = useCallback(() => {
    switch (status) {
      case "loading":
        return <div>Loading...</div>;
      case "error":
        if (error instanceof Error) {
          return <span>Error: {error.message}</span>;
        }
        break;
      default:
        return (
          <div>
            <div>설비코드: {data?.data?.code}</div>
            <div>설비명: {data?.data?.name}</div>
            <div>설비유형: {data?.data?.equipmentType}</div>
          </div>
        );
    }
  }, [status]);

  return (
    <>
      {modal?.modalType === "Equipment" && modal.isOpen && (
        <ModalContainer>
          <Flex align="center">
            <Text fz="lg" mr="auto">
              설비
            </Text>
            <CloseButton
              size="xl"
              iconSize={32}
              aria-label="Close modal"
              onClick={closeModal}
            />
          </Flex>
          <SubText>
            <Text fz="sm" mb="sm">
              {renderByStatus()}
            </Text>
          </SubText>
        </ModalContainer>
      )}
    </>
  );
};

const ModalContainer = styled.div`
  position: fixed;
  width: 624px;
  height: auto;
  border-radius: 4px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 20px;
  background-color: ${theme.colors?.white?.[1]};
  border: 1px solid #ccc;
  z-index: 100;
`;

const SubText = styled.div`
  width: 100%;
  margin-top: 12px;
`;
