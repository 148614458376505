import { ProductionInstance } from "@/instance/axios";
import { ProductionPlansGenerateDraftWorksPost200Response } from "@sizlcorp/sizl-api-document/dist/models";
import { ProductionPlansGenerateDraftWorksPostRequest } from "@sizlcorp/sizl-api-document/dist/models/src/model/production-plans-generate-draft-works-post-request";
import { useQuery } from "react-query";

export const QUERY_KEY = "/production-plans/generate-draft-works";

const useGenerateDraftWorksQuery = ({
  itemCode,
  targetQuantity,
}: Partial<ProductionPlansGenerateDraftWorksPostRequest>) => {
  return useQuery<ProductionPlansGenerateDraftWorksPost200Response>({
    queryKey: [QUERY_KEY, { itemCode, targetQuantity }],
    enabled: !!(itemCode && targetQuantity),
    queryFn: () => {
      if (!itemCode || !targetQuantity) {
        return Promise.resolve({ data: [] } as any);
      }

      return ProductionInstance.productionPlansGenerateDraftWorksPost({
        productionPlansGenerateDraftWorksPostRequest: {
          itemCode,
          targetQuantity,
        },
      })
        .then((res: any) => res.data)
        .catch((err: any) => {
          console.error(err);
          return [];
        });
    },
  });
};

export default useGenerateDraftWorksQuery;
