import { css } from "@emotion/css";
import styled from "@emotion/styled";
import {
    Anchor,
    Button,
    Flex,
    Pagination,
    Text,
    useMantineColorScheme
} from "@mantine/core";
import { useEffect, useRef, useState } from "react";
import type { Column, SortColumn } from "react-data-grid";
import DataGrid, { FormatterProps, SelectColumn } from "react-data-grid";

import { inspections } from "@/api/inspect/inspectSpecification/useInspectionQuery";
import { specifications } from "@/api/inspect/inspectSpecification/useSpecificationsQuery";
import { LedgersHeader } from "@/constants/columnHeader";
import { ItemsDetailForm } from "@/features/item/components/form/ItemsDetailForm";
import { useLedgersState } from "@/features/ledgersAll/hook/useLedgersState";
import { useModal } from "@/features/modal/ModalStackManager";
import { ProductionPlanViewForm } from "@/features/productionPlan/view";
import { CustomFilter } from "@/features/ui/Base/List/CustomFilter/CustomFilter";
import { CustomSorter } from "@/features/ui/Base/List/CustomSorter/CustomSorter";
import { SearchBox, SearchProps } from "@/features/ui/Base/List/SearchBox/SearchBox";
import { theme } from "@/styles/theme";
import DatetimeUtil from "@/utils/dateTimeUtil";
import { InspectionsGet200ResponseRowsInner } from "@sizlcorp/sizl-api-document/dist/models";
import { IconSearch } from "@tabler/icons-react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { InspectionDetailForm } from "../form/detailForm";

export interface Row {
    id: number;
    itemCode: string;
    itemData: {
        user_lot_expiration?: string;
        user_lot_name?: string;
        unit_text?: string;
    };
    locationCode: string;
    locationData: {
        site_code?: string;
        updated_at?: string;
        name: string
    };
    lotId: number;
    lotData: {
        name?: string;
        expiration?: string;
    };
    quantity: string;
    userId: number;
    userCode: string;
    createdAt: string;
    additional: {
        productionPlanId: number;
        workLogId: number;
    }
    workLogData: {
        id: number;
        workLogType: string;
        defectCode: string;
        defectName: string;
    }
}

interface ColorThemeProps {
    isDarkMode: boolean;
}

interface Additional {
    productionPlanId: number;
    workLogId: number;
    workId: number;
}

export const InspectionRecordTable = () => {
    // const [rows, setRows] = useState<any>(createRows);
    const { openModal } = useModal();
    const { colorScheme, toggleColorScheme } = useMantineColorScheme();
    const isDarkMode: boolean = colorScheme === "light";

    const [sortColumns, setSortColumns] = useState<readonly SortColumn[]>([]);
    const [formatterProps, setFormatterProps] = useState<
        FormatterProps<Row, unknown> | undefined
    >();
    const [selectedRows, setSelectedRows] = useState(
        (): ReadonlySet<any> => new Set()
    );
    const [formatterPropsData, setFormatterPropsData] = useState<any>([]);
    const [activePage, setPage] = useState(1);
    const [query, setQuery] = useState({ $and: [] });
    const [sort, setSort] = useState([]);
    const [search, setSearch] = useState<SearchProps>({
        search: "",
        searchFields: [],
        pageSize: "10",
    });

    let state: any = useLedgersState();
    const createdAt = {
        ...query,
        createdAt: { $between: [state.Date[0], state.Date[1]] },
    };

    const searchFieldsHeader = LedgersHeader.filter(
        (ledger) => ledger.category === "text"
    );

    const { t } = useTranslation();

    // 검색조건이 변경되면 current 페이지를 1로 초기화
    const isSort = sort.length;

    const { data } = useQuery(specifications.find({}))
    const specificationsData = data?.data || [];

    const { data: inspectionData, refetch } = useQuery(inspections.get({
        query: {
            $and: [
                {
                    specificationId: {
                        $in: specificationsData.map((specification) => specification.id)
                    }
                }
            ]
        },
        populate: ["lot"],
        search: search.search,
        page: activePage,
        pageSize: Number(search.pageSize),
        sort: sort.length ? sort.join(",") : "-id"
    }))

    useEffect(() => {
        setPage(1);
        refetch();
    }, [query.$and.length, search.search, search.searchFields.length, search.pageSize, isSort, refetch]);

    const rows: readonly InspectionsGet200ResponseRowsInner[] = inspectionData?.data.rows ?? [];

    // const additional: Additional[] = rows
    //     .map((row) => row.additional)
    //     .filter((item): item is Additional => item !== undefined);

    const selectedRowsRef = useRef<ReadonlySet<any>>(new Set());

    useEffect(() => {
        const selectedRowId = selectedRows.values().next().value;
        if (selectedRowId) {
            const selectedRow = rows.find((row) => row.id === selectedRowId);

            setFormatterPropsData(selectedRow);
        } else {
            setFormatterPropsData([]);
        }
    }, [selectedRows]);

    const columns: readonly Column<InspectionsGet200ResponseRowsInner>[] = [
        {
            ...SelectColumn,
            width: 70,
            maxWidth: 500,
            resizable: true,
            headerCellClass: css`
        & > * {
          justify-content: flex-start;
          padding-left: 24px;
        }
      `,
            cellClass: css`
        .rdg-checkbox-label {
          padding-left: 24px;
        }
      `,
        },
        {
            key: "itemCode",
            name: "품목코드",
            sortable: true,
            resizable: true,
            formatter: ({ row }) => {
                const detailItemAction = () => {
                    openModal(
                        <ItemsDetailForm itemCode={row.itemCode} />,
                        null,
                        "재고 상세"
                    );
                };
                return <Anchor onClick={detailItemAction}>{row.itemCode}</Anchor>;
            },
        },
        {
            key: "lotName",
            name: "로트명",
            sortable: true,
            resizable: true,
            formatter: ({ row }) => {

                return (
                    <Text>
                        {row.lot?.name}
                    </Text>
                );
            },
        },
        {
            key: "additional.productionPlanId",
            name: "생산계획번호",
            sortable: true,
            resizable: true,
            cellClass: css`
        justify-content: flex-end;
      `,
            formatter: ({ row }) => <Anchor onClick={() => openModal(
                <ProductionPlanViewForm ProductionPlanId={row?.productionPlanId} />,
                null,
                "생산계획 상세"
            )}>
                {row?.productionPlanId}
            </Anchor>,
        },
        {
            key: "summary",
            name: "검사 기준 요약",
            sortable: true,
            resizable: true,
            formatter: (formatterProps) => {
                const summaryNames = formatterProps.row.specificationData?.criteria?.map(
                    (data) => data.name
                );
                return (
                    <>
                        {summaryNames && summaryNames?.length > 1
                            ? summaryNames[0] + " 외 " + (summaryNames.length - 1) + "개"
                            : summaryNames && summaryNames[0]}
                    </>
                );
            },
        },
        {
            key: "updatedAt",
            name: "검사일자",
            sortable: true,
            resizable: true,
            formatter: ({ row }) => <span>{DatetimeUtil(row.updatedAt ?? '')}</span>,
        },
        {
            key: "options",
            name: "액션",
            sortable: true,
            resizable: true,
            formatter: (formatterProps) => {
                const detailItemAction = () => {
                    openModal(
                        <InspectionDetailForm inspectionId={formatterProps?.row.id} />,
                        null,
                        "검사기록 상세"
                    );
                };
                return (
                    <OptionBox>
                        <OptionBtn
                            variant="subtle"
                            onClick={detailItemAction}
                            leftIcon={<IconSearch />}
                            color="blue"
                        ></OptionBtn>
                    </OptionBox>
                );
            },
        },
    ];

    return (
        <LedgersTableWrapper>
            <GridWrapper isDarkMode={isDarkMode}>
                <TableWrapper>
                    <RetrieveWrapper>
                        <Flex direction="row" gap="xs" justify="flex-start" align="flex-center">
                            <CustomFilter filterType={LedgersHeader} setQuery={setQuery} />
                            <CustomSorter sorterType={LedgersHeader} setSort={setSort} />
                        </Flex>
                        <SearchBox searchType={searchFieldsHeader} setSearch={setSearch} />
                    </RetrieveWrapper>
                    <DataGrid
                        columns={columns as any}
                        rows={rows as any}
                        rowHeight={40}
                        rowKeyGetter={(row: Row) => row.id}
                        sortColumns={sortColumns}
                        selectedRows={selectedRows}
                        onSelectedRowsChange={(e) => {
                            setSelectedRows(e);
                            selectedRowsRef.current = e;
                        }}
                    />
                </TableWrapper>
                <PaginationWrapper>
                    <Pagination
                        onChange={setPage}
                        value={activePage}
                        total={inspectionData?.data?.totalPages ?? 0}
                        size="lg"
                        radius="sm"
                    />
                </PaginationWrapper>
            </GridWrapper>
        </LedgersTableWrapper>
    );
};

const LedgersTableWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
`;

const GridWrapper = styled.div<ColorThemeProps>`
  & *[role="grid"] {
    height: inherit;
    --rdg-background-color: ${(props) => (props.isDarkMode ? "white" : "none")};
    --rdg-header-background-color: ${(props) =>
        props.isDarkMode ? "white" : "none"};
    --rdg-color: ${(props) => (props.isDarkMode ? "black" : "white")};
    --rdg-row-hover-background-color: ${(props) =>
        props.isDarkMode ? "#f5f5f5" : theme?.colors?.gray?.[7]};
  }
  & *[role="columnheader"] {
    // color: #7d8fa9;
    font-size: 12px;
    font-family: Roboto;
    font-weight: 500;
    word-wrap: break-word;
    // border: none;
    box-shadow: none;
    display: flex;
    align-items: center;
  }
  & *[aria-colindex="1"] {
  }

  & *[role="row"] {
    height: 100px;
  }

  & *[role="gridcell"] {
    display: flex;
    align-items: center;
    // border-left: none;
    // border-right: none;
    box-shadow: none;

    & > * {
      justify-content: flex-start;
    }
  }
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: space-between;
  gap: 1rem;
`;
const OptionBox = styled.fieldset`
  display: flex;
  gap: 10px;
  border: none;
`;
const OptionBtn = styled<any>(Button)`
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const PaginationWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const TableWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const RetrieveWrapper = styled.div`
  display: flex;
  flex-direction: row;
  padding-bottom: 10px;
  justify-content: space-between;
`;

const DateSelectBtn = styled<any>(Button)`
  display: flex;
  align-items: flex-start;
  height: 36px;
  border-radius: 4px;
  margin-right: 20px;
`;

