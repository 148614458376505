import { theme } from "@/styles/theme";
import { setFilteringDecimalPoint, setToLocaleString } from "@/utils/unitMark";
import { Flex, Text } from "@mantine/core";

interface WorkQuantityCellProps {
    data: {
        targetQuantity?: string; // 계획 수량
        totalQuantity?: string; // 총생산 수량
        todoQuantity?: string; // 생산 잔량
        defectTotal?: string; // 불량 수량
        unitText?: string; // 단위
    }
}

export const WorkQuantityCell = ({ data }: WorkQuantityCellProps) => {
    const { targetQuantity, totalQuantity, todoQuantity, defectTotal, unitText } = data;

    return (
        <Flex direction="column" justify="center" align="center" gap="sm" wrap="wrap">
            <Flex gap="xs" justify="flex-end" align="center" w="100%">
                <Text fz="sm" c={theme.colors?.blue?.[9]} ta="right" w="50%">
                    {setToLocaleString(setFilteringDecimalPoint(targetQuantity, 2))} {unitText}
                </Text>
                <Text fz="sm" c={theme.colors?.orange?.[9]} ta="right" w="50%">
                    {setToLocaleString(setFilteringDecimalPoint(todoQuantity, 2))} {unitText}
                </Text>
            </Flex>
            <Flex gap="xs" justify="flex-end" align="center" w="100%">
                <Text fz="sm" c={theme.colors?.teal?.[9]} ta="right" w="50%">
                    {setToLocaleString(setFilteringDecimalPoint(totalQuantity, 2))} {unitText}
                </Text>
                <Text fz="sm" c={theme.colors?.red?.[9]} ta="right" w="50%">
                    {setToLocaleString(setFilteringDecimalPoint(defectTotal, 2))} {unitText}
                </Text>
            </Flex>
        </Flex>
    )
};