import { useQuery } from "react-query";

import DefaultInstance from "@/instance/axios";
import { ItemsGet200ResponseRowsInnerOperationsInner, OperationsGet200Response } from "@sizlcorp/sizl-api-document/dist/models";

export type OperationOption = {
    value: string;
    label: string;
};

export const useOperationsMultiAutoComplete = (
    focus = false,
    keyword = "",
    options?: {
        onSuccess: (data: OperationOption[]) => void;
    }
) => {
    return useQuery(
        ["operations-get-multi-autocomplete-keyword", keyword],
        () =>
            DefaultInstance.operationsFindPost({
                search: keyword,
                searchFields: ["name"],
            }).then((res) => res.data),
        {
            enabled: focus,
            select: (
                data: ItemsGet200ResponseRowsInnerOperationsInner[]
            ): OperationOption[] =>
                data.map((Operation) => {
                    return {
                        value: String(Operation.code),
                        label: String(Operation.code),
                    };
                }) ?? [],
            onSuccess: options?.onSuccess,
        }
    );
};

const getOperationByCode = (code: string | null) =>
    DefaultInstance.operationsGet({
        ...(code && { search: code }),
        searchFields: ["code"],
    }).then((res) => res.data);

export const useOperationsMultiCode = (
    enabled: boolean = false,
    code: string | null,
    options?: {
        onSuccess: (data?: OperationOption[]) => void;
    }
) => {
    return useQuery(
        ["operations-multi-get-code", code],
        () => getOperationByCode(code),
        {
            enabled,
            select: (data: OperationsGet200Response) =>
                data?.rows?.map((Operation) => {
                    return {
                        value: Operation.code as string,
                        label: Operation.code as string,
                    };
                }),
            onSuccess: options?.onSuccess,
        }
    );
};
