import { Title, TitleProps } from "@mantine/core";

export const DetailTitle = (params: TitleProps) => {
  const { children } = params;

  return (
    <Title order={1} fw={"700"} lh={"130%"} fs={"normal"} {...params}>
      {children}
    </Title>
  );
};
