import { SitesAutoComplete } from "@/features/ui/autoComplete/site/sites-autoComplete";
import { Box, Button, Code, Text, TextInput } from "@mantine/core";
import { useForm } from "@mantine/form";

export function LocationForm(params: {
  name: string;
  onSubmit?: (values: any) => void;
}) {
  const { name, onSubmit } = params;
  const form = useForm({
    initialValues: {
      name: name,
      code: null,
      siteCode: null,
    },
  });

  return (
    <Box maw={320} mx="auto">
      <form onSubmit={form.onSubmit((values) => onSubmit && onSubmit(values))}>
        <TextInput
          label="name"
          placeholder="name"
          {...form.getInputProps("name")}
        />
        <TextInput
          label="code"
          placeholder="code"
          {...form.getInputProps("code")}
        />
        <SitesAutoComplete
          label="siteCode"
          width="100%"
          placeholder="siteCode"
          {...form.getInputProps("siteCode")}
        />

        <Text size="sm" weight={500} mt="xl">
          Form values:
        </Text>
        <Code block mt={5}>
          {JSON.stringify(form.values, null, 2)}
        </Code>
        <Button type="submit">Submit</Button>
      </form>
    </Box>
  );
}
