import styled from "@emotion/styled";
import { Title } from "@mantine/core";

interface HeaderSubTitleProps {
  children: React.ReactNode;
}

export const HeaderSubTitle = ({ children }: HeaderSubTitleProps) => {
  return <SubTitle>{children}</SubTitle>;
};

const SubTitle = styled(Title)`
  font-family: Noto Sans KR;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px; /* 150% */
  letter-spacing: -0.24px;
`;
