import { useModal } from "@/features/modal/ModalStackManager";
import { SiteDetailForm } from "@/features/site/components/form/SiteDetailForm";
import { BadgeBox } from "@/features/standard/badge/BadgeBox";
import { BadgeContainer } from "@/features/standard/badge/BadgeContainer";
import { BadgeIcon } from "@/features/standard/badge/BadgeIcon";
import { BadgeMain } from "@/features/standard/badge/BadgeMain";
import { BadgeQuantity } from "@/features/standard/badge/BadgeQuantity";
import { BadgeSmallBox } from "@/features/standard/badge/BadgeSmallBox";
import { BadgeTextBox } from "@/features/standard/badge/BadgeTextBox";
import { DetailBadge } from "@/features/ui/detail/DetailBadge";
import { DetailContent } from "@/features/ui/detail/DetailContent";
import { DetailField } from "@/features/ui/detail/DetailField";
import { DetailHeader } from "@/features/ui/detail/DetailHeader";
import { DetailInformTitle } from "@/features/ui/detail/DetailInformTitle";
import { DetailLabel } from "@/features/ui/detail/DetailLabel";
import { DetailLink } from "@/features/ui/detail/DetailLink";
import { DetailLinkWrapper } from "@/features/ui/detail/DetailLinkWrapper";
import { DetailTitle } from "@/features/ui/detail/DetailTitle";
import { UsersDetailForm } from "@/features/users/components/form/UsersDetailForm";
import DatetimeUtil from "@/utils/dateTimeUtil";
import { Anchor, Space, Text } from "@mantine/core";
import { MoldsGet200ResponseRowsInner } from "@sizlcorp/sizl-api-document/dist/models";
import { MoldDataForm } from "./moldDataForm";


const DetailMold = Object.assign(DetailHeader, {
    Title: DetailTitle,
    Inform: DetailInformTitle,
    Field: DetailField,
    Label: DetailLabel,
    Link: DetailLink,
    LinkWrapper: DetailLinkWrapper,
    Badge: DetailBadge,
    Content: DetailContent,
});

const Badge = Object.assign(BadgeMain, {
    Box: BadgeBox,
    SmallBox: BadgeSmallBox,
    Quantity: BadgeQuantity,
    Icon: BadgeIcon,
    Text: Text,
    TextBox: BadgeTextBox,
    Container: BadgeContainer
});

export interface DetailMoldInformProps {
    data: MoldsGet200ResponseRowsInner | any;
}

export const MoldDetailInform = (params: DetailMoldInformProps) => {
    const { data } = params;
    const mold = data?.data?.rows[0];

    const { openModal } = useModal();

    return (
        <>
            <MoldDataForm data={mold} />
            <DetailMold>
                <DetailMold.Title>{`[금형] ${mold?.code}`}</DetailMold.Title>
                <Space h={8} />
                <DetailMold.Field>
                    <DetailMold.Label>{"금형코드"}</DetailMold.Label>
                    <DetailMold.Content>{mold?.code}</DetailMold.Content>
                </DetailMold.Field>
                <DetailMold.Field>
                    <DetailMold.Label>{"사업장"}</DetailMold.Label>
                    <DetailMold.LinkWrapper>
                        <DetailMold.Badge>{"사업장"}</DetailMold.Badge>
                        <DetailMold.Link
                            onClick={() => openModal(
                                <SiteDetailForm siteCode={mold?.siteCode} />,
                                null,
                                ""
                            )}
                        >
                            {mold?.siteCode}
                        </DetailMold.Link>
                    </DetailMold.LinkWrapper>
                </DetailMold.Field>
                <DetailMold.Field>
                    <DetailMold.Label>{"금형 용량"}</DetailMold.Label>
                    <DetailMold.Content>{mold?.capacity}</DetailMold.Content>
                </DetailMold.Field>
                <DetailMold.Field>
                    <DetailMold.Label>{"생성정보"}</DetailMold.Label>
                    <DetailMold.Link>
                        <Anchor
                            onClick={() => {
                                openModal(
                                    <UsersDetailForm UserCode={mold?.creatorUserCode} />,
                                    null,
                                    ""
                                );
                            }}
                        >
                            {mold?.creatorUserCode}({DatetimeUtil(mold?.createdAt ?? '')})
                        </Anchor>
                    </DetailMold.Link>
                </DetailMold.Field>
                <DetailMold.Field>
                    <DetailMold.Label>{"수정정보"}</DetailMold.Label>
                    <DetailMold.Link>
                        <Anchor
                            onClick={() => {
                                openModal(
                                    <UsersDetailForm UserCode={mold?.updaterUserCode} />,
                                    null,
                                    ""
                                );
                            }}
                        >
                            {mold?.updaterUserCode}({DatetimeUtil(mold?.updatedAt ?? '')})
                        </Anchor>
                    </DetailMold.Link>
                </DetailMold.Field>
            </DetailMold>
        </>
    )
}