import { mutateRoutings } from "@/api/routings/useRoutingsQuery";
import useWorkSitesQuery from "@/api/useGetSitesQuery";
import { useModal } from "@/features/modal/ModalStackManager";
import customAlert from "@/features/ui/alert/alert";
import { LocationAutoComplete } from "@/features/ui/autoComplete/location/location-autoComplate";
import { SitesAutoComplete } from "@/features/ui/autoComplete/site/sites-autoComplete";
import { validateFormValues } from "@/utils/validation";
import styled from "@emotion/styled";
import { Box, Button, TextInput } from "@mantine/core";
import { useForm } from "@mantine/form";
import { DefaultApiRoutingsRoutingIdPutRequest } from "@sizlcorp/sizl-api-document/dist/models";
import { useMutation, useQueryClient } from "react-query";

export function RoutingsUpdateForm(params: { formatterProps: any }) {
  const { closeModal } = useModal();
  const { formatterProps } = params;
  const queryClient = useQueryClient();
  const form = useForm({
    initialValues: {
      code: formatterProps.code,
      name: formatterProps.name,
      siteCode: formatterProps.siteCode,
      fromLocationCode: formatterProps.fromLocationCode,
      toLocationCode: formatterProps.toLocationCode,
    },
  });

  const { mutate: updateMutate } = useMutation(
    (params: DefaultApiRoutingsRoutingIdPutRequest) =>
      mutateRoutings.update(params).mutationFn(params as DefaultApiRoutingsRoutingIdPutRequest | any),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('routings');
      },
    }
  );

  const { data } = useWorkSitesQuery();

  const siteData = data?.data?.rows as any[];

  const siteCode =
    (siteData &&
      (siteData?.map((value) => {
        return value.code;
      }) as any)) ||
    [];

  const onSubmit = async () => {
    try {
      updateMutate(
        {
          routingId: formatterProps.id as number,
          routingsGetRequest: {
            siteCode: form.values.siteCode as any,
            code: form.values.code as any,
            name: form.values.name as any,
            fromLocationCode: form.values.fromLocationCode as any,
            toLocationCode: form.values.toLocationCode as any,
          },
        },
        {
          onSuccess: (res) => {
            closeModal(formatterProps);
            if (res.status === 200) {
              customAlert(
                "라우팅 생성에 성공하였습니다.",
                "생성 성공",
                "green"
              )
            } else {
              customAlert(
                "라우팅 생성에 실패하였습니다.",
                "생성 실패",
                "red"
              )
            }
          },
        } as const
      );
    } catch (e) {
      customAlert(
        "라우팅 생성에 실패하였습니다.",
        "생성 실패",
        "red"
      )
    }
  };

  return (
    <Box maw={320} mx="auto">
      <Form>
        <ModalFieldSet>
          <TextInput
            withAsterisk
            label="라우팅코드"
            placeholder="code"
            defaultValue={formatterProps.code}
            {...form.getInputProps("code")}
            error={form.isDirty("code") ? "저장 필요" : ""}
            styles={{
              input: {
                color: form.isDirty("code")
                  ? "cadetblue !important"
                  : "inherit",
                borderColor: form.isDirty("code")
                  ? "cadetblue !important"
                  : "inherit",
              },
              error: { fontSize: 11, color: "cadetblue" },
            }}
          />
          <TextInput
            withAsterisk
            label="라우팅코드"
            placeholder="name"
            defaultValue={formatterProps.name}
            {...form.getInputProps("name")}
            error={form.isDirty("name") ? "저장 필요" : ""}
            styles={{
              input: {
                color: form.isDirty("name")
                  ? "cadetblue !important"
                  : "inherit",
                borderColor: form.isDirty("name")
                  ? "cadetblue !important"
                  : "inherit",
              },
              error: { fontSize: 11, color: "cadetblue" },
            }}
          />
          <SitesAutoComplete
            withAsterisk
            label="사업장코드"
            width={"100%"}
            data={siteCode}
            defaultValue={formatterProps.siteCode}
            {...form.getInputProps("siteCode")}
            error={form.isDirty("siteCode") ? "저장 필요" : ""}
            styles={{
              input: {
                color: form.isDirty("siteCode")
                  ? "cadetblue !important"
                  : "inherit",
                borderColor: form.isDirty("siteCode")
                  ? "cadetblue !important"
                  : "inherit",
              },
              error: { fontSize: 11, color: "cadetblue" },
            }}
          />
          <LocationAutoComplete
            withAsterisk
            label="자재 출고 로케이션코드"
            width={"100%"}
            defaultValue={formatterProps.fromLocationCode}
            {...form.getInputProps("fromLocationCode")}
            error={form.isDirty("fromLocationCode") ? "저장 필요" : ""}
            styles={{
              input: {
                color: form.isDirty("fromLocationCode")
                  ? "cadetblue !important"
                  : "inherit",
                borderColor: form.isDirty("fromLocationCode")
                  ? "cadetblue !important"
                  : "inherit",
              },
              error: { fontSize: 11, color: "cadetblue" },
            }}
          />
          <LocationAutoComplete
            withAsterisk
            label="재공품 입고 로케이션코드"
            width={"100%"}
            defaultValue={formatterProps.toLocationCode}
            {...form.getInputProps("toLocationCode")}
            error={form.isDirty("toLocationCode") ? "저장 필요" : ""}
            styles={{
              input: {
                color: form.isDirty("toLocationCode")
                  ? "cadetblue !important"
                  : "inherit",
                borderColor: form.isDirty("toLocationCode")
                  ? "cadetblue !important"
                  : "inherit",
              },
              error: { fontSize: 11, color: "cadetblue" },
            }}
          />

          <ModalConfirmBtnBox>
            <Button color="gray" onClick={closeModal}>
              취소
            </Button>
            <Button
              disabled={validateFormValues({
                pageType: "routings",
                values: form.values,
              })}
              onClick={onSubmit}
            >
              라우팅 수정
            </Button>
          </ModalConfirmBtnBox>
        </ModalFieldSet>
      </Form>
    </Box>
  );
}

const Form = styled.form`
  width: 100%;
  height: 100%;
`;

const ModalFieldSet = styled.fieldset`
  border: none;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const ModalConfirmBtnBox = styled.div`
  padding: 24px 0;
  display: flex;
  justify-content: flex-end;
  gap: 20px;
`;
