import { useQuery } from "react-query";

import { DefaultInstance } from "@/instance/axios";
import { EquipmentsGet200Response } from "@sizlcorp/sizl-api-document/dist/models";
import { AxiosResponse } from "axios";

// 추후에 쿼리KEY를 관리할 예정이다.

export const EQUIPMENT_QUERY_KEY = "/equipment";

const getEquipmentsFetch = (params?: any) => {
  if (params) {
    const query: any = {};
    // query를 보내는 조건은 필터 검색을 할 때이다.
    if (params.query.$and.length > 0) {
      query["query"] = JSON.stringify(params.query);
    }
    // 검색내용이 있을 때만 search를 보내야 한다.
    if (params.search) {
      query["search"] = params.search;
      query["searchFields"] = params.searchFields;
    }
    return DefaultInstance.equipmentsGet({
      ...query,
      page: params.activePage ?? 1,
      pageSize: params.pageSize ?? 10,
      populate: ['location', "works"],
      sort: params?.sort,
    });
  } else return DefaultInstance.equipmentsGet({});
};

const useGetEquipmentsQuery = (params?: any) => {
  return useQuery<AxiosResponse<EquipmentsGet200Response, any>>({
    queryKey: params
      ? [
        EQUIPMENT_QUERY_KEY,
        params.activePage,
        JSON.stringify(params.query),
        params.searchFields,
        params.search,
      ]
      : [EQUIPMENT_QUERY_KEY],
    queryFn: () => (params ? getEquipmentsFetch(params) : getEquipmentsFetch()),
  });
};

export default useGetEquipmentsQuery;
