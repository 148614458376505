import { ActionButtonBox } from "@/features/standard/ActionButtonBox";
import { ActionHeader } from "@/features/standard/ActionHeader";
import { Header } from "@/features/standard/Header";
import { Main } from "@/features/standard/Main";
import { HeaderSubTitle } from "@/features/standard/SubTitle";
import { HeaderTitle } from "@/features/standard/Title";
import { Wrapper } from "@/features/standard/Wrapper";
import { WmsLogsTable } from "@/features/wmsLogs/components/table";

import { Button } from "@mantine/core";
import { WmsLogsGet200ResponseRowsInner } from "@sizlcorp/sizl-api-document/dist/models";
import { useState } from "react";

const WmsLogs = Object.assign({}, Main, {
    Header: Header,
    HeaderTitle: HeaderTitle,
    HeaderSubTitle: HeaderSubTitle,
    InventoryWrapper: Wrapper,
    ActionHeader: ActionHeader,
    ActionButton: Button,
    ActionButtonBox: ActionButtonBox,
    WmsLogsTable: WmsLogsTable,
});

export const WmsLogsPage = () => {

    const [isRowBtnActive, setIsRowBtnActive] = useState<boolean>(true);
    const [data, setData] = useState<WmsLogsGet200ResponseRowsInner>();

    const handleRowSelect = (
        selectedRows : any,
        formatterPropsData : WmsLogsGet200ResponseRowsInner
    ) => {
        setIsRowBtnActive(formatterPropsData?.actionType !== "TRANSFER");
        setData(formatterPropsData);
    }

    return (
        <>
            <WmsLogs.InventoryWrapper>
                <WmsLogs.Header>
                    <WmsLogs.HeaderTitle>WMS작업 이력 조회</WmsLogs.HeaderTitle>
                    <WmsLogs.HeaderSubTitle>
                        우리회사에서 다루는 wms작업 이력을 조회합니다.
                    </WmsLogs.HeaderSubTitle>
                </WmsLogs.Header>
                <WmsLogs.WmsLogsTable onRowSelect={handleRowSelect} />
            </WmsLogs.InventoryWrapper>
        </>
    );
};