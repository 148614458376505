import { useModal } from "@/features/modal/ModalStackManager";
import { DetailBadge } from "@/features/ui/detail/DetailBadge";
import { DetailContent } from "@/features/ui/detail/DetailContent";
import { DetailField } from "@/features/ui/detail/DetailField";
import { DetailHeader } from "@/features/ui/detail/DetailHeader";
import { DetailInformTitle } from "@/features/ui/detail/DetailInformTitle";
import { DetailLabel } from "@/features/ui/detail/DetailLabel";
import { DetailLink } from "@/features/ui/detail/DetailLink";
import { DetailLinkWrapper } from "@/features/ui/detail/DetailLinkWrapper";
import { DetailTitle } from "@/features/ui/detail/DetailTitle";
import { theme } from "@/styles/theme";
import { Button, Flex } from "@mantine/core";
import { IconEdit } from "@tabler/icons-react";
import { InspectCriteriaCreateForm } from "../form/InspectCriteriaCreateForm";

const DetailOperation = Object.assign(DetailHeader, {
  Title: DetailTitle,
  Inform: DetailInformTitle,
  Field: DetailField,
  Label: DetailLabel,
  Link: DetailLink,
  LinkWrapper: DetailLinkWrapper,
  Badge: DetailBadge,
  Content: DetailContent,
  Button: Button,
});

interface InspectCriteriaInformProps {
  data?: any;
}

export const InspectCriteriaCreateInform = (
  params: InspectCriteriaInformProps
) => {
  const { data } = params;

  const { openModal } = useModal();

  return (
    <DetailOperation bg={"#FFFFFF"}>
      <Flex w={"100%"} justify={"space-between"} align={"center"}>
        <DetailOperation.Title size={26}>{"검사 기준"}</DetailOperation.Title>
        <DetailOperation.Button
          disabled={data.criteria?.length || !data.itemCode}
          bg={theme.colors?.blue?.[6]}
          rightIcon={<IconEdit />}
          onClick={() => {
            openModal(
              <InspectCriteriaCreateForm data={data} />,
              "",
              "검사 기준 추가",
              true
            );
          }}
        >
          {"추가"}
        </DetailOperation.Button>
      </Flex>
      <DetailOperation.Inform>
        {
          "품질검사를 위한 검사 기준을 조회합니다.변경된 검사 기준은 새로 만들어지는 생산 계획부터 적용됩니다."
        }
      </DetailOperation.Inform>
    </DetailOperation>
  );
};
