import {
  MantineReactTable,
  useMantineReactTable,
  // createRow,
  type MRT_ColumnDef
} from 'mantine-react-table';
import { useMemo } from 'react';

import { EquipmentDetailForm } from '@/features/equipment/components/form/EquipmentDetailForm';
import { useModal } from '@/features/modal/ModalStackManager';
import { MoldDetailForm } from '@/features/mold/form/moldDetailForm';
import { OperationDetailForm } from '@/features/operation/components/form/OperationDetailForm';
import { RoutingDetailForm } from '@/features/routings/components/form/routingDetailForm';
import { WorkView } from '@/features/work/WorkView/WorkView';
import DatetimeUtil from '@/utils/dateTimeUtil';
import { setToLocaleString } from '@/utils/unitMark';
import { Anchor, Badge, Flex } from '@mantine/core';
import { ProductionPlanFormProps } from '../../view';

export interface Row {
  targetQuantity: string | any;
  id: number | any;
  scheduledAt: string | any;
  seq: number | any;
  operationCode: string | any;
  operationData: {
    moldCode: string | any;
    routingCode: string | any;
    seq: number | any;
    routing: {
      name: string | any;
    }
  };
  equipmentCode: string | any;
  summary: {
    todoQuantity: number | any;
    defectTotal: number | any;
  }
  item: {
    unitText: string | any;
  }
  works: {
    item: {
      unitText: string | any;
    }
  }
}

export const SummaryTabs = ({ data }: ProductionPlanFormProps) => {

  const { openModal } = useModal();

  const columns = useMemo<MRT_ColumnDef<Row>[]>(
    () => [
      {
        accessorFn: (row) => row.operationData.seq,
        accessorKey: 'seq',
        header: '시퀀스',
        size: 40,
        Cell: (rows) => {
          return (
            <div style={{ textAlign: 'right' }}>{rows.row._valuesCache.seq}</div>
          )
        }
      },
      {
        accessorFn: (row) => row.operationData.routing?.name,
        accessorKey: 'routingCode',
        header: '라우팅코드',
        size: 120,
        mantineEditTextInputProps: {
          type: 'string',
          required: true,
        },
        Cell: (rows) => {
          return (
            <div>
              <Anchor onClick={() => openModal(<RoutingDetailForm routingCode={rows.row.original.operationData.routingCode} />, null, "")}>
                {rows.row.original.operationData.routing?.name}
              </Anchor>
            </div>
          )
        },
      },
      {
        accessorFn: (row) => row.operationCode,
        accessorKey: 'operationCode',
        header: '공정코드',
        size: 120,
        mantineEditTextInputProps: {
          type: 'string',
          required: true,
        },
        Cell: (rows) => {
          return (
            <Anchor onClick={() => openModal(<OperationDetailForm operactionCode={rows.row._valuesCache.operationCode} />, null, "")}>
              {rows.row._valuesCache.operationCode}
            </Anchor>
          )
        },
      },
      {
        accessorFn: (row) => DatetimeUtil(row.scheduledAt),
        accessorKey: 'scheduledAt',
        header: '작업예정일시',
        size: 120,
        mantineEditTextInputProps: {
          type: 'string',
          required: true,
        },
      },
      {
        accessorFn: (row) => row.operationData.moldCode,
        accessorKey: 'moldCode',
        header: '사용 금형',
        size: 120,
        mantineEditTextInputProps: {
          type: 'string',
          required: true,
        },
        Cell: (rows) => {
          return (
            <Flex direction="column">
              {rows.row._valuesCache.moldCode && <Badge size="sm" w={40}>금형</Badge>}
              <Anchor
                onClick={() => openModal(
                  <MoldDetailForm moldCode={rows.row._valuesCache.moldCode} />,
                  null,
                  ""
                )}
              >
                {rows.row._valuesCache.moldCode}
              </Anchor>
            </Flex>
          )
        }
      },
      {
        accessorFn: (row) => row.equipmentCode,
        accessorKey: 'equipmentCode',
        header: '합당 설비',
        size: 120,
        mantineEditTextInputProps: {
          type: 'string',
          required: true,
        },
        Cell: (rows) => {
          return (
            <Flex direction="column">
              {rows.row._valuesCache.equipmentCode && <Badge size="sm" w={40}>설비</Badge>}
              <Anchor onClick={() => openModal(<EquipmentDetailForm equipmentCode={rows.row._valuesCache.equipmentCode} />, null, "")}>{rows.row._valuesCache.equipmentCode}</Anchor>
            </Flex>
          )
        }
      },
      {
        accessorFn: (row) => row.targetQuantity + " " + row.item.unitText,
        accessorKey: 'targetQuantity',
        header: '목표수량',
        mantineEditTextInputProps: {
          type: 'string',
          required: true,
        },
        size: 80,
        Cell: (rows) => {
          const unitText = rows.row.original.works?.item?.unitText;
          return (
            <div style={{ textAlign: 'right' }}>{rows.row._valuesCache.targetQuantity} {unitText}</div>
          )
        }
      },
      {
        accessorFn: (row) => setToLocaleString(row.summary.todoQuantity) + " " + row.item.unitText,
        accessorKey: 'todoQuantity',
        header: '생산수량',
        mantineEditTextInputProps: {
          type: 'string',
          required: true,
        },
        size: 80,
        Cell: (rows) => {
          const unitText = rows.row.original.works?.item?.unitText;
          return (
            <div style={{ textAlign: 'right' }}>{rows.row._valuesCache.todoQuantity} {unitText}</div>
          )
        }
      },
      {
        accessorFn: (row) => setToLocaleString(row.summary.defectTotal) + " " + row.item.unitText,
        accessorKey: 'defectTotal',
        header: '불량수량',
        size: 80,
        mantineEditTextInputProps: {
          type: 'string',
          required: true,
        },
        Cell: (rows) => {
          const unitText = rows.row.original.works?.item?.unitText;
          return (
            <div style={{ textAlign: 'right' }}>{rows.row._valuesCache.defectTotal} {unitText}</div>
          )
        }
      },
      {
        accessorFn: (row) => row.id,
        accessorKey: 'id',
        header: '상세보기',
        mantineEditTextInputProps: {
          type: 'string',
          required: true,
        },
        size: 100,
        Cell: (rows) => {
          return (
            <Anchor
              onClick={() => openModal(
                <WorkView workId={rows.row._valuesCache.id} />,
                null,
                ""
              )}
            >
              {rows.row._valuesCache.id}
            </Anchor>
          )
        }
      },
    ],
    [],
  );

  const worksData: Row[] = data && Array.isArray(data.works) ? data.works.map(row => {
    if (Array.isArray(row) && row.length > 0) {
      return {
        ...row,
      };
    } else {
      return row;
    }
  }) as unknown as Row[] : [];

  const table = useMantineReactTable({
    columns,
    data: worksData,
    editDisplayMode: 'modal', //default ('row', 'cell', 'table', and 'custom' are also available)
    enableEditing: false,
    enableToolbarInternalActions: false, // 우측상단 아이콘 안보이게
    enablePagination: false,
  });

  return <MantineReactTable table={table} />;
};
