import { equipments } from "@/api/equipment/useEquipmentQuery";
import { MonitoringBox } from "@/features/monitoring/components/parentsBox/Monitoring";
import { Parents } from "@/features/monitoring/components/parentsBox/Parents";
import { Main } from "@/features/standard/Main";
import { BASE_URL } from "@/instance/axios";
import { AuthSignupPost201ResponseEquipment } from "@sizlcorp/sizl-api-document/dist/models";
import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useSocket } from "socket.io-react-hook";

const Monitoring = Object.assign({}, Main, {
    Parents: Parents,
});

export const MonitoringTable = () => {

    const { data: equipmentData } = useQuery(equipments.find({
        query: {
            $and: [
                {
                    "influxEquipmentCode": {
                        $and: [{ '$not': null }, { '$not': '' }],
                    },
                },
            ],
        },
        sort: "seq, id",
        populate: ['location', "works"],
    }));

    const [monitoringData, setMonitoringData] = useState<Array<any>>([]);
    const equipmentCode = equipmentData?.data &&
        equipmentData?.data?.map((data: AuthSignupPost201ResponseEquipment) => data.code);

    const { socket, error } = useSocket(BASE_URL, {
        autoConnect: true,
    });
    // console.log(equipmentCode)
    useEffect(() => {
        equipmentCode?.forEach((code, index) => {
            const roomName = `PM_${code}`;
            socket.on(roomName, (message: any) => {
                setMonitoringData((prevData: any) => {
                    const newData = [...prevData];
                    newData[index] = message;
                    return newData;
                });
            });

            socket.emit(
                "call",
                "socket.join",
                { room: roomName },
                function (err: any, res: any) {
                    if (err) {
                        console.error(err);
                    }
                }
            );
        });
        // 컴포넌트가 언마운트될 때 소켓의 이벤트 리스너를 제거
        return () => {
            equipmentCode?.forEach((code) => {
                const roomName = `PM_${code}`;
                socket.off(roomName);
            });
        };
    }, [socket, equipmentData])

    return (
        <>
            <Monitoring.Parents>
                {monitoringData?.map((data: any, index: number) => {
                    return (
                        <MonitoringBox data={data} index={index} />
                    )
                })}

            </Monitoring.Parents>
        </>
    )
}