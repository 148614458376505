import { useModal } from "@/features/modal/ModalStackManager";
import { WorkView } from "@/features/work/WorkView/WorkView";
import { TextEllipsis } from "@/features/workByManagement/WorkTableRow";
import { theme } from "@/styles/theme";
import { setFilteringDecimalPoint } from "@/utils/unitMark";
import { trackingStatusColor } from "@/utils/workStatus";
import { Badge, Flex, Progress, Text, Tooltip } from "@mantine/core";
import { AuthSignupPost201ResponseEquipmentPurchaseOrderItem, ProductionPlansGet200ResponseRowsInnerWorksInnerTrackingStatusEnum } from "@sizlcorp/sizl-api-document/dist/models";
import { useTranslation } from "react-i18next";

interface WorkEquipmentCellProps {
    data: {
        itemName?: string;
        itemCode?: string;
        itemId?: number;
        itemSpec?: string;
        workId?: number;
        operationCode?: string;
        percent: string;
        trackingStatus?: ProductionPlansGet200ResponseRowsInnerWorksInnerTrackingStatusEnum,
        purchaseOrderItem?: AuthSignupPost201ResponseEquipmentPurchaseOrderItem
    }
}

export const WorkProgressCell = ({ data }: WorkEquipmentCellProps) => {
    const { openModal } = useModal();
    const { t } = useTranslation();

    const { itemName, itemCode, itemId, itemSpec, workId, operationCode, percent, trackingStatus, purchaseOrderItem } = data;

    if (!itemId) {
        return null;
    }

    return (
        <Flex direction="column" gap="md">
            {/* <WorkLogProgressBar workId={workId} /> */}
            <Flex gap="md">
                <Flex direction="column" gap="xs">
                    <Badge variant="outline" color={trackingStatus ? trackingStatusColor[trackingStatus] : ""}>{t(trackingStatus as ProductionPlansGet200ResponseRowsInnerWorksInnerTrackingStatusEnum)}</Badge>
                    {
                        purchaseOrderItem ? <Badge color="violet" variant="outline">수주</Badge> : null
                    }
                </Flex>
                <Tooltip label={
                    <Flex direction="column">
                        <TextEllipsis fz="sm" fw={700}>{operationCode}</TextEllipsis>
                        <TextEllipsis fz="sm" fw={700}>{itemName} ({itemCode})</TextEllipsis>
                        <TextEllipsis fz="sm" fw={700}>{itemSpec}</TextEllipsis>
                    </Flex>
                }>
                    <Flex direction="column" onClick={() => {
                        openModal(<WorkView workId={workId} />, null, "작업지시")
                    }}>
                        <TextEllipsis fz="sm" fw={700} c={theme.colors?.blue?.[7]}>{operationCode}</TextEllipsis>
                        <TextEllipsis fz="sm" fw={700} c={theme.colors?.blue?.[7]}>{itemName} ({itemCode})</TextEllipsis>
                        <TextEllipsis fz="sm" fw={700} c={theme.colors?.blue?.[7]}>{itemSpec ?? "-"}</TextEllipsis>
                    </Flex>
                </Tooltip>
            </Flex>
            <Flex justify="center" align="center" gap="xs">
                <Progress value={Number(percent)} size="sm" w="85%" />
                <Text>{setFilteringDecimalPoint(percent, 2)}%</Text>
            </Flex>
        </Flex>
    );
};
