import { useModal } from "@/features/modal/ModalStackManager";
import { ActionHeader } from "@/features/standard/ActionHeader";
import { Header } from "@/features/standard/Header";
import { Main } from "@/features/standard/Main";
import { HeaderSubTitle } from "@/features/standard/SubTitle";
import { HeaderTitle } from "@/features/standard/Title";
import { Wrapper } from "@/features/standard/Wrapper";
import { UsersCreateForm } from "@/features/users/components/form/UsersCreateForm";
import { UsersTable } from "@/features/users/components/table/UsersTable";
import { usePub } from "@/hooks";
import { Button } from "@mantine/core";
import { IconPlus, IconTrash } from "@tabler/icons-react";

const UsersWrap = Object.assign({}, Main, {
  Header: Header,
  HeaderTitle: HeaderTitle,
  HeaderSubTitle: HeaderSubTitle,
  Wrapper: Wrapper,
  ActionHeader: ActionHeader,
  ActionButton: Button,
  CreateForm: UsersCreateForm,
});

export const Users = () => {

  const { openModal } = useModal();

  const publish = usePub();

  return (
    <UsersWrap.Wrapper>
      <UsersWrap.ActionHeader>
        <UsersWrap.ActionButton
          leftIcon={<IconPlus />}
          onClick={() =>
            openModal(<UsersWrap.CreateForm />, null, "사용자 등록", true)
          }
        >
          추가
        </UsersWrap.ActionButton>
        <UsersWrap.ActionButton
          color="red"
          rightIcon={<IconTrash />}
          onClick={() => publish("deleteSelectedItems")}
        >
          선택된 행 삭제
        </UsersWrap.ActionButton>
      </UsersWrap.ActionHeader>
      <UsersWrap.Header>
        <UsersWrap.HeaderTitle>사용자 리스트</UsersWrap.HeaderTitle>
        <UsersWrap.HeaderSubTitle>
          우리 회사의 사용자에 대해 조회할 수 있는 페이지 입니다.
        </UsersWrap.HeaderSubTitle>
      </UsersWrap.Header>
      <UsersTable />
    </UsersWrap.Wrapper>
  );
};
