import { ActionButtonBox } from "@/features/standard/ActionButtonBox";
import { ActionHeader } from "@/features/standard/ActionHeader";
import { Header } from "@/features/standard/Header";
import { Main } from "@/features/standard/Main";
import { HeaderSubTitle } from "@/features/standard/SubTitle";
import { HeaderTitle } from "@/features/standard/Title";
import { Wrapper } from "@/features/standard/Wrapper";
import { WipInventoryTable } from "@/features/wipInventory/components/table";
import { Button } from "@mantine/core";

const WipInventory = Object.assign({}, Main, {
    Header: Header,
    HeaderTitle: HeaderTitle,
    HeaderSubTitle: HeaderSubTitle,
    InventoryWrapper: Wrapper,
    ActionHeader: ActionHeader,
    ActionButton: Button,
    ActionButtonBox: ActionButtonBox,
    WipInventoryTable: WipInventoryTable,
});

export const WipInventoryPage = () => {
    return (
        <>
            <WipInventory.InventoryWrapper>
                <WipInventory.Header>
                    <WipInventory.HeaderTitle>재공품</WipInventory.HeaderTitle>
                    <WipInventory.HeaderSubTitle>
                        우리회사에서 다루는 재공품을 관리합니다.
                    </WipInventory.HeaderSubTitle>
                </WipInventory.Header>
                <WipInventory.WipInventoryTable />
            </WipInventory.InventoryWrapper>
        </>
    );
};