import { useModal } from "@/features/modal/ModalStackManager";
import { SiteCreateForm } from "@/features/site/components/form/SiteCreateForm";
import { SiteTable } from "@/features/site/components/table/SiteTable";
import { ActionHeader } from "@/features/standard/ActionHeader";
import { Header } from "@/features/standard/Header";
import { Main } from "@/features/standard/Main";
import { HeaderSubTitle } from "@/features/standard/SubTitle";
import { HeaderTitle } from "@/features/standard/Title";
import { Wrapper } from "@/features/standard/Wrapper";
import { usePub } from "@/hooks";
import { Button } from "@mantine/core";
import { IconPlus, IconTrash } from "@tabler/icons-react";

const SitesWrap = Object.assign({}, Main, {
  Header: Header,
  HeaderTitle: HeaderTitle,
  HeaderSubTitle: HeaderSubTitle,
  Wrapper: Wrapper,
  ActionHeader: ActionHeader,
  ActionButton: Button,
  CreateForm: SiteCreateForm,
});



export const Site = () => {

  const { openModal } = useModal();
  const publish = usePub();

  return (
    <SitesWrap.Wrapper>
      <SitesWrap.ActionHeader>
        <SitesWrap.ActionButton
          leftIcon={<IconPlus />}
          onClick={() =>
            openModal(<SitesWrap.CreateForm />, null, "사업장 추가", true)
          }
        >
          추가
        </SitesWrap.ActionButton>
        <SitesWrap.ActionButton
          color="red"
          rightIcon={<IconTrash />}
          onClick={() => publish("deleteSelectedItems")}
        >
          선택된 행 삭제
        </SitesWrap.ActionButton>
      </SitesWrap.ActionHeader>
      <SitesWrap.Header>
        <SitesWrap.HeaderTitle>사업장 리스트</SitesWrap.HeaderTitle>
        <SitesWrap.HeaderSubTitle>
          우리 회사에서 다루는 사업장에 대해 조회할 수 있는 페이지 입니다.
        </SitesWrap.HeaderSubTitle>
      </SitesWrap.Header>
      <SiteTable />
    </SitesWrap.Wrapper>
  );

};