import styled from "@emotion/styled";

interface WrapperProps {
  children: React.ReactNode;
}

export const Wrapper = ({ children }: WrapperProps) => {
  return <Container>{children}</Container>;
};

const Container = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  padding: 8px;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
`;
