import { MasterInstance } from "@/instance/axios";
import { createMutationKeys, createQueryKeys, mergeQueryKeys } from "@lukemorales/query-key-factory";
import { MasterApiMoldStocksFindPostRequest, MasterApiMoldStocksGetRequest, MasterApiMoldStocksMoldStockIdDeleteRequest, MasterApiMoldStocksMoldStockIdPutRequest, MasterApiMoldStocksPostRequest } from "@sizlcorp/sizl-api-document/dist/models";


export const moldStocks = createQueryKeys('moldStock', {
    all: null,
    get: (params: MasterApiMoldStocksGetRequest) => {
        const query = { ...params }; // params 객체를 복사합니다.
        if (Array.isArray(params.query.$and) && params.query.$and.length > 0) {
            query["query"] = JSON.stringify(params.query);
        }
        // params.search가 있을 때만 query.search와 query.searchFields를 설정합니다.
        if (params.search) {
            query["search"] = params.search;
            query["searchFields"] = params.searchFields;
        }
        return {
            queryKey: [query, JSON.stringify(query.query)],
            queryFn: () => MasterInstance.moldStocksGet(query)
        }
    },
    find: (params: MasterApiMoldStocksFindPostRequest) => {
        const query = { ...params }; // params 객체를 복사합니다.
        if (Array.isArray(params.query.$and) && params.query.$and.length > 0) {
            query["query"] = JSON.stringify(params.query);
        }
        // params.search가 있을 때만 query.search와 query.searchFields를 설정합니다.
        if (params.search) {
            query["search"] = params.search;
            query["searchFields"] = params.searchFields;
        }
        return {
            queryKey: [query, JSON.stringify(query.query)],
            queryFn: () => MasterInstance.moldStocksFindPost(query)
        }
    },
})

export const mutateMoldStocks = createMutationKeys('moldStock', {
    create: (params: MasterApiMoldStocksPostRequest) => ({
        mutationKey: [params.moldStocksGetRequest],
        mutationFn: () => MasterInstance.moldStocksPost(params),
    }),
    update: (params: MasterApiMoldStocksMoldStockIdPutRequest) => ({
        mutationKey: [params.moldStockId, params.moldStocksGetRequest],
        mutationFn: () => MasterInstance.moldStocksMoldStockIdPut(params)
    }),
    delete: (params: MasterApiMoldStocksMoldStockIdDeleteRequest) => ({
        mutationKey: [params.moldStockId],
        mutationFn: () => MasterInstance.moldStocksMoldStockIdDelete(params)
    }),
});

export const moldStockQueryKeys = mergeQueryKeys(moldStocks, mutateMoldStocks);