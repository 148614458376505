import { sorterMap } from "@/constants/sorter";
import styled from "@emotion/styled";
import { Button, Select } from "@mantine/core";
import { useForm } from "@mantine/form";

interface formTypeProps {
  name: string;
  label: string;
  type: string;
}

export const CustomSorterForm = (params: {
  sorterType: { label: string; value: string; category: string }[];
  onSubmit?: (values: any) => void;
}) => {
  const { sorterType, onSubmit } = params;
  const form = useForm<formTypeProps>({
    initialValues: {
      name: "",
      label: "",
      type: "",
    },
  });

  const searchChange = (e: string, field: string) => {
    form.setFieldValue(field, e);
  };

  return (
    <ModalContent>
      <Form
        onSubmit={form.onSubmit((values) => onSubmit && onSubmit(values))}
      >
        <FilterSelect
          placeholder="컬럼 선택"
          data={sorterType}
          {...form.getInputProps("name")}
          dropdownPosition="bottom"
          withinPortal={true}
          onChange={(e: string) => {
            searchChange(e, "name");
          }}
        />
        <TypeSelect
          placeholder="정렬 선택"
          data={sorterMap}
          {...form.getInputProps("type")}
          onSearchChange={(e: string) => searchChange(e, "label")}
          dropdownPosition="bottom"
          withinPortal={true}
        />
        <Button type="submit">Submit</Button>
      </Form>
    </ModalContent>
  );
};

const ModalContent = styled.div`
  width: 25rem;
  height: auto;
`;

const Form = styled.form`
  width: 100%;
  display: flex;
  gap: 10px;
  justify-content: center;
  align-items: top;
  position: relative;
`;

const FilterSelect = styled<any>(Select)`
  width: 60%;
`;

const TypeSelect = styled<any>(Select)`
  width: 40%;
`;


