// 예시: DrawerContext.tsx
import { Drawer } from "@mantine/core";
import React, { createContext, useContext, useState } from "react";

type DrawerContextType = {
  openDrawer: (component: React.ReactNode, parameter: any) => Promise<any>;
};

const DrawerContext = createContext<DrawerContextType | undefined>(undefined);

export const useDrawer = () => {
  const drawerContext = useContext(DrawerContext);
  if (!drawerContext) {
    throw new Error("useDrawer must be used within a DrawerProvider");
  }
  return drawerContext;
};

type DrawerProviderProps = {
  children: React.ReactNode;
};

export const DrawerProvider: React.FC<DrawerProviderProps> = ({ children }) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [drawerComponent, setDrawerComponent] = useState<React.ReactNode>(null);
  const [drawerResult, setDrawerResult] = useState<any>(null);

  let resolveDrawerPromise: (value: any) => void;

  const openDrawer = (component: React.ReactNode, parameter: any) => {
    setDrawerComponent(component);
    setIsDrawerOpen(true);

    return new Promise((resolve) => {
      resolveDrawerPromise = resolve;
      console.log("openDrawer", resolve, resolveDrawerPromise);
    });
  };

  const closeDrawer = (result?: any) => {
    setDrawerResult(result);
    console.log("closeDrawer", resolveDrawerPromise);
    if (resolveDrawerPromise) {
      resolveDrawerPromise(drawerResult);
    }
    setIsDrawerOpen(false);
  };

  return (
    <DrawerContext.Provider value={{ openDrawer }}>
      {children}

      <Drawer
        position="right"
        onClose={closeDrawer}
        opened={isDrawerOpen}
        size="md"
      >
        {drawerComponent}
        {/* 모달 내용 */}
      </Drawer>
    </DrawerContext.Provider>
  );
};
