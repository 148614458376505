import { ItemAutoComplete } from "@/features/ui/autoComplete/item/item-autoComplete";
import { DetailBadge } from "@/features/ui/detail/DetailBadge";
import { DetailContent } from "@/features/ui/detail/DetailContent";
import { DetailField } from "@/features/ui/detail/DetailField";
import { DetailHeader } from "@/features/ui/detail/DetailHeader";
import { DetailInformTitle } from "@/features/ui/detail/DetailInformTitle";
import { DetailLabel } from "@/features/ui/detail/DetailLabel";
import { DetailLink } from "@/features/ui/detail/DetailLink";
import { DetailLinkWrapper } from "@/features/ui/detail/DetailLinkWrapper";
import { DetailTitle } from "@/features/ui/detail/DetailTitle";
import { Space } from "@mantine/core";

const DetailOperation = Object.assign(DetailHeader, {
  Title: DetailTitle,
  Inform: DetailInformTitle,
  Field: DetailField,
  Label: DetailLabel,
  Link: DetailLink,
  LinkWrapper: DetailLinkWrapper,
  Badge: DetailBadge,
  Content: DetailContent,
});

interface InspectSpecificationInformWithItemProps {
  formData: any;
}

export const InspectSpecificationInformWithItem = (
  params: InspectSpecificationInformWithItemProps
) => {
  const { formData } = params;

  return (
    <DetailOperation gap={15}>
      <DetailOperation.Title>{"검사표준"}</DetailOperation.Title>
      <DetailOperation.Inform>{"검사표준"}</DetailOperation.Inform>
      <Space h={10} />
      <DetailOperation.Field align={"center"}>
        <DetailOperation.Label>{"품목"}</DetailOperation.Label>
        <ItemAutoComplete {...formData.getInputProps("itemCode")} />
      </DetailOperation.Field>
      <DetailOperation.Field>
        <DetailOperation.Label>{"공정코드"}</DetailOperation.Label>
        <DetailOperation.Content></DetailOperation.Content>
      </DetailOperation.Field>
      <DetailOperation.Field>
        <DetailOperation.Label>{"검사 종류"}</DetailOperation.Label>
        <DetailOperation.Content>{"초중종몰 검사"}</DetailOperation.Content>
      </DetailOperation.Field>
      <DetailOperation.Field>
        <DetailOperation.Label>{"생성정보"}</DetailOperation.Label>
        <DetailOperation.Content>
          {/* {`${data?.creatorUserCode ?? ""}(${dayjs(data?.createdAt).format(
            "YYYY-MM-DD HH:mm:ss"
          )})`} */}
        </DetailOperation.Content>
      </DetailOperation.Field>
      <DetailOperation.Field>
        <DetailOperation.Label>{"수정정보"}</DetailOperation.Label>
      </DetailOperation.Field>
    </DetailOperation>
  );
};
