import { mutateInventories } from "@/api/inventories/useInventoriesQuery";
import { WorkInputForm } from "@/features/inventory/components/Form/WorkInputForm";
import { LeftActionBar, RightActionBar } from "@/features/layout";
import { useModal } from "@/features/modal/ModalStackManager";
import { ProductionPlanWithWorksForm } from "@/features/productionPlan/form/CreateForm";
import { Calendar, isCalendarDate } from "@/features/ui/Calendar";
import { DefectTypeItem, DefectTypeSet } from "@/features/ui/DefectTypeSet";
import customAlert from "@/features/ui/alert/alert";
import { WorkUpdateForm } from "@/features/workByEquipment/components/WorkUpdateForm";
import { WorkActionBar } from "@/features/workByManagement/WorkActionBar";
import { WorkHeader } from "@/features/workByManagement/WorkHeader";
import { WorkNotFound } from "@/features/workByManagement/WorkNotFound";
import { WorkPassArea } from "@/features/workByManagement/WorkPassArea";
import { WorkProductionPlanTable } from "@/features/workByManagement/WorkProductionPlanTable";
import { WorkProductionPlanTableHead } from "@/features/workByManagement/WorkProductionPlanTableHead";
import { WorkProductionPlanTableRow } from "@/features/workByManagement/WorkProductionPlanTableRow";
import { WorkSiteSelect } from "@/features/workByManagement/WorkSiteSelect";
import { WorkTable } from "@/features/workByManagement/WorkTable";
import { WorkTableActionBar } from "@/features/workByManagement/WorkTableActionBar";
import { WorkTableBody } from "@/features/workByManagement/WorkTableBody";
import { WorkTableHead } from "@/features/workByManagement/WorkTableHead";
import { WorkTableRow } from "@/features/workByManagement/WorkTableRow";
import { WorkTableWrapper } from "@/features/workByManagement/WorkTableWrapper";
import useWorkMutation from "@/features/workByManagement/api/useWorkMutation";
import { workPage } from "@/features/workByManagement/api/useWorkPageQuery";
import { SocketData } from "@/features/workByManagement/context/WorkProvider";
import useAutoResetState from "@/features/workByManagement/hooks/useAutoResetState";
import { useWorkActions } from "@/features/workByManagement/hooks/useWorkActions";
import { useWorkState } from "@/features/workByManagement/hooks/useWorkState";
import useLocalStorage from "@/hooks/useLocalStorage";
import { BASE_URL } from "@/instance/axios";
import { getStartAndEndOfDateRange } from "@/utils/dateTimeUtil";
import styled from "@emotion/styled";
import { Button, Menu, Pagination, Select } from "@mantine/core";
import { AuthSignupPost201ResponseEquipmentPurchaseOrderItem, AuthSignupPost201ResponseEquipmentWorksInner, DefaultApiWmsFlushPutRequest, ProductionPlansGet200ResponseRowsInnerWorksInner } from "@sizlcorp/sizl-api-document/dist/models";
import { IconCalendar, IconSettings } from "@tabler/icons-react";
import { useEffect, useMemo, useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useSocket } from "socket.io-react-hook";

const Work = {
    ActionBar: WorkActionBar,
    LeftActionBar: LeftActionBar,
    RightActionBar: RightActionBar,
    SiteSelect: WorkSiteSelect,
    Select: Select,
    Button: Button,
    WorkHeader: WorkHeader,
    WorkTableActionBar: WorkTableActionBar,
    WorkTableWrapper: WorkTableWrapper,
    WorkProductionPlanTable: WorkProductionPlanTable,
    WorkProductionPlanTableHead: WorkProductionPlanTableHead,
    WorkProductionPlanTableRow: WorkProductionPlanTableRow,
    WorkTable: WorkTable,
    WorkTableHead: WorkTableHead,
    WorkTableBody: WorkTableBody,
    WorkTableRow: WorkTableRow,
};

export const WorkByEquipment = () => {
    const state = useWorkState();
    const actions = useWorkActions();
    const [activePage, setPage] = useState(1);
    const { openModal } = useModal();
    const [defectTypes, setDefectTypes] = useLocalStorage<DefectTypeItem[][]>("defectTypes", [[], []]);
    // useAutoResetState를 사용하여 상태를 관리
    const [updateSuccess, setUpdateSuccess] = useAutoResetState<boolean>(false, 50);

    const { updateQuantityMutate, updateDefectMutate, deleteMutate, putEquipmentCorrectionMutate } = useWorkMutation();

    // const { mutate : flushMutate } = useInventoryMutation("flush");
    const queryClient = useQueryClient();
    const { mutate: flushMutate } = useMutation(
        (params: DefaultApiWmsFlushPutRequest) =>
            mutateInventories.flush(params).mutationFn(params as DefaultApiWmsFlushPutRequest | any),
        {
            onSuccess: () => {
                queryClient.invalidateQueries("inventories");
            }
        }
    )

    // const { data: equipmentData,  } = useWorkByEquipmentQuery({
    //     defectCodes: defectTypes && defectTypes[1],
    //     activePage,
    //     scheduledAt: getStartAndEndOfDateRange(state.date[0], state.date[1])[0]
    // });

    const [socketData, setSocketData] = useState<SocketData[]>([]);

    const { data: equipmentData, refetch } = useQuery(
        workPage.equipment({
            defectCodes: defectTypes && defectTypes[1],
            page: activePage,
            scheduledAt: getStartAndEndOfDateRange(state.date[0], state.date[1])[0],
            sort: "-id",
        })
    )

    // 초기 배열 선언 및 타입을 명확히 정의
    const initialEquipmentWorks: (AuthSignupPost201ResponseEquipmentWorksInner & { purchaseOrderItem?: AuthSignupPost201ResponseEquipmentPurchaseOrderItem })[] = [];

    // 데이터 처리
    const equipments = useMemo(() => {
        return equipmentData?.rows?.reduce((acc, cur) => {
            const { works = [] } = cur;

            if (works.length === 0) {
                acc.push({
                    equipmentCode: cur.code,
                    equipmentName: cur.name,
                    purchaseOrderItem: cur.purchaseOrderItem as AuthSignupPost201ResponseEquipmentPurchaseOrderItem,
                });
            } else {
                acc.push(...works.map((work) => ({
                    ...work,
                    purchaseOrderItem: cur.purchaseOrderItem as AuthSignupPost201ResponseEquipmentPurchaseOrderItem,
                })));
            }

            return acc;
        }, initialEquipmentWorks) ?? [];
    }, [equipmentData?.rows]);


    const { socket, error } = useSocket(BASE_URL, {
        autoConnect: true,
    });

    useEffect(() => {
        equipments?.forEach((equipment) => {
            const roomName = equipment.equipmentCode ? `EC_${equipment.equipmentCode}` : "";

            socket.on(roomName, (message: SocketData) => {
                setSocketData((prev: SocketData[]) => {
                    const newData = [...prev, {
                        ...message,
                        workId: equipment.id
                    }];
                    return newData;
                })
            });

            socket.emit(
                "call",
                "socket.join",
                { room: roomName },
                function (err: any, res: any) {
                    if (err) {
                        console.error(err);
                    }
                }
            );
        });

        // 컴포넌트가 언마운트될 때 소켓의 이벤트 리스너를 제거
        return () => {
            equipments?.forEach((equipment) => {
                const roomName = equipment.equipmentCode ? `EC_${equipment.equipmentCode}` : "";
                socket.off(roomName);
            });
        };

    }, [socket, equipments]);

    const handleUpdateQuantity = async (work: ProductionPlansGet200ResponseRowsInnerWorksInner | AuthSignupPost201ResponseEquipmentWorksInner) => {
        const quantityData = state.tempQuantity.find(data => data.key === (work.id && work.id.toString()));
        if (work.id && quantityData?.value) {
            try {

                await updateQuantityMutate({
                    workId: work.id,
                    worksWorkIdPerformancePutRequest: {
                        accQuantity: quantityData.value
                    }
                }, {
                    onSuccess: () => {
                        actions.setTempQuantity([]);
                        setUpdateSuccess(true);  // 성공적인 API 호출 후 상태 업데이트
                    }
                });
            } catch (error) {
                alert("변경되지 않은 수량이 존재합니다. 다시 시도해주세요.");
            }
        }
    };

    const handleUpdateDefects = async (work: ProductionPlansGet200ResponseRowsInnerWorksInner | AuthSignupPost201ResponseEquipmentWorksInner) => {
        const defectData = state.tempDefects.find(data => data.id === (work.id && work.id.toString()));
        // Update one defect at a time
        const updateDefectSequentially = async (defect: { key: string; value: string }) => {
            try {
                if (work.id) {
                    await updateDefectMutate({
                        workId: work.id,
                        worksWorkIdDefectPutRequest: {
                            defectCode: defect.key,
                            accQuantity: defect.value
                        }
                    });
                }
            } catch (e: any) {
                console.error("Error updating defect: ", e);
                throw e; // 에러를 다시 throw하여 밖에서 처리할 수 있도록 합니다.
            }
        };

        if (work.id && defectData) {
            for await (const defect of defectData.defect) {
                try {
                    // Await each update operation before continuing to the next one
                    await updateDefectSequentially(defect);
                } catch (e: any) {
                    if (e.response?.data?.message === "defectQuantity is greater than startQuantity") {
                        alert("불량 수량 저장에 실패했습니다. 불량 수량이 완료 수량을 초과하는지 다시 확인해주세요");
                        break; // 중단하고 나머지 업데이트를 취소합니다.
                    }
                    // Handle other unexpected errors
                    alert("예상치 못한 오류가 발생했습니다. 나중에 다시 시도 해주십시오.");
                    break; // Stop processing further updates in case of any error
                }
            }
            // Reset temporary defects and set update success state
            actions.setTempDefects([]);
            setUpdateSuccess(true);
        }
    };

    return (
        <WorkExampleContainer>
            <Work.ActionBar>
                <Work.LeftActionBar>
                    {/* TODO: 현재 선택된 사업장 => 추가 필요 */}
                    {/* <Work.Select data={[]} placeholder="사업장 선택" /> */}
                    <Work.SiteSelect />
                    {/* TODO: 현재 선택된 라인 => 추후 테이블 추가 필요하고 equipment에 lineId 추가 필요  */}
                    <Work.Select data={[]} placeholder="라인 선택" />
                    <Work.Button
                        leftIcon={<IconCalendar />}
                        onClick={() => {
                            openModal(<Calendar dates={state.date} type="default" />, null, "날짜 선택", true)
                                .then((value) => { if (isCalendarDate(value)) actions.setDate(value) })
                        }
                        }
                    >날짜선택</Work.Button>
                </Work.LeftActionBar>
                <Work.RightActionBar>
                    <Menu shadow="md" width={150} position="bottom-end">
                        <Menu.Target>
                            <Button color="gray" rightIcon={<IconSettings />}>
                                Settings
                            </Button>
                        </Menu.Target>
                        <Menu.Dropdown>
                            <Menu.Item onClick={() => {
                                openModal(<DefectTypeSet />, null, "불량 유형 설정").then((result) => {
                                    // result 데이터가 특정 데이터일 경우 불량 타입 재조회 (불량 유형 설정을 완료했다는 의미)
                                    if (Array.isArray(result)) {
                                        setDefectTypes(result as DefectTypeItem[][])
                                    }
                                })
                            }}>
                                Settings
                            </Menu.Item>
                        </Menu.Dropdown>
                    </Menu>
                </Work.RightActionBar>
            </Work.ActionBar>
            <Work.WorkHeader dateType="default" />
            <Work.WorkTableActionBar>
                <Work.LeftActionBar>
                    <Work.Button onClick={() => openModal(
                        <ProductionPlanWithWorksForm />,
                        {},
                        "생산계획 생성",
                        true
                    ).then(() => refetch())}>생산계획 생성</Work.Button>
                    <Work.Button variant="light" disabled={!((state.works) && state.works.length === 1)} onClick={() => {
                        const [work] = state.works;
                        openModal(
                            <WorkInputForm<'equipment'>
                                workRowData={work as AuthSignupPost201ResponseEquipmentWorksInner}
                            />,
                            null,
                            "원/부자재 투입",
                            true
                        );
                    }}>원/부자재 투입</Work.Button>
                    <Work.Button variant="light" color="orange" disabled={!((state.works) && state.works.length === 1)} onClick={() => {
                        const [work] = state.works;
                        flushMutate({
                            wmsFlushPutRequest: {
                                locationCode: ((work as ProductionPlansGet200ResponseRowsInnerWorksInner)?.targetLocationCode ?? '') || ((work as ProductionPlansGet200ResponseRowsInnerWorksInner)?.equipment?.targetLocationCode ?? '') || ((work as ProductionPlansGet200ResponseRowsInnerWorksInner)?.operationData?.routing?.toLocationCode ?? '')
                            }
                        }, {
                            onSuccess: (res: any) => {
                                res.data.length > 0 ?
                                    customAlert(
                                        "재고가 초기화 되었습니다.",
                                        "재고 초기화",
                                        "info"
                                    ) : customAlert(
                                        "이미 초기화 되었습니다.",
                                        "재고 초기화",
                                        "red"
                                    )
                            }
                        })
                    }}>재고 초기화</Work.Button>
                    <Work.Button variant="light" color="teal" disabled={!((state.works) && state.works.length === 1)} onClick={async () => {
                        // TODO: AuthSignupPost201ResponseEquipmentWorksInner 타입에도 trackingStatus 타입이 추가되어야 함. 2024-05-21 jswon
                        const [work] = state.works as (ProductionPlansGet200ResponseRowsInnerWorksInner)[];
                        if (work.id && work.equipmentCode && work.trackingStatus === "WORKING") {
                            const result = await putEquipmentCorrectionMutate({
                                equipmentsCorrectionPutRequest: {
                                    equipmentCode: work.equipmentCode
                                }
                            });
                            result.data.length ? customAlert(
                                "재고 보정이 완료되었습니다.",
                                "재고 보정",
                                "info"
                            ) : customAlert(
                                "보정할 재고가 존재하지 않습니다.",
                                "재고 보정",
                                "red"
                            )
                        } else {
                            customAlert(
                                "작업이 진행 중인 설비에 대해서만 재고 보정이 가능합니다.",
                                "재고 보정",
                                "red"
                            )
                        }
                    }}>재고 보정</Work.Button>
                </Work.LeftActionBar>
                <Work.RightActionBar>
                    <Work.Button color="yellow" disabled={!((state.works) && state.works.length === 1)} onClick={() => {
                        const [work] = state.works;
                        openModal(
                            <WorkUpdateForm<'equipment'>
                                workRowData={work as AuthSignupPost201ResponseEquipmentWorksInner}
                            />,
                            null,
                            "작업 수정",
                            true
                        )
                    }}>수정</Work.Button>
                    <Work.Button color="red" disabled={!((state.works) && state.works.length === 1)} onClick={() => {
                        if (window.confirm("선택한 작업을 삭제하시겠습니까?")) {
                            state.works.forEach((work) => {
                                work.id && deleteMutate({ workId: work.id });
                            })
                        }
                    }}>삭제</Work.Button>
                    {/* <Work.Button onClick={() => {
                        // doSomething
                        state.works.forEach(async (work) => {
                            await handleUpdateQuantity(work);
                            await handleUpdateDefects(work);
                        });
                    }}>모두 저장</Work.Button> */}
                    <Work.Button disabled={!((state.works) && state.works.length >= 1)}
                        onClick={() => {
                            // const [work] = state.works;
                            state.works.forEach(async (work) => {
                                await handleUpdateQuantity(work);
                                await handleUpdateDefects(work);
                            });
                        }}>저장</Work.Button>
                </Work.RightActionBar>
            </Work.WorkTableActionBar>
            <Work.WorkTableWrapper>
                <Work.WorkTable isWithBorder={true}>
                    <Work.WorkTableHead pageKey="equipment" defectTypes={defectTypes && defectTypes[1]} />
                    <Work.WorkTableBody>
                        {/* WorkRow 영역 */}
                        {
                            equipments && equipments.length ? equipments?.map((equipment, index) => {
                                // 소켓 데이터를 해당 ID에 맞게 필터링
                                const socketDataObject = socketData.find((data) => data.workId === equipment.id);

                                return (
                                    <WorkPassArea<'equipment'> pageKey="equipment" socketData={socketDataObject} rowData={equipment} key={equipment.id || index} defectTypes={defectTypes && defectTypes[1]} formReset={updateSuccess} />
                                );
                            }) : <WorkNotFound defectLength={defectTypes ? defectTypes[1].length : 0} />
                        }
                    </Work.WorkTableBody>
                </Work.WorkTable>
            </Work.WorkTableWrapper>
            <Pagination
                onChange={setPage}
                value={activePage}
                total={equipmentData?.totalPages as number}
                size="lg"
                radius="sm"
            />
        </WorkExampleContainer>
    )
}

const WorkExampleContainer = styled.div`
    display: flex;
    padding: 8px;
    flex-direction: column;
    align-items: center; // flex-start;
    gap: 8px;
`