import { css } from "@emotion/css";
import styled from "@emotion/styled";
import { Anchor, Button, Flex, Pagination, useMantineColorScheme } from "@mantine/core";
import { IconEdit, IconTrash } from "@tabler/icons-react";
import { useEffect, useRef, useState } from "react";
import type { Column, SortColumn } from "react-data-grid";
import DataGrid, { SelectColumn } from "react-data-grid";

import { useModal } from "@/features/modal/ModalStackManager";
import { useSub } from "@/hooks";

import { mutateOperationOutsources, operationOutsources } from "@/api/operationOutsource/useOperationOutsourcesQuery";
import { operationOutsourcesHeader } from "@/constants/columnHeader";
import { queryDefaultValues } from "@/constants/queryDefaultValues";
import { LocationDetailForm } from "@/features/location/detail/LocationDetailForm";
import { OperationDetailForm } from "@/features/operation/components/form/OperationDetailForm";
import { UpdateForm } from "@/features/operationOutsource/form/UpdateForm";
import { CustomFilter } from "@/features/ui/Base/List/CustomFilter/CustomFilter";
import { CustomSorter } from "@/features/ui/Base/List/CustomSorter/CustomSorter";
import { SearchBox, SearchProps } from "@/features/ui/Base/List/SearchBox/SearchBox";
import customAlert from "@/features/ui/alert/alert";
import useQueryString from "@/hooks/useQueryString";
import useUpdateUrlParams from "@/hooks/useUpdateUrlParams";
import { theme } from "@/styles/theme";
import { DefaultApiOperationOutsourcesOperationOutsourceIdDeleteRequest, OperationOutsourcesGet200ResponseRowsInner } from "@sizlcorp/sizl-api-document/dist/models";
import { useMutation, useQuery } from "react-query";
import { OperationOutsourceDetailForm } from "../form/DetailForm";

interface Row {
  id?: number;
  name?: string;
  operationCode?: string;
  fromLocationCode?: string;
  toLocationCode?: string;
  fromLocationName?: string;
  toLocationName?: string;
}

interface ColorThemeProps {
  isDarkMode: boolean;
}

export const OperationOutsourceTable = () => {
  const [initialQueryString, setQueryString] = useQueryString(queryDefaultValues);

  const { colorScheme, toggleColorScheme } = useMantineColorScheme();
  const isDarkMode: boolean = colorScheme === "light";

  const [sortColumns, setSortColumns] = useState<readonly SortColumn[]>([]);

  const [selectedRows, setSelectedRows] = useState(
    (): ReadonlySet<any> => new Set()
  );

  const [activePage, setPage] = useState(initialQueryString.page);
  const [query, setQuery] = useState(JSON.parse(initialQueryString.query));
  const [sort, setSort] = useState(initialQueryString.sort);
  const [search, setSearch] = useState<SearchProps>({
    search: initialQueryString.search,
    searchFields: initialQueryString.searchFields,
    pageSize: initialQueryString.pageSize,
  });

  const updateUrlParams = useUpdateUrlParams();

  const { openModal } = useModal();

  const { data: operationOutsource, refetch } = useQuery(operationOutsources.get({
    query: query,
    search: search.search,
    searchFields: search.searchFields.length
      ? search.searchFields
      : operationOutsourcesHeader.map(
        (operationOutsource) => operationOutsource.value
      ),
    page: activePage,
    pageSize: Number(search.pageSize),
    sort: sort.length ? sort.join(",") : "-id",
    populate: ["location"],
  }));

  // 새로운 데이터가 로드될 때, 현재 페이지가 유효하지 않으면 1페이지로 설정
  useEffect(() => {
    if (operationOutsource && activePage > (operationOutsource?.data?.totalPages ?? 0)) {
      setPage(1);
      setQueryString((prev) => ({
        ...prev,
        page: 1,
      }));
    }
  }, [operationOutsource, activePage, setQueryString]);

  // 페이지 Query String으로 라우팅 이동
  useEffect(() => {
    updateUrlParams({
      page: activePage,
      pageSize: search.pageSize,
      search: search.search,
      searchFields: search.searchFields,
      query: JSON.stringify(query),
      sort: sort
    }, queryDefaultValues);
  }, [activePage, search, query, sort, updateUrlParams]);

  const rows: readonly OperationOutsourcesGet200ResponseRowsInner[] =
    operationOutsource?.data.rows ?? [];
  const selectedRowsRef = useRef<ReadonlySet<any>>(new Set());

  const { mutate: deleteMutate } = useMutation(
    (params: DefaultApiOperationOutsourcesOperationOutsourceIdDeleteRequest) =>
      mutateOperationOutsources.delete(params).mutationFn(params as DefaultApiOperationOutsourcesOperationOutsourceIdDeleteRequest | any),
    {
      onSuccess: () => {
        refetch();
      }
    }
  );
  const columns: readonly Column<Row>[] = [
    {
      ...SelectColumn,
      width: 70,
      maxWidth: 500,
      resizable: true,
      headerCellClass: css`
        & > * {
          justify-content: flex-start;
          padding-left: 24px;
        }
      `,
      cellClass: css`
        .rdg-checkbox-label {
          padding-left: 24px;
        }
      `,
    },
    {
      key: "name",
      name: "외주처명",
      sortable: true,
      resizable: true,
      formatter: (formatterProps) => {
        return (
          <Anchor
            onClick={() =>
              openModal(
                <OperationOutsourceDetailForm name={formatterProps.row.name} />,
                null,
                "",
                true
              )
            }
          >
            {formatterProps.row.name}
          </Anchor>
        );
      },
    },
    {
      key: "operationCode",
      name: "공정코드",
      sortable: true,
      resizable: true,
      formatter: (formatterProps) => {
        return (
          <Anchor
            onClick={() =>
              openModal(
                <OperationDetailForm operactionCode={formatterProps.row.operationCode} />,
                null,
                "",
                true
              )
            }
          >
            {formatterProps.row.operationCode}
          </Anchor>
        );
      },
    },
    {
      key: "fromLocationName",
      name: "자재 출고 로케이션이름",
      sortable: true,
      resizable: true,
      formatter: (formatterProps) => {
        return (
          <Anchor
            onClick={() =>
              openModal(<LocationDetailForm locationCode={formatterProps.row.fromLocationCode} />, null, "")}>
            {formatterProps.row.fromLocationName}
          </Anchor>
        )
      },
    },
    {
      key: "toLocationName",
      name: "재공품 입고 로케이션이름",
      sortable: true,
      resizable: true,
      formatter: (formatterProps) => {
        return (
          <Anchor
            onClick={() =>
              openModal(<LocationDetailForm locationCode={formatterProps.row.toLocationCode} />, null, "")}>
            {formatterProps.row.toLocationName}
          </Anchor>
        )
      },
    },
    {
      key: "options",
      name: "액션",
      sortable: true,
      resizable: true,
      formatter: (formatterProps) => {
        const deleteItemAction = async (tid: number) => {
          if (window.confirm("정말로 삭제하시겠습니까?")) {
            deleteItem(tid);
          }
        };

        // delete item after confirm
        const deleteItem = async (deleteItemId: number) => {
          deleteMutate(
            { operationOutsourceId: deleteItemId },
            {
              onSuccess: (res) => {
                if (res.status === 200) {
                  customAlert(
                    "공정 삭제에 성공하였습니다.",
                    "삭제 성공",
                    "green"
                  );
                } else {
                  customAlert(
                    "삭제가 불가능한 공정입니다.",
                    "삭제 실패",
                    "red"
                  );
                }
              },
            }
          );
        };

        return (
          <OptionBox>
            <OptionBtn
              variant="subtle"
              leftIcon={<IconEdit />}
              color="yellow"
              onClick={() => {
                openModal(
                  <UpdateForm formatterProps={formatterProps?.row} />,
                  null,
                  "외주처 수정",
                  true
                );
              }}
            ></OptionBtn>
            <OptionBtn
              variant="subtle"
              leftIcon={<IconTrash />}
              color="red"
              onClick={() => {
                deleteItemAction(formatterProps?.row?.id as any);
              }}
            ></OptionBtn>
          </OptionBox>
        );
      },
    },
  ];

  useSub("deleteSelectedItems", () => {
    const rows = Array.from(selectedRowsRef.current);
    if (!rows.length) return;
    const deleteRows: number[] = Array.from(rows[rows.length - 1].values());
    if (window.confirm("정말로 삭제하시겠습니까?")) {
      deleteRows.forEach((row: number) => {
        deleteMutate({ operationOutsourceId: row });
      });
      selectedRowsRef.current = new Set();
    }
  });

  return (
    <OperationTableWrapper>
      <GridWrapper isDarkMode={isDarkMode}>
        <TableWrapper>
          <RetrieveWrapper>
            <Flex direction="row" gap="xs" justify="flex-start" align="flex-center">
              <CustomFilter filterType={operationOutsourcesHeader} setQuery={setQuery} query={query} />
              <CustomSorter sorterType={operationOutsourcesHeader} setSort={setSort} sort={sort} />
            </Flex>
            <SearchBox
              searchType={operationOutsourcesHeader}
              setSearch={setSearch}
              search={search}
            />
          </RetrieveWrapper>
          <DataGrid
            columns={columns ?? []}
            rows={rows}
            rowHeight={40}
            rowKeyGetter={(row: Row) => row.id}
            sortColumns={sortColumns}
            selectedRows={selectedRows}
            onSelectedRowsChange={(e) => {
              setSelectedRows(e);
              const newSelectedRows = new Set(selectedRowsRef.current);
              newSelectedRows.add(e);
              selectedRowsRef.current = newSelectedRows;
            }}
          />
        </TableWrapper>
        <PaginationWrapper>
          <Pagination
            onChange={setPage}
            value={activePage}
            total={operationOutsource?.data?.totalPages ?? 0}
            size="lg"
            radius="sm"
          />
        </PaginationWrapper>
      </GridWrapper>
    </OperationTableWrapper>
  );
};

const OperationTableWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
`;

const GridWrapper = styled.div<ColorThemeProps>`
  & *[role="grid"] {
    height: inherit;
    --rdg-background-color: ${(props) => (props.isDarkMode ? "white" : "none")};
    --rdg-header-background-color: ${(props) =>
    props.isDarkMode ? "white" : "none"};
    --rdg-color: ${(props) => (props.isDarkMode ? "black" : "white")};
    --rdg-row-hover-background-color: ${(props) =>
    props.isDarkMode ? "#f5f5f5" : theme?.colors?.gray?.[7]};
  }
  & *[role="columnheader"] {
    // color: #7d8fa9;
    font-size: 12px;
    font-family: Roboto;
    font-weight: 500;
    word-wrap: break-word;
    // border: none;
    box-shadow: none;
    display: flex;
    align-items: center;
  }
  & *[aria-colindex="1"] {
  }

  & *[role="row"] {
    height: 100px;
  }

  & *[role="gridcell"] {
    display: flex;
    align-items: center;
    // border-left: none;
    // border-right: none;
    box-shadow: none;

    & > * {
      justify-content: flex-start;
    }
  }
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: space-between;
  gap: 1rem;
`;
const OptionBox = styled.fieldset`
  display: flex;
  gap: 10px;
  border: none;
`;
const OptionBtn = styled<any>(Button)`
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const PaginationWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const TableWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
const RetrieveWrapper = styled.div`
  display: flex;
  flex-direction: row;
  padding-bottom: 10px;
  justify-content: space-between;
`;
