import styled from "@emotion/styled";
import { Box, Button, Select, TextInput } from "@mantine/core";
import { useForm } from "@mantine/form";

import { ItemAutoComplete } from "@/features/ui/autoComplete/item/item-autoComplete";
import { RoutingAutoComplete } from "@/features/ui/autoComplete/routing/routing-autoComplete";

import { consumeType, operationType } from "@/constants/operations";
import { useModal } from "@/features/modal/ModalStackManager";
// import i18n from "@/language/i18n";
import { mutateOperations } from "@/api/operation/useOperationQuery";
import customAlert from "@/features/ui/alert/alert";
import { MoldAutoComplete } from "@/features/ui/autoComplete/mold/mold-autoComplete";
import { handleErrorResponse } from "@/utils/errorMessage";
import { validateFormValues } from "@/utils/validation";
import { DefaultApiOperationsPostRequest } from "@sizlcorp/sizl-api-document/dist/models";
import { useTranslation } from "react-i18next";
import { useMutation, useQueryClient } from "react-query";

export const OperationCreateForm = () => {
  const { t } = useTranslation();
  const lnOperationType = operationType.map((item: any) => ({
    ...item,
    label: t(item.label),
  }));

  const lnConsumeType = consumeType.map((item: any) => ({
    ...item,
    label: t(item.label),
  }));

  const { closeModal } = useModal();
  const queryClient = useQueryClient();

  const { mutate: postMutate } = useMutation(
    (params: DefaultApiOperationsPostRequest) =>
      mutateOperations.create(params).mutationFn(params as DefaultApiOperationsPostRequest | any),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('operations');
      },
    }
  );

  const form = useForm({
    initialValues: {
      itemCode: undefined,
      code: undefined,
      operationType: undefined,
      moldCode: undefined,
      consumeType: undefined,
      routingCode: undefined,
      seq: undefined,
    },
  });

  const onSubmit = async () => {
    try {
      postMutate(
        {
          operationsGetRequest: {
            itemCode: form.values.itemCode as any,
            code: form.values.code as any,
            operationType: form.values.operationType as any,
            moldCode: form.values.moldCode as any,
            consumeType: form.values.consumeType as any,
            routingCode: form.values.routingCode as any,
            seq: form.values.seq as any,
          }
        },
        {
          onSuccess: (res) => {
            closeModal(form.values);
            if (res.status === 200) {
              customAlert(
                "공정 생성에 성공하였습니다.",
                "생성 성공",
                "green"
              )
            } else {
              customAlert(
                "공정 생성에 실패하였습니다.",
                "생성 실패",
                "red"
              )
            }
          },
          onError: (error: any) => {
            customAlert(handleErrorResponse({ error, errorContext: '공정코드' }), '공정 생성 실패', 'red')
          }
        }
      );
    } catch (e) {
      customAlert(
        "공정 생성에 실패하였습니다.",
        "생성 실패",
        "red"
      )
    }
  };

  return (
    <Box w={420} mx="auto">
      <Form>
        <ModalFieldSet>
          <ItemAutoComplete
            withAsterisk
            label="품목코드"
            {...form.getInputProps("itemCode")}
            itemType="PRODUCT"
          />
          <RoutingAutoComplete
            withAsterisk
            label="라우팅코드"
            {...form.getInputProps("routingCode")}
          />
          <TextInput
            withAsterisk
            label="공정코드"
            {...form.getInputProps("code")}
          />
          <Select
            withAsterisk
            label="공정유형"
            data={lnOperationType}
            {...form.getInputProps("operationType")}
          />
          <MoldAutoComplete
            label="금형코드"
            {...form.getInputProps("moldCode")}
          />
          <Select
            withAsterisk
            label="소모 시점"
            data={lnConsumeType}
            {...form.getInputProps("consumeType")}
          />
          <TextInput
            withAsterisk
            label="공정순서"
            {...form.getInputProps("seq")}
          />
          <ModalConfirmBtnBox>
            <Button color="gray" onClick={closeModal}>
              취소
            </Button>
            <Button
              disabled={validateFormValues({
                pageType: "operation",
                values: form.values,
              })}
              onClick={onSubmit}
            >
              공정 생성
            </Button>
          </ModalConfirmBtnBox>
        </ModalFieldSet>
      </Form>
    </Box>
  );
};

const Form = styled.form`
  width: 100%;
  height: 100%;
`;

const ModalFieldSet = styled.fieldset`
  border: none;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const ModalConfirmBtnBox = styled.div`
  padding: 24px 0;
  display: flex;
  justify-content: flex-end;
  gap: 20px;
`;
