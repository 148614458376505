import styled from "@emotion/styled";
import { Button, Checkbox, Input, NumberInput, Select } from "@mantine/core";

// import { OperationsGetRequest } from "@sizlcorp/sizl-api-document/dist/models";
import { theme } from "@/styles/theme";
import { IconTrashX } from "@tabler/icons-react";

const Tr = styled.tr`
  display: flex;
  align-items: flex-start;
  align-self: stretch;
  height: 100%;
  width: 100%;
`;

const Td = styled.td<{ width?: number }>`
  display: flex;
  width: ${(props) => (props.width ? `${props.width}%` : "auto")};
  padding: 7px 10px;
  align-items: flex-start;
  gap: 10px;
`;

const TdMold = styled(Td)`
  flex: 1 0 0;
`;

const DeleteButton = styled<any>(Button)`
  color: black;
  background-color: ${theme.colors?.white};
  font-size: 14px;

  &:hover {
    background-color: ${theme.colors?.blue?.[0]};
  }
`;

export const InspectCriteriaCreateRow = ({ formIndex, formData }: any) => {
  const handleClickDelete = (index: number) => {
    formData.removeListItem("criteria", index);
  };

  return (
    <>
      <Tr key={formIndex}>
        <Td width={7}>{formIndex + 1}</Td>
        <Td width={25}>
          <Input {...formData.getInputProps(`criteria.${formIndex}.name`)} />
        </Td>
        <Td width={20}>
          <Select
            data={[{ value: "boolean", label: "체크" }]}
            {...formData.getInputProps(`criteria.${formIndex}.type`)}
          ></Select>
        </Td>
        <Td width={14}>
          <Checkbox
            label="체크시 합격"
            checked={formData.values.criteria[formIndex].standard}
            {...formData.getInputProps(`criteria.${formIndex}.standard`)}
          />
        </Td>
        <Td width={10}>
          <NumberInput
            min={1}
            defaultValue={1}
            {...formData.getInputProps(`criteria.${formIndex}.sampleNum`)}
          />
        </Td>
        <Td width={12}>
          <Input
            disabled
            {...formData.getInputProps(`criteria.${formIndex}.min`)}
          />
        </Td>
        <Td width={12}>
          <Input
            disabled
            {...formData.getInputProps(`criteria.${formIndex}.max`)}
          />
        </Td>
        <Td width={12}>
          <DeleteButton
            leftIcon={<IconTrashX />}
            disabled={!(formData.values.criteria?.length > 1)}
            onClick={() => handleClickDelete(formIndex)}
          >
            삭제
          </DeleteButton>
        </Td>
      </Tr>
    </>
  );
};
