import { useModal } from "@/features/modal/ModalStackManager";
import { theme } from "@/styles/theme";
import styled from "@emotion/styled";
import { Button } from "@mantine/core";
import { IconPlus, IconX } from "@tabler/icons-react";
import { EventHandler, useEffect, useState } from "react";
import { CustomSorterForm } from "./CustomSorterForm";

interface InputItemProps {
  id: string;
  values: {
    name: string;
    label: string;
    type: string;
  };
}

interface sorterProps {
  sorterType: { label: string; value: string; category: string }[];
  setSort: any;
  sort?: string[];
}

export const CustomSorter = ({ sorterType, setSort, sort }: sorterProps) => {
  const { openModal, closeModal } = useModal();
  const [inputs, setInputs] = useState<any[]>([]);
  const [inputLength, setInputLength] = useState<number | any>();

  useEffect(() => {
    if (sort) {
      const newInputs = sort.map((sorting, index) => {
        const type = sorting.startsWith("-") ? "-" : "";
        const name = sorting.replace(/^-/, "");
        const label = type === "-" ? "내림차순" : "오름차순";
        return { id: index + 1, values: { name, label, type } };
      });
      setInputs(newInputs);
    }
  }, [sort]);

  // sorter를 생성할 때 임의의 ID값을 추가
  const onCloseHandler: EventHandler<any> = (values) => {
    closeModal(values);
    setInputs((prevSorterList: any[]) => {
      const newId = prevSorterList.length + 1;
      return [...prevSorterList, { id: newId, values }];
    });
  };

  // filter 핸들러
  const SorterHandler = (i: any) => {
    setInputLength(i);
  };

  useEffect(() => {
    setInputLength(inputLength);
  }, [inputs[inputLength]?.values.name]);

  // 만들어진 sorter 닫기
  const SorterCloseHandler = (id: any) => {
    const inputData = inputs.find((item: any) => item.id === id);
    const fieldKey = inputData.values.type.toString() + inputData.values.name.toString();
    setInputs(inputs.filter((item: any) => item.id !== id));

    setSort((prevArray: string[]) => {
      const newArray = prevArray.filter((item: string) => item !== fieldKey);
      return newArray;
    });
  };

  const addSorterHandler: React.MouseEventHandler<HTMLButtonElement> = (e) => {
    e.preventDefault();
    openModal(
      <CustomSorterForm sorterType={sorterType} onSubmit={onCloseHandler} />,
      null,
      "사용자 정의 필터",
      true
    ).then((result) => {
      handleFilterChange(result);
    });
  };

  // 필터 정보 객체로 만들어 부모 컴포넌트에서 전달 받은 query 상태 변경
  const handleFilterChange = (newSort: InputItemProps["values"]) => {
    // 바깥 영역 클릭 시 모달 닫기
    if (!newSort || !newSort.name) return;

    const sorting = newSort.type + newSort.name;

    setSort((prevArray: string[]) => {
      const newArray = [
        ...prevArray,
        sorting
      ];
      return [...newArray];
    });
  };

  return (
    <SorterContainer>
      {inputs &&
        inputs?.map((v: InputItemProps, i: any) => {
          const target = sorterType.find(
            (item) => item.value === v.values.name
          );
          const name = target ? target.label : null;
          return (
            <SorterButton
              rightIcon={
                <IconX onClick={() => SorterCloseHandler(v.id)} size="1rem" />
              }
              onClick={() => SorterHandler(i)}
              key={v.values.name}
            >
              {`"${name}" : ${v.values.label} 정렬`}
            </SorterButton>
          );
        })}
      <AddSorterBtn
        variant="subtle"
        color="dark"
        leftIcon={<IconPlus size="1rem" />}
        onClick={(e: any) => addSorterHandler(e)}
      >
        {"정렬 추가"}
      </AddSorterBtn>
    </SorterContainer>
  );
};

const SorterContainer = styled.div`
  width: auto;
  height: auto;
  display: flex;
`;

const AddSorterBtn = styled<any>(Button)`
  // color: var(--black-0, #000000);
  // background-color: ${theme.colors?.white};
`;

const SorterButton = styled<any>(Button)`
  font-size: 14px;
  // background-color: ${theme.colors?.white};
  // color: ${theme.colors?.dark?.[8]};
  border-radius: 200px;
  // border: 1px solid ${theme.colors?.dark?.[8]};
  margin: 0 8px;
`;
