import { DefaultInstance } from "@/instance/axios";
import { EquipmentsGet200Response } from "@sizlcorp/sizl-api-document/dist/models";
import { AxiosResponse } from "axios";
import dayjs from "dayjs";
import { useQuery } from "react-query";

// 추후에 쿼리KEY를 관리할 예정이다.

export const WORK_BY_EQUIPMENT_QUERY_KEY = "/workByEquipment";

const getWorkByEquipmentFetch = ({
  scheduledAt,
  defectCodes,
  activePage,
}: any) => {
  return DefaultInstance.equipmentsGet(
    {
      page: activePage ?? 1,
      populate: ["works", 'purchaseOrderItem'],
      sort: "seq",
    },
    {
      params: {
        scheduledAt:
          dayjs(scheduledAt).toISOString() ?? dayjs(new Date()).format(),
        // 백에서 직렬화 처리 해줌
        defectCodes:
          defectCodes?.length > 0
            ? defectCodes?.map((data: any) => data.code)
            : [null],
      },
    }
  );
};

const useWorkByEquipmentQuery = (
  queryType: "workByEquipment",
  params?: any
) => {
  let fetcherFunction: any;

  switch (queryType) {
    case "workByEquipment":
      fetcherFunction = getWorkByEquipmentFetch;
      break;
    default:
      throw new Error("Invalid mutation type");
  }

  return useQuery<AxiosResponse<EquipmentsGet200Response, any>>({
    queryKey: [
      WORK_BY_EQUIPMENT_QUERY_KEY,
      params.scheduledAt,
      params.activePage,
      params.defectCodes,
    ],
    queryFn: () => fetcherFunction(params),
  });
};

export default useWorkByEquipmentQuery;
