import styled from "@emotion/styled";

interface ActionHeaderProps {
  children: React.ReactNode;
}

export const ActionHeader = ({ children }: ActionHeaderProps) => {
  return <Wrapper>{children}</Wrapper>;
};

const Wrapper = styled.div`
  display: flex;
  padding: 8px;
  justify-content: space-between;
  align-items: flex-start;
  align-content: flex-start;
  row-gap: 16px;
  align-self: stretch;
  flex-wrap: wrap;
`;
