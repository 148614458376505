import { theme } from "@/styles/theme";
import styled from "@emotion/styled";

interface DetailWrapperProps {
    children: React.ReactNode;
}

export const DetailWrapper = ({ children } : DetailWrapperProps) => {
    return (
        <Wrapper>{children}</Wrapper>
    )
};

const Wrapper = styled.div`
    padding : 8px;
    background-color: ${theme.colors?.gray?.[2]};
`