import DefaultInstance from "@/instance/axios";
import { ItemsGet200ResponseRowsInnerOperationsInner } from "@sizlcorp/sizl-api-document/dist/models";
import { AxiosResponse } from "axios";
import { useQuery } from "react-query";

export type OperationOption = {
  value: string;
  label: string;
  group: string;
};

const getAutoCompleteKeyword = (keyword: string) =>
  DefaultInstance.operationsFindPost({
    search: keyword,
    searchFields: ["code", "name"],
  }).then(
    (res: AxiosResponse<ItemsGet200ResponseRowsInnerOperationsInner[]>) =>
      res.data
  );

export const useOperationsAutoComplete = (
  focus = false,
  keyword = "",
  options?: {
    onSuccess: (data: OperationOption[]) => void;
  }
) => {
  return useQuery(
    ["operations-get-autocomplete", keyword],
    () => getAutoCompleteKeyword(keyword),
    {
      enabled: focus,
      select: (data: ItemsGet200ResponseRowsInnerOperationsInner[]) =>
        data?.map((operation) => {
          return {
            value: String(operation.code),
            label: String(operation.code), // operation.itemCode
            itemCode: String(operation.itemCode),
            group: String(operation.routingCode),
          };
        }) ?? [],
      onSuccess: options?.onSuccess,
    }
  );
};

const getOperationsByCode = (code: string | null) =>
  DefaultInstance.operationsFindPost({
    ...(code && { search: code }),
    searchFields: ["code"],
  }).then(
    (res: AxiosResponse<ItemsGet200ResponseRowsInnerOperationsInner[]>) =>
      res.data
  );

export const useOperationsCode = (
  enabled: boolean,
  code: string | null,
  options?: {
    onSuccess: (data?: OperationOption[]) => void;
  }
) => {
  return useQuery(
    ["operations-get-code", code],
    () => getOperationsByCode(code),
    {
      enabled: enabled ?? false,
      select: (data: ItemsGet200ResponseRowsInnerOperationsInner[]) =>
        data?.map((operation) => {
          return {
            value: String(operation.code),
            label: String(operation.code), // operation.itemCode
            group: String(operation.routingCode),
            itemCode: String(operation.itemCode),
          };
        }) ?? [],
      onSuccess: options?.onSuccess,
    }
  );
};
