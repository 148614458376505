import { useEffect, useState } from 'react';

// useAutoResetState라는 커스텀 훅을 생성
const useAutoResetState = <T>(initialValue: T, delay: number): [T, React.Dispatch<React.SetStateAction<T>>] => {
    const [state, setState] = useState<T>(initialValue);

    useEffect(() => {
        const timer = setTimeout(() => {
            setState(initialValue);
        }, delay);

        // 컴포넌트 언마운트 시 타이머 클리어
        return () => clearTimeout(timer);
    }, [state, initialValue, delay]);

    return [state, setState];
}

export default useAutoResetState;
