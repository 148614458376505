import { useModal } from "@/features/modal/ModalStackManager";
import styled from "@emotion/styled";
import { Button } from "@mantine/core";
import { IconEdit, IconTrash } from "@tabler/icons-react";


export const ProductionPlanDetailActionHeader = (
) => {
    
  const { openModal } = useModal();

  return (
    <ActionBarContainer>
      <LeftActionBar>
        <Button
          onClick={() =>
            openModal("", "", "")
          }
        >
          액션
        </Button>
        <Button
          variant="outline"
          onClick={() => 
            // openModal(<LotTrackingForward />, "", "")
            {}
          }
        >
            로트추적 (정방향)
        </Button>
      </LeftActionBar>
      <RightActionBar>
        <Button rightIcon={<IconEdit />} color="orange">
          수정
        </Button>
        <Button
            rightIcon={<IconTrash />}
            color="red"
        >
            삭제
        </Button>
      </RightActionBar>
    </ActionBarContainer>
  );
};

const ActionBarContainer = styled.div`
  display: flex;
  padding: 8px 0;
  justify-content: space-between;
  align-items: flex-start;
  align-content: flex-start;
  row-gap: 16px;
  align-self: stretch;
  flex-wrap: wrap;
  background: inherit;
`;

const LeftActionBar = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 8px;
`;

const RightActionBar = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  gap: 8px;
`;
