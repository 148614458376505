import { Select } from "@mantine/core";

interface FormSelectProps {
  defaultValue: string;
  data: string[];
  label: string;
}

export const FormSelect = ({ defaultValue, data, label }: FormSelectProps) => {
  return <Select data={data} defaultValue={defaultValue} label={label} />;
};
