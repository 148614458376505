import { theme } from "@/styles/theme";
import styled from "@emotion/styled";
import { Tabs } from "@mantine/core";

import { ReactNode } from "react";

interface WorkTabProps {
  children: ReactNode;
}

export const WorkTab = ({ children }: WorkTabProps) => {
  return (
    <WorkTabContainer>
      <Tabs
        variant="outline"
        defaultValue="operationInfo" // productionHistory
        styles={(theme) => ({
          tab: {
            "&:not([data-active])": {
              backgroundColor: theme.colors?.gray?.[0],
            },
          },
        })}
      >
        <Tabs.List>
          <LeftSpace />
          <Tabs.Tab value="operationInfo">공정 정보</Tabs.Tab>
          <Tabs.Tab value="inputLot">투입 로트</Tabs.Tab>
          <Tabs.Tab value="productionInventory">생산 가능 재고</Tabs.Tab>
          <Tabs.Tab value="productionHistory">생산 기록</Tabs.Tab>
          <Tabs.Tab value="inspectRecord">검사 기록</Tabs.Tab>
          <Tabs.Tab value="statusHistory">가동 기록</Tabs.Tab>
          <Tabs.Tab value="statusTimeLine">가동 시간</Tabs.Tab>
          {/* <Tabs.Tab value="inventory">미투입 선차감 재고 현황</Tabs.Tab> */}
          <RightSpace />
        </Tabs.List>
        {children}
      </Tabs>
    </WorkTabContainer>
  );
};

const WorkTabContainer = styled.div`
  width: 100%; //80rem;
`;

const LeftSpace = styled.span`
  width: 32px;
  align-self: stretch;
  background: ${theme.colors?.gray?.[3]};
  border-bottom: ${theme.colors?.gray?.[3]} solid 1px;
  opacity: 0.2;
`;

const RightSpace = styled.span`
  flex: 1 0 0;
  align-self: stretch;
  background: ${theme.colors?.gray?.[3]};
  border-bottom: ${theme.colors?.gray?.[3]} solid 1px;
  opacity: 0.2;
`;
