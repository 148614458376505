import styled from "@emotion/styled";

interface BadgeBoxProps {
    children: React.ReactNode;
}

export const BadgeBox = (params: BadgeBoxProps) => {
    const { children } = params;

    return (
        <Wrapper>
            {children}
        </Wrapper>
    )
}

const Wrapper = styled.div`
    display : flex;
    justify-content : flex-end;
    align-items : center;
    flex : 1 0 0;
    align-self: stretch;
`