import { MasterInstance } from "@/instance/axios";
import { createMutationKeys, createQueryKeys, mergeQueryKeys } from "@lukemorales/query-key-factory";
import { MasterApiMoldsFindPostRequest, MasterApiMoldsGetRequest, MasterApiMoldsMoldIdDeleteRequest, MasterApiMoldsMoldIdGetRequest, MasterApiMoldsMoldIdPutRequest, MasterApiMoldsPostRequest } from "@sizlcorp/sizl-api-document/dist/models";


export const molds = createQueryKeys('molds', {
    all : null,
    get : (params: MasterApiMoldsGetRequest) => {
        const query = {...params}; // params 객체를 복사합니다.
        // params.query.$and가 배열이고 길이가 0보다 클 때만 query.query를 설정합니다.
        if (Array.isArray(params.query.$and) && params.query.$and.length > 0) {
            query["query"] = JSON.stringify(params.query);
        }
        // params.search가 있을 때만 query.search와 query.searchFields를 설정합니다.
        if (params.search) {
            query["search"] = params.search;
            query["searchFields"] = params.searchFields;
        }
        return {
            queryKey : [query, JSON.stringify(query.query)],
            queryFn : () => MasterInstance.moldsGet(query),
        };
    },
    detail : (params : MasterApiMoldsMoldIdGetRequest) => ({
        queryKey : [params.moldId],
        queryFn : () => MasterInstance.moldsMoldIdGet(params)
    }),
    find : (params: MasterApiMoldsFindPostRequest) => {
        const query = {...params}; // params 객체를 복사합니다.
        // params.query.$and가 배열이고 길이가 0보다 클 때만 query.query를 설정합니다.
        if (Array.isArray(params.query.$and) && params.query.$and.length > 0) {
            query["query"] = JSON.stringify(params.query);
        }
        // params.search가 있을 때만 query.search와 query.searchFields를 설정합니다.
        if (params.search) {
            query["search"] = params.search;
            query["searchFields"] = params.searchFields;
        }
        return {
            queryKey : [query, JSON.stringify(query.query)],
            queryFn : () => MasterInstance.moldsFindPost(query),
        };
    },
});

export const mutateMolds = createMutationKeys('molds', {
    create : (params : MasterApiMoldsPostRequest) => ({
        mutationKey : [params.moldsGetRequest],
        mutationFn : () => MasterInstance.moldsPost(params),
    }),
    update : (params : MasterApiMoldsMoldIdPutRequest) => ({
        mutationKey : [params.moldId, params.moldsGetRequest],
        mutationFn : () => MasterInstance.moldsMoldIdPut(params)
    }),
    delete : (params : MasterApiMoldsMoldIdDeleteRequest) => ({
        mutationKey : [params.moldId],
        mutationFn : () => MasterInstance.moldsMoldIdDelete(params)
    }),
});

export const moldsQueryKeys = mergeQueryKeys(molds, mutateMolds);