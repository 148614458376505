import {
    MantineReactTable,
    useMantineReactTable,
    // createRow,
    type MRT_ColumnDef
} from 'mantine-react-table';
import { useMemo } from 'react';

import { ItemsDetailForm } from '@/features/item/components/form/ItemsDetailForm';
import { useModal } from '@/features/modal/ModalStackManager';
import { MoldDetailForm } from '@/features/mold/form/moldDetailForm';
import { OperationDetailForm } from '@/features/operation/components/form/OperationDetailForm';
import { RoutingDetailForm } from '@/features/routings/components/form/routingDetailForm';
import { Anchor, Badge, DefaultMantineColor, Flex } from '@mantine/core';
import { useTranslation } from 'react-i18next';
import { ProductionPlanFormProps } from '../../view';

export interface Row {
    operationData: {
        id: number | any;
        moldCode: string | any;
        routingCode: string | any;
        seq: number | any;
        itemCode: string | any;
        code: string | any;
        operationType: string | any;
        consumeType: string | any;
        routing: {
            name: string | any;
        }
    };
}

export const OperationTabs = ({ data }: ProductionPlanFormProps) => {

    const { openModal } = useModal();

    const { t } = useTranslation();

    const operationTypeColor = {
        "NORMAL": "green.7",
        "ASSEMBLE": "blue.7",
        "EXTERNAL": "yellow.7",
    }

    const columns = useMemo<MRT_ColumnDef<Row>[]>(
        () => [
            {
                accessorFn: (row) =>
                    <Anchor
                        onClick={() => {
                            openModal(
                                <ItemsDetailForm itemCode={row.operationData.itemCode} />,
                                null,
                                "품목 상세"
                            )
                        }}
                    >
                        {row.operationData.itemCode}
                    </Anchor>,
                accessorKey: 'itemCode',
                header: '품목코드',
                size: 80,
            },
            {
                accessorFn: (row) => row.operationData.routing.name,
                accessorKey: 'routingCode',
                header: '라우팅명',
                size: 120,
                mantineEditTextInputProps: {
                    type: 'string',
                    required: true,
                },
                Cell: (rows) => {
                    return (
                        <Anchor
                            onClick={() => {
                                openModal(
                                    <RoutingDetailForm routingCode={rows.row._valuesCache.routingCode} />,
                                    null,
                                    ""
                                )
                            }}
                        >
                            {rows.row.original.operationData.routing.name}
                        </Anchor>
                    )
                }
            },
            {
                accessorFn: (row) => row.operationData.code,
                accessorKey: 'operationCode',
                header: '공정코드',
                size: 120,
                mantineEditTextInputProps: {
                    type: 'string',
                    required: true,
                },
                Cell: (rows) => {
                    return (
                        <Anchor
                            onClick={() => openModal(<OperationDetailForm operactionCode={rows.row.original.operationData.code} />, null, "")}
                        >
                            {rows.row.original.operationData?.code}
                        </Anchor>
                    )
                },
            },
            {
                accessorFn: (row) => t(row.operationData.operationType),
                accessorKey: 'operationType',
                header: '공정타입',
                size: 120,
                mantineEditTextInputProps: {
                    type: 'string',
                    required: true,
                },
                Cell: (rows) => {
                    const operationType = rows.row._valuesCache.operationType as keyof typeof operationTypeColor;

                    return (
                        <Badge color={operationTypeColor[operationType] as DefaultMantineColor}>
                            {t(rows.row._valuesCache.operationType)}
                        </Badge>
                    )
                }
            },
            {
                accessorFn: (row) => row.operationData.moldCode,
                accessorKey: 'moldCode',
                header: '사용 금형',
                size: 120,
                mantineEditTextInputProps: {
                    type: 'string',
                    required: true,
                },
                Cell: (rows) => {
                    return (
                        <Flex direction="column">
                            {rows.row._valuesCache.moldCode && <Badge size="sm" w={40}>금형</Badge>}
                            <Anchor
                                onClick={() => openModal(
                                    <MoldDetailForm moldCode={rows.row._valuesCache.moldCode} />,
                                    null,
                                    ""
                                )}
                            >
                                {rows.row._valuesCache.moldCode}
                            </Anchor>
                        </Flex>
                    )
                }
            },
            {
                accessorFn: (row) => t(row.operationData.consumeType),
                accessorKey: 'consumeType',
                header: '소모 시점',
                size: 120,
                mantineEditTextInputProps: {
                    type: 'string',
                    required: true,
                },
            },
            {
                accessorFn: (row) => row.operationData.seq,
                accessorKey: 'seq',
                header: '공정순서',
                size: 120,
                mantineEditTextInputProps: {
                    type: 'string',
                    required: true,
                },
                Cell: (rows) => {
                    return (
                        <div style={{ textAlign: 'right' }}>{rows.row._valuesCache.seq}</div>
                    )
                }
            },
        ],
        [],
    );

    const worksData: Row[] = data && Array.isArray(data.works) ? data.works.map(row => {
        if (Array.isArray(row) && row.length > 0) {
            return {
                ...row,
            };
        } else {
            return row;
        }
    }) as Row[] : [];

    const table = useMantineReactTable({
        columns,
        data: worksData,
        editDisplayMode: 'modal', //default ('row', 'cell', 'table', and 'custom' are also available)
        enableEditing: false,
        enableToolbarInternalActions: false, // 우측상단 아이콘 안보이게
        enablePagination: false,
    });

    return <MantineReactTable table={table} />;
};
